import React, { useState, useEffect } from "react";
import {
  deleteRealStates,
  getRealStates,
  searchRealStatesByCode,
  searchRealStatesByStatus,
} from "./../../services/apiRealState";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import UsefulContext from "./../../../ultis/usefulProvider";
import moment from "moment";
import Alert from "sweetalert2";
const XLSX = require("xlsx");
import LoadingComponent from "../Loading/LoadingComponent";

import FixedHeaderStory from "react-data-table-component";

const PropertyListing = ({ history }) => {
  const { userProfiles } = React.useContext(UsefulContext);

  const [imoveis, setImoveis] = useState([]);
  const [dataValue, setDataValue] = useState([]);
  const [queryCode, setQueryCode] = useState("");
  const [QueryButtom, setQueryButtom] = useState("");
  const [queryStatus, setQueryStatus] = useState("");

  const [loading, setLoading] = useState(false);

  const [queryOwner, setQueryOwner] = useState("");
  const [queryZipCode, setQueryZipCode] = useState("");
  const [queryAddress, setQueryAddress] = useState("");
  const [inputFilter, setInputFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");


  const columns = [
    {
      name: "Editar",
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle
            className="p-2"
            style={{
              borderRadius: "6px",
            }}
          >
            <i className="flaticon-381-settings-6"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/* {userProfiles?.editProperty && ( */}
            <Dropdown.Item onClick={() => onEdit(row)}>Editar</Dropdown.Item>
            {/* )} */}
            {/* {userProfiles?.addProperties && ( */}
            <Dropdown.Item onClick={() => onDuplicate(row)}>Duplicar</Dropdown.Item>
            {/* )} */}
            {/* {userProfiles?.deleteProperties && ( */}
            <Dropdown.Item onClick={() => onDelete(row)}>Excluir</Dropdown.Item>
            {/* )} */}
            <Dropdown.Item onClick={() => copyAddress(row?.address)}>Copiar endereço</Dropdown.Item>

          </Dropdown.Menu>
        </Dropdown>
      ),
    },
    {
      name: "Código",
      wrap: true,
      sortable: true,
      selector: (row) => row.code,
    },
    {
      name: "Área Terreno",
      wrap: true,
      sortable: true,
      selector: (row) => row.landAreaNumber,
    },
    {
      name: "Área Construída",
      wrap: true,
      sortable: true,
      selector: (row) => row.buildingAreaNumber,
    },
    {
      name: "Área Galpão",
      wrap: true,
      sortable: true,
      selector: (row) => row.shedAreaNumber,
    },
    {
      name: "Cidade",
      selector: (row) => row.city,
      wrap: true,
      sortable: true,
      format: (row) => `${row.city?.slice(0, 1000)}`,
    },
    {
      name: "Endereço",
      selector: (row) => row.address,
      wrap: true,
      sortable: true,
      format: (row) => `${row.address?.slice(0, 1000)}`,
    },
    {
      name: "Cep",
      wrap: true,
      sortable: true,
      selector: (row) => row.zipCode,
    },
    {
      name: "Valor Locação",
      wrap: true,
      sortable: true,
      selector: (row) => row.leaseValueNumber,
    },
    {
      name: "Valor Venda",
      wrap: true,
      sortable: true,
      selector: (row) => row.saleValueNumber,
    },
    {
      name: "Status",
      selector: (row) => row.approvedStatus,
      wrap: true,
      sortable: true,
      format: (row) => `${row.approvedStatus?.slice(0, 1000)}`,
    },
    {
      name: "Proprietário",
      selector: (row) => row.ownerAdministrator,
      wrap: true,
      sortable: true,
      format: (row) => `${row.ownerAdministrator?.slice(0, 1000)}`,
    },
  ];

  const customStyles = {
    table: {
      style: {
        minHeight: "600px", // override the row height
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };

  const copyAddress = (address) => {
    navigator.clipboard.writeText(address);
  }

  useEffect(() => {
    if (queryAddress === "") {
      loadData();
    } else {
      _getRealStatesAddress(queryAddress);
    }
  }, [queryAddress]);

  useEffect(() => {
    if (queryZipCode === "") {
      loadData();
    } else {
      _getRealStatesZipCode(queryZipCode);
    }
  }, [queryZipCode]);

  useEffect(() => {
    if (queryOwner === "") {
      loadData();
    } else {
      _getRealStatesOwner(queryOwner);
    }
  }, [queryOwner]);

  useEffect(() => {
    if (queryStatus === "") {
      loadData();
    } else {
      _getRealStatesStatus(queryStatus);
    }
  }, [queryStatus]);

  useEffect(() => {
    if (queryCode === "") {
      loadData();
    } else {
      _getRealStatesCode(queryCode);
    }
  }, [queryCode]);

  useEffect(() => {
    if (QueryButtom === "") {
      loadData();
    } else {
      _getRealStatesCode(QueryButtom);
    }
  }, [QueryButtom]);

  useEffect(() => {
    moment.locale("pt-br");
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const _result = await getRealStates();
      const dataData = [];

      if (_result?.response) {

        setLoading(false);
        return;
      } else {

        var _filtered = _result?.data?.filter((elem) => {
          setLoading(false);
          return !elem?.code?.startsWith("TC");
        });


        if (_filtered?.length) {
          setImoveis(
            _filtered?.sort((a, b) => {
              return  Number(b.code.slice(b.code.length - 4)) - Number(a.code.slice(a.code.length - 4))
            })
          );
        }

        const replaceValue = (_value, type) => {
          if (_value && _value > 0) {
            if(type === 'currency')
            return _value.toLocaleString("pt-BR",{style: 'currency', currency: 'BRL'});
            else if(type === 'decimal')
            return _value.toLocaleString("pt-BR");

          } else {
            return "";
          }
        };
        if(_result.data){
          for (let i = 0; i < _result.data.length; i++) {
            dataData.push({
              _id: _result.data[i]?._id,
              code: _result.data[i]?.code,
              landAreaNumber: replaceValue(_result.data[i]?.landAreaNumber,'decimal'),
              buildingAreaNumber: replaceValue(_result.data[i]?.buildingAreaNumber,'decimal'),
              shedAreaNumber: replaceValue(_result.data[i]?.shedAreaNumber,'decimal'),
              city: _result.data[i]?.city,
              address: _result.data[i]?.address + " - " + _result.data[i]?.number,
              leaseValueNumber: _result.data[i]?.leaseValueNumber ? replaceValue(_result.data[i]?.leaseValueNumber,'currency'): '',
              saleValueNumber: _result.data[i]?.saleValueNumber ?  replaceValue(_result.data[i]?.saleValueNumber,'currency'): '',
              zipCode: replaceCep(_result.data[i]?.zipCode),
              approvedStatus: getStatusDescription(_result.data[i]?.approvedStatus),
              ownerAdministrator: _result.data[i]?.ownerAdministrator ? _result.data[i]?.ownerAdministrator : _result.data[i]?.owner ?  _result.data[i]?.owner : '',

            });
          }
        setDataValue(dataData.filter(compareToFilter).sort((a, b) => {
          return  Number(b.code.slice(b.code.length - 4)) - Number(a.code.slice(a.code.length - 4))
        }));
        setLoading(false);

        }

      }
    } catch (error) {
      setLoading(false);
    }
  };

  const replaceCep = (_value) => {
    if(_value){
      _value = _value.replace(/\D/g, "");
      _value = _value.replace(/^(\d{5})(\d)/, "$1-$2");
      return _value;
    } else {
      return '-';
    } 
  };

  const _getRealStatesCode = () => {
    try {
      searchRealStatesByCode(queryCode)
        .then((res) => {
          if (res.data.length === 0) {
            Alert.fire({
              title: "Atenção",
              text: "Não existe imóvel com o Código informado.",
            });
          } else {
            setImoveis(res.data);
          }
        })
    } catch (error) {
      console.log(error);
    }
  };

  const _getRealStatesOwner = () => {
    try {
      searchRealStatesByOwner(queryOwner)
        .then((res) => {
          if (res.data.length === 0) {
            Alert.fire({
              title: "Atenção",
              text: "Não existe imóvel com o Proprietário informado.",
            });
          } else {
            setImoveis(res.data);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  const _getRealStatesZipCode = () => {
    try {
      searchRealStatesByZipCode(queryZipCode)
        .then((res) => {
          if (res.data.length === 0) {
            Alert.fire({
              title: "Atenção",
              text: "Não existe imóvel com o CEP informado.",
            });
          } else {
            setImoveis(res.data);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  const _getRealStatesAddress = () => {
    try {
      searchRealStatesByAddress(queryAddress)
        .then((res) => {
          if (res.data.length === 0) {
            Alert.fire({
              title: "Atenção",
              text: "Não existe imóvel com o Endereço informado.",
            });
          } else {
            setImoveis(res.data);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  const _getRealStatesStatus = () => {
    try {
      searchRealStatesByStatus(queryStatus)
        .then((res) => {
          if (res.data.length === 0) {
            Alert.fire({
              title: "Atenção",
              text: "Não existe imóvel com o Status informado.",
            });
          } else {
            setImoveis(res.data);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  const onEdit = (props) => {
    history.push({
      pathname: "/general-register",
      state: { data: props, duplicate: false },
    });
  };

  const onDetails = (props) => {
    window.open(`/property-details/${props._id}`);
  };

  const onDuplicate = (props) => {
    history.push({
      pathname: "/general-register",
      state: { data: props, duplicate: true },
    });
  };

  const onDelete = (data) => {
    Alert.fire({
      title: "Atenção",
      text: "Deseja excluir esse imóvel?",
      confirmButtonText: "Excluir",
      confirmButtonColor: "red",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRealStates(data._id, data).then((result) => {
          loadData();
        });
      }
    });
  };

  const getStatusDescription = (key) => {
    if(key){
      switch (key) {
        case "approved":
          return "Aprovado";
        case "waiting":
          return "Aguardando aprovação";
        case "disapproved":
          return "Reprovado";
        case "incompleted":
          return "Incompleto";
        case "findOwner":
          return "Encontrar proprietário";
        default:
          return "Outro";
      }
    }  
  };

  // Salvar imóveis cadastrados em Planilha xlsx
  const saveToPlan = () => {
    let arrayImoveis = XLSX.utils.json_to_sheet(imoveis);
    let arquivo = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(arquivo, arrayImoveis, "Imoveis Cadastrados");
    XLSX.writeFile(arquivo, "Imoveis.xlsx");
  };

  const saveToPlanFilter = () => {
    let arrayImoveis = XLSX.utils.json_to_sheet(dataValue);
    let arquivo = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(arquivo, arrayImoveis, "Imoveis Filtrados");
    XLSX.writeFile(arquivo, "ImoveisFiltrados.xlsx");
  };


  const compareToFilter = (value) => {
 let retorno = false
      if(inputFilter) {
        let filter =  [inputFilter]
          if(value.code?.toUpperCase()?.includes(inputFilter.toUpperCase()) ||
          value.address?.toUpperCase().includes(inputFilter.toUpperCase()) ||
          value.cep?.toUpperCase().includes(inputFilter.toUpperCase()) ||
          value.ownerAdministrator?.toUpperCase().includes(inputFilter.toUpperCase()) ||
          value.status?.toUpperCase().includes(inputFilter.toUpperCase()) ||
          value.city?.toUpperCase().includes(inputFilter.toUpperCase()) ||
          value.landAreaNumber?.toLocaleString("pt-BR")?.includes(inputFilter) ||
          value.buildingAreaNumber?.toLocaleString("pt-BR")?.includes(inputFilter) ||
          value.shedAreaNumber?.toLocaleString("pt-BR")?.includes(inputFilter)
              ){    
            retorno = true
          } else {  
            retorno =  false
          }
      } else {
        retorno =  true
      }
  if(retorno){
    if(value.code?.toUpperCase()?.includes('TC-')){
      retorno = false
    }
    if(value.approvedStatus !== statusFilter && statusFilter){
      retorno = false
    }
  }
    return retorno
  }

  return (
    <div>
      <div className="form-head page-titles">
        <div className="row d-flex align-items-center">
          <div className="col-md-4">
            <h4 className="text-black font-w6000">Imóveis</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to="/property-listing">Imóveis</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/property-listing">Imóveis cadastrados</Link>
              </li>
            </ol>
          </div>
        </div>

        {/* {userProfiles?.addProperties && ( */}
        <div className="row d-flex align-items-center mt-5 mb-5 ml-1">
         
          <div className="col-md-4">
            <input
              id="setQueryCode"
              value={inputFilter}
              onChange={e => setInputFilter(e.target.value)}
              className="form-control input-rounded-white"
              placeholder="Filtrar"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  loadData();
                }
              }}
            />
          </div>

          <div className="col-md-2 ml-2">
            <select
              id="setQueryStatus"
              className="form-control input-rounded-white"
              placeholder="Status"
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="">Selecione</option>
              <option value="Aprovado">Aprovado</option>
              <option value="Aguardando aprovação">Aguardando aprovação</option>
              <option value="Reprovado">Reprovado</option>
              <option value="Incompleto">Incompleto</option>
              <option value="Encontrar proprietário">Encontrar proprietário</option>
              <option value="Outro">Outro</option>
            </select>
          </div>

          <div className="col-md-1">
            <button
              style={{
                color: "#fff",
                backgroundColor: "#144b72",
              }}
              className="btn btn-primary rounded w-100"
              onClick={loadData}
            >
              <i class="fal fa-search"></i>
            </button>
          </div>

          <div className="col-md-4">
          {userProfiles?.addProperties && (

          <Link
            to="/general-register"
            style={{
              color: "#fff",
              backgroundColor: "#144b72",
            }}
            className="btn btn-primary rounded  mr-3"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>

            Imóvel
          </Link>
          )}
          {userProfiles?.exportProperties && (
            <>
          <button
            style={{
              color: "#fff",
              backgroundColor: "#144b72",
            }}
            className="btn btn-primary rounded  mr-3"
            onClick={saveToPlan}
          >
            <i class="fa fa-download" aria-hidden="true"></i>

          Todos
          </button>

          <button
          style={{
            color: "#fff",
            backgroundColor: "#144b72",
          }}
          className="btn btn-primary rounded  mr-3"
          onClick={saveToPlanFilter}
          >
                        <i class="fa fa-download" aria-hidden="true"></i>
          Filtrados
          </button>
          </>
          )}

          </div>
        </div>
      </div>

      {loading ? (
        <LoadingComponent />
      ) : (
        <FixedHeaderStory
          columns={columns}
          data={dataValue}
          fixedHeader
          pagination
          responsive
          customStyles={customStyles}
          fixedHeaderScrollHeight="600px"
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
          noDataComponent={"Nenhuma informação foi encontrado"}
          paginationComponentOptions={paginationComponentOptions}
          onRowClicked={(row) =>onDetails(row)}
        />
      )}
    </div>
  );
};

export default PropertyListing;
