import React from "react";
import "../../../css/spinner.css";
import spinnerImage from "../../../images/spinn.png";

const LoadingComponent = () => (
  <div className="loading-spin">
    <img src={spinnerImage} className="image-spin" />
    <h2 style={{ marginLeft: "-100px" }}>Carregando os dados....</h2>
  </div>
);

export default LoadingComponent;
