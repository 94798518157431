import { api } from "./api";

const { REACT_APP_API_BASEURL } = process.env;

export const getActionArea = async (_userId) => {
    return await api.get(`${REACT_APP_API_BASEURL}/actionArea?userId=${_userId}`);
}

export const postActionArea = async (data) => {
    return await api.post(`${REACT_APP_API_BASEURL}/actionArea`, data);
}

export const putActionArea = async (_id, body) => {
    return await api.put(`${REACT_APP_API_BASEURL}/actionArea?id=${_id}`,body);
}