import React, { useEffect, useState } from "react";
import { getActionArea, postActionArea, putActionArea } from "./../../services/apiActionArea";
import { postUser, putUser } from "../../services/apiUser";
import { getProfiles } from "../../services/apiProfiles";
import { auth } from "../../../firebaseApi";
import moment from "moment";
import Alert from "sweetalert2";
import "../../../css/general_register.css";

const UserRegister = ({ history, location }) => {
  const { state } = location;

  const [user, setUser] = useState({});
  const [actionArea, setActionArea] = useState({});
  const [pageStatus, setPageStatus] = useState();
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    moment.locale("pt-br");
    loadData();
  }, []);

  useEffect(() => {
    if (state) {
      setUser(state.data);
      loadActionArea(state.data._id);
      setPageStatus(state.status);
    }
  }, [state]);

  const loadData = async () => {
    try {
      const _response = await getProfiles();
      setProfiles(_response.data);
    } catch (error) {
      await Alert.fire(error?.response?.data?.message);
    }
  };

  const loadActionArea = async (_uid) => {
    try {
      const _reusult = await getActionArea(_uid);
      if (_reusult.data) {
        setActionArea(_reusult.data);
      }
    } catch (error) {
      await Alert.fire(error?.response?.data?.message);
    }
  };

  const handleSubmit = async () => {
    if (!user?.name) {
      await Alert.fire({ title: "Atenção!", text: `O campo Nome é inválido!`, icon: "warning" });
      return;
    }
    if (!user?.profile) {
      await Alert.fire({ title: "Atenção!", text: `O campo Perfil é inválido!`, icon: "warning" });
      return;
    }
    try {
      if (!pageStatus) {
        await postUser({ name: user?.name, email: user?.email, password: user?.password, profile: user?.profile });
        await Alert.fire({ title: "Sucesso!", text: `Usuário criado com sucesso!`, icon: "success" });
        history.goBack();
      } else if (pageStatus === 2) {
        var _json = JSON.parse(JSON.stringify(user));
        _json.id = _json._id;
        await putUser(_json);
        if (actionArea?.area) {
          if (actionArea._id) {
            await putActionArea(actionArea?._id, actionArea?.area);
          } else {
            await postActionArea({ userId: _json._id, area: actionArea?.area });
          }
        }
        await Alert.fire({ title: "Sucesso!", text: `Usuário editado com sucesso!`, icon: "success" });
        history.goBack();
      }
    } catch (error) {
      await Alert.fire({ title: "Erro!", text: `Verifique as informações preenchidas`, icon: "error" });
    }
  };

  const sendResetEmail = async () => {
    try {
      await auth.sendPasswordResetEmail(user?.email);
      await Alert.fire({ title: "Sucesso!", text: `Verifique o email ${user?.email}`, icon: "success" });
    } catch (error) {
      await Alert.fire({ title: "Erro!", text: `Ocorreu um erro ao enviar o email de reset de senha`, icon: "error" });
    }
  }

  return (
    <>
      <div className="container-fluid-register">
        <div className="form-head page-titles d-flex  align-items-center bg-brad">
          <div className="mr-auto  d-lg-block">
            <h4 className="text-black font-w600">Cadastro de Usuário</h4>
          </div>
        </div>
      </div>
      <div className="form">
        <div className="background">
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-3 text-center">Cadastrar Usuário</h4>
            </div>
          </div>
          <div className="row fs-14">
            <div className="col-md-6 mt-4">
              <label className="ml-3 labelNormal">Nome:</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="inputName"
                onChange={(e) => setUser({ ...user, name: e.target.value })}
                value={user.name}
                placeholder=""
              />
            </div>
            <div className="col-md-6 mt-4">
              <label className="ml-3 labelNormal">Email:</label>
              <input
                className="form-control input-rounded"
                type="email"
                name="inputEmail"
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                value={user.email}
                placeholder=""
              />
            </div>
            <div className="col-md-6 mt-4">
              <label className="ml-3 labelNormal">Perfil:</label>
              <select
                className="form-control input-rounded"
                name="inputRole"
                onChange={(e) => setUser({ ...user, profile: e.target.value })}
                value={user.profile}
              >
                <option selected={true} disabled>
                  {"selecione"}
                </option>
                {profiles &&
                  profiles.map((_data, index) => {
                    return (
                      <option key={index} value={_data._id}>
                        {_data.type}
                      </option>
                    );
                  })}
              </select>
            </div>
            {!pageStatus ?
              <div className="col-md-6 mt-4">
                <label className="ml-3 labelNormal">Senha provisória:</label>
                <input
                  className="form-control input-rounded"
                  type="password"
                  name="inputPassword"
                  onChange={(e) => setUser({ ...user, password: e.target.value })}
                  value={user.password}
                  placeholder=""
                />
                <div className="ml-3">
                  <label className="text-muted" style={{ fontSize: "10px" }}>
                    A senha deve conter 8 dígitos, sendo: 1 letra maiúscula, 1 caracter especial e 1 número
                  </label>
                </div>
              </div> :
              <div className="mt-5 text-right">
                <button
                  onClick={sendResetEmail}
                  style={{
                    color: "#fff",
                    backgroundColor: "#144b72",
                    borderRadius: "6px",
                  }}
                  className="btn mt-1 ml-5 form-control input-rounded">
                  Resetar senha
                </button>
              </div>}
            {pageStatus === 2 && <div className="col-md-6 mt-3">
              <label className="ml-3 labelNormal">Área de atuação:</label>
              <input
                className="form-control input-rounded"
                type="text"
                maxLength={8}
                onChange={(e) =>
                  setActionArea({
                    ...actionArea,
                    area: e.target.value.replace(/\D/g, ""),
                  })
                }
                value={actionArea?.area}
              />
              <div className="ml-3">
                <label className="text-muted" style={{ fontSize: "10px" }}>
                  Entre com o cep
                </label>
              </div>
            </div>}
          </div>
          <div className="mt-5 text-right">
            <button
              onClick={handleSubmit}
              type="submit"
              style={{
                color: "#fff",
                backgroundColor: "#144b72",
                borderRadius: "6px",
              }}
              className="btn  borderRadius  mt-3">
              {(pageStatus && pageStatus === 2) ? 'Atualizar' : 'Salvar'}
            </button>
          </div>
        </div>
      </div>
    </>
  );

};

export default UserRegister;