const enumSalesChannel = {
      zap: "zap",
      olx: "olx",
      vivaReal: "vivaReal",
      imovelWeb: "imovelWeb",
      casaMineira: "casaMineira",
      mercadoLivre: "mercadoLivre",
      site: "site",
      placa: "placa",
      google: "google",
      facebook: "facebook",
      instagram: "instagram",
      linkedin: "linkedin",
      all: "all",
      otherPortals: "otherPortals",
      recommendation: "recommendation",
      networking: "networking",
};

const enumSalesChannelDescriptions = (key) => {
      switch (key) {
            case "zap":
                  return "zap";
            case "olx":
                  return "olx";
            case "vivaReal":
                  return "vivaReal";
            case "imovelWeb":
                  return "imovelWeb";
            case "casaMineira":
                  return "casaMineira";
            case "mercadoLivre":
                  return "mercadoLivre";
            case "site":
                  return "site";
            case "placa":
                  return "placa";
            case "google":
                  return "google";
            case "facebook":
                  return "facebook";
            case "instagram":
                  return "Instagram";
            case "linkedin":
                  return "linkedin";
            case "all":
                  return "Todos";
            case "otherPortals":
                  return "Outros Portais";
            case "recommendation":
                  return "Indicação";
            case "networking":
                  return "networking";
            default:
                  return key;
      }
};

const enumSalesChannelDescriptionsInverter = (key) => {
      switch (key) {
            case "zap":
                  return "zap";
            case "olx":
                  return "olx";
            case "vivaReal":
                  return "vivaReal";
            case "imovelWeb":
                  return "imovelWeb";
            case "casaMineira":
                  return "casaMineira";
            case "mercadoLivre":
                  return "mercadoLivre";
            case "site":
                  return "site";
            case "placa":
                  return "placa";
            case "google":
                  return "google";
            case "facebook":
                  return "facebook";
            case "Instagram":
                  return "instagram";
            case "linkedin":
                  return "linkedin";
            case "Todos":
                  return "all";
            case "Outros Portais":
                  return "otherPortals";
            case "Indicação":
                  return "recommendation";
            case "networking":
                  return "networking";
            default:
                  return key;
      }
};

Object.freeze(enumSalesChannel);

module.exports = {
      enumSalesChannel,
      enumSalesChannelDescriptions,
      enumSalesChannelDescriptionsInverter,
};
