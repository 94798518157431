import React, { useEffect, useState } from "react";
import { BsCurrencyDollar } from "react-icons/bs";

// Enum de tradução de Disponibilidade
const enumAvailabilityDescriptions = (key) => {
  switch (key) {
    case "competitors":
      return "Concorrentes";
    case "available":
      return "Disponível";
    case "unavailable":
      return "Indisponível";
    case "leonel":
      return "Leonel";
    default:
      return key;
  }
};
Object.freeze(enumAvailabilityDescriptions);

// Enum de tradução de Disponibilidade
const enumTypeAgreementDescriptions = (key) => {
  switch (key) {
    case "BTS":
      return "BTS";
    case "location":
      return "Locação";
    case "sale":
      return "Venda";
    case "saleAndLease":
      return "Venda e locação";
    case "saleRented":
        return "Venda já alugado";
    case "saleLeaseback":
        return "Sale leaseback";
    default:
      return key;
  }
};
Object.freeze(enumTypeAgreementDescriptions);

const AvailabilityCard = (props) => {
  const dados = props.dados;

  const [totalCostValue, setTotalCostValue] = useState(0);
  const replaceCoin = (_value) => {
    if (_value && _value > 0) {
      return _value.toLocaleString("pt-BR",{style: 'currency', currency: 'BRL'});
    } else {
      return "";
    }
  };

  const totalCostOcupation = () => {
    let totalCost = 0;
    totalCost =  (dados?.iptuValueNumber ?? 0) + (dados?.leaseValueNumber ?? 0) + (dados?.condominiumValueNumber ?? 0);
    setTotalCostValue(replaceCoin(totalCost));
  }

  useEffect(() => {
    totalCostOcupation()
  },[dados])

  return (
    <>
      <div className="card pl-2">
        <div className="card-header row">
          <div className="row ml-2">
            <BsCurrencyDollar color="#5e72e4" className="fa-lg" />
            <h4 className="ml-3">Valores e disponibilidade</h4>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            {dados?.availability ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-blue">Disponibilidade</h4>
                <h5>{enumAvailabilityDescriptions(dados?.availability)}</h5>
              </div>
            ) : null}

            {dados?.activeOrInactive ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-blue">Ativo</h4>
                <h5>{dados?.activeOrInactive}</h5>
              </div>
            ) : null}

            {dados?.transactionType ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-blue">Tipo transação</h4>
                <h5>{enumTypeAgreementDescriptions(dados?.transactionType)}</h5>
              </div>
            ) : null}

            {dados?.leaseValueNumber ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-blue">Valor Locação (R$)</h4>
                <h5>{replaceCoin(dados?.leaseValueNumber)}</h5>
              </div>
            ) : null}

            {dados?.saleValueNumber ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-blue">Valor Venda (R$)</h4>
                <h5>{replaceCoin(dados?.saleValueNumber)}</h5>
              </div>
            ) : null}

            {dados?.iptuValueNumber ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-blue">Valor IPTU (R$ mensal)</h4>
                <h5>{replaceCoin(dados?.iptuValueNumber)}</h5>
              </div>
            ) : null}

            {dados?.condominiumValueNumber ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-blue">Valor de Condomínio (R$)</h4>
                <h5>{replaceCoin(dados?.condominiumValueNumber)}</h5>
              </div>
            ) : null}

            {dados?.negociationValueNumber ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-blue">Valor negociado (R$)</h4>
                <h5>{replaceCoin(dados?.negociationValueNumber)}</h5>
              </div>
            ) : null}

            {totalCostValue!= "0.00" &&
            totalCostValue&&
            totalCostValue!= "0" ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-blue">Custo total da Ocupação (R$)</h4>
                <h5>{totalCostValue}</h5>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailabilityCard;
