import React from "react";
import "../../../css/spinner.css";
import spinnerImage from "../../../images/spinn.png";


const Loading = () => {
    return (
        <div><img src={spinnerImage} className="image-spin"/></div>
    );
}

export default Loading;