import React from "react";
import { BsHouseDoorFill } from "react-icons/bs";

// Enum de Property Type
const enumPropertyTypeDescriptions = (key) => {
  switch (key) {
    case "area":
      return "Area";
    case "hangar":
      return "Galpão";
    case "commercial":
      return "Imóvel Comercial";
    case "store":
      return "Loja";
    case "businessAdaptableResidence":
      return "Residência adpatável comércio";
    case "residential":
      return "Residencial";
    case "rurais":
      return "Rurais";
    case "commercialRoom":
      return "Sala Comercial";
    case "walledLand":
      return "Terreno Murado";
    case "ground":
      return "Terreno";
    default:
      return key;
  }
};
Object.freeze(enumPropertyTypeDescriptions);

const PropertyDataCard = (props) => {
  const dados = props.dados;

  const replaceCep = (_value) => {
    if (_value != undefined && _value != null) {
      _value = _value.replace(/\D/g, "");
      _value = _value.replace(/^(\d{5})(\d)/, "$1-$2");
      return _value;
    }
  };

  return (
    <>
      <div className="card pl-2">
        <div className="card-header row">
          <div className="row ml-2">
            <BsHouseDoorFill color="#D82A2B" className="fa-lg" />
            <h4 className="ml-3">Dados do imóvel</h4>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            {dados?.propertyType ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-red">Tipo do imóvel</h4>
                <h5>{enumPropertyTypeDescriptions(dados?.propertyType)}</h5>
              </div>
            ) : null}
            {dados?.code ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-red">Código do imóvel</h4>
                <h5>{dados?.code}</h5>
              </div>
            ) : null}
            {dados?.zipCode ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-red">CEP</h4>
                <h5>{replaceCep(dados?.zipCode)}</h5>
              </div>
            ) : null}
            {dados?.address && dados?.number ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-red">Endereço</h4>
                <div>
                  <h5>
                    {dados?.address} - {dados?.number}
                  </h5>
                </div>
              </div>
            ) : null}

            {dados?.complement ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-red">Complemento</h4>
                <h5>{dados?.complement}</h5>
              </div>
            ) : null}

            {dados?.buildName ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-red">Nome do condomínio/empreendimento</h4>
                <h5>{dados?.buildName}</h5>
              </div>
            ) : null}

            {dados?.commercialRegion ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-red">Região Comercial</h4>
                <h5>{dados?.commercialRegion}</h5>
              </div>
            ) : null}

            {dados?.district ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-red">Bairro</h4>
                <h5>{dados?.district}</h5>
              </div>
            ) : null}
            {dados?.city ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-red">Cidade</h4>
                <h5>{dados?.city}</h5>
              </div>
            ) : null}

            {dados?.state ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-red">Estado</h4>
                <h5>{dados?.state}</h5>
              </div>
            ) : null}

            {dados?.reference ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-red">Referência</h4>
                <h5>{dados?.reference}</h5>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyDataCard;
