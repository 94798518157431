import { api } from "./api";

const { REACT_APP_API_BASEURL } = process.env;

export const getProfile = async (id) => {
    return await api.get(`${REACT_APP_API_BASEURL}/profiles`,{data: {_id: id}});
}

export const getProfiles = async () => {
    return await api.get(`${REACT_APP_API_BASEURL}/profiles`);
}

export const createProfile = async (data) => {
    return await api.post(`${REACT_APP_API_BASEURL}/profiles`, data);
}

export const updateProfiles = async (data) => {
    return await api.put(`${REACT_APP_API_BASEURL}/profiles`, data);
}

export const deleteProfile = async (id) => {
    return await api.delete(`${REACT_APP_API_BASEURL}/profiles`, { data: { id: id } });
}