import React, {Fragment, useEffect, useState, useRef, useContext } from "react";
import { FirebaseApp } from "../../../firebaseApi";
import {createRealStates,updateRealStates,getCodeByPropertyType} from "../../services/apiRealState";
import moment from "moment";
import "../../../css/general_register.css";
import {enumPropertyType,enumPropertyTypeDescriptions,} from "../../../enums/EPROPERTYTYPE";
import { enumStatus, enumStatusAreaDescriptions } from "../../../enums/ESTATUS";
import {enumLineOfBusiness,enumLineOfBusinessDescriptions} from "../../../enums/ELINEOFBUSINESS";
import {enumEconomicSector,enumEconomicSectorDescriptions,} from "../../../enums/EECONOMICSECTOR";
import {enumAvailability,enumAvailabilityDescriptions} from "../../../enums/EAVALIABILITY";
import Alert from "sweetalert2";
import UsefulContext from "../../../ultis/usefulProvider";
import Geocode from "react-geocode";
import PageHeaderSearch from "../user/pesquisador/page-header-search";

const uuid = require("uuid");

const PageGeneralRegisterSearch = ({ history, location }) => {
  const { user } = useContext(UsefulContext);
  const { state } = location;

  const inputImagens = useRef();

  const [imovel, setImovel] = useState({});

  // Criando um state onde ficarão armazenadas as imagens em array
  const [imagefiles, setImagefiles] = useState([]);
  const [previewimg, setPreviewImg] = useState([]);
  const [imageDesc, setImageDesc] = useState([]);
  const [urls, setUrls] = useState([]);
  const [main, setMain] = useState();
  const [contador, setContador] = useState(0);
  const [upload, setUpload] = useState(false);
  const [imovelId, setImovelId] = useState();

  const [descriptionValue, setDescriptionValue] = useState("");
  const [capaPlataform, setCapaPlataform] = useState("");
  const [capaSite, setCapaSite] = useState("");
  const [imagePlataform, setImagePlataform] = useState("");
  const [imageSite, setImageSite] = useState("");
  //constante que pega os arquivos de iamgem enviados pelo input file
  const getfile = async (e) => {
    
    setContador(previewimg?.concat(mappedFiles).length); //contador para updateRealStates
   
    let array = [];
    array = Array.from(e?.target?.files);
    const mappedFiles = array.map((file) => ({
      ...file,
      preview: URL.createObjectURL(file),
      name:file?.name,
      id: Math.random(),
    }));

    for (let i = 0; i < e?.target?.files?.length; i++) {
      setImagefiles((prevState) => [...prevState, e?.target?.files[i]]);
    }

    setPreviewImg(previewimg?.concat(mappedFiles));
  };

  //constante responsável pelo envio das iamgens ao firebase
  const handleUpload = (id) => {
    imagefiles.map((image) => {
      const uploadTask = FirebaseApp.storage()
        .ref("images/" + id + "/" + image.name)
        .put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          // setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          await FirebaseApp.storage()
            .ref("images/" + id + "/")
            .child(image.name)
            .getDownloadURL()
            .then((urls) => {
              setUrls((prevState) => [...prevState, urls]);
            });
        }
      );
    });
  };

  useEffect(() => {
    getTotalPhotos()
  }, [urls]);

  function getTotalPhotos(){
    if (urls.length > 0 && urls.length === imagefiles.length) {
      const picture = urls.map((url, index) => ({
        id: imagefiles[index]?.id,
        image: url,
        description: imageDesc[index],
        capaSite: capaSite[index],
        capaPlataform: capaPlataform[index],
        imageSite: imageSite[index],
        imagePlataform: imagePlataform[index]
      }));

      if(picture){
        const array = imovel?.pictures?.length > 0 ? imovel?.pictures?.concat(picture) : picture
        setImovel({ ...imovel, pictures: array })
        setUpload(true)
      }
     
    }
  }

  const createImovelId = async () => {
    let create = await createRealStates(imovel);
    let dados = create.data;
    setImovelId(dados?._id);
    if (imagefiles.length === 0) {
      setUpload(true);
    } else {
      handleUpload(dados?._id);
    }
  };

  //salva o imóvel ou atualiza caso o path venha com o id
  const saveImovel = async () => {
    try {
      if (!imovel?.propertyType) {
        await Alert.fire({
          title: "Atenção",
          text: "Selecione o tipo do imóvel",
        });
        return;
      }
      if (!imovel?.zipCode) {
        await Alert.fire({
          title: "Atenção",
          text: "O CEP é obrigatório",
        });
        return;
      }

      if (imovel?.address && imovel?.city) {
        const _resultGeoCode = await Geocode.fromAddress(
          `${imovel?.address}, ${imovel?.city}`,
          "AIzaSyA-uIcPdvgx5alYfpWXuQzRE1yJG88jWBY",
          "pt",
          "br"
        );
        const { lat, lng } = _resultGeoCode.results[0].geometry.location;
  
        setImovel({
          ...imovel,
          approvedStatus: 'waiting',
          userId: user._id,
          pickupEnroller: user.name,
          updatedAt: moment(),
          latitudeAndLongitude: lat + "," + lng, 
        
        });
        
      } else {
        await Alert.fire({
          title: "Atenção",
          text: "Endereço do imóvel incompleto.",
        });
        return;
      }

      if (imovel?._id) {
        if (!imovel.code) {
          setImovel({
            ...imovel,
            code : `TC-${uuid.v4()}`
          });
        }
        if (imagefiles.length === 0) {
          setUpload(true);
        } else {
          handleUpload(imovel?._id);
        }
        
      } else {
        createImovelId()
          .then(() => {
            Alert.fire({
              title: "Sucesso",
              text: "O imóvel foi salvo com sucesso",
              icon: "success",
            });
          })
          .catch((err) => {
            Alert.fire({
              title: "Atenção",
              text: "Verifique os valores preenchidos.",
            });
  
          });
      }
      } catch (error) {
  
      }
  };

  const loadCep = async (zip) => {
    try {
      if (imovel?.zipCode) {
        var cep = imovel?.zipCode?.replace(/\D/g, "");
        let url = `https://viacep.com.br/ws/${cep}/json/`;
        fetch(url).then((response) => {
          response.json().then((data) => {
            var _imovel = JSON.parse(JSON.stringify(imovel));
            if (data?.logradouro) {
              _imovel.address = data?.logradouro;
            }
            if (data?.bairro) {
              _imovel.district = data?.bairro;
            }
            if (data?.uf) {
              _imovel.state = data?.uf;
            }
            if (data?.localidade) {
              _imovel.city = data?.localidade;
            }
            setImovel(_imovel);
          });
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const makeRequest = async (cep) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json`);
      const data = await response.json();

      if (data?.logradouro) {
        setImovel({ ...imovel, address: data?.logradouro });
      }
      if (data?.bairro) {
        setImovel({ ...imovel, district: data?.bairro });
      }
      if (data?.uf) {
        setImovel({ ...imovel, state: data.uf });
      }
      if (data?.localidade) {
        setImovel({ ...imovel, city: data.localidade });
      }
    } catch (error) {
      await Alert.fire("Ocorreu algum erro ao tentar buscar o cep");
    }
  };

  const getCode = async (_propertyType) => {
    try {
      if (state) {
        setImovel({ ...imovel, propertyType: _propertyType });
        return;
      }
      const _result = await getCodeByPropertyType(_propertyType);
      if (_result?.response) {
        return;
      }
      var _imovel = JSON.parse(JSON.stringify(imovel));
      _imovel.code = _result?.data;
      _imovel.propertyType = _propertyType;
      setImovel(_imovel);
    } catch (error) {}
  };

  useEffect(() => {
    if (state) {
      setImovel(state.data);
    }
  }, [state]);

  useEffect(() => {
    moment.locale("pt-br");
    window.scrollTo(0, 0);
  }, []);

  // Determinar a Main image
 
  useEffect(() => {
    if (imovel?._id && upload) {
      updateRealStates(imovel?._id, imovel).then((resultUpdate) => {
        Alert.fire({
          title: "Sucesso",
          text: "O imóvel foi salvo com sucesso",
          icon: "success",
        });
        history.push('/page-research')
      });
    }

    if (imovelId && upload) {

      updateRealStates(imovelId, imovel).then((resultUpdate) => {
        Alert.fire({
          title: "Sucesso",
          text: "O imóvel foi salvo com sucesso",
          icon: "success",
        });
        history.push('/page-research')
      });
    }
}, [upload]);

  const giveUp = () => {
    Alert.fire({
      title: "Atenção",
      text: "Você realmente deseja desistir de publicar esse imóvel?",
      confirmButtonText: "Desistir",
      confirmButtonColor: "red",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        history.push("/page-research");
      }
    });
  };

  return (
    <>
      <PageHeaderSearch />
      <Fragment>
      <div className="form" style={{position:'relative',top:130}}>
        <div className="p-4">
          <div className="row">
            <div className="col-md-12 mt-4 mb-5">
              <h4 className="mb-1 text-center text-primary">
                Cadastro geral de imóveis
              </h4>
            </div>
          </div>
          <div className="card card-purpleregister">
            <div className="col-md-12">
              <h4 className="fs-18 text-black">Informações iniciais:</h4>
            </div>
            <div className="row fs-14">
              <div className="col-md-6 mt-3">
                <label className="ml-3 text-black">Tipo do imóvel: *</label>
                <select
                  className="form-control input-rounded"
                  value={imovel?.propertyType}
                  onChange={(e) => getCode(e.target.value)}>
                  <option value="">Selecione</option>
                  <option value={enumPropertyType.area}>
                    {enumPropertyTypeDescriptions(enumPropertyType.area)}
                  </option>
                  <option value={enumPropertyType.hangar}>
                    {enumPropertyTypeDescriptions(enumPropertyType.hangar)}
                  </option>
                  <option value={enumPropertyType.commercial}>
                    {enumPropertyTypeDescriptions(enumPropertyType.commercial)}
                  </option>
                  <option value={enumPropertyType.store}>
                    {enumPropertyTypeDescriptions(enumPropertyType.store)}
                  </option>
                  <option value={enumPropertyType.businessAdaptableResidence}>
                    {enumPropertyTypeDescriptions(
                      enumPropertyType.businessAdaptableResidence
                    )}
                  </option>
                  <option value={enumPropertyType.residential}>
                    {enumPropertyTypeDescriptions(enumPropertyType.residential)}
                  </option>
                  <option value={enumPropertyType.rurais}>
                    {enumPropertyTypeDescriptions(enumPropertyType.rurais)}
                  </option>
                  <option value={enumPropertyType.commercialRoom}>
                    {enumPropertyTypeDescriptions(
                      enumPropertyType.commercialRoom
                    )}
                  </option>
                  <option value={enumPropertyType.walledLand}>
                    {enumPropertyTypeDescriptions(enumPropertyType.walledLand)}
                  </option>
                  <option value={enumPropertyType.ground}>
                    {enumPropertyTypeDescriptions(enumPropertyType.ground)}
                  </option>
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <label className="ml-3 text-black">Status</label>
                <select
                  className="form-control input-rounded"
                  value={imovel?.status}
                  onChange={(e) =>
                    setImovel({
                      ...imovel,
                      status: e.target.value,
                    })
                  }
                >
                  <option value="">Selecione</option>
                  <option value={enumStatus.construction}>
                    {enumStatusAreaDescriptions(enumStatus.construction)}
                  </option>
                  <option value={enumStatus.project}>
                    {enumStatusAreaDescriptions(enumStatus.project)}
                  </option>
                  <option value={enumStatus.ready}>
                    {enumStatusAreaDescriptions(enumStatus.ready)}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="row fs-14">
            <div className="col-md-12">
              <h4 className="fs-18 text-black">Informações Complementares:</h4>
            </div>
            <div className="col-md-5 mt-3">
              <label className="ml-3">Endereço</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="address"
                value={imovel?.address}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    address: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-md-2 mt-3">
              <label className="ml-3">Nº</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="number"
                value={imovel?.number}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    number: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-md-5 mt-3">
              <label className="ml-3">Bairro</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="district"
                value={imovel?.district}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    district: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-md-6 mt-3">
              <label className="ml-3">Bairro Comercial</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="commercialRegion"
                value={imovel?.commercialRegion}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    commercialRegion: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-md-6 mt-3">
              <label className="ml-3">Cidade</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="city"
                value={imovel?.city}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    city: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-md-6 mt-3">
              <label className="ml-3">Estado</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="state"
                value={imovel.state}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    state: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-md-6 mt-3">
              <div className="row">
                <div className="col-lg-8 col-md-7 col-sm-7 col-7">
                  <label className="ml-3">CEP</label>
                  <input
                    className="form-control input-rounded"
                    type="text"
                    value={imovel.zipCode}
                    onChange={(e) =>
                      setImovel({
                        ...imovel,
                        zipCode: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-lg-2 mt-4 col-md-4 col-sm-4 col-4">
                  <button className="btn btn-primary rounded" onClick={loadCep}>
                    <i className="flaticon-381-search-1 mr-1"></i>buscar
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <button
                type="button"
                style={{ borderRadius: "6px" }}
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#ModalPictures">
                Adicionar Imagens
              </button>
              <div
                className="modal fade"
                id="ModalPictures"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content text-center">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Adicionar Imagens
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body pt-0" id="modal-body">
                      <div className="row d-flex justify-content-end"></div>
                      <div className="row">
                        <label className="btn btn-primary mr-2" htmlFor="inputPictures">
                          Adicionar imagem
                          <span className="btn-icon-right">
                            <i className="fa fa-upload mr-2" />
                          </span>
                        </label>
                      </div>
                      <div className="row">
                        <input
                          className="form-control-file filearch"
                          type="file"
                          id="inputPictures"
                          name="inputPictures"
                          multiple={true}
                          onChange={getfile}
                          accept="image/*"
                        />
                      </div>
                      <div className="PreviewImages" ref={inputImagens}>
                        {previewimg.map((file, index) => {
                          return (
                            <div className="PreviewImages text-left" key={index}>
                              <div className="row">
                                <div className="col-md-6 mt-3" style={{overflow:'hidden'}}>
                                <button 
                                className="btn btn-danger pt-1 pb-1 pl-2 pr-2" 
                                style={{position:'absolute', left:'2px',borderRadius:'0.5rem'}}
                                onClick={() => 
                                  {
                                    Alert.fire({
                                      title: "Atenção",
                                      text: "Deseja apagar essa imagem?",
                                      confirmButtonText: "Excluir",
                                      confirmButtonColor: "red",
                                      showCancelButton: true,
                                      cancelButtonText: "Cancelar",
                                      reverseButtons: true,
                                    }).then((result) => {
                                      if (result.isConfirmed) {

                                      imagefiles.splice(index,1)
                                      previewimg.splice(index,1)

                                      setImageDesc([]);
                                      //handleUpload();
                                      for (var i = 0; i < 50; i++) {
                                        let imageDescription = document.getElementsByName(
                                          "inputDescription_" + i
                                        );
                                        let capaPlataforma = document.getElementsByName(
                                          "plataform-capa" + i
                                        )
                                        let capaSitio = document.getElementsByName(
                                          "site-capa" + i
                                        )
                                        let imagemPlataforma = document.getElementsByName(
                                          "platforma" + i
                                        )
                                        let imagemSitio = document.getElementsByName(
                                          "site" + i
                                        )
                                        setCapaPlataform((capaPlatform) => [
                                            ...capaPlatform, capaPlataforma[0]?.checked ? capaPlataforma[0].checked : false
                                        ]);
                                        setCapaSite((capaSite) => [
                                          ...capaSite, capaSitio[0]?.checked ? capaSitio[0].checked : false
                                        ]);
                                        setImageSite((imageSite) => [
                                          ...imageSite, imagemSitio[0]?.checked ? imagemSitio[0].checked  : false
                                       ]);
                                        setImagePlataform((imagePlataform) => [
                                          ...imagePlataform, imagemPlataforma[0]?.checked ? imagemPlataforma[0].checked : false
                                       ]);
                                        if (imageDescription[0]) {
                                          setImageDesc((imageDesc) => [
                                            ...imageDesc,
                                            imageDescription[0].value,
                                          ]);
                                        }
                                      }
                                      }
                                    });
                                    
                                  }
                                }
                                >
                                  X
                                </button>
                                  <img src={file.preview} height="100px" className="mt-3" />
                                </div>{" "}
                                {previewimg ? (
                                  <div className="col-md-6 mt-3 vertical-align">
                                    <div className="custom-control custom-radio mb-2">
                                      <input
                                        id={"plataform" + index}
                                        name={"plataform-capa" + index}
                                        type="checkbox"
                                        className="custom-control-input"
                                        required
                                        onChange={() => setMain(file)}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"plataform" + index}
                                      >
                                        Capa Plataforma
                                      </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                      <input
                                        id={"secondary" + index}
                                        name={"site-capa" + index}
                                        type="checkbox"
                                        className="custom-control-input"
                                        required
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"secondary" + index}
                                      >
                                        Capa Site
                                      </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                      <input
                                        id={"site" + index}
                                        name={"site" + index}
                                        type="checkbox"
                                        className="custom-control-input"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"site" + index}
                                      >
                                        Imagem Site
                                      </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                      <input
                                        id={"plataforma" + index}
                                        name={"plataforma" + index}
                                        type="checkbox"
                                        className="custom-control-input"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"plataforma" + index}
                                      >
                                        Imagem Plataforma
                                      </label>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <input
                                type="text"
                                className="mt-3 mb-3 form-control"
                                name={`inputDescription_${index}`}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-dark" data-dismiss="modal">
                        Cancelar
                      </button>
                      <button
                        type="button"
                        className="btn btn-success"
                        data-dismiss="modal"
                        onClick={() => {
                          var up_names = document.getElementsByName("plataform").checked;
                          setImageDesc([]);
                          //handleUpload();
                          for (var i = 0; i < 50; i++) {
                            let imageDescription = document.getElementsByName(
                              "inputDescription_" + i
                            );
                            let capaPlataforma = document.getElementsByName(
                              "plataform-capa" + i
                            )
                            let capaSitio = document.getElementsByName(
                              "site-capa" + i
                            )
                            let imagemPlataforma = document.getElementsByName(
                              "platforma" + i
                            )
                            let imagemSitio = document.getElementsByName(
                              "site" + i
                            )
                            setCapaPlataform((capaPlatform) => [
                                ...capaPlatform, capaPlataforma[0]?.checked ? capaPlataforma[0].checked : false
                            ]);
                            setCapaSite((capaSite) => [
                              ...capaSite, capaSitio[0]?.checked ? capaSitio[0].checked : false
                            ]);
                            setImageSite((imageSite) => [
                              ...imageSite, imagemSitio[0]?.checked ? imagemSitio[0].checked  : false
                           ]);
                            setImagePlataform((imagePlataform) => [
                              ...imagePlataform, imagemPlataforma[0]?.checked ? imagemPlataforma[0].checked : false
                           ]);
                            if (imageDescription[0]) {
                              setImageDesc((imageDesc) => [
                                ...imageDesc,
                                imageDescription[0].value,
                              ]);
                            }
                          }
                        }}
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-darkpurpleregister mt-5">
            <div className="row fs-14 ">
              <div className="col-md-12">
                <h4 className="fs-18 text-light">Informações Contato:</h4>
              </div>
              <div className="col-md-4 mt-3">
                <label className="ml-3">Disponibilidade</label>
                <select
                  className="form-control input-rounded"
                  value={imovel?.availability}
                  onChange={(e) =>
                    setImovel({
                      ...imovel,
                      availability: e.target.value,
                    })
                  }
                >
                  <option value="">Selecione</option>
                  <option value={enumAvailability.available}>
                    {enumAvailabilityDescriptions(enumAvailability.available)}
                  </option>
                  <option value={enumAvailability.unavailable}>
                    {enumAvailabilityDescriptions(enumAvailability.unavailable)}
                  </option>
                  <option value={enumAvailability.leonel}>
                    {enumAvailabilityDescriptions(enumAvailability.leonel)}
                  </option>
                  <option value={enumAvailability.competitors}>
                    {enumAvailabilityDescriptions(enumAvailability.competitors)}
                  </option>
                </select>
              </div>
              
              <div className="col-md-4 mt-3">
              <label className="ml-3">Proprietário</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="owner"
                value={imovel?.ownerAdministrator}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    ownerAdministrator: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-md-4 mt-3">
              <label className="ml-3">Contato proprietário</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="ownerPhone"
                value={imovel?.ownerPhone}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    ownerPhone: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Email proprietário</label>
              <input
                className="form-control input-rounded"
                type="email"
                name="ownerEmail"
                value={imovel?.ownerEmail}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    ownerEmail: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Representante</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="representative"
                value={imovel?.representative}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    representative: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Contato do representante</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="representativePhone"
                value={imovel?.representativePhone}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    representativePhone: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Email do representante</label>
              <input
                className="form-control input-rounded"
                type="email"
                name="representantEmail"
                value={imovel?.representantEmail}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    representantEmail: e.target.value,
                  })
                }
              />
            </div>

              <div className="col-md-4 mt-3">
                <label className="ml-3">Último Ocupante</label>
                <input
                  className="form-control input-rounded"
                  type="text"
                  name="lastOccupant"
                  value={imovel?.lastOccupant}
                  onChange={(e) =>
                    setImovel({
                      ...imovel,
                      lastOccupant: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 mt-3">
                <label className="ml-3">Ocupante atual</label>
                <input
                  className="form-control input-rounded"
                  type="text"
                  name="currentOccupant"
                  value={imovel?.currentOccupant}
                  onChange={(e) =>
                    setImovel({
                      ...imovel,
                      currentOccupant: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 mt-3">
                <label className="ml-3">Contato Ocupante</label>
                <input
                  className="form-control input-rounded"
                  type="tel"
                  name="occupantContact"
                  value={imovel?.occupantContact}
                  onChange={(e) =>
                    setImovel({
                      ...imovel,
                      occupantContact: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 mt-3">
                <label className="ml-3">Setor Econômico</label>
                <select
                  className="form-control input-rounded"
                  value={imovel?.economicSector}
                  onChange={(e) =>
                    setImovel({
                      ...imovel,
                      economicSector: e.target.value,
                    })
                  }
                >
                  <option value="">Selecione</option>
                  <option value={enumEconomicSector.industry}>
                    {enumEconomicSectorDescriptions(
                      enumEconomicSector.industry
                    )}
                  </option>
                  <option value={enumEconomicSector.services}>
                    {enumEconomicSectorDescriptions(
                      enumEconomicSector.services
                    )}
                  </option>
                  <option value={enumEconomicSector.commerce}>
                    {enumEconomicSectorDescriptions(
                      enumEconomicSector.commerce
                    )}
                  </option>
                  <option value={enumEconomicSector.indcomllogistics}>
                    {enumEconomicSectorDescriptions(
                      enumEconomicSector.indcomllogistics
                    )}
                  </option>
                  <option value={enumEconomicSector.logisticandcommercial}>
                    {enumEconomicSectorDescriptions(
                      enumEconomicSector.logisticandcommercial
                    )}
                  </option>
                </select>
              </div>
              <div className="col-md-4 mt-3">
                <label className="ml-3">Ramo de atividade do Ocupante</label>
                <select
                  className="form-control input-rounded"
                  value={imovel?.enumLineOfBusiness}
                  onChange={(e) =>
                    setImovel({
                      ...imovel,
                      enumLineOfBusiness: e.target.value,
                    })
                  }
                >
                  <option value="">Selecione</option>
                  <option
                    value={
                      enumLineOfBusiness.agricultureExtractionCultivationAndProcessing
                    }
                  >
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.agricultureExtractionCultivationAndProcessing
                    )}
                  </option>
                  <option value={enumLineOfBusiness.foodAndDrinks}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.foodAndDrinks
                    )}
                  </option>
                  <option value={enumLineOfBusiness.automobile}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.automobile
                    )}
                  </option>
                  <option value={enumLineOfBusiness.consultingAccountingLaw}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.consultingAccountingLaw
                    )}
                  </option>
                  <option value={enumLineOfBusiness.education}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.education
                    )}
                  </option>
                  <option value={enumLineOfBusiness.entertainmentAndTourism}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.entertainmentAndTourism
                    )}
                  </option>
                  <option
                    value={
                      enumLineOfBusiness.realestateConstructionEngineeringRealstate
                    }
                  >
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.realestateConstructionEngineeringRealstate
                    )}
                  </option>
                  <option value={enumLineOfBusiness.quickWashAndCleaning}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.quickWashAndCleaning
                    )}
                  </option>
                  <option value={enumLineOfBusiness.machinesAndEquipment}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.machinesAndEquipment
                    )}
                  </option>
                  <option value={enumLineOfBusiness.constructionMaterials}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.constructionMaterials
                    )}
                  </option>
                  <option value={enumLineOfBusiness.marketsAndHypermarkets}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.marketsAndHypermarkets
                    )}
                  </option>
                  <option
                    value={enumLineOfBusiness.automobilePartsAndEquipment}
                  >
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.automobilePartsAndEquipment
                    )}
                  </option>{" "}
                  <option value={enumLineOfBusiness.plasticsAndPackaging}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.plasticsAndPackaging
                    )}
                  </option>
                  <option
                    value={enumLineOfBusiness.chemistryAndPharmaceuticals}
                  >
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.chemistryAndPharmaceuticals
                    )}
                  </option>
                  <option value={enumLineOfBusiness.animalHealth}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.animalHealth
                    )}
                  </option>
                  <option value={enumLineOfBusiness.healthAndBeauty}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.healthAndBeauty
                    )}
                  </option>
                  <option value={enumLineOfBusiness.steelAndMetallurgy}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.steelAndMetallurgy
                    )}
                  </option>
                  <option value={enumLineOfBusiness.technology}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.technology
                    )}
                  </option>
                  <option
                    value={enumLineOfBusiness.hardwareAndSoftwareTechnology}
                  >
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.hardwareAndSoftwareTechnology
                    )}
                  </option>
                  <option value={enumLineOfBusiness.textileAndFootwear}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.textileAndFootwear
                    )}
                  </option>
                  <option value={enumLineOfBusiness.transportationAndLogistics}>
                    {enumLineOfBusinessDescriptions(
                      enumLineOfBusiness.transportationAndLogistics
                    )}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="row text-right">
            <div className="col-lg-2 mt-3 col-md-8 col-sm-8 col-8">
              <button
                type="submit"
                onClick={saveImovel}
                style={{
                  borderRadius: "6px",
                }}
                className="btn btn-primary mt-3"
              >
                {" "}
                Salvar imóvel
              </button>
            </div>
            <div className="col-lg-2 mt-3 col-md-3 col-sm-3 col-3">
              <button
                type="submit"
                onClick={giveUp}
                style={{
                  borderRadius: "6px",
                  backgroundColor: "#D2D7F6",
                  color: "#3B4CB8",
                }}
                className="btn btn-primary mt-3"
              >
                {" "}
                Desistir
              </button>
            </div>
          </div>
        </div>
      </div>
      </Fragment>
    </>
  );
};
export default PageGeneralRegisterSearch;