import React from 'react'
import '../../../css/edit_user_page.css'
import { Link } from 'react-router-dom'
import avatar1 from '../../../images/avatar/1.jpg'

const UserEdit = () => {
    return (
        <>
            <div className="row">
                <div className='form-head page-titles d-flex  align-items-center padding'>
                    <div className='mr-auto  d-lg-block'>
                        <h4 className='text-black font-w600'>Henry Jr</h4>
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item active'>
                                <Link to='/users-listing'>Usuários</Link>
                            </li>
                            <li className='breadcrumb-item active'>
                                <Link to='/users-edit'>Editar</Link>
                            </li>
                            <li className='breadcrumb-item'>
                                <Link to='/users-edit'>henry@leonel.com</Link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row principal">
                <div className="col-md-4 left-side p-0">
                    <div className="card text-center cartao">
                        <div className="card-body mt-5 corpo-body">
                            <div className="position-relative mb-3 d-inline-block">
                                <img src={avatar1} alt="" className="rounded" width={140} />
                              
                            </div>
                            <h4 className="text-black fs-20 font-w600">
                                Samuel Rodriguez
                            </h4>
                        </div>

                    </div>
                </div>
                <div className="col-md-8 lado-direito">
                    <div className="row">
                        <div className="col-md-6">
                            <label className="ml-2">Nome</label>
                            <input
                                className="form-control input-rounded"
                                type="text"
                                name="nameInputCreate"
                                placeholder="Seu Nome"
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="ml-2">Email</label>
                            <input
                                className="form-control input-rounded"
                                type="email"
                                name="emailInputCreate"
                                placeholder="email@email.com"
                            />

                        </div>
                    </div>
                    
                    <div className="row mt-4">
                        
                        <div className="col-md-6">
                            <label className="ml-2">Data de Nascimento</label>
                            <input
                                className="form-control input-rounded"
                                type="date"
                                name="dateInputType"
                                placeholder="03/08/1990"

                            ></input>

                        </div>
                    </div>
                </div>
                <div className="divBtn text-right col-md-12 pb-4 pr-4">
                    <Link to="/users-listing"><button className="btn btnCancel btn-rounded text-primary">Cancelar</button> </Link>
                    <button className="btn btnRegister btn-primary btn-rounded btnRegister ml-2">Salvar</button>
                </div>
            </div>

        </>
    )
}



export default UserEdit