import {
  Route,
  Redirect
} from 'react-router-dom';
import React from 'react';

const PrivateRoute = ({ component: Component, isAuthenticated, isResearch, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (isAuthenticated ? ( isResearch ? <Redirect to="/page-research" /> : <Component {...props} /> ) : <Redirect to="/page-login" />
    )} />
  );
};

export default PrivateRoute;