export const enumPropertyType = {
  area: "area",
  hangar: "hangar",
  commercial: "commercial",
  store: "store",
  businessAdaptableResidence: "businessAdaptableResidence",
  residential: "residential",
  rurais: "rurais",
  commercialRoom: "commercialRoom",
  walledLand: "walledLand",
  ground: "ground",
};

export const enumGetKey = (key) => {
  switch (key) {
    case "area":
      return "AR";
    case "hangar":
      return "GA";
    case "commercial":
      return "IC";
    case "store":
      return "LO";
    case "businessAdaptableResidence":
      return "RA";
    case "residential":
      return "RE";
    case "rurais":
      return "FAZ";
    case "commercialRoom":
      return "SC";
    case "walledLand":
      return "TM";
    case "ground":
      return "TE";
    default:
      return "TC";
  }
};

export const enumGetKeyDescription = (key) => {
  switch (key) {
    case "AR":
      return "Área";
    case "GA":
      return "Galpão";
    case "IC":
      return "Imóvel Comercial";
    case "LO":
      return "Loja";
    case "RA":
      return "Res. Adp. Com.";
    case "RE":
      return "Residencial";
    case "FAZ":
      return "rurais";
    case "SC":
      return "Sala Comercial";
    case "TM":
      return "Ter. Murado";
    case "TE":
      return "Terreno";
    default:
      return "Cód. temporário";
  }
};

export const enumPropertyTypeDescriptions = (key) => {
  switch (key) {
    case "area":
      return "Area";
    case "hangar":
      return "Galpão";
    case "commercial":
      return "Imóvel Comercial";
    case "store":
      return "Loja";
    case "businessAdaptableResidence":
      return "Residência adpatável comércio";
    case "residential":
      return "Residencial";
    case "rurais":
      return "Rurais";
    case "commercialRoom":
      return "Sala Comercial";
    case "walledLand":
      return "Terreno Murado";
    case "ground":
      return "Terreno";
    default:
      return "Cód. temporário";
  }
};

export const enumPropertyTypeDescriptionsInverter = (key) => {
  switch (key) {
    case "Área":
      return "area";
    case "Galpão":
      return "hangar";
    case "Imóvel Comercial":
      return "commercial";
    case "Loja":
      return "store";
    case "Residência adpatável comércio":
      return "businessAdaptableResidence";
    case "Residencial":
      return "residential";
    case "Rurais":
      return "rurais";
    case "Sala Comercial":
      return "commercialRoom";
    case "Terreno Murado":
      return "walledLand";
    case "Terreno":
      return "ground";
    default:
      return "Cód. temporário";
  }
};

Object.freeze(enumPropertyType);