

export const enumApprovedStatus = {
  waiting: "Aguardando aprovação",
  approved: "Aprovado",
  disapproved: "Reprovado",
  other: "Outro",
  incompleted: "Incompleto",
  findOwner: "Encontrar proprietário",
};

export const enumApprovedStatusInverter = (key) => {
  switch (key) {
    case "Aguardando aprovação":
      return "waiting";
    case "Aprovado":
      return "approved";
    case "Reprovado":
      return "disapproved";
    case "Outro":
      return "other";
    case "Incompleto":
      return "incompleted";
    case "Encontrar proprietário":
      return "findOwner";
    default:
      return "other";
  }
}

  export const enumApprovedStatusDescription = (key) => {
    switch (key) {
      case "waiting":
        return "Aguardando aprovação";
      case "approved":
        return "Aprovado";
      case "disapproved":
        return "Reprovado";
      case "other":
        return "Outro";
      case "incompleted":
        return "Incompleto";
      case "findOwner":
        return "Encontrar proprietário";
      default:
        return "Outro";
    }
  }

Object.freeze(enumApprovedStatus);