const enumZoning = {
  commercial: "commercial",
  industrial: "industrial",
  mixed: "mixed",
  residential: "residential",
  rural: "rural",
};

const enumZoningDescriptions = (key) => {
  switch (key) {
    case "commercial":
      return "Comercial";
    case "industrial":
      return "Industrial";
    case "mixed":
      return "Misto";
    case "residential":
      return "Residencial";
    case "rural":
      return "Rural";
    default:
      return key;
  }
};

const enumZoningDescriptionsInverter = (key) => {
  switch (key) {
    case "Comercial":
      return "commercial";
    case "Industrial":
      return "industrial";
    case "Misto":
      return "mixed";
    case "Residencial":
      return "residential";
    case "Rural":
      return "rural";
    default:
      return key;
  }
};

Object.freeze(enumZoning);

module.exports = {
  enumZoning,
  enumZoningDescriptions,
  enumZoningDescriptionsInverter,
};
