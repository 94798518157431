import React, { useEffect, useState } from "react";
import { createRealStates } from "./../../services/apiRealState";
import { Link } from "react-router-dom";
import DropzonePlanilha from "../Dropzone/DropzonePlanilha";
import planilha from "../../../images/modeloPlanilhaImoveis.xlsx";
import DataTable from "react-data-table-component";
import moment from "moment";
import Loading from "./../Loading/LoadingPlanilha";
import Alert from "sweetalert2";
import * as XLSX from "xlsx";
const uuid = require("uuid");
import {generalColumns} from './columnsGeneral'
// enums
import { enumStatusDescriptionsInverter } from "./../../../enums/ESTATUS";
import { enumFinishingLevelDescriptionsInverter } from "./../../../enums/ELEVELOFFINISH";
import { enumUsageDescriptionsInverter } from "./../../../enums/EUSAGE";
import { enumPropertyTypeDescriptionsInverter } from "./../../../enums/EPROPERTYTYPE";
import { enumPropertyStatusDescriptionsInverter } from "./../../../enums/EPROPERTYSTATUS";
import { enumEconomicSectorDescriptionsInverter } from "./../../../enums/EECONOMICSECTOR";
import { enumExclusivityDescriptionsInverter } from "./../../../enums/EEXCLUSIVITY";
import { enumTypeAgreementDescriptionsInverter } from "./../../../enums/ETYPEAGREEMENT";
import { enumMarketedByDescriptionsInverter } from "./../../../enums/EMARKETDBY";
import { enumLineOfBusinessDescriptionsInverter } from "./../../../enums/ELINEOFBUSINESS";
import { enumZoningDescriptionsInverter } from "./../../../enums/EZONING";
import { enumSalesChannelDescriptionsInverter } from "./../../../enums/ESALESCHANNEL";
import { enumAvailabilityDescriptionsInverter } from "./../../../enums/EAVALIABILITY";
import { enumApprovedStatusDescription } from "./../../../enums/EAPPROVEDSTATUS";
import { enumApprovedStatusInverter } from "./../../../enums/EAPPROVEDSTATUS";

const InputImoveis = ({ history }) => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [loggedInState, setLoggedInState] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const returnFile = (e) => {
          const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, {raw: false});

            dataFormater(json)
        };
        reader.readAsArrayBuffer(e[0]);
  }

  const dataFormater = async (data) => {
    let array = []; 
    for(let i in data){
      array.push({
        code: data[i]?.codigo,
        propertyType: enumPropertyTypeDescriptionsInverter(data[i]?.tipo_de_imovel),
        use: enumUsageDescriptionsInverter(data[i]?.uso),
        status: enumStatusDescriptionsInverter(data[i]?.status),
        class: data[i]?.classe,
        landAreaNumber: Number(data[i]?.area_terreno ?? 0),
        shedAreaNumber: Number(data[i]?.area_galpao ?? 0),
        officeAreaNumber: Number(data[i]?.area_escritorio ?? 0),
        buildingAreaNumber: Number(data[i]?.area_construida ?? 0),
        rightFootNumber: Number(data[i]?.pe_direito ?? 0),
        numberOfDocksNumber: Number(data[i]?.numero_docas ?? 0),
        typeOfDocks: data[i]?.tipo_docas,
        internalManeuverYard: data[i]?.patio_manobra_interno,
        carParking: data[i]?.estacionamento_carretas,
        finishingLevel: enumFinishingLevelDescriptionsInverter(data[i]?.idade_do_imovel),
        propertyStatus: enumPropertyStatusDescriptionsInverter(data[i]?.nivel_do_acabamento),
        economicSector: enumEconomicSectorDescriptionsInverter(data[i]?.estado_do_imovel),
        infrastructure: data[i]?.infraestrutura,
        obsDifferentials: data[i]?.diferenciais,
        occurrencesComments: data[i]?.comentarios,
        exclusivity: enumExclusivityDescriptionsInverter(data[i]?.exclusividade),
        address: data[i]?.endereco,
        number: data[i]?.numero,
        district: data[i]?.bairro,
        commercialDistrict: data[i]?.bairro_comercial,
        city: data[i]?.cidade,
        state: data[i]?.estado,
        zipCode: data[i]?.cep,
        commercialRegion: data[i]?.regiao_comercial,
        technicalRegion: data[i]?.regiao_tecnica,
        referralRouteHighways: data[i]?.via_de_referencia,
        latitudeAndLongitude: data[i]?.latitude_longitude,
        activeOrInactive: data[i]?.ativo_ou_inativo,
        highLight: data[i]?.destaque_super_destaque_sem_destaque,
        availability: enumAvailabilityDescriptionsInverter(data[i]?.disponibilidade),
        availableAreaNumber: Number(data[i]?.area_disponivel ?? 0),
        saleValueNumber: Number(data[i]?.valor_venda ?? 0),
        leaseValueNumber: Number(data[i]?.valor_locacao ?? 0),
        iptuValueNumber: Number(data[i]?.valor_iptu ?? 0),
        condominiumValueNumber: Number(data[i]?.valor_condominio ?? 0),
        totalCostOfOccupationNumber: Number(data[i]?.custo_total_ocupacao ?? 0),
        transactionType: data[i]?.tipo_de_transacao,
        agreedCommission: data[i]?.comissao_acordada,
        marketedBy: enumMarketedByDescriptionsInverter(data[i]?.comercializado_por),
        amountTransactedNumber: Number(data[i]?.valor_transacionado ?? 0),
        findOwner: data[i]?.encontrar_proprietario,
        ownerAdministrator: data[i]?.proprietario_administrador,
        representative: data[i]?.representante,
        contactPhone: data[i]?.contato_telefone,
        contactPhone2: data[i]?.contato_telefone2,
        contactEmail: data[i]?.contato_email,
        lastOccupant: data[i]?.ultimo_ocupante,
        currentOccupant: data[i]?.ocupante_atual,
        occupantContact: data[i]?.contato_ocupante,
        occupantsLineOfBusiness: data[i]?.remo_atividade_ocupante,
        contractPeriod: data[i]?.periodo_contrato,
        contractExpiration: data[i]?.vencimento_contrato,
        landUseAndOccupancyZoning: data[i]?.uso_ocupacao_solo_zoneamento,
        index: data[i]?.indice,
        cityHallRegister: data[i]?.cadastro_prefeitura,
        adDescription: data[i]?.descricao_anuncio,
        salesChannel: data[i]?.canais_venda,
        pickupEnroller: data[i]?.captador_cadastrador,
        registrationDate: moment(data[i]?.data_cadastro, 'DD/MM/YYYY').format('DD/MM/YYYY'),
        approvedStatus: enumApprovedStatusInverter(data[i]?.status_aprovacao),
        createdAt: moment(),
        updatedAt: moment()
      })
    }
    setData(array)
  }

  const saveFile = async () => {
    try {
      const _result = await createRealStates(data);
      if(_result?.response){
        await Alert.fire(_result?.response?.data);
      }
      setLoggedInState("");
      history.goBack();
    } catch (error) {
      setLoggedInState("");
      await Alert.fire('Erro ao adicionar planilha');
    }
  };


  return (
    <>
      <div className="container-fluid-register">
        <div className="form-head page-titles d-flex  align-items-center bg-brad">
          <div className="mr-auto  d-lg-block">
            <h4 className="text-black font-w600">Planilha de Imóveis</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to="#">Imóveis</Link>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-lg-12">
            {loggedInState === "logging in" ? (<div className=""><Loading /></div>) : ("")}
            <div className="card card-body">
              <div className="row">
                <div className="col-lg-12 text-right mb-5">
                  <a href={planilha} download>
                    <button className="btn btn-primary rounded">
                      Baixar Modelo
                    </button>
                  </a>
                </div>
                <div className="col-lg-12">
                  <DropzonePlanilha returnFile={returnFile} />
                </div>
                <div className="col-lg-12">
                  <DataTable
                    pagination
                    highlightOnHover
                    columns={generalColumns}
                    data={data}/>
                </div>
                <div className="col-lg-12 text-right">
                  <div className="mt-5">
                    <button
                      onClick={saveFile}
                      type="submit"
                      className="btn btn-primary rounded">
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputImoveis;