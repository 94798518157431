import React from 'react';
import { Link } from 'react-router-dom';
import "../../../css/general_register.css";

const RegionsRegister = () => {

    return (
        <>
            <div className="container-fluid-register">
                <div className='form-head page-titles d-flex  align-items-center bg-brad'>
                    <div className='mr-auto  d-lg-block'>
                        <h4 className='text-black font-w600'>Cadastro de Usuário</h4>
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item active'>
                                <Link to='/general-register'>Usuários</Link>
                            </li>
                            <li className='breadcrumb-item'>
                                <Link to='/general-register'>Cadastro de novo usuário</Link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="form">
                {/* Formulário Formik e valores iniciais dos inputs */}
                <div className="background">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-3 text-center">Cadastro de um Usuário</h4>
                        </div>
                    </div>
                    <div className="row fs-14">
                        <div className="col-md-4">
                            <label className="ml-3 labelNormal">Nome:</label>
                            <input
                                className="form-control input-rounded"
                                type="text"
                                name="inputName"
                                onChange={handleChange}
                                value={values.inputName}
                                placeholder="">
                            </input>
                        </div>
                        <div className="col-md-4">
                            <label className="ml-3 labelNormal">CPF:</label>
                            <input
                                className="form-control input-rounded"
                                type="text"
                                name="inputCpf"
                                onChange={handleChange}
                                value={values.inputCpf}
                                placeholder="">
                            </input>
                        </div>
                        <div className="col-md-4">
                            <label className="ml-3 labelNormal">Email:</label>
                            <input
                                className="form-control input-rounded"
                                type="email"
                                name="inputEmail"
                                onChange={handleChange}
                                value={values.inputEmail}
                                placeholder="">
                            </input>
                        </div>
                        <div className="col-md-6">
                            <label className="ml-3 labelNormal">Perfil:</label>
                            <select
                                className="form-control input-rounded"
                                name="inputRole"
                                onChange={handleChange}
                                value={values.inputRole}>
                                <option value="">Perfil</option>
                                <option value="FIELD_RESEARCHER">Pesquisador de campo</option>
                                <option value="INTERNAL_RESEARCHER">Pesquisador interno</option>
                                <option value="INTERNAL_BROKER">Corretor interno</option>
                                <option value="EXTERNAL_BROKER">Corretor externo</option>
                                <option value="COMMERCIAL_MANAGER">Gerente comercial</option>
                                <option value="DIRECTORS">Diretor</option>
                                <option value="TECHNICAL_MANAGER">Gerente técnico</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label className="ml-3 labelNormal">CRECI:</label>
                            <input
                                className="form-control input-rounded"
                                type="text"
                                name="inputCreci"
                                onChange={handleChange}
                                value={values.inputCreci}
                                placeholder="">
                            </input>
                        </div>
                        <div className="col-md-3">
                            <label className="ml-3 labelNormal">Distrito:</label>
                            <input
                                className="form-control input-rounded"
                                type="text"
                                name="inputDistrito"
                                onChange={handleChange}
                                value={values.inputDistrito}
                                placeholder="/SP">
                            </input>
                        </div>
                    </div>
                    <div className="mt-5 text-right">
                        <Link to="/users-listing">
                            <button
                                type="button"
                                style={{ color: "#fff", backgroundColor: "#144b72", borderRadius: "6px" }}
                                className="btn borderRadius btnCancel mt-3 mr-3 text-primary"
                            >Cancelar</button>
                        </Link>
                        <button
                            type="submit"
                            style={{ color: "#fff", backgroundColor: "#144b72", borderRadius: "6px" }}
                            className="btn  borderRadius  mt-3"> Salvar</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegionsRegister