import React from "react";
import { BsStickiesFill } from "react-icons/bs";

// Enum de tradução de Disponibilidade
const enumStatusAreaDescriptions = (key) => {
  switch (key) {
    case "construction":
      return "Construção";
    case "project":
      return "Projeto";
    case "ready":
      return "Pronto";
    case "development":
      return "Em desenvolvimento";
    default:
      return key;
  }
};
Object.freeze(enumStatusAreaDescriptions);

const enumExclusivityDescriptions = (key) => {
  switch (key) {
    case "yes":
      return "Sim";
    case "no":
      return "Não";
    default:
      return key;
  }
};
Object.freeze(enumExclusivityDescriptions);

const enumFalseTrue = (key) => {
  switch (key) {
    case "true":
      return "Sim";
    case "false":
      return "Não";
    case "yes":
      return "Sim";
    case "no":
      return "Não";
    default:
      return key;
  }
};
Object.freeze(enumFalseTrue);

const OwnerDataCard = (props) => {
  const dados = props.dados;

  const replaceCoin = (_value) => {
    if (_value != undefined && _value != null) {
      _value = _value.toString();
      if (_value == "0.00") {
        return "0";
      } else {
        _value = _value.replace(/\D/g, "");
        _value = _value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        return _value;
      }
    } else {
      return _value;
    }
  };

  return (
    <>
      <div className="card pl-2">
        <div className="card-header row">
          <div className="row ml-2">
            <BsStickiesFill color="#ff9900" className="fa-lg" />
            <h4 className="ml-3">Outras informações</h4>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            {dados?.pickupEnroller ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Cadastrador/Captador ou Parceria</h4>
                <h5>{dados?.pickupEnroller}</h5>
              </div>
            ) : null}

            {dados?.exclusivity ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Exclusividade</h4>
                <h5>{enumExclusivityDescriptions(dados?.exclusivity)}</h5>
              </div>
            ) : null}

            {dados?.status ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Status</h4>
                <h5>{enumStatusAreaDescriptions(dados?.status)}</h5>
              </div>
            ) : null}

            {dados?.landAreaNumber ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Área de terreno m²</h4>
                <h5>{replaceCoin(dados?.landAreaNumber)}</h5>
              </div>
            ) : null}

            {dados?.shedAreaNumber ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Área de Galpão m²</h4>
                <h5>{replaceCoin(dados?.shedAreaNumber)}</h5>
              </div>
            ) : null}

            {dados?.officeAreaNumber ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Área de Escritório m²</h4>
                <h5>{replaceCoin(dados?.officeAreaNumber)}</h5>
              </div>
            ) : null}

            {dados?.buildingAreaNumber ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Área Construída m²</h4>
                <h5>{replaceCoin(dados?.buildingAreaNumber)}</h5>
              </div>
            ) : null}

            {dados?.rightFoot ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Pé direito m</h4>
                <h5>{enumFalseTrue(dados?.rightFootNumber)}</h5>
              </div>
            ) : null}

            {dados?.rightFootNumber ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Altura pé direito</h4>
                <h5>{replaceCoin(dados?.rightFootNumber)}</h5>
              </div>
            ) : null}

            {dados?.docks ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Docas</h4>
                <h5>{enumFalseTrue(dados?.docks)}</h5>
              </div>
            ) : null}

            {dados?.typeOfDocks ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Tipo de docas</h4>
                <h5>{dados?.typeOfDocks}</h5>
              </div>
            ) : null}

            {dados?.numberOfDocksNumber ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Número de docas</h4>
                <h5>{dados?.numberOfDocksNumber}</h5>
              </div>
            ) : null}

            {dados?.internalManeuverYard ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Pátio de Manobra Interno</h4>
                <h5>{enumFalseTrue(dados?.internalManeuverYard)}</h5>
              </div>
            ) : null}

            {dados?.carParking ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Estacionamento para Carretas</h4>
                <h5>{enumFalseTrue(dados?.carParking)}</h5>
              </div>
            ) : null}

            {dados?.dressingRoom ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Vestiário</h4>
                <h5>{enumFalseTrue(dados?.dressingRoom)}</h5>
              </div>
            ) : null}

            {dados?.refectory ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Refeitório</h4>
                <h5>{enumFalseTrue(dados?.refectory)}</h5>
              </div>
            ) : null}

            {dados?.overheadCrane ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Ponte Rolante</h4>
                <h5>{enumFalseTrue(dados?.overheadCrane)}</h5>
              </div>
            ) : null}

            {dados?.transformer ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Transformador</h4>
                <h5>{enumFalseTrue(dados?.transformer)}</h5>
              </div>
            ) : null}

            {dados?.infrastructure ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Infraestrutura</h4>
                <h5>{dados?.infrastructure}</h5>
              </div>
            ) : null}

            {dados?.obsDifferentials ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Diferenciais</h4>
                <h5>{dados?.obsDifferentials}</h5>
              </div>
            ) : null}

            {dados?.observation ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Observações</h4>
                <h5>{dados?.observation}</h5>
              </div>
            ) : null}

            {dados?.lastOccupant ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-orange">Último Ocupante</h4>
                <h5>{dados?.lastOccupant}</h5>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerDataCard;
