import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { starFive } from "../AppsMenu/AppsMenu/Shop/productData/ProductStar";
import { getRealStates } from "./../../services/apiRealState";
import "../../../css/property-guide.css";
import logo from "../../../images/leonelLogo.png";

const ImoveisLeonel = () => {

    // Constante que irá receber os valores vindos do banco de dados
    const [dados, setDados] = useState([]);

    useEffect(() => {
        try {
            getRealStates().then(res => { setDados(res.data); }).catch(error => { console.log(error); });
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container mt-0">
            <div className="card card-body">
                <div className="row">
                    <div className="col-lg-6">
                        <h4 className="font-w600 mt-3" style={{ color: "#144b72" }}>Imóveis Disponíveis</h4>
                    </div>
                    <div className="col-lg-6 text-right">
                        <img src={logo} width={150}></img>
                    </div>
                </div>
            </div>
            <div className="row">
                {dados?.map((dados) => {
                    return (
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 m-0" key={dados._id} >
                            <div className="card card-body p-3" style={{ maxWidth: "80%" }}>
                                <div className="new-arrival-product">
                                    <div className="new-arrivals-img-contnent" style={{ borderRadius: "6px" }} >
                                        <Link to={`/property-details/${dados._id}`} target='_blank'>
                                            <img
                                                className="img-fluid"
                                                alt=""
                                                src={dados.plataformMainPicture ? dados.plataformMainPicture : "/semImagem.png"}
                                            />
                                        </Link>
                                    </div>
                                    <div className="new-arrival-content text-center mt-3">
                                        {starFive}
                                        <div className="mb-2 fs-12">
                                            {dados.district ? <span>Galpão no bairro {dados.district} </span> : null}
                                            {dados.land_area ? <span>com {dados.land_area}m<sup>2</sup></span> : null}
                                        </div>
                                        <div className="price fs-16">
                                            {dados.sale_value ?
                                                <div className="col-12">
                                                    Venda:{" "}
                                                    <span
                                                        className="fs-18 text-black"
                                                        style={{ fontWeight: "400" }}
                                                    >
                                                        R$ {dados.sale_value}
                                                    </span>
                                                </div>
                                                : <div className="col-12">
                                                    <span
                                                        className="fs-14 text-black"
                                                        style={{ fontWeight: "200" }}
                                                    >
                                                        Sem valor para venda
                                                    </span>
                                                </div>}
                                            {dados.lease_value ?
                                                <div className="col-12">
                                                    Locação:{" "}
                                                    <span
                                                        className="fs-18 text-black"
                                                        style={{ fontWeight: "400" }}
                                                    >
                                                        {dados.lease_value}
                                                    </span>
                                                </div>
                                                : <div className="col-12">
                                                    <span
                                                        className="fs-14 text-black"
                                                        style={{ fontWeight: "200" }}
                                                    >
                                                        Sem valor para locação
                                                    </span>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ImoveisLeonel;