const enumLineOfBusiness = {
      agricultureExtractionCultivationAndProcessing:
            "agricultureExtractionCultivationAndProcessing",
      foodAndDrinks: "foodAndDrinks",
      automobile: "automobile",
      consultingAccountingLaw: "consultingAccountingLaw",
      education: "education",
      entertainmentAndTourism: "entertainmentAndTourism",
      realestateConstructionEngineeringRealstate:
            "realestateConstructionEngineeringRealstate",
      quickWashAndCleaning: "quickWashAndCleaning",
      machinesAndEquipment: "machinesAndEquipment",
      constructionMaterials: "constructionMaterials",
      marketsAndHypermarkets: "marketsAndHypermarkets",
      automobilePartsAndEquipment: "automobilePartsAndEquipment",
      plasticsAndPackaging: "plasticsAndPackaging",
      chemistryAndPharmaceuticals: "chemistryAndPharmaceuticals",
      animalHealth: "animalHealth",
      healthAndBeauty: "healthAndBeauty",
      steelAndMetallurgy: "steelAndMetallurgy",
      technology: "technology",
      hardwareAndSoftwareTechnology: "hardwareAndSoftwareTechnology",
      textileAndFootwear: "textileAndFootwear",
      transportationAndLogistics: "transportationAndLogistics",
};

const enumLineOfBusinessDescriptions = (key) => {
      switch (key) {
            case "agricultureExtractionCultivationAndProcessing":
                  return "Agropecuária ( extração, cultivo e beneficiamento)";
            case "foodAndDrinks":
                  return "Alimentos e Bebidas";
            case "automobile":
                  return "Automobilística";
            case "consultingAccountingLaw":
                  return "Consultoria ( Contabilidade, Advocacia)";
            case "education":
                  return "Educação";
            case "entertainmentAndTourism":
                  return "Entretenimento e Turismo";
            case "realestateConstructionEngineeringRealstate":
                  return "Imobiliários (Construtoras, Engenharia, Imobiliárias)";
            case "quickWashAndCleaning":
                  return "Lava Rápido e Limpeza";
            case "machinesAndEquipment":
                  return "Máquinas e Equipamentos";
            case "constructionMaterials":
                  return "Materias de Construção";
            case "marketsAndHypermarkets":
                  return "Mercados e Hipermercados";
            case "automobilePartsAndEquipment":
                  return "Peças e Equipamentos Automobilísticos";
            case "plasticsAndPackaging":
                  return "Plásticos e Embalagens";
            case "chemistryAndPharmaceuticals":
                  return "Química e Farmacêutica";
            case "animalHealth":
                  return "Saúde  Animal";
            case "healthAndBeauty":
                  return "Saúde e Beleza";
            case "steelAndMetallurgy":
                  return "Siderurgíca e Metalurgíca";
            case "technology":
                  return "Tecnologia";
            case "hardwareAndSoftwareTechnology":
                  return "Tecnologia  Hardware e Software";
            case "textileAndFootwear":
                  return "Têxtil e Calçados";
            case "transportationAndLogistics":
                  return "Transporte e Logística";
            default:
                  return key;
      }
};

const enumLineOfBusinessDescriptionsInverter = (key) => {
      switch (key) {
            case "Agropecuária ( extração, cultivo e beneficiamento)":
                  return "agricultureExtractionCultivationAndProcessing";
            case "Alimentos e Bebidas":
                  return "foodAndDrinks";
            case "Automobilística":
                  return "automobile";
            case "Consultoria ( Contabilidade, Advocacia)":
                  return "consultingAccountingLaw";
            case "Educação":
                  return "education";
            case "Entretenimento e Turismo":
                  return "entertainmentAndTourism";
            case "Imobiliários (Construtoras, Engenharia, Imobiliárias)":
                  return "realestateConstructionEngineeringRealstate";
            case "Lava Rápido e Limpeza":
                  return "quickWashAndCleaning";
            case "Máquinas e Equipamentos":
                  return "machinesAndEquipment";
            case "Materias de Construção":
                  return "constructionMaterials";
            case "Mercados e Hipermercados":
                  return "marketsAndHypermarkets";
            case "Peças e Equipamentos Automobilísticos":
                  return "automobilePartsAndEquipment";
            case "Plásticos e Embalagens":
                  return "plasticsAndPackaging";
            case "Química e Farmacêutica":
                  return "chemistryAndPharmaceuticals";
            case "Saúde  Animal":
                  return "animalHealth";
            case "Saúde e Beleza":
                  return "healthAndBeauty";
            case "Siderurgíca e Metalurgíca":
                  return "steelAndMetallurgy";
            case "Tecnologia":
                  return "technology";
            case "Tecnologia  Hardware e Software":
                  return "hardwareAndSoftwareTechnology";
            case "Têxtil e Calçados":
                  return "textileAndFootwear";
            case "Transporte e Logística":
                  return "transportationAndLogistics";
            default:
                  return key;
      }
};

Object.freeze(enumLineOfBusiness);

module.exports = {
      enumLineOfBusiness,
      enumLineOfBusinessDescriptions,
      enumLineOfBusinessDescriptionsInverter,
};
