import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getGroups, deleteGroup } from './../../services/apiGroups';
import moment from 'moment';
import Alert from "sweetalert2";
import UsefulContext from './../../../ultis/usefulProvider';
import './../../../css/listagem_usuarios.css';

function GroupsListing({ history }) {

  const { userProfiles } = React.useContext(UsefulContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);
  
  const loadData = () => {
    try {
      getGroups().then(result => { setData(result.data) }).catch(error => { console.log(JSON.parse(error)) })
    } catch (error) {
      console.log(error);
    }
  }

  const onVisualize = (_data) => {
    history.push({ pathname: '/groups-register', state: { 'data': _data, 'status': 1 } })
  }

  const onEdit = (_data) => {
    history.push({ pathname: '/groups-register', state: { 'data': _data, 'status': 2 } })
  }

  const onDelete = (_data) => {
    Alert.fire({
      title: 'Atenção!',
      text: `Deseja mesmo excluir este grupo?`,
      icon: 'warning',
      confirmButtonText: 'Excluir grupo',
      showCancelButton: true,
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteGroup(_data._id).then((resultDelete) => {
          Alert.fire({ title: 'Sucesso!', text: `O grupo foi removido com sucesso`, icon: 'success' }).then(() => { loadData(); })
        }).catch((error) => {
          Alert.fire({ title: 'Ocorreu algum erro ao excluir o grupo!', text: `${error}`, icon: 'error' }).then(() => { loadData(); })
        })
      }
    })
  }

  return (
    <>
      <div className="container-fluid-users">
        <div className='form-head page-titles d-flex  align-items-center'>
          <div className='mr-auto  d-lg-block'>
            <h4 className='text-black font-w600'>Lista de Grupos</h4>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item active'>
                <Link to='/groups-listing'>Grupos</Link>
              </li>
              <li className='breadcrumb-item'>
                <Link to='/groups-listing'>Groups List</Link>
              </li>
            </ol>
          </div>
          {userProfiles?.addGroup && <Link to='/groups-register' style={{ color: "#fff", backgroundColor: "#144b72" }} className='btn btn-primary rounded  mr-3'>
            Cadastrar grupo
          </Link>
          }
        </div>
        <div className='row'>
          <div className='col-xl-6 col-xxl-12'>
          </div>
          <div className='col-xl-12'>
            <div className='table-responsive table-hover fs-14'>
              <div id='orderList' className='dataTables_wrapper no-footer'>
                <table className='table table-hover display mb-4 dataTablesCard card-table dataTable no-footer' id='example5'>
                  <thead>
                    <tr role='row'>
                      <th className='sorting'>Id</th>
                      <th className='sorting'>Nome</th>
                      <th className='sorting'>Descrição</th>
                      <th className='sorting'>Regiões</th>
                      <th className='sorting'>Data de registro</th>
                      <th className='sorting' />
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((_data, index) => {
                      return (
                        <tr role='row' className='odd' key={_data._id}>
                          <td>{(++index)}</td>
                          <td>{_data.name}</td>
                          <td>{_data.description}</td>
                          <td>{`${JSON.parse(_data.regions).length} no total`}</td>
                          <td>{moment(_data.created).format('DD-MM-YYYY')}</td>
                          { userProfiles?.groupListing && <td>
                            <button onClick={() => onVisualize(_data)} className="btn btn-primary shadow btn-xs sharp mr-1">
                              <i className="fa fa-eye"></i>
                            </button>
                          </td> }
                          {userProfiles?.editGroup && <td>
                            <button onClick={() => onEdit(_data)} className="btn btn-warning shadow btn-xs sharp">
                              <i className="fa fa-pencil"></i>
                            </button>
                          </td>}
                          { userProfiles?.deleteGroup && <td>
                            <button onClick={() => onDelete(_data)} className="btn btn-danger shadow btn-xs sharp">
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GroupsListing