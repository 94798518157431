import React, { useEffect, useState } from "react";
import { getRealStates, queryRequest } from "./../../services/apiRealState";
import { starFive } from "../AppsMenu/AppsMenu/Shop/productData/ProductStar";
import { Link } from "react-router-dom";
import { FiFilter } from "react-icons/fi";
import { CgFileDocument } from "react-icons/cg";
import { FaWindowClose } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import Alert from "sweetalert2";
import UsefulContext from "./../../../ultis/usefulProvider";
import CurrencyInput from "react-currency-input-field";
import "../../../css/property-guide.css";
import LoadingComponent from "../Loading/LoadingComponent";
import { PaginatedList } from "react-paginated-list";
import Multiselect from 'multiselect-react-dropdown';
import {enumPropertyType,enumPropertyTypeDescriptions} from "./../../../enums/EPROPERTYTYPE";
import { enumUsageDescriptions } from "./../../../enums/EUSAGE";
import { enumStatusAreaDescriptions } from "./../../../enums/ESTATUS";
import {enumTypeAgreement,enumTypeAgreementDescriptions} from "./../../../enums/ETYPEAGREEMENT";
import {enumFinishingLevelDescriptions} from "./../../../enums/ELEVELOFFINISH";
import { enumMarketedByDescriptions } from "./../../../enums/EMARKETDBY";
import {enumLineOfBusinessDescriptions,} from "./../../../enums/ELINEOFBUSINESS";
import {enumEconomicSectorDescriptions} from "./../../../enums/EECONOMICSECTOR";

import {enumAvailability,enumAvailabilityDescriptions,} from "./../../../enums/EAVALIABILITY";


const { REACT_APP_WEB_BASEURL } = process.env;

const PropertyGuide = () => {
  const [showAllFilters, setShowAllFilters] = useState(false);

  const [generalFilter, setGeneralFilter] = useState('');
  const [tipologyFilter, setTipologyFilter] = useState('');
  const [codeFilter, setCodeFilter] = useState('');
  const [regionFilter, setRegionFilter] = useState('');
  const [addressFilter, setAddressFilter] = useState('');
  const [availableFilter, setAvailableFilter] = useState('');
  const [keyFilter, setKeyFilter] = useState('');
  const [districtFilter, setDistrictFilter] = useState('');
  const [transactionFilter, setTransactionFilter] = useState('');
  const [cepFilter, setCepFilter] = useState('');

  const [cityFilter, setCityFilter] = useState('');
  const [ownerFilter, setOwnerFilter] = useState('');

  const [sizeFilter, setSizeFilter] = useState('');
  const [sizeMinFilter, setSizeMinFilter] = useState('');
  const [sizeMaxFilter, setSizeMaxFilter] = useState('');

  const [valueFilter, setValueFilter] = useState('');
  const [valueMinFilter, setValueMinFilter] = useState('');
  const [valueMaxFilter, setValueMaxFilter] = useState('');

  const [areaFilter, setAreaFilter] = useState('');
  const [areaMinFilter, setAreaMinFilter] = useState('');
  const [areaMaxFilter, setAreaMaxFilter] = useState('');

  const [valueBeetwen, setValueBeetwen] = useState(false);
  const [filterValueSelect, setFilterValueSelect] = useState('');
  
  const [areaBeetwen, setAreaBeetwen] = useState(false);
  const [filterAreaSelect, setFilterAreaSelect] = useState('');
  
  const [sizeBeetwen, setSizeBeetwen] = useState(false);
  const [filterSizeSelect, setFilterSizeSelect] = useState('');
  const [clearing, setClearing] = useState(false);


  const [key, setKey] = useState("");
  const [keyArray, setKeyArray] = useState("");

  const [announceChannel, setAnnounceChannel] = useState("");
  const [announceArray, setAnnounceArray] = useState("");

  const [loading, setLoading] = useState(false);

  const { userProfiles } = React.useContext(UsefulContext);

  // Constante que irá receber os valores vindos do banco de dados
  const [dados, setDados] = useState([]);
  const [query, setQuery] = useState({});

  const optionsKey = [
    {name: 'Leonel', id:'leonel'},
    {name: 'Cão de guarda', id:'watchdog'},
    {name: 'Proprietário', id:'owner'},
    {name: 'Imobiliária', id:'realState'},
    {name: 'Outros', id:'other'},
  ]

  const optionsAnnounce = [
    {name: 'Zap', id:'zap'},
    {name: 'Olx', id:'olx'},
    {name: 'Viva real', id:'vivaReal'},
    {name: 'Imóvel Web', id:'imovelWeb'},
    {name: 'Casa mineira', id:'casaMineira'},
    {name: 'Mercado livre', id:'mercadoLivre'},
    {name: 'Site', id:'site'},
    {name: 'Placa', id:'placa'},
    {name: 'Google', id:'google'},
    {name: 'Facebook', id:'facebook'},
    {name: 'Instagram', id:'instagram'},
    {name: 'Linkedin', id:'linkedin'},
    {name: 'Indicação', id:'indicacao'},
    {name: 'Networking', id:'networking'},
    {name: 'Todos', id:'all'},

  ]

  useEffect(() => {
    getRealStatesFunction();
  }, []);

  const reset = () => {
    setQuery({});
    getRealStatesFunction();
  };

  const filterData = (value) => {
    let retorno = false;

    if(showAllFilters){
      if(!cepFilter && !transactionFilter && !codeFilter && !addressFilter && !tipologyFilter && !regionFilter && !sizeFilter && !areaFilter && !valueFilter && !keyArray && !announceArray && !availableFilter && !ownerFilter && !cityFilter && !districtFilter){
        retorno = true;
      } else {
        let checkAll = 0
        if(value?.code?.toUpperCase()?.includes(codeFilter.toUpperCase()) || !codeFilter){
          checkAll ++;
        }

        if(value?.address?.toUpperCase()?.includes(addressFilter.toUpperCase()) || !addressFilter){
          checkAll ++;
        }

        if(value?.zipCode?.toUpperCase()?.includes(cepFilter.toUpperCase()) || !cepFilter){
          checkAll ++;
        }

        if(value?.transactionType?.toUpperCase() === transactionFilter.toUpperCase() || value?.transactionType?.toUpperCase() ===  enumTypeAgreementDescriptions(transactionFilter).toUpperCase()|| !transactionFilter){
          checkAll ++;
        }

        if(value?.propertyType?.toUpperCase() === tipologyFilter.toUpperCase() || value?.propertyType?.toUpperCase() === enumPropertyTypeDescriptions(tipologyFilter).toUpperCase()|| !tipologyFilter){
          checkAll ++;  
        }

        if(value?.district?.toUpperCase()?.includes(districtFilter.toUpperCase()) || !districtFilter){
          checkAll ++;
        }

        if(value?.city?.toUpperCase()?.includes(cityFilter.toUpperCase()) || !cityFilter){
          checkAll ++;
        }

        if(value?.owner?.toUpperCase()?.includes(ownerFilter.toUpperCase()) || value?.ownerAdministrator?.toUpperCase()?.includes(ownerFilter.toUpperCase()) || !ownerFilter){
          checkAll ++;
        }

        if(value?.availability?.toUpperCase() === availableFilter.toUpperCase() || value?.availability?.toUpperCase() === enumAvailabilityDescriptions(availableFilter).toUpperCase() || !availableFilter){
          checkAll ++;
        }

        if(value?.salesChannel?.length && announceArray?.length){
          let verification = false;
           for(let i = 0; i < value?.salesChannel.length; i++){
            if(announceArray.includes(value?.salesChannel[i])){
              verification = true;
            }
           }
           if( verification){
            checkAll++;
           }
        } else  if(announceArray?.includes(value?.salesChannel) || !announceArray){
          checkAll ++;
        }
       
        if(value?.keyLocation?.length && keyArray?.length){
          let verification = false;
           for(let i = 0; i < value?.keyLocation.length; i++){
            if(keyArray.includes(value?.keyLocation[i])){
              verification = true;
            }
           }
           if( verification){
            checkAll++;
           }
        } else if(keyArray?.includes(value?.keyLocation) || !keyArray){
          checkAll ++;
        }

        if(value?.commercialRegion?.toUpperCase()?.includes(regionFilter.toUpperCase())|| value?.commercialRegion?.toUpperCase()?.includes(regionFilter.toUpperCase()) || !regionFilter){
          checkAll ++;
        }

        if(sizeBeetwen){
          if(value?.landAreaNumber >= Number(sizeMinFilter) && value?.landAreaNumber <= Number(sizeMaxFilter) ){
            checkAll ++;
          }
        }
        else if (!sizeFilter){
          checkAll ++;
        }
         else if(filterSizeSelect){

          switch(filterSizeSelect){
            case 'less':
              if(value?.landAreaNumber < sizeFilter && value?.landAreaNumber !==0 ){
                checkAll ++;
              }
              break;
            case 'more':
              if(value?.landAreaNumber >sizeFilter ){
                checkAll ++;
              }
              break;
            case 'equal':
              if(value?.landAreaNumber == sizeFilter){
                checkAll ++;
              }
              break;
          }
         
        }

        if(areaBeetwen){
          if(value?.buildingAreaNumber >= areaMinFilter && value?.buildingAreaNumber <= areaMaxFilter ){
            checkAll ++;
          }
        } else if (!areaFilter){
          checkAll ++;
        }
         else if(filterAreaSelect){

          switch(filterAreaSelect){
            case 'less':
              if(value?.buildingAreaNumber < areaFilter && value?.buildingAreaNumber !==0 ){
                checkAll ++;
              }
              break;
            case 'more':
              if(value?.buildingAreaNumber > areaFilter){
                checkAll ++;
              }
              break;
            case 'equal':
              if(value?.buildingAreaNumber === areaFilter){
                checkAll ++;
              }
              break;
          }
          
        }

        if(valueBeetwen){
          if(value?.leaseValueNumber >=valueMinFilter && value?.leaseValueNumber <= valueMaxFilter || value?.saleValueNumber >=valueMinFilter && value?.saleValueNumber <= valueMaxFilter){
            checkAll ++;
          }
        }
         else if (!valueFilter){
          checkAll ++;
        }
        else if(filterValueSelect) {

          switch(filterValueSelect){
            case 'less':
              if((value?.leaseValueNumber < valueFilter && value?.leaseValueNumber !==0) || (value?.saleValueNumber < valueFilter && value?.saleValueNumber !==0) ){
                checkAll ++;
              }
              break;
            case 'more':
              if(value?.leaseValueNumber > valueFilter || value?.saleValueNumber > valueFilter){
                checkAll ++;
              }
              break;
            case 'equal':
              if(value?.leaseValueNumber === valueFilter || value?.saleValueNumber === valueFilter) {
                checkAll ++;
              }
              break;
            
          }
          
        }

        if(checkAll === 15){
          retorno = true;
        } else {
          retorno = false;
        }
      }

    } else {
      let filters = [
        value?.code,
        value?.owner,
        value?.ownerAdministrator,
        value?.saleValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
        value?.leaseValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
        value?.landAreaNumber?.toLocaleString('pt-br',),
        value?.shedAreaNumber?.toLocaleString('pt-br'), 
        value?.buildingAreaNumber?.toLocaleString('pt-br'), 
        value?.officeAreaNumber?.toLocaleString('pt-br'), 
        value?.rightFootNumber?.toLocaleString('pt-br'), 
        value?.numberOfDocksNumber?.toLocaleString('pt-br'), 
        value?.availableAreaNumber?.toLocaleString('pt-br'), 
        value?.negociationValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}), 
        value?.iptuValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}), 
        value?.condominiumValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}), 
        value?.totalCostOfOccupationNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}), 
        value?.address, 
        value?.city,
        value?.cep,
        value?.propertyType,
        enumUsageDescriptions(value?.use),
        value?.use,
        enumStatusAreaDescriptions(value?.status),
        value?.status,
        enumFinishingLevelDescriptions(value?.finishingLevel),
        value?.finishingLevel,
        enumEconomicSectorDescriptions(value?.economicSector),
        value?.economicSector,
        value?.infrastructure,
        value?.obsDifferentials,
        value?.occurrencesComments,
        value?.buildName,
        value?.state,
        value?.zipCode,
        value?.commercialRegion,
        value?.technicalRegion,
        value?.referralRouteHighways,
        value?.latitudeAndLongitude,
        enumAvailabilityDescriptions(value?.availability),
        enumTypeAgreementDescriptions(value?.transactionType),
        enumMarketedByDescriptions(value?.marketedBy),
        value?.representative,
        value?.researcher,
        enumLineOfBusinessDescriptions(value?.occupantsLineOfBusiness),
        value?.descriptionAdvertisement,
        value?.adDescription,
        value?.district,
      ]
      if(generalFilter){
        for (let i = 0; i<filters?.length; i++){
          if(filters[i]?.toUpperCase()?.includes(generalFilter.toUpperCase())){
            retorno = true;
          }
        }
      } else {
        retorno = true;
      }
    }
    return retorno;
  }


  const getRealStatesFunction = async () => {
    setLoading(true);
    try {
      setDados([]);
      const _result = await getRealStates();

      let approved = []
      let notApproved = []
      let filtered = _result.data.filter(filterData)
      for(let i in filtered){
        if(filtered[i].code.includes('TC')){
          notApproved.push(filtered[i])
        } else {
          approved.push(filtered[i])
        }
      }


      let finalArray = approved.sort((a, b) => {
        return Number(b?.code?.slice(b?.code?.length - 5)) -  Number(a?.code?.slice(a?.code?.length - 5))
    })
      let array = finalArray.concat(notApproved)

      setDados(array);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  
  const _executeQuery = async () => {
    setLoading(true);
    try {
      setDados([]);
      const _result = await queryRequest(query);
      if (_result?.response) {
        await Alert.fire("Atenção", _result?.response?.data, "warning");
        setLoading(false);
      } else {
        setDados(_result.data.sort((a, b) => {
          return Number(b.code.slice(b.code.length - 4)) -  Number(a.code.slice(a.code.length - 4))
        }));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onClickFunction = () => {
    navigator.clipboard.writeText(REACT_APP_WEB_BASEURL + "/page-imoveis-leonel");
    Alert.fire("Link copiado!");
  };

  const replaceValue = (_value) => {
    if (_value > 0) {
      return _value?.toLocaleString("pt-BR",{style: 'currency', currency: 'BRL'});
    } else {
      return "";
    }
  };

  const getCapaPicture = (pictures) => {
    let picture = pictures[0].image
    for(let i = 0; i < pictures.length; i++){
      if(pictures[i].capaPlataform === true){
        picture = pictures[i].image
      }
    }
    return picture
  }

  function clear(){
    setGeneralFilter('')
    setTipologyFilter('')
    setCodeFilter('')
    setRegionFilter('')
    setAddressFilter('')
    setAvailableFilter('')
    setKeyFilter('')
    setDistrictFilter('')
    setCityFilter('')
    setOwnerFilter('')
    setSizeFilter('')
    setSizeMaxFilter('')
    setSizeMinFilter('')
    setSizeBeetwen('')
    setValueBeetwen('')
    setValueFilter('')
    setValueMaxFilter('')
    setValueMinFilter('')
    setAreaFilter('')
    setAreaMaxFilter('')
    setAreaMinFilter('')
    setAreaBeetwen('')
    setFilterAreaSelect('')
    setFilterAreaSelect('')
    setFilterSizeSelect('')
    setKey('')
    setAnnounceArray('')
    setKeyArray('')
    setAnnounceChannel('')
    setTransactionFilter('')
    setCepFilter('')
    setClearing(true) 
  }

  useEffect(() => {
    if(clearing){
      getRealStatesFunction()
      setClearing(false)
    }
  },[clearing])

  useEffect(() => {
    if(filterValueSelect === 'beetwen'){
      setValueBeetwen(true)
    } else {
      setValueBeetwen(false)
    }
  },[filterValueSelect])

  useEffect(() => {
    if(filterAreaSelect === 'beetwen'){
      setAreaBeetwen(true)
    } else {
      setAreaBeetwen(false)
    }
  },[filterAreaSelect])

  useEffect(() => {
    if(filterSizeSelect === 'beetwen'){
      setSizeBeetwen(true)
    } else {
      setSizeBeetwen(false)
    }
  },[filterSizeSelect])

  useEffect(() =>{

    if(key.length){
      let array= [];
      for (const i in key){
        array.push(key[i]?.id)
      }
      setKeyArray(array)
    } else {
      setKeyArray()

    }
  },[key])

  useEffect(() =>{

    if(announceChannel.length){
      let array= [];
      for (const i in announceChannel){
        array.push(announceChannel[i]?.id)
      }
      setAnnounceArray(array)
    } else {
      setAnnounceArray()

    }
  },[announceChannel])

  return (
    <div className="mt-5 ml-5 mt-0">
      <div className="form-head page-titles d-flex  align-items-center">
        <div className="mr-auto  d-lg-block">
          <h4 className="text-black font-w600">Imóveis</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/property-guide">Imóveis</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/property-guide">Busca avançada</Link>
            </li>
          </ol>
        </div>
      </div>
      <div className="card card-body">
        {/* Titulo e Botao de Filtro */}
        <div className="row col-lg-12">
          <div className="col-lg-9 col-md-3 mt-2">
            {showAllFilters && (
              <h4 className="card-title" style={{ color: "#3b4cb8", fontSize: "16 px" }}>
                <CgFileDocument className="mr-2" />
               Busca avançada
              </h4>
            )}
            
            {!showAllFilters && (
              <div className="row col-lg-12">
                <div className="col-lg-9">
                  <input
                  value={generalFilter}
                    className="form-control"
                    placeholder="Pesquisar"
                    style={{ borderRadius: "10px" }}
                    onChange={(e) => setGeneralFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getRealStatesFunction();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-2">
                  <button
                    onClick={getRealStatesFunction}
                    className="form-control"
                    style={{
                      fontSize: "16px",
                      borderRadius: "10px",
                      backgroundColor: "#144B72",
                      color: "#fff",
                    }}
                  >
                    <BiSearch />
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-3 col-md-3 text-right">
            {!showAllFilters && (
              <button
                onClick={() => [getRealStatesFunction(), setShowAllFilters(true), setQuery({})]}
                className="btn btn-primary mt-1 mr-2"
                style={{
                  borderRadius: "6px",
                  color: "#fff",
                  backgroundColor: "#144b72",
                  fontSize: "12px",
                }}
              >
                <FiFilter className="mr-2" />
                Busca avançada
              </button>
            )}
            {showAllFilters && (
              <FaWindowClose
                onClick={() => [setShowAllFilters(false), clear()]}
                style={{
                  cursor: "pointer",
                  borderRadius: "6px",
                  color: "#fff",
                  color: "#144b72",
                  fontSize: "30px",
                }}
              />
            )}
          </div>
          {showAllFilters && (
            <div className="row">
               <div className="col-lg-6 col-sm-12 mb-2">
                <label>Disponibilidade</label>
                <select
                className="form-control "
                value={availableFilter}
                style={{ borderRadius: "10px" }}
                    onChange={e => setAvailableFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getRealStatesFunction();
                      }
                    }}
              >
                <option value="">Selecione</option>
                <option value={enumAvailability.available}>
                  {enumAvailabilityDescriptions(enumAvailability.available)}
                </option>
                <option value={enumAvailability.unavailable}>
                  {enumAvailabilityDescriptions(enumAvailability.unavailable)}
                </option>
             
              </select>
              </div>

              <div className="col-lg-6 col-sm-12 mb-2">
                <label>Transação</label>
                <select
                className="form-control "
                value={transactionFilter}
                style={{ borderRadius: "10px" }}
                    onChange={e => setTransactionFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getRealStatesFunction();
                      }
                    }}
              >
                 <option value="">Selecione</option>
                <option value={enumTypeAgreement.buitToSuit}>
                  {enumTypeAgreementDescriptions(enumTypeAgreement.buitToSuit)}
                </option>
                <option value={enumTypeAgreement.location}>
                  {enumTypeAgreementDescriptions(enumTypeAgreement.location)}
                </option>
                <option value={enumTypeAgreement.sale}>
                  {enumTypeAgreementDescriptions(enumTypeAgreement.sale)}
                </option>
                <option value={enumTypeAgreement.saleAndLease}>
                  {enumTypeAgreementDescriptions(enumTypeAgreement.saleAndLease)}
                </option>
                <option value={enumTypeAgreement.saleRented}>
                  {enumTypeAgreementDescriptions(enumTypeAgreement.saleRented)}
                </option>
                <option value={enumTypeAgreement.saleLeaseback}>
                  {enumTypeAgreementDescriptions(enumTypeAgreement.saleLeaseback)}
                </option>
             
              </select>
              </div>

              <div className="col-lg-6 col-sm-12  mb-2">
                <label>Tipologia</label>
               
                <select
               className="form-control"
               style={{ borderRadius: "10px" }}
               value={tipologyFilter}
               onChange={e => setTipologyFilter(e.target.value)}
               onKeyPress={(e) => {
                 if (e.key === "Enter") {
                   getRealStatesFunction();
                 }
               }}
              >
                <option value="">Selecione</option>
                <option value={enumPropertyType.area}>
                  {enumPropertyTypeDescriptions(enumPropertyType.area)}
                </option>
                <option value={enumPropertyType.hangar}>
                  {enumPropertyTypeDescriptions(enumPropertyType.hangar)}
                </option>
                <option value={enumPropertyType.commercial}>
                  {enumPropertyTypeDescriptions(enumPropertyType.commercial)}
                </option>
                <option value={enumPropertyType.store}>
                  {enumPropertyTypeDescriptions(enumPropertyType.store)}
                </option>
                <option value={enumPropertyType.businessAdaptableResidence}>
                  {enumPropertyTypeDescriptions(enumPropertyType.businessAdaptableResidence)}
                </option>
                <option value={enumPropertyType.residential}>
                  {enumPropertyTypeDescriptions(enumPropertyType.residential)}
                </option>
                <option value={enumPropertyType.rurais}>
                  {enumPropertyTypeDescriptions(enumPropertyType.rurais)}
                </option>
                <option value={enumPropertyType.commercialRoom}>
                  {enumPropertyTypeDescriptions(enumPropertyType.commercialRoom)}
                </option>
                <option value={enumPropertyType.walledLand}>
                  {enumPropertyTypeDescriptions(enumPropertyType.walledLand)}
                </option>
                <option value={enumPropertyType.ground}>
                  {enumPropertyTypeDescriptions(enumPropertyType.ground)}
                </option>
              </select>
                </div>
             
              <div className="col-lg-6 col-sm-12 mb-2">
                <label>Endereço</label>
                <input  
                    className="form-control"
                    style={{ borderRadius: "10px" }}
                    value={addressFilter}
                    onChange={e => setAddressFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getRealStatesFunction();
                      }
                    }}
                    >
                </input>
              </div>
              <div className="col-lg-4 col-sm-12 mb-2">
                <label>Cidade</label>
                <input  
                    className="form-control"
                    style={{ borderRadius: "10px" }}
                    value={cityFilter}
                    onChange={e => setCityFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getRealStatesFunction();
                      }
                    }}
                    >
                </input>
              </div>
              <div className="col-lg-4 col-sm-12 mb-2">
                <label>Bairro</label>
                <input  
                    className="form-control"
                    style={{ borderRadius: "10px" }}
                    value={districtFilter}
                    onChange={e => setDistrictFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getRealStatesFunction();
                      }
                    }}
                    >
                </input>
              </div>
              <div className="col-lg-4 col-sm-12 mb-2">
                <label>CEP</label>
                <input  
                    className="form-control"
                    style={{ borderRadius: "10px" }}
                    value={cepFilter}
                    onChange={e => setCepFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getRealStatesFunction();
                      }
                    }}
                    >
                </input>
              </div>
              <div className="col-lg-6 col-sm-12 mb-2">
              <label>Código</label>
              <input  
                    className="form-control"
                    style={{ borderRadius: "10px" }}
                    value={codeFilter}
                    onChange={e => setCodeFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getRealStatesFunction();
                      }
                    }}
                    >
                </input>
                </div>
                <div className="col-lg-6 col-sm-12  mb-2">
                <label>Região Comercial</label>
                <input  
                    className="form-control"
                    style={{ borderRadius: "10px" }}
                    value={regionFilter}
                    onChange={e => setRegionFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getRealStatesFunction();
                      }
                    }}
                    >
                </input>
                
                </div>
                {!sizeBeetwen ? (
                 <div className="col-lg-8 col-sm-12  mb-2">
                 <label>Tamanho imóvel (terreno)</label>
                
                 <CurrencyInput
                className="form-control"
                style={{ borderRadius: "10px" }}
                groupSeparator="."
                decimalSeparator=","
                onValueChange={(value) =>
                  setSizeFilter(Number(value?.replace(/,/g, ".")),)
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getRealStatesFunction();
                  }
                }}
              />
                 </div>
                ) : (
                  <>
                  <div className="col-lg-2 col-sm-12  mb-2">
                  <label>Tamanho Min</label>
                 
                  <CurrencyInput
                className="form-control"
                style={{ borderRadius: "10px" }}
                groupSeparator="."
                decimalSeparator=","
                onValueChange={(value) =>
                  setSizeMinFilter(Number(value?.replace(/,/g, ".")),)
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getRealStatesFunction();
                  }
                }}
              />
                  </div>
                  <div className="col-lg-2 col-sm-12  mb-2">
                  <label>Tamanho Max</label>
                 
                  <CurrencyInput
                className="form-control"
                style={{ borderRadius: "10px" }}
                groupSeparator="."
                decimalSeparator=","
                onValueChange={(value) =>
                  setSizeMaxFilter(Number(value?.replace(/,/g, ".")),)
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getRealStatesFunction();
                  }
                }}
              />
                  </div>
                  </>
                )}
                 <div className="col-lg-4 col-sm-12  mb-2">
                <label>Filtrar tamanho</label>
                <select  
                    className="form-control"
                    style={{ borderRadius: "10px" }}
                    value={filterSizeSelect}
                    onChange={e => setFilterSizeSelect(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getRealStatesFunction();
                      }
                    }}
                    >       
                                          <option value={''}>Selecione</option>
                      <option value={'equal'}>Igual</option>
                      <option value={'less'}>Menor</option>
                      <option value={'more'}>Maior</option>
                      <option value={'beetwen'}>Entre</option>
                </select>
                </div>

                {!valueBeetwen ? (
                  <div className="col-lg-8 col-sm-12  mb-2">
                  <label>Valor</label>
                 
                  <CurrencyInput
                className="form-control"
                style={{ borderRadius: "10px" }}
                groupSeparator="."
                decimalSeparator=","
                onValueChange={(value) =>
                  setValueFilter(Number(value?.replace(/,/g, ".")),)
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getRealStatesFunction();
                  }
                }}
              />
                  </div>
                ) : (
                  <>
                  <div className="col-lg-2 col-sm-12  mb-2">
                  <label>Valor Min</label>
                 
                  <CurrencyInput
                className="form-control"
                style={{ borderRadius: "10px" }}
                groupSeparator="."
                decimalSeparator=","
                onValueChange={(value) =>
                  setValueMinFilter(Number(value?.replace(/,/g, ".")),)
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getRealStatesFunction();
                  }
                }}
              />
                  </div>
                  <div className="col-lg-2 col-sm-12  mb-2">
                  <label>Valor Max</label>
                
                  <CurrencyInput
                className="form-control"
                style={{ borderRadius: "10px" }}
                groupSeparator="."
                decimalSeparator=","
                onValueChange={(value) =>
                  setValueMaxFilter(Number(value?.replace(/,/g, ".")),)
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getRealStatesFunction();
                  }
                }}
              />
                  </div>
                  </>
                )}
              
                <div className="col-lg-4 col-sm-12  mb-2">
                <label>Filtrar valor</label>
                <select  
                    className="form-control"
                    style={{ borderRadius: "10px" }}
                    value={filterValueSelect}
                    onChange={e => setFilterValueSelect(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getRealStatesFunction();
                      }
                    }}
                    >
                      <option value={''}>Selecione</option>
                      <option value={'equal'}>Igual</option>
                      <option value={'less'}>Menor</option>
                      <option value={'more'}>Maior</option>
                      <option value={'beetwen'}>Entre</option>
                </select>
                </div>

               
                {!areaBeetwen ? (
                 <div className="col-lg-8 col-sm-12  mb-2">
                 <label>Tamanho área (construida)</label>
                 <CurrencyInput
                className="form-control"
                style={{ borderRadius: "10px" }}
                groupSeparator="."
                decimalSeparator=","
                onValueChange={(value) =>
                  setAreaFilter(Number(value?.replace(/,/g, ".")),)
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getRealStatesFunction();
                  }
                }}
              />
                 </div>
                ) : (
                  <>
                  <div className="col-lg-2 col-sm-12  mb-2">
                  <label>Área Min</label>
                  <CurrencyInput
                className="form-control"
                style={{ borderRadius: "10px" }}
                groupSeparator="."
                decimalSeparator=","
                onValueChange={(value) =>
                  setAreaMinFilter(Number(value?.replace(/,/g, ".")),)
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getRealStatesFunction();
                  }
                }}
              />
                  </div>
                  <div className="col-lg-2 col-sm-12  mb-2">
                  <label>Área Max</label>
                  <CurrencyInput
                className="form-control"
                style={{ borderRadius: "10px" }}
                groupSeparator="."
                decimalSeparator=","
                onValueChange={(value) =>
                  setAreaMaxFilter(Number(value?.replace(/,/g, ".")),)
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getRealStatesFunction();
                  }
                }}
              />
                  </div>
                  </>
                )}
                 <div className="col-lg-4 col-sm-12  mb-2">
                <label>Filtrar area</label>
                <select  
                    className="form-control"
                    style={{ borderRadius: "10px" }}
                    value={filterAreaSelect}
                    onChange={e => setFilterAreaSelect(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getRealStatesFunction();
                      }
                    }}
                    >
                                          <option value={''}>Selecione</option>
                      <option value={'equal'}>Igual</option>
                      <option value={'less'}>Menor</option>
                      <option value={'more'}>Maior</option>
                      <option value={'beetwen'}>Entre</option>
                </select>
                </div>
                <div className="col-lg-4 col-sm-12  mb-2">
                <label>Proprietário</label>
                <input  
                      className="form-control"
                      style={{ borderRadius: "10px" }}
                      value={ownerFilter}
                      onChange={e => setOwnerFilter(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          getRealStatesFunction();
                        }
                    }}
                      >
                  </input>
                </div>
                <div className="col-lg-4 col-sm-12 mb-2">
                <label>Chave</label>
                <Multiselect
                      showArrow={true}
                      showCheckbox={true}
                      placeholder='Selecione'
                      options={optionsKey} // Options to display in the dropdown
                      selectedValues={key}
                      onRemove={(e) => setKey(e)} // Function will trigger on select event
                      onSelect={(e) => setKey(e)} // Function will trigger on select event
                      displayValue="name" // Property name to display in the dropdown options
                    />
              </div>
              <div className="col-lg-4 col-sm-12 mb-2">
                <label>Canal de anúncio</label>
                <Multiselect
                showArrow={true}
                showCheckbox={true}
                placeholder='Selecione'
                options={optionsAnnounce} // Options to display in the dropdown
                selectedValues={announceChannel}
                onRemove={(e) => setAnnounceChannel(e)} // Function will trigger on select event
                onSelect={(e) => setAnnounceChannel(e)} // Function will trigger on select event
                displayValue="name" // Property name to display in the dropdown options
              />
              </div>
               
                <div className="col-lg-12 text-right">
                <button 
                    style={{
                      fontSize: "16px",
                      borderRadius: "10px",
                      marginRight:"1rem",
                      backgroundColor: "#fff",
                      color: "#144B72",
                      padding: '0.5rem',
                      border:"none"
                    }}
                    onClick={clear}>Limpar</button>
                  <button 
                    style={{
                      fontSize: "16px",
                      borderRadius: "10px",
                      backgroundColor: "#144B72",
                      color: "#fff",
                      padding: '0.5rem',
                      border:"none"
                    }}
                    onClick={getRealStatesFunction}>Buscar</button>
                </div>
            </div>           
          )}         
        </div>       
      </div>
      {/* Anúncio do Imóvel */}
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="mb-5">
          <PaginatedList
            list={dados}
            itemsPerPage={24}
            renderList={(dados) => (
              <>
                <div className="row mt-5 mb-5">
                  {dados?.map((dados) => {
                    return (
                      <div
                        className="col-xl-3 col-lg-3 col-md-3 col-sm-12 m-0"
                        key={dados._id}
                      >
                        <div className="card card-body p-3" style={{ maxWidth: "80%" }}>
                          <div className="text-right mb-2">
                            <input id={"checkbox_" + dados._id} type="checkbox"></input>
                          </div>
                          <div className="new-arrival-product">
                            <div
                              className="new-arrivals-img-contnent"
                              style={{ borderRadius: "6px" }}
                            >
                              <Link to={`/property-details/${dados._id}`} target='_blank'>
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src={
                                    dados?.pictures?.length
                                      ? getCapaPicture(dados?.pictures)
                                      :  "/semImagem.png"
                                  }
                                />
                              </Link>
                            </div>
                            <div className="new-arrival-content text-center mt-3">
                              <span>
                                {dados?.code && dados?.code?.startsWith("TC")
                                  ? ""
                                  : dados?.code}
                              </span>
                              <h4>
                                <Link to={`/property-details/${dados._id}`} target='_blank'>
                                  {dados?.address} - {dados?.number} -
                                  {dados?.city}
                                </Link>
                              </h4>
                              {starFive}
                              <div className="mb-2 fs-12">
                                {dados.district ? (
                                  <span>Bairro {dados.district} </span>
                                ) : null}
                                {dados.landAreaNumber ? (
                                  <span>
                                     com {dados.landAreaNumber.toLocaleString()}m<sup>2</sup>
                                  </span>
                                ) : null}
                              </div>
                              <div className="price fs-16">
                                {dados.sale_value || dados.saleValueNumber ? (
                                  dados.saleValueNumber !== "-" ? (
                                    <div className="col-12">
                                      Venda:{" "}
                                      <span
                                        className="fs-16 text-black"
                                        style={{ fontWeight: "400" }}
                                      >
                                        {replaceValue(dados.sale_value)}
                                        {replaceValue(dados.saleValueNumber)}
                                      </span>
                                    </div>
                                  ) : null
                                ) : null}
                                {dados.lease_value || dados.leaseValueNumber ? (
                                  dados.leaseValueNumber !== "-" ? (
                                    <div className="col-12">
                                      Locação:{" "}
                                      <span
                                        className="fs-16 text-black"
                                        style={{ fontWeight: "400" }}
                                      >
                                        {replaceValue(dados.lease_value)}{" "}
                                        {replaceValue(dados.leaseValueNumber)}
                                      </span>
                                    </div>
                                  ) : null
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          />
        </div>
      )}
      {/* Anúncio do Imóvel */}
    </div>
  );
};

export default PropertyGuide;
