import React, { useState, useEffect } from "react";
import { deleteRealStates, getRealStates } from "./../../services/apiRealState";
import { Link } from "react-router-dom";
import moment from 'moment';
import Alert from "sweetalert2";

const RegionsListing = ({ history }) => {

  const [imoveis, setImoveis] = useState({});

  useEffect(() => {
    moment.locale('pt-br')
    loadData();
  }, []);

  const loadData = () => {
    getRealStates().then((response) => {
      setImoveis(response.data.sort((a, b) => {
        if (a && b) {
          if (moment(a.updatedAt).unix() < moment(b.updatedAt).unix()) {
            return 1;
          }
          if (moment(a.updatedAt).unix() > moment(b.updatedAt).unix()) {
            return -1;
          }
        }
        return 0;
      }))
    })
  };

  const onEdit = (props) => {
    history.push({ pathname: '/general-register', state: { 'data': props } });
  }

  const onDelete = (data) => {
    Alert.fire({
      title: 'Atenção',
      text: 'Deseja excluir esse imóvel?',
      confirmButtonText: 'Excluir',
      confirmButtonColor: "red",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRealStates(data._id, data).then(result => {
          loadData();
        });
      }
    })
  }

  return (
    <div className="container">
      <div className='form-head page-titles d-flex  align-items-center'>
        <div className='mr-auto  d-lg-block'>
          <h4 className='text-black font-w600'>Regiões Comerciais</h4>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item active'>
              <Link to='#'>Regiões cadastradas</Link>
            </li>
            <li className='breadcrumb-item'>
              <Link to='#'>Lista das regiões cadastradas</Link>
            </li>
          </ol>
        </div>
        <Link to='/ImportKMZ' style={{ color: "#fff", backgroundColor: "#144b72" }} className='btn btn-primary rounded  mr-3'>
          Cadastrar região
        </Link>
      </div>
      <div className='row'>
        <div className='col-xl-6 col-xxl-12'>
        </div>
        <div className='col-xl-12'>
          <div className='table-responsive table-hover fs-14'>
            <div id='orderList' className='dataTables_wrapper no-footer'>
              <table
                className='table table-hover display mb-4 dataTablesCard card-table dataTable no-footer'>
                <thead>
                  <tr role='row'>
                    <th className='sorting'>Id</th>
                    <th className='sorting'>Região</th>
                    <th className='sorting'>Data de Cadastro</th>
                    <th className='sorting'>Pesquisadores</th>
                    <th className='sorting' />
                  </tr>
                </thead>
                <tbody>
                  {/* Utilização do Map para criar novas linhas com base nos imóveis registrados */}
                  {(imoveis && imoveis.length) && imoveis.map((dados, index) => {
                    return (
                      <tr key={index} role="row" className="odd">
                        <td >{(++index)}</td>
                        <td >{dados.code}</td>
                        <td >{dados.classe}</td>
                        <td >{dados.city}</td>
                        <td>
                          <button onClick={() => onEdit(dados)} className="btn btn-primary shadow btn-xs sharp mr-1">
                            <i className="fa fa-pencil"></i>
                          </button>

                        </td>
                        <td>
                          <button onClick={() => onDelete(dados)}className="btn btn-danger shadow btn-xs sharp">
                            <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegionsListing