const enumMarketedBy = {
  competitors: "competitors",
  available: "available",
  unavailable: "unavailable",
  leonel: "leonel",
};

const enumMarketedByDescriptions = (key) => {
  switch (key) {
    case "competitors":
      return "Concorrentes";
    case "available":
      return "Disponível";
    case "unavailable":
      return "Indisponível";
    case "leonel":
      return "Leonel";
    default:
      return key;
  }
};

const enumMarketedByDescriptionsInverter = (key) => {
  switch (key) {
    case "Concorrentes":
      return "competitors";
    case "Leonel":
      return "leonel";
    default:
      return key;
  }
};

Object.freeze(enumMarketedBy);

module.exports = {
  enumMarketedBy,
  enumMarketedByDescriptions,
  enumMarketedByDescriptionsInverter,
};
