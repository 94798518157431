// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../images/Rectangle 1.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".flyer {\n    width:210mm;\n    height: 297mm;\n    background-color: white;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: 70vw 60vh;\n}", "",{"version":3,"sources":["webpack://src/css/flyer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,yDAAkD;IAClD,4BAA4B;IAC5B,0BAA0B;AAC9B","sourcesContent":[".flyer {\n    width:210mm;\n    height: 297mm;\n    background-color: white;\n    background-image: url('../images/Rectangle 1.png');\n    background-repeat: no-repeat;\n    background-size: 70vw 60vh;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
