// Importando biblioteca do Firebase
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firebase-database'; 

// Importando configurações do app firebase
import firebaseConfig from './firebaseConfig';

// Inicialização do Firebase pegando as configurações do APP
export const FirebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const storageRef = firebase.storage();
export const dataBase = firebase.database();

export default { FirebaseApp };