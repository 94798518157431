import { api } from "./api";

const { REACT_APP_API_BASEURL } = process.env;

export const getGroups = async () => {
    return await api.get(`${REACT_APP_API_BASEURL}/groups`);
}

export const createGroup = async (data) => {
    return await api.post(`${REACT_APP_API_BASEURL}/groups`, data);
}

export const updateGroup = async (data) => {
    return await api.put(`${REACT_APP_API_BASEURL}/groups`, data);
}

export const deleteGroup = async (id) => {
    return await api.delete(`${REACT_APP_API_BASEURL}/groups`, { data: { id: id } });
}