import React, { useState, useEffect } from "react";
import { auth, dataBase } from "../../../firebaseApi";
import { getChat } from "./../../services/apiChats";
import moment from "moment";
import "moment/locale/pt-br";
var uuid = require("uuid");

const MsgBox = ({ user, openMsg, PerfectScrollbar, offMsg }) => {
  const [messages, setMessages] = useState([]);
  const [msgText, setMsgText] = useState([]);
  const [chatId, setChatId] = useState(null);

  useEffect(() => {
    moment.locale("pt-br");
  }, []);

  useEffect(() => {
    if (openMsg) {
      loadChat();
    }
    setMessages([]);
    setMsgText([]);
    setChatId(null);
  }, [openMsg]);

  useEffect(() => {
    if (chatId) {
      try {
        const chatRef = dataBase.ref().child(`chat/${chatId}`);

        const onChildAdd = chatRef.on("value", (snapshot) => {
          if (snapshot && JSON.stringify(snapshot) && snapshot.val()) {
            const ordenedList = Object.values(snapshot.val()).sort((a, b) => {
              if (a && b) {
                if (a.serverTimeRegister > b.serverTimeRegister) {
                  return 1;
                }
                if (a.serverTimeRegister < b.serverTimeRegister) {
                  return -1;
                }
              }
              return 0;
            });
            setMessages(ordenedList);
          }
        });

        // Stop listening for updates when no longer required
        return () => chatRef.off("value", onChildAdd);
      } catch (error) {
        console.log(error);
      }
    }
  }, [chatId]);

  useEffect(() => {
    var objDiv = document.getElementById("DZ_W_Contacts_Body3");
    objDiv.scrollTop = objDiv.scrollHeight;
  }, [messages]);

  const loadChat = async () => {
    try {
      var _resultGetChat = await getChat(
        auth.currentUser?.uid,
        user.uidFirebase
      );
      setChatId(_resultGetChat.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      addMessage();
    }
  };

  const addMessage = () => {
    if (chatId) {
      dataBase.ref(`chat/${chatId}`).push({
        _id: uuid.v4(),
        createdAt: moment().utc().toISOString(),
        serverTimeRegister: { ".sv": "timestamp" },
        text: msgText,
        user: {
          _id: 1,
          uuid: auth.currentUser?.uid,
          name: auth.currentUser?.displayName,
        },
      });
    }

    setMsgText("");
  };

  return (
    <div className={`card chat dz-chat-history-box ${openMsg ? "" : "d-none"}`}>
      <div className="card-header chat-list-header text-center">
        <a href="#" className="dz-chat-history-back" onClick={() => offMsg()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="18px"
            height="18px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <rect
                fill="#000000"
                opacity="0.3"
                transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000) "
                x="14"
                y="7"
                width="2"
                height="10"
                rx="1"
              />
              <path
                d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                fill="#000000"
                fillRule="nonzero"
                transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "
              />
            </g>
          </svg>
        </a>
        <div>
          <h6 className="mb-1">{user ? user.name : ""}</h6>
        </div>
      </div>
      <PerfectScrollbar
        className={`card-body msg_card_body dz-scroll ${
          openMsg ? "ps ps--active-y" : ""
        } `}
        id="DZ_W_Contacts_Body3"
      >
        {messages.map((dados, index) => {
          return (
            <div key={index}>
              {dados.user.uuid == auth.currentUser?.uid ? (
                <div className="d-flex justify-content-start mb-4">
                  {/* <div className="img_cont_msg">
                    <img
                      src={profile}
                      className="rounded-circle user_img_msg"
                      alt=""
                    />
                  </div> */}
                  <div className="msg_cotainer">
                    {dados?.text}
                    <span className="msg_time">
                      {moment(dados?.createdAt).format("lll")}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-end mb-4">
                  <div className="msg_cotainer_send">
                    {dados?.text}
                    <span className="msg_time_send">
                      {moment(dados?.createdAt).format("lll")}
                    </span>
                  </div>
                  {/* <div className="img_cont_msg">
                    <img
                      src={profile}
                      className="rounded-circle user_img_msg"
                      alt=""
                    />
                  </div> */}
                </div>
              )}
            </div>
          );
        })}
      </PerfectScrollbar>
      <div className="card-footer type_msg">
        <div className="input-group">
          <textarea
            id="msgTextArea"
            className="form-control"
            placeholder="Digite sua mensagem..."
            value={msgText}
            onChange={(e) => setMsgText(e.target.value)}
            onKeyDown={(e) => onEnterPress(e)}
          ></textarea>
          <div onClick={() => addMessage()}>
            <div className="input-group-append">
              <button type="button" className="btn btn-primary">
                <i className="fa fa-location-arrow"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MsgBox;
