import React, { useState, useEffect } from "react";
import leonel_icon from "../../images/leonelLogo.png";
import { auth } from "./../../firebaseApi";
import Alert from "sweetalert2";

const ForgotPassword = ({ history }) => {
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();

  useEffect(() => {
    if (email) {
      if (
        !email.match(`^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$`)
      ) {
        setEmailError("Email inválido");
      } else {
        setEmailError();
      }
    } else {
      setEmailError();
    }
  }, [email]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!email.match(`^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$`)) {
      return;
    }

    try {
      auth
        .sendPasswordResetEmail(email)
        .then(async (result) => {
          await Alert.fire({
            title: `Sucesso`,
            text: `Siga os passos enviados para ${email}`,
            icon: `success`,
          });

          history.push("/");
        })
        .catch((error) => {
          if (error?.code.includes("auth/user-not-found")) {
            Alert.fire({
              title: error?.code,
              text: "Não há registro de usuário correspondente a este identificador. O usuário pode ter sido excluído.",
              icon: "error",
            });
          } else {
            console.log(error);
            Alert.fire({
              title: "Erro",
              text: error,
              icon: "error",
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12 mt-3 pb-0">
                  <a className="ml-4 text-primary" href="/page-login">
                    Voltar
                  </a>
                </div>
                <div
                  className="col-xl-12 mt-0 pt-0 text-center"
                  style={{ top: "5%" }}
                >
                  <img
                    src={leonel_icon}
                    height="60vw"
                    className="leonel_icon "
                  ></img>

                  <div className="auth-form">
                    <h4 className="text-center mb-4">Recuperar senha</h4>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="text-left form-group">
                        <label>
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          defaultValue="hello@example.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div
                          id="val-password-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {emailError}
                        </div>
                      </div>
                      <div className="text-center">
                        <input
                          type="submit"
                          value="Enviar"
                          className="btn btn-primary btn-block"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
