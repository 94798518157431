import React, { useState, useEffect } from "react";
import { auth } from "./../firebaseApi";
import UsefulContext from "./../ultis/usefulProvider";

//React router dom
import { BrowserRouter as Router, Switch } from "react-router-dom";

import PrivateRoute from "../PrivateRoute";
import PublicRoute from "../PublicRoute";

//Css
import "./index.css";
import "./chart.css";
import "./step.css";

//Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

//Dashboard
import Home from "./components/Dashboard/Home";
import PropertyListing from "./components/Dashboard/property-listing";
import PropertyListingToApprove from "./components/Dashboard/property-listing-to-approve";
import UsersListing from "./components/Dashboard/users-listing";
import UserEdit from "./components/Dashboard/edit-user-page";
import PropertyGuide from "./components/Dashboard/property-guide";
import GeneralRegister from "./components/imoveis/general-register";
import ImportKMZ from "./components/Dashboard/ImportKMZ";
import MapImoveis from "./components/Dashboard/map-imoveis";
import FlyerDetails from "./components/Dashboard/flyerDetails";
import ImportPlan from "./components/Dashboard/importPlan";
import realTimeMap from "./components/Dashboard/realTimeMap";


//Imóveis
import PropertyDetail from "./components/Dashboard/property-detail";

//User
import UserProfile from "./components/user/perfil-usuario";
import UsersView from "./components/user/user-profile";

//Pesquisador
import PagePropertyListingSearch from "./components/Dashboard/page-research";
import PageGeneralRegisterSearch from "./components/imoveis/page-general-register-search";
import PageMapImoveisSearch from "./components/Dashboard/page-map-imoveis-search";
import PageProfileSearch from "./components/user/pesquisador/page-profile-search";

//Profiles
import ProfilesRegister from "./components/Dashboard/profiles-register";
import ProfilesListing from "./components/Dashboard/profiles-listing";

//Permissoes
import PermissionsListing from "./components/Dashboard/permissions-listing";
import PermissionsRegister from "./components/Dashboard/permissions-register";

//Rules
import RulesListing from "./components/Dashboard/rules-listing";
import RulesRegister from "./components/Dashboard/rules-register";

//Regions
import RegionsListing from "./components/Dashboard/regions-listing";
import RegionsRegister from "./components/Dashboard/regions-register";

//Pages
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import UserRegister from "./components/Dashboard/users-register";
import GroupsRegister from "./components/Dashboard/groups-register";
import GroupsListing from "./components/Dashboard/groups-listing";
import Config from "./components/Dashboard/config";
import InputImoveis from "./components/Dashboard/inputImoveis";
import ImoveisLeonel from "./components/Dashboard/imoveis-leonel";
import UserActionArea from "./components/Dashboard/user-action-area";
import Faq from "./components/Dashboard/faq";
import EditRegions from "./components/Dashboard/editRegions";

function Markup() {
  const { getUser, userProfiles } = React.useContext(UsefulContext);

  const [authentication, setAuthState] = useState({
    authenticated: false,
    initializing: true,
    isResearch: false
  });

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  useEffect(
    () =>
      auth.onAuthStateChanged((user) => {
        if (user && user.uid) {
          getUser(user.uid);
          setAuthState({
            authenticated: true,
            initializing: false,
            isResearch: false
          });
        } else {
          setAuthState({
            authenticated: false,
            initializing: false,
            isResearch: false
          });
        }
      }),
    [setAuthState]
  );

  useEffect(() => {
    if(userProfiles){
      setAuthState({
        authenticated: true,
        initializing: false,
        isResearch: userProfiles?.researcher
      });
    }
  },[userProfiles])

  if (authentication.initializing) {
    return <div>Loading</div>;
  }

  return (
    <Router basename="">
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"} ${!path ? "right-profile" : ""
          }`}>
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              <PublicRoute component={Login} path="/page-login" exact />

              <PublicRoute
                component={ForgotPassword}
                path="/page-forgot-password"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                isResearch={authentication.isResearch}
                component={GeneralRegister}
                path="/general-register"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                isResearch={authentication.isResearch}
                component={Faq}
                path="/faq"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                isResearch={authentication.isResearch}
                component={Home}
                path="/"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={UserProfile}
                path="/user-profile"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={PagePropertyListingSearch}
                path="/page-research"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={UserRegister}
                path="/users-register"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={UserActionArea}
                path="/user-action-area"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={GroupsRegister}
                path="/groups-register"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={GroupsListing}
                path="/groups-listing"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={PropertyListing}
                path="/property-listing"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={PropertyListingToApprove}
                path="/property-listing-to-approve"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={PagePropertyListingSearch}
                path="/page-property-listing-search"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={InputImoveis}
                path="/input-imoveis"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={GeneralRegister}
                path="/general-register/:id"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={PageGeneralRegisterSearch}
                path="/page-general-register-search"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={PropertyDetail}
                path="/property-details/:id"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={PropertyGuide}
                path="/property-guide"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={ImportKMZ}
                path="/importKMZ"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={EditRegions}
                path="/deleteKMZ"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={MapImoveis}
                path="/map-imoveis"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={PageMapImoveisSearch}
                path="/page-map-imoveis-search"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={UsersListing}
                path="/users-listing"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={UsersView}
                path="/user-view"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={UserEdit}
                path="/users-edit"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={FlyerDetails}
                path="/flyer-details/:id"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={ImportPlan}
                path="/importPlan"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={realTimeMap}
                path="/realTimeMap"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={ProfilesListing}
                path="/profiles-listing"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={ProfilesRegister}
                path="/profiles-register"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={PermissionsListing}
                path="/permissions-listing"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={PermissionsRegister}
                path="/permissions-register"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={RulesListing}
                path="/rules-listing"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={RulesRegister}
                path="/rules-register"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={RegionsListing}
                path="/regions-listing"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={RegionsRegister}
                path="/regions-register"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={Config}
                path="/configurations"
                exact
              />

              <PrivateRoute
                isAuthenticated={authentication.authenticated}
                component={ImoveisLeonel}
                path="/page-imoveis-leonel"
                exact
              />
            </Switch>
          </div>
        </div>

        {!pagePath && <Footer />}
      </div>
    </Router>
  );
}

export default Markup;