import React, { useState, useEffect } from "react";
import { getUserList } from "../../services/apiUser";
import moment from "moment";

/// Images
import profile from "../../../images/engineer_icon.png";
import MsgBox from "./MsgBox";
import { auth } from "../../../firebaseApi";

const Chat = ({ PerfectScrollbar, toggleChatBox, toggleTab }) => {
  const [openMsg, setOpenMsg] = useState(false);
  const [users, setUsers] = useState([]);
  const [userOpen, setUserOpen] = useState();

  useEffect(() => {
    moment.locale("pt-br");
    loadData();
  }, []);

  const loadData = () => {
    try {
      getUserList().then((response) => {
        setUsers(
          response.data
            .filter((_user) => _user.uidFirebase !== auth.currentUser?.uid)
            .sort((a, b) => {
              if (a && b) {
                if (moment(a.created).unix() < moment(b.created).unix()) {
                  return 1;
                }
                if (moment(a.created).unix() > moment(b.created).unix()) {
                  return -1;
                }
              }
              return 0;
            })
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={`tab-pane fade  ${toggleTab === "chat" ? "active show" : ""}`}
      id="chat"
      role="tabpanel"
    >
      <div
        className={`card mb-sm-3 mb-md-0 contacts_card dz-chat-user-box ${
          openMsg ? "d-none" : ""
        }`}
      >
        <PerfectScrollbar
          className={`card-body contacts_body p-0 dz-scroll  ${
            toggleChatBox ? "ps ps--active-y" : ""
          }`}
          id="DZ_W_Contacts_Body"
        >
          <ul className="contacts">
            {users.map((dados, index) => {
              return (
                <li
                  key={index}
                  className="active dz-chat-user"
                  onClick={() => {
                    setUserOpen(dados);
                    setOpenMsg(true);
                  }}
                >
                  <div className="d-flex bd-highlight">
                    <div className="img_cont">
                      <img
                        src={profile}
                        className="rounded-circle user_img"
                        alt=""
                      />
                      <span className="online_icon"></span>
                    </div>
                    <div className="user_info">
                      <span>{dados.name}</span>
                      <p>{dados.email}</p>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </PerfectScrollbar>
      </div>
      <MsgBox
        user={userOpen}
        openMsg={openMsg}
        PerfectScrollbar={PerfectScrollbar}
        offMsg={() => setOpenMsg(false)}
      />
    </div>
  );
};

export default Chat;
