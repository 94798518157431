import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "../../../css/DropzoneMap.css";
import archive from "../../../../src/images/archive-map.png";

function DropzoneIMG(props) {
  const onDrop = useCallback((acceptedFiles) => {
    props.returnFile(acceptedFiles);
  }, []);

  const {
    acceptedFiles,
    fileRejections,
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    accept: ".png, .jpg",
    onDrop,
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <section>
      <div {...getRootProps({ className: "dropzone-map" })}>
        <input {...getInputProps()} />

        <center>
          <img src={archive} height={50}></img>
        </center>
        <h3>
          <center>
            <strong>
              <font color="#144b72">Insira o documento, aqui!</font>
            </strong>
          </center>
        </h3>
        {isDragActive ? (
          <p>Arraste até aqui...</p>
        ) : (
          <p>
            <center>
              <strong>
                Aceitamos somente imagens em .PNG ou .JPG
              </strong>
            </center>
          </p>
        )}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <aside>
            <div className="mt-4">
              <ul>
                {acceptedFileItems.length ? (
                  <h5 className="text-primary">
                    Documento aceito:
                    <ul className="text-black">{acceptedFileItems}</ul>{" "}
                  </h5>
                ) : null}
              </ul>
              <ul>
                {fileRejectionItems.length ? (
                  <h5 className="text-danger">
                    Documento inválido:
                    <ul className="text-black">{fileRejectionItems}</ul>{" "}
                  </h5>
                ) : null}
              </ul>
            </div>
          </aside>
        </div>
      </div>

    </section>
  );
}

export default DropzoneIMG;
