import React, { useState, useEffect } from "react";
import {
  deleteRealStates,
  getRealStates,
} from "../../services/apiRealState";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import UsefulContext from "../../../ultis/usefulProvider";
import Alert from "sweetalert2";
const XLSX = require("xlsx");

import LoadingComponent from "../Loading/LoadingComponent";
import FixedHeaderStory from "react-data-table-component";
import { getUserById } from "../../services/apiUser";

const PropertyListingToApprove = ({ history }) => {
  const { userProfiles } = React.useContext(UsefulContext);

  const [loading, setLoading] = useState(false);

  const [imoveis, setImoveis] = useState([]);
  const [dataValue, setDataValue] = useState([]);
  const [query, setQuery] = useState("");
  const [inputFilter, setInputFilter] = useState("");

  const columns = [
    {
      name: "Editar",
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle className="p-2" style={{ borderRadius: "6px" }}>
            <i className="flaticon-381-settings-6"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onEdit(row)}>Editar</Dropdown.Item>
            <Dropdown.Item onClick={() => onDelete(row)}>Excluir</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
    {
      name: "Endereço",
      selector: (row) => row.address,
      format: (row) => `${row?.address?.slice(0, 1000)}`,
      wrap: true,
      sortable: true,
    },
    {
      name: "Área Terreno",
      wrap: true,
      sortable: true,
      selector: (row) => row.landArea,
    },
    {
      name: "Área Construída",
      wrap: true,
      sortable: true,
      selector: (row) => row.buildingArea,
    },
    {
      name: "Área Galpão",
      wrap: true,
      sortable: true,
      selector: (row) => row.shedArea,
    },
    {
      name: "Cep",
      wrap: true,
      sortable: true,
      selector: (row) => row.cep,
    },
    {
      name: "Cidade",
      selector: (row) => row.city,
      wrap: true,
      sortable: true,
      format: (row) => `${row?.city?.slice(0, 1000)}`,
    },
    {
      name: "Pesquisador",
      selector: (row) => row.researcher,
      wrap: true,
      sortable: true,
      format: (row) => `${row?.researcher}`,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      wrap: true,
      sortable: true,
      format: (row) => `${row?.status?.slice(0, 1000)}`,
    },
  ];

  const customStyles = {
    table: {
      style: {
        minHeight: "600px", // override the row height
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
  };

 

  const filterStatus = (value) => {
    const status = ['Incompleto','Aguardando aprovação','Encontrar proprietário']
    let retorno = false;

    if(value.code?.includes('TC-')){
      if(inputFilter){
        if(
        value?.code?.toUpperCase().includes(inputFilter.toUpperCase()) ||
        value?.address?.toUpperCase().includes(inputFilter.toUpperCase()) ||
        value?.cep?.includes(inputFilter.toUpperCase()) ||
        value?.researcher?.toUpperCase().includes(inputFilter.toUpperCase()) ||
        value?.city?.toUpperCase().includes(inputFilter.toUpperCase()) ||
        value?.district?.toUpperCase().includes(inputFilter.toUpperCase()) ||
        value?.landArea?.toLocaleString("pt-BR")?.includes(inputFilter) ||
        value?.buildingArea?.toLocaleString("pt-BR")?.includes(inputFilter) ||
        value?.shedArea?.toLocaleString("pt-BR")?.includes(inputFilter)
            ){
              if(status.includes(value?.status)){
                retorno = true;
              } else {
                retorno = false;
              }
        } else {
          retorno = false;
        } 
      } else {
        retorno = true;
      }
    } else {
      if(inputFilter){
        if(
        value.code?.includes('TC-') ||
        value?.address?.toUpperCase().includes(inputFilter.toUpperCase()) ||
        value?.cep?.includes(inputFilter.toUpperCase()) ||
        value?.researcher?.toUpperCase().includes(inputFilter.toUpperCase()) ||
        value?.city?.toUpperCase().includes(inputFilter.toUpperCase()) ||
        value?.district?.toUpperCase().includes(inputFilter.toUpperCase()) ||
        value?.landArea?.toLocaleString("pt-BR")?.includes(inputFilter) ||
        value?.buildingArea?.toLocaleString("pt-BR")?.includes(inputFilter) ||
        value?.shedArea?.toLocaleString("pt-BR")?.includes(inputFilter)
            ){
              if(status.includes(value?.status)){
                retorno = true;
              } else {
                retorno = false;
              }
        } else {
          retorno = false;
        } 
      } else {
        if(status.includes(value?.status) ){
          retorno = true;
        } else {
          retorno = false;
        }
      }
      // if(value.code.includes("TC-")){
      //   retorno = true;
      // }
    }
   
       return retorno;
  }

  const loadData = async () => {
    setLoading(true);
    try {
      const _result = await getRealStates();
      if(_result.data.length){
        setImoveis(_result.data)
      }
      const dataData = [];
      for (let i = 0; i < _result.data.length; i++) {
        dataData.push({
          _id: _result.data[i]?._id,
          code: _result.data[i]?.code,
          researcher: _result.data[i]?.pickupEnroller ? _result.data[i]?.pickupEnroller : 'Não informado' ,
          landArea: replaceValue(_result.data[i]?.landAreaNumber),
          buildingArea: replaceValue(_result.data[i]?.buildingAreaNumber),
          shedArea: replaceValue(_result.data[i]?.shedAreaNumber),
          cep: replaceCep(_result.data[i]?.zipCode),
          city: _result.data[i]?.city,
          district: _result.data[i]?.district,
          address: _result.data[i]?.address + " - " + _result.data[i]?.number,
          status: getStatusDescription(_result.data[i]?.approvedStatus),
        });
      }

      setDataValue(dataData.filter(filterStatus))
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onEdit = (props) => {
    history.push({
      pathname: "/general-register",
      state: { data: props, duplicate: false },
    });
  };

  const replaceCep = (_value) => {
    if(_value){
      _value = _value.replace(/\D/g, "");
      _value = _value.replace(/^(\d{5})(\d)/, "$1-$2");
      return _value;
    } else {
      return '-';
    }
  };

  const onDelete = async (data) => {
    try {
      const _result = await Alert.fire({
        title: "Atenção",
        text: "Deseja excluir esse imóvel?",
        confirmButtonText: "Excluir",
        confirmButtonColor: "red",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (_result.isConfirmed) {
        await deleteRealStates(data._id);
        loadData();
      }
    } catch (error) {}
  };

  const getStatusDescription = (key) => {
    if(key){
      switch (key) {
        case "approved":
          return "Aprovado";
        case "waiting":
          return "Aguardando aprovação";
        case "disapproved":
          return "Reprovado";
        case "incompleted":
          return "Incompleto";
        case "findOwner":
          return "Encontrar proprietário";
        default:
          return "Outro";
      }
    }
  };

  const replaceValue = (_value) => {
    if (_value && _value > 0) {
      return _value.toLocaleString("pt-BR",{style: 'currency', currency: 'BRL'});
    } else {
      return "";
    }
  };

  const saveToPlan = () => {
    let arrayImoveis = XLSX.utils.json_to_sheet(imoveis);
    let arquivo = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(arquivo, arrayImoveis, "Imoveis Cadastrados");
    XLSX.writeFile(arquivo, "Imoveis.xlsx");
  };

  const saveToPlanFilter = () => {
    let arrayImoveis = XLSX.utils.json_to_sheet(dataValue);
    let arquivo = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(arquivo, arrayImoveis, "Imoveis Filtrados");
    XLSX.writeFile(arquivo, "ImoveisFiltrados.xlsx");
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <div className="form-head page-titles">
        <div className="row d-flex  align-items-center">
        <div className="mr-auto  d-lg-block">
          <h4 className="text-black font-w600">Imóveis</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/property-listing">Imóveis</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/property-listing">Imóveis para aprovação</Link>
            </li>
          </ol>
        </div>
       </div>
       <div className="row d-flex align-items-center mt-5 mb-5 ml-1">
        <div className="col-md-4">
            <input
              id="setQueryCode"
              value={inputFilter}
              onChange={e => setInputFilter(e.target.value)}
              className="form-control input-rounded-white"
              placeholder="Filtrar"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  loadData();
                }
              }}
            />
          </div>
          <div className="col-md-1">
            <button
              style={{
                color: "#fff",
                backgroundColor: "#144b72",
              }}
              className="btn btn-primary rounded w-100"
              onClick={loadData}
            >
              <i class="fal fa-search"></i>
            </button>
          </div>
          {userProfiles?.exportProperties && (
            <>
          <button
            style={{
              color: "#fff",
              backgroundColor: "#144b72",
            }}
            className="btn btn-primary rounded  mr-3"
            onClick={saveToPlan}
          >
            <i class="fa fa-download" aria-hidden="true"></i>

          Todos
          </button>

          <button
          style={{
            color: "#fff",
            backgroundColor: "#144b72",
          }}
          className="btn btn-primary rounded  mr-3"
          onClick={saveToPlanFilter}
          >
                        <i class="fa fa-download" aria-hidden="true"></i>
          Filtrados
          </button>
          </>
          )}
          </div>
      </div>

      {loading ? (
        <LoadingComponent />
      ) : (
        <FixedHeaderStory
          columns={columns}
          data={dataValue}
          fixedHeader
          pagination
          responsive
          customStyles={customStyles}
          fixedHeaderScrollHeight="600px"
          noDataComponent={"Nenhuma informação foi encontrado"}
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
          paginationComponentOptions={paginationComponentOptions}
        />
      )}
    </div>
  );
};

export default PropertyListingToApprove;
