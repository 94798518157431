import { GeoJSON } from './geojson.js';
import { storageRef } from "./../firebaseApi";
import tokml from "geojson-to-kml";

export const createKml = async (_array) => {

    // cria o cabeçalho para o geoJson
    const data = {
        "type": "FeatureCollection",
        "features": []
    }

    for (let x = 0; x < _array.length; x++) {
        data.features.push(getFeature(_array[x].region, _array[x].coordinates));
    }

    const geojson = new GeoJSON();

    const res = geojson.parse(data, { Point: ["lat", "lng"] });

    const kmlFile = tokml(res?.properties);

    // Download it
    return await uploadFile(kmlFile);
}

const getFeature = (_name, _points) => {

    const _feature = {
        "type": "Feature",
        "geometry": {
            "type": "Polygon",
            "coordinates": []
        },
        "properties": {
            "name": _name,
        }
    }

    // realiza o input das coordenadas dentro da estrutura do geoJson
    for (let x = 0; x < _points.length; x++) {
        _feature.geometry.coordinates.push(_points[x]);
    }

    return _feature;
}

const uploadFile = async (_kmlFile) => {
    const uploadUrl = storageRef.ref(`tempFiles`).child(`${uuidv4()}.kml`);
    await uploadUrl.put(new Blob([_kmlFile]));
    return await uploadUrl.getDownloadURL();
}

const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}