import { api } from "./api";
import { enumAvailabilityDescriptionsInverter } from "./../../enums/EAVALIABILITY";

const numberTypePreProcessing = (v) => {
      try {
            if (
                  !v ||
                  v === null ||
                  v === undefined ||
                  v === "undefined" ||
                  v === ""
            ) {
                  return 0;
            }
            var _onlyNumber = String(v).replace(/\D/g, "");
            if (
                  !_onlyNumber ||
                  _onlyNumber === null ||
                  _onlyNumber === undefined ||
                  _onlyNumber === "undefined" ||
                  _onlyNumber === ""
            ) {
                  return 0;
            }
            const _num = Number(_onlyNumber);
            if (Number.isNaN(_num)) {
                  return 0;
            }
            return _num;
      } catch (error) {
            return 0;
      }
};

export const getRealStates = async (data) => {
      if (data) {
            return await api.get("/realStates/", { params: data });
      } else {
            return await api.get("/realStates/");
      }
};

export const getRealStatesToAprove = async () => {
      return await api.get("/realStates/realStatesToAprove/");
};

export const searchByRealStatesToAprove = async (address) => {
      return await api.get(
            "/realStates/searchByRealStatesToAprove?address=" + address
      );
};

export const getCodeByPropertyType = async (propertyType) => {
      return await api.get(
            `/realStates/getCodeByPropertyType?propertyType=${propertyType}`
      );
};

export const searchRealStatesByZipCode = async (zipCode) => {
      return await api.get(`/realStates/searchByZipCode?zipCode=${zipCode}`);
};

export const getRealStatesByUserId = async (userId) => {
      return await api.get(`/realStates/byUserId?userId=${userId}`);
};

export const getRealStatesById = async (id) => {
      return await api.get("/realStates/" + id);
};

export const searchRealStatesByCode = async (code) => {
      return await api.get("/realStates/searchByCode?code=" + code);
};

export const searchRealStatesByAll = async (code) => {
      return await api.get("/realStates/searchByAll?data=" + code);
};

/* export const searchRealStatesByOwner = async (owner) => {
      return await api.get("/realStates/searchByOwner?owner=" + owner);
};

export const searchRealStatesByAddress = async (address) => {
      return await api.get("/realStates/searchByAddress?address=" + address);
};
 */
export const searchRealStatesByStatus = async (approvedStatus) => {
      return await api.get(
            "/realStates/searchByStatus?approvedStatus=" + approvedStatus
      );
};

export const searchByValue = async (initialValue, finalValue) => {
      return await api.get(
            "/realStates/searchByValue?initialValue=" +
                  initialValue +
                  "&finalValue=" +
                  finalValue
      );
};

export const searchRealStatesByCeiling = async (ceiling) => {
      return await api.get("/realStates/searchByCeiling?ceiling=" + ceiling);
};

export const getRealStatesPictureById = async (id) => {
      return await api.get("/picture/realState/" + id);
};

export const queryRequest = async (_value) => {
      return await api.get(`/realstates/query?value=${_value}`);
};

export const createRealStates = async (realState) => {
      return await api.post("/realStates/", realState);
};

export const updateRealStates = async (id, realState) => {
      return await api.put("/realStates/update/" + id, realState);
};

export const deleteRealStates = async (id) => {
      return await api.delete("/realStates/delete/" + id);
};

export const searchRealStates = async (_values) => {
      var params = {};

      // Código OK
      if (_values.code) {
            params.code = { $regex: _values.code, $options: "i" };
      }

      // Tipo de imóvel OK
      if (_values.propertyType) {
            params.propertyType = {
                  $regex: _values.propertyType,
                  $options: "i",
            };
      }

      // Tipo de docas
      if (_values.typeOfDocks) {
            params.typeOfDocks = { $regex: _values.typeOfDocks, $options: "i" };
      }

      // Pátio de Manobra interno
      if (_values.internalManeuverYard) {
            params.internalManeuverYard = {
                  $regex: _values.internalManeuverYard,
                  $options: "i",
            };
      }

      // Estacionamento para carretas
      if (_values.carParking) {
            params.carParking = { $regex: _values.carParking, $options: "i" };
      }

      // Endereço
      if (_values.address) {
            params.address = { $regex: _values.address, $options: "i" };
      }

      // Bairro
      if (_values.district) {
            params.district = { $regex: _values.district, $options: "i" };
      }

      // Cidade
      if (_values.city) {
            params.city = { $regex: _values.city, $options: "i" };
      }

      // Estado
      if (_values.state) {
            params.state = { $regex: _values.state, $options: "i" };
      }

      // CEP
      if (_values.zipCode) {
            params.zipCode = {
                  $regex: numberTypePreProcessing(_values.zipCode),
                  $options: "i",
            };
      }

      // Região Comercial
      if (_values.commercialRegion) {
            params.commercialRegion = {
                  $regex: _values.commercialRegion,
                  $options: "i",
            };
      }

      // Região Técnica
      if (_values.technicalRegion) {
            params.technicalRegion = {
                  $regex: _values.technicalRegion,
                  $options: "i",
            };
      }

      // Via de referencia
      if (_values.referralRouteHighways) {
            params.referralRouteHighways = {
                  $regex: _values.referralRouteHighways,
                  $options: "i",
            };
      }

      // Latitude e Longitude
      if (_values.latitudeAndLongitude) {
            params.latitudeAndLongitude = {
                  $regex: _values.latitudeAndLongitude,
                  $options: "i",
            };
      }

      // Disponibilidade
      if (_values.availability) {
            params.availability = enumAvailabilityDescriptionsInverter(
                  _values.availability
            );
      }

      // Proprietario
      if (_values.ownerAdministrator) {
            params.ownerAdministrator = {
                  $regex: _values.ownerAdministrator,
                  $options: "i",
            };
      }

      // Ocupante
      if (_values.currentOccupant) {
            params.currentOccupant = {
                  $regex: _values.currentOccupant,
                  $options: "i",
            };
      }

      // Área de Galpão (recebe valor mínimo e valor máximo)
      if (_values.shedAreaNumberStartRange && _values.shedAreaNumberEndRange) {
            params.shedAreaNumber = {
                  $lte: _values.shedAreaNumberStartRange,
                  $gte: _values.shedAreaNumberEndRange,
            };
      }

      // Área de Escritório (recebe valor mínimo e valor máximo)
      if (
            _values.officeAreaNumberStartRange &&
            _values.officeAreaNumberEndRange
      ) {
            params.officeAreaNumber = {
                  $lte: _values.officeAreaNumberStartRange,
                  $gte: _values.officeAreaNumberEndRange,
            };
      }

      // Área construida (recebe valor mínimo e valor máximo)
      if (
            _values.buildingAreaNumberStartRange &&
            _values.buildingAreaNumberEndRange
      ) {
            params.buildingAreaNumber = {
                  $lte: _values.buildingAreaNumberStartRange,
                  $gte: _values.buildingAreaNumberEndRange,
            };
      }

      // Pé direito mínimo (recebe valor mínimo)
      if (
            _values.rightFootNumberStartRange &&
            _values.rightFootNumberEndRange
      ) {
            params.rightFootNumber = {
                  $lte: _values.rightFootNumberStartRange,
                  $gte: _values.rightFootNumberEndRange,
            };
      }

      // Área disponível (recebe valor mínimo e valor máximo)
      if (
            _values.availableAreaNumberStartRange &&
            _values.availableAreaNumberEndRange
      ) {
            params.availableAreaNumber = {
                  $lte: _values.availableAreaNumberStartRange,
                  $gte: _values.availableAreaNumberEndRange,
            };
      }

      // Valor de venda (recebe valor mínimo e valor máximo)
      if (
            _values.saleValueNumberStartRange &&
            _values.saleValueNumberEndRange
      ) {
            params.saleValueNumber = {
                  $lte: _values.saleValueNumberStartRange,
                  $gte: _values.saleValueNumberEndRange,
            };
      }

      // Valor de locação (recebe valor mínimo e valor máximo)
      if (
            _values.leaseValueNumberStartRange &&
            _values.leaseValueNumberEndRange
      ) {
            params.leaseValueNumber = {
                  $lte: _values.leaseValueNumberStartRange,
                  $gte: _values.leaseValueNumberEndRange,
            };
      }

      // Área de Terreno (recebe valor mínimo e valor máximo)

      if (_values.landAreaNumberStartRange && _values.landAreaNumberEndRange) {
            params.landAreaNumber = {
                  $lte: _values.landAreaNumberStartRange,
                  $gte: _values.landAreaNumberEndRange,
            };
      }

      return await api.post(`/realStates/searchBy`, params);
};
