import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { deleteRegions, getRegions, postRegion } from "../../services/apiRegions";
import { Link } from "react-router-dom";
import { kml } from "@tmcw/togeojson";
import UsefulContext from "../../../ultis/usefulProvider";
import ReactLeafletKml from "react-leaflet-kml";
import DropzoneMap from "../Dropzone/DropzoneMap";
import Geocode from "react-geocode";
import Alert from "sweetalert2";
import moment from 'moment';

Geocode.setApiKey("AIzaSyA-uIcPdvgx5alYfpWXuQzRE1yJG88jWBY");
Geocode.setLanguage("pt");
Geocode.setRegion("br");

const EditRegions = ({ history }) => {
    const[regions,setRegions] = useState([])

    const getRegionsData = async() => {
        const response = await getRegions();
        if(response.data){
          setRegions(response.data)
        }
    }

    const deleteRegion = async(region) => {
      Alert.fire({
        title: "Atenção",
        text: `Deseja excluir a região comercial ${region.region}?`,
        confirmButtonText: "Excluir",
        confirmButtonColor: "red",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteRegions(region._id)
          getRegionsData()
        }
      });
    }

    useEffect(() => {
        getRegionsData()
        window.scrollTo(0, 0);
    }, []);
    

  return (
    <>
      <div className="container-fluid-register">
        <div className="form-head page-titles d-flex  align-items-center bg-brad">
          <div className="mr-auto  d-lg-block">
            <h4 className="text-black font-w600">Apagar Regiões do KMZ</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to="#">Regiões</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
         <div className="col-xl-6 col-xxl-12"></div>

          <div className="col-lg-12">
            <div className="table-responsive table-hover fs-14">
                <div
                    id="orderList"
                        className="dataTables_wrapper no-footer"
                                          >
                <table className="table table-hover display mb-4 dataTablesCard card-table dataTable no-footer">
                <thead>
                    <tr role="row">
                        <th className="sorting">
                            Região Comercial
                        </th>
                        <th className="sorting">
                            Data de adição
                        </th>
                        <th className="sorting" />
                    </tr>
                </thead>
                <tbody>
                    {regions.length > 0 &&
                        regions.map((dados,index) => {
                            return(
                            
                            <tr role="row" key={dados?._id} >
                               <td> {dados?.region}</td>
                               <td> {moment(dados?.createdAt).format('DD-MM-YYYY')}</td>
                               <td>
                                    <button
                                    onClick={() => deleteRegion(dados)}
                                    className="btn btn-danger shadow btn-xs sharp"
                                    >
                                        <i className="fa fa-trash"></i>
                                    </button>
                               </td>
                            </tr>
                            )                          
                        })}                  
                </tbody>
                </table>
                </div>
                </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default EditRegions;