import React, { useEffect, useState } from "react";
import { getRegions } from "../../services/apiRegions";

import "react-datepicker/dist/react-datepicker.css";
import Alert from "sweetalert2";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Select from "react-select";
import { getAbsorption, 
  getLocationPrice, 
  getSalePrice, 
  getTotalBusyArea, 
  getTotalFutureStock, 
  getTotalLocationArea, 
  getTotalStock, 
  getVacancy } 
  from "../../services/apiDashboard";
import Chart from 'react-apexcharts'

const Home = () => {
  
  const [endDate, setEndDate] = useState(new Date())
  const [startDate, setStartDate] = useState(() => {
    const date = new Date()
    date.setDate(date.getDate()-30)
    return date
  })


  const [regions, setRegions] = useState([]);
  const [regionsToDisplay, setRegionsToDisplay] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);

  const [filter,setFilter] = useState({
    initialDate: moment().subtract(30,'days').format('DD-MM-YYYY'),
    finalDate: moment().format('DD-MM-YYYY'),
    region: "",
    district:"",
    city:"",
    period:'quarter'
  })

  const [totalStock,setTotalStock] = useState(0);
  const [totalFutureStock,setTotalFutureStock] = useState(0);
  const [totalLocationArea,setTotalLocationArea] = useState(0);
  const [totalBusyArea,setTotalBusyArea] = useState(0);

  const [salePrice,setSalePrice] = useState([]);
  const [locationPrice,setLocationPrice] = useState([]);
  const [absorption,setAbsorption] = useState([]);
  const [vacancy,setVacancy] = useState([]);

  const [loading,setLoading] = useState(false);

  const loadRegions = async () => {
    try {
      const _result = await getRegions();
      if (_result?.response) {
        await Alert.fire(_result?.response?.data);
        return;
      } else if (!_result?.data?.length) {
        return;
      }
      setRegions(_result?.data);
      var _tempRegions = [{ label: "Selecionar todos", value: "" }];
      for (let x = 0; x < _result?.data.length; x++) {
        const _reg = _result?.data[x];
        _tempRegions.push({ value: _reg?.region, label: _reg?.region });
      }
      setRegionsToDisplay(_tempRegions);
    } catch (error) {
      await Alert.fire(JSON.stringify(error));
    }
  }

  const loadData = async () => {
    try {
      setLoading(true)
      const vacancia = await getVacancy(filter);
      setVacancy(vacancia.data)
  
      const absorcao = await getAbsorption(filter);
      setAbsorption(absorcao.data)
  
      const precoLocacao = await getLocationPrice(filter);
      setLocationPrice(precoLocacao.data)
  
      const precoVenda = await getSalePrice(filter);
      setSalePrice(precoVenda.data)
  
      const areaOcupada = await getTotalBusyArea(filter);
      setTotalBusyArea(areaOcupada.data.totalBusyArea)
  
      const estoqueFuturo = await getTotalFutureStock(filter);
      setTotalFutureStock(estoqueFuturo.data.totalFutureStock)
  
      const areaLocacao = await getTotalLocationArea(filter);
      setTotalLocationArea(areaLocacao.data.totalLocationArea)
      
      const estoqueTotal = await getTotalStock(filter);
      setTotalStock(estoqueTotal.data.totalStock)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
   
  }

  useEffect(()=>{
    loadRegions()
    loadData()
  },[])

  return (
    <>
    <div className="container-fluid-register">
      <div className="form-head page-titles d-flex align-items-center bg-brad">
        <div className="mr-auto d-lg-block">
          <h4 className="text-black font-w600">Dashboard</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="#">Relatórios</Link>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                  <label>Data início:</label>
                  <DatePicker 
                  className="input-dashboard"
                  selected={startDate} 
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => 
                    [
                      setStartDate(date),
                      setFilter({
                        ...filter,
                        initialDate:moment(date).format('DD-MM-YYYY'),
                      })
                    ]} 
                  >

                  </DatePicker>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                <label>Data fim:</label>

                  <DatePicker 
                  className="input-dashboard"
                  selected={endDate} 
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => 
                    [
                      setEndDate(date),
                      setFilter({
                        ...filter,
                        finalDate:moment(date).format('DD-MM-YYYY'),
                      })
                    ]} 
                  >
                    
                  </DatePicker>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                <label>Região Comercial:</label>

                {regionsToDisplay.length ?
               
                        <Select
                          name="regions"
                          value={selectedRegions}
                          options={regionsToDisplay}
                          onChange={(e) =>
                            [ setFilter({
                              ...filter,
                              region: e.target?.value,
                            }),setSelectedRegions(e)]
                          }
                        />
                        : <input
                        className="input-dashboard"
                        type="text"
                        name="commercialRegion"
                        value={filter?.region}
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            region: e.target.value,
                          })
                        }
                      />}
             
                </div>
                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                <label>Cidade:</label>

                  <input 
                  className="input-dashboard"
                  value={filter?.city}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      city: e.target.value,
                    })
                  }
                  ></input>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                <label>Bairro:</label>

                  <input 
                  className="input-dashboard"
                  value={filter?.district}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      district: e.target.value,
                    })
                  }
                  ></input>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                  <button 
                  className="mt-4 btn-primary p-2 input-dashboard" 
                  style={{fontSize:'14px'}}
                  onClick={() => loadData()}
                  >Buscar</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header">
              Estoque total (m²)
            </div>
            <div className="card-body">
              <div className="row">
              <div className="col-lg-12 text-center">
              {loading ? (
                  <div class="spinner-border" role="status">
                  </div>
                ):
                (
                  <h2>{totalStock}</h2>

                )}
                </div>
               
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="card">
          <div className="card-header">
             Área total ocupada (m²)
            </div>
            <div className="card-body">
              <div className="row">
              <div className="col-lg-12 text-center">
                {loading ? (
                  <div class="spinner-border" role="status">
                  </div>
                ):
                (
                  <h2>{totalBusyArea}</h2>
                )}
                  
                </div>
             
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="card">
          <div className="card-header">
            Área total disp. locação (m²)
            </div>
            <div className="card-body">
              <div className="row">
              <div className="col-lg-12 text-center">
              {loading ? (
                  <div class="spinner-border" role="status">
                  </div>
                ):
                (
                  <h2>{totalLocationArea}</h2>
                )}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="card">
          <div className="card-header">
            Futuro estoque (m²)
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 text-center">
                {loading ? (
                  <div class="spinner-border" role="status">
                  </div>
                ):
                (
                  <h2>{totalFutureStock}</h2>
                )}
                
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header">
              Preço médio de venda
            </div>
            <div className="card-body">
              <div className="row">
              {loading ? (
                  <span>Carregando...</span>
                ):
                (
                  <Chart 
              options={{
                xaxis: {
                  type: 'category',
                  categories:["AR","GA","IC","LO","RA","RE","FAZ","SC","TM","TE"]
                },
                chart: {
                  type: 'bar',
                  height: 350
                },
                dataLabels: {
                  enabled: false
                },
                yaxis: {  
                  title: {
                    text: 'Preço'
                  },
                  labels: {
                    formatter: function(value) {
                     
                      return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                    }
                  }
                
                },
                fill: {
                  opacity: 1
                },
                tooltip: {
                  y: {
                    formatter: function (val) {
                      return val.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) 
                    }
                  }
                }
                 
              }} 
              series={[
                {
                  name: 'Preço medio venda',
                  data: salePrice.y
                }
               ]
              } 
              type="bar" 
              width={600} 
              height={320} />
                )}
             
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header">
              Preço médio de locação
            </div>
            <div className="card-body">
              <div className="row">
              {loading ? (
                  <span>Carregando...</span>
                ):
                (
                  <Chart 
                  options={{
                    xaxis: {
                      type: 'category',
                      categories:["AR","GA","IC","LO","RA","RE","FAZ","SC","TM","TE"]
                    },
                    chart: {
                      type: 'bar',
                      height: 350
                    },
                    dataLabels: {
                      enabled: false
                    },
                    yaxis: {  
                      title: {
                        text: 'Preço'
                      },
                      labels: {
                        formatter: function(value) {
                         
                          return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                        }
                      }
                    
                    },
                    fill: {
                      opacity: 1
                    },
                    tooltip: {
                      y: {
                        formatter: function (val) {
                          return val.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) 
                        }
                      }
                    }
                     
                  }} 
                  series={[
                    {
                      name: 'Preço medio locação',
                      data: locationPrice.y
                    }
                   ]
                  } 
                  type="bar" 
                  width={600} 
                  height={320} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row w-100">
                <div className="col-lg-9">
                Absorção
                </div>
                <div className="col-lg-3">
                      <select class="input-dashboard w-100" 
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          period: e.target.value,
                        })
                      }
                      >
                        <option disabled>Período</option>
                        <option value="quarter" selected>Trimestre</option>
                        <option value="semester">Semestre</option>
                        <option value="year">Ano</option>
                      </select>
                </div>
              </div>
             
            </div>
            <div className="card-body">
              <div className="row">
              {loading ? (
                  <span>Carregando...</span>
                ):
                (
                  <Chart 
              options={{
                xaxis: {
                  type: 'category',
                  categories:["AR","GA","IC","LO","RA","RE","FAZ","SC","TM","TE"]
                },
                chart: {
                  type: 'area',
                  height: 350
                },
                dataLabels: {
                  enabled: false
                },
                yaxis: {  
                  title: {
                    text: 'Absorção'
                  },
                  labels: {
                    formatter: function(value) {
                     
                      return value.toLocaleString('pt-br') + 'm²'
                    }
                  }
                
                },
                fill: {
                  opacity: 1
                },
                tooltip: {
                  y: {
                    formatter: function (val) {
                      return val.toLocaleString('pt-br') + 'm²'
                    }
                  }
                }
                 
              }} 
              series={[
                {
                  name: 'Absorção atual',
                  data: absorption.yActual
                },
                {
                  name: 'Absorção último período',
                  data: absorption.yPast
                }
               ]
              } 
              type="area" 
              width={600} 
              height={320} />
                )}
             
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header">
              Vacância
            </div>
            <div className="card-body">
              <div className="row">
              {loading ? (
                  <span>Carregando...</span>
                ):
                (
                  <Chart 
                  options={{
                    xaxis: {
                      type: 'category',
                      categories:["AR","GA","IC","LO","RA","RE","FAZ","SC","TM","TE"]
                    },
                    chart: {
                      type: 'area',
                      height: 350
                    },
                    dataLabels: {
                      enabled: false
                    },
                    yaxis: {  
                      title: {
                        text: 'Vacância'
                      },
                      labels: {
                        formatter: function(value) {
                         
                          return value.toLocaleString('pt-br') + '%'
                        }
                      }
                    
                    },
                    fill: {
                      opacity: 1
                    },
                    tooltip: {
                      y: {
                        formatter: function (val) {
                          return val.toLocaleString('pt-br') + '%'
                        }
                      }
                    }
                     
                  }} 
                  series={[
                    {
                      name: 'Vacância',
                      data: vacancy.y
                    },
                   ]
                  } 
                  type="area" 
                  width={600} 
                  height={320} />
                )}
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default Home;
