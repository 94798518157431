export const enumEconomicSector = {
  industry: "industry",
  services: "services",
  commerce: "commerce",
  indcomllogistics: "ind_coml_logistics",
  logisticandcommercial: "logisticandcommercial",
};

export const enumEconomicSectorDescriptions = (key) => {
  switch (key) {
    case "industry":
      return "Indústria";
    case "commerce":
      return "Comércio";
    case "services":
      return "Serviços";
    case "ind_coml_logistics":
      return "Ind/Coml/Logístico";
    case "logisticandcommercial":
      return "Logístico e comercial";
    default:
      return key;
  }
};

export const enumEconomicSectorDescriptionsInverter = (key) => {
  switch (key) {
    case "Indústria":
      return "industry";
    case "Comércio":
      return "commerce";
    case "Serviços":
      return "services";
    case "Ind/Coml/Logístico":
      return "ind_coml_logistics";
    case "Logístico e comercial":
      return "logisticandcommercial";
    default:
      return key;
  }
};

Object.freeze(enumEconomicSector);


