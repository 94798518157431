import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { deleteRegions, getRegions, postRegion, postKMZ } from "../../services/apiRegions";
import { Link } from "react-router-dom";
import { kml } from "@tmcw/togeojson";
import UsefulContext from "../../../ultis/usefulProvider";
import ReactLeafletKml from "react-leaflet-kml";
import DropzoneMap from "../Dropzone/DropzoneMap";
import Geocode from "react-geocode";
import Alert from "sweetalert2";
import moment from 'moment';
import { FirebaseApp } from "../../../firebaseApi";


Geocode.setApiKey("AIzaSyA-uIcPdvgx5alYfpWXuQzRE1yJG88jWBY");
Geocode.setLanguage("pt");
Geocode.setRegion("br");

const ImportKMZ = ({ history }) => {
  const { userProfiles } = React.useContext(UsefulContext);
  const [_kml, set_Kml] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [url, setUrl] = useState(null);

  let inicialPosition = [-21.168689731059985, -47.81409986083559];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const teste = async (filed) => {

    const [file] = await bucket.upload(filed, {
      destination: `uploads/${filed.name}`,
      public: true, // Alias for predefinedAcl = 'publicRead'
    });
    
    const [metadata] = file.getMetadata();
    const url = metadata.mediaLink;
  }

  
  const saveFileKMZ = (file) => {
    const uploadTask = FirebaseApp.storage()
    .ref("file/KMZ/"+file.name)
    .put(file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      // setProgress(progress);
    },
    (error) => {
      console.log(error);
    },
    async () => {
      await FirebaseApp.storage()
        .ref("file/KMZ/"+file.name)
        .getMetadata()
        .then((link) => {
          setUrl(link);
        });
    }
  );
  }
  const returnFile = async (_selectedFile) => {
    try {
      setSelectedFile(_selectedFile[0]);
      if(_selectedFile[0].name.includes('.kmz')){
        saveFileKMZ(_selectedFile[0])
      } else {
        const reader = new FileReader();
        reader.onload = async (e) => {
  
          const text = e.target.result;
  
          const parser = new DOMParser();
  
          const this_kml = parser.parseFromString(text, "text/xml");
          set_Kml(this_kml);
        };
        reader.readAsText(_selectedFile[0]);
      }
     
    } catch (error) {
      console.log(error);
    }
  };



  const saveFile = async () => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        const parser = new DOMParser();
        const this_kml = parser.parseFromString(text, "text/xml");
        const _featureCollection = kml(this_kml);
        var _array = [];
        _featureCollection?.features.forEach(element => {
          _array.push({
            'region': element?.properties?.name,
            'coordinates': element?.geometry?.coordinates,
            'masterPlan': "",
            'observation': "",
            'createdAt': moment().toISOString()
          });
        });

        var actual = await getRegions();
        let arrayActualize = []
        if (actual.data.length){
          let allRegions = [];
          for ( let i in actual.data){
              allRegions.push(actual.data[i].region.toUpperCase())
          }
         let regionsAdd = [];
          for (let j in _array){
            if(!allRegions.includes(_array[j].region.toUpperCase()) && !regionsAdd.includes(_array[j].region)){
              arrayActualize.push(_array[j])
              regionsAdd.push(_array[j].region)
            } else {
              Alert.fire({
                icon:'error',
                title: "Erro",
                text: `Já existe a região comercial ${_array[j].region}`,
              });
          }  
        }
        } else {
          arrayActualize = _array
        }

        var _result = await postRegion(arrayActualize);
        if(_result?.response){
          Alert.fire({
            title: "Atenção",
            text: "Verifique o arquivo enviado.",
          });
          return;
        } else {
          Alert.fire({
            icon:'success',
            title: "Sucesso",
            text: "KMZ adicionado",
          });
          setSelectedFile(null)
        }
        
       // history.goBack();
      };
      reader.readAsText(selectedFile);
    } catch (error) {
      await Alert.fire(JSON.stringify(error));
    }
  };

  return (
    <>
      <div className="container-fluid-register">
        <div className="form-head page-titles d-flex  align-items-center bg-brad">
          <div className="mr-auto  d-lg-block">
            <h4 className="text-black font-w600">Mapa de Regiões</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to="#">Regiões</Link>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-body">
              <div className="row">
                <h4 className="pl-3">Adicione uma ou mais regiões comerciais: </h4>
                {/* {userProfiles?.addKMZ && ( */}
                  <div className="col-lg-12">
                    <DropzoneMap returnFile={returnFile} />
                  </div>
                {/* )} */}
              
                  {userProfiles?.mapOfRegions && (
                  <div className="col-lg-12">
                    <MapContainer
                      style={{ height: "500px", width: "100%", zIndex: 0 }}
                      zoom={10}
                      center={inicialPosition}
                      scrollWheelZoom={true}>
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      />
                      {_kml && <ReactLeafletKml kml={_kml} />}
                    </MapContainer>
                  </div>
                )}
              </div>
              <div className="row">
              <div className="col-lg-12 text-right mb-5 ">
                    <button
                      onClick={saveFile}
                      type="submit"
                      className="btn btn-primary rounded">
                      Salvar
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportKMZ;