import React, { useState, useEffect } from "react";
import { getProfiles, deleteProfile } from "../../services/apiProfiles";
import UsefulContext from './../../../ultis/usefulProvider';
import { Link } from "react-router-dom";
import moment from 'moment';
import Alert from "sweetalert2";

const ProfilesListing = ({ history }) => {

    const { userProfiles } = React.useContext(UsefulContext);

    const [profiles, setProfiles] = useState({});

    useEffect(() => {
        moment.locale('pt-br')
        loadData();
    }, []);

    const loadData = () => {
        getProfiles().then((response) => {
            setProfiles(response.data.sort((a, b) => {
                if (a && b) {
                    if (moment(a.updatedAt).unix() < moment(b.updatedAt).unix()) {
                        return 1;
                    }
                    if (moment(a.updatedAt).unix() > moment(b.updatedAt).unix()) {
                        return -1;
                    }
                }
                return 0;
            }))
        })
    };

    const onEdit = (props) => {
        history.push({ pathname: '/profiles-register', state: { 'data': props, 'status': 2 } })
            ;
    }

    const onVisualize = (props) => {
        history.push({ pathname: '/profiles-register', state: { 'data': props, 'status': 1 } })
            ;
    }

    const onDelete = (data) => {
        Alert.fire({
            title: 'Atenção',
            text: 'Deseja excluir esse perfil de acesso?',
            confirmButtonText: 'Excluir',
            confirmButtonColor: "red",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteProfile(data._id).then((resultDelete) => {
                    Alert.fire({ title: 'Sucesso!', text: `O grupo foi removido com sucesso`, icon: 'success' }).then(() => { loadData(); })
                }).catch((error) => {
                    Alert.fire({ title: 'Ocorreu algum erro ao excluir o grupo!', text: `${error}`, icon: 'error' }).then(() => { loadData(); })
                })
            }
        })
    }

    return (
        <div className="container">
            <div className='form-head page-titles d-flex  align-items-center'>
                <div className='mr-auto  d-lg-block'>
                    <h4 className='text-black font-w600'>Perfis de Acesso</h4>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item active'>
                            <Link to='#'>Perfil de Acesso</Link>
                        </li>
                        <li className='breadcrumb-item'>
                            <Link to='#'>Perfis de Acesso Cadastrados</Link>
                        </li>
                    </ol>
                </div>
                {userProfiles?.addProfiles &&
                    <Link to='/profiles-register' style={{ color: "#fff", backgroundColor: "#144b72" }} className='btn btn-primary rounded  mr-3'>
                        Cadastrar Perfil de Acesso
                    </Link>
                }
            </div>
            <div className='row'>
                <div className='col-xl-6 col-xxl-12'>
                </div>
                <div className='col-xl-12'>
                    <div className='table-responsive table-hover fs-14'>
                        <div id='orderList' className='dataTables_wrapper no-footer'>
                            <table
                                className='table table-hover display mb-4 dataTablesCard card-table dataTable no-footer'>
                                <thead>
                                    <tr role='row'>
                                        <th className='sorting'>Id</th>
                                        <th className='sorting'>Nome</th>
                                        <th className='sorting'>Data de Cadastro</th>
                                        <th className='sorting'>Data de Atualização</th>
                                        <th className='sorting' />
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Utilização do Map para criar novas linhas com base nos imóveis registrados */}
                                    {(profiles && profiles.length) && profiles.map((dados, index) => {
                                        return (
                                            <tr key={index} role="row" className="odd">
                                                <td >{(++index)}</td>
                                                <td >{dados.type}</td>
                                                <td >{moment(dados.created).format("DD-MM-YYYY")}</td>
                                                <td >{moment(dados.updated).format("DD-MM-YYYY")}</td>

                                                {userProfiles?.profilesListing && <td>
                                                    <button onClick={() => onVisualize(dados)} className="btn btn-primary shadow btn-xs sharp mr-1">
                                                        <i className="fa fa-eye"></i>
                                                    </button>
                                                </td>}
                                                {userProfiles?.editProfiles && <td>
                                                    <button onClick={() => onEdit(dados)} className="btn btn-warning shadow btn-xs sharp">
                                                        <i className="fa fa-pencil"></i>
                                                    </button>
                                                </td>}
                                                {userProfiles?.deleteProfiles && <td>
                                                    <button onClick={() => onDelete(dados)} className="btn btn-danger shadow btn-xs sharp">
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </td>}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfilesListing
