import React, { useEffect, useState, useContext } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  KmlLayer,
  InfoWindow
} from "react-google-maps";
import { getRegions } from "../../services/apiRegions";
import { searchRealStatesByZipCode } from "../../services/apiRealState";
import { getActionArea } from "../../services/apiActionArea";
import { createKml } from '../../../ultis/createKML';
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import UsefulContext from "../../../ultis/usefulProvider";
import Select from "react-select";
import Alert from "sweetalert2";
import PageHeaderSearch from "../user/pesquisador/page-header-search";

const _googleUrl =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyA-uIcPdvgx5alYfpWXuQzRE1yJG88jWBY&v=3.exp&libraries=geometry,drawing,places";

const PageMapImoveisSearch = ({ history }) => {

  const { user } = useContext(UsefulContext);

  const [pinArray, setPinArray] = useState(null);
  const [display, setDisplay] = useState(false);
  const [regions, setRegions] = useState([]);
  const [regionsToDisplay, setRegionsToDisplay] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [kmlUrl, setkmlUrl] = useState("");
  const [infoWindowID, setInfoWindowID] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    loadRegions();
  }, []);

  useEffect(() => {
    if (user) {
      load(user._id);
    }
  }, [user]);

  useEffect(() => {
    if (pinArray) {
      setDisplay(true);
    }
  }, [pinArray]);

  const loadRegions = async () => {
    try {
      const _result = await getRegions();
      if (_result?.response) {
        await Alert.fire(_result?.response?.data);
        return;
      } else if (!_result?.data?.length) {
        return;
      }
      setRegions(_result?.data);
      var _tempRegions = [{ label: "Selecionar todos", value: "all" }];
      for (let x = 0; x < _result?.data.length; x++) {
        const _reg = _result?.data[x];
        _tempRegions.push({ value: _reg?._id, label: _reg?.region });
      }
      setRegionsToDisplay(_tempRegions);
    } catch (error) {
      await Alert.fire(JSON.stringify(error));
    }
  }

  // criar função para recuperar area de atuação
  const load = async (_uid) => {
    try {
      const _result = await getActionArea(_uid);
      if (_result?.response) {
        await Alert.fire(_result?.response?.data);
        return;
      }
      loadPins(_result?.data?.area);
    } catch (error) {
      await Alert.fire(JSON.stringify(error));
    }
  };

  const loadPins = async (_zipCode) => {
    try {
      const _result = await searchRealStatesByZipCode(_zipCode);
      if (_result?.response) {
        await Alert.fire(_result?.response?.data);
        return;
      } else if (!_result?.data?.length) {
        setDisplay(true);
        return;
      }
      var _array = [];
      for (let x = 0; x < _result?.data?.length; x++) {
        const _realState = JSON.parse(JSON.stringify(_result?.data[x]));
        if (_realState?.latitudeAndLongitude) {
          try {
            const _lat = _realState?.latitudeAndLongitude?.split(",")[0];
            const _lng = _realState?.latitudeAndLongitude?.split(",")[1];
            const _id = _realState?._id;
            const _availability = _realState?.availability;
            const _code = _realState?.code; // código
            const _image = _realState?.pictures ? _realState?.pictures[0]?.image : ''; // uma imagem miniatura
            const _address = _realState?.address; // um endereço
            const _number = _realState?.number;
            const _district = _realState?.district;
            const _commercialRegion = _realState?.commercialRegion;
            const _city = _realState?.city;
            const _state = _realState?.state;
            const _zipCode = _realState?.zipCode;
            const _buildingArea = _realState?.buildingAreaNumber; // area de galpao
            const _landArea = _realState?.landAreaNumber; // area de terreno.
            if (
              _lat !== null &&
              _lat !== undefined &&
              _lng !== null &&
              _lng !== undefined &&
              _id !== null &&
              _id !== undefined
            ) {
              _array.push({
                lat: _lat,
                lng: _lng,
                id: _id,
                availability: _availability,
                code: _code,
                image: _image,
                address: _address,
                number: _number,
                district: _district,
                commercialRegion: _commercialRegion,
                city: _city,
                state: _state,
                zipCode: _zipCode,
                buildingArea: _buildingArea,
                landArea: _landArea
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
      setPinArray(_array);
    } catch (error) {
      await Alert.fire(JSON.stringify(error));
    }
  };

  const loadKMLS = async () => {
    try {
      if (selectedRegions.length) {
        var _regions = [];
        for (let x = 0; x < selectedRegions.length; x++) {
          const _cvValue = regions.filter((arg) => arg._id === selectedRegions[x].value);
          for (let y = 0; y < _cvValue.length; y++) {
            const element = _cvValue[y];
            _regions.push({ region: element?.region, coordinates: element?.coordinates });
          }
        }
        const _url = await createKml(_regions);
        setkmlUrl(_url);
      } else {
        setkmlUrl("");
      }
    } catch (error) {
      await Alert.fire(JSON.stringify(error));
    }
  }

  const handleChange = (selected) => {
    if (selected.find(option => option.value === "all")) {
      handleChange(regionsToDisplay.slice(1));
    } else {
      setSelectedRegions(selected);
    }
  }

  return (
    <>
      <PageHeaderSearch />
      <div className="container-fluid-register">
        <div className="form-head page-titles d-flex  align-items-center bg-brad">
          <div className="page-titles ml-2">
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item"
                style={{ fontSize: "20px", fontWeight: "500" }}>
                <Link to="/page-profile-search">
                  <AiOutlineArrowLeft />
                  Voltar
                </Link>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-body">
              <div className="mb-3">
                <div className="col-lg-12">
                  <h6>Selecione o KML/KMZ</h6>
                  <div className="row">
                    <div className="mt-2 col-lg-10">
                      {regionsToDisplay.length ?
                        <Select
                          name="regions"
                          isMulti
                          value={selectedRegions}
                          options={regionsToDisplay}
                          onChange={handleChange}
                        />
                        : null}
                    </div>
                    <button onClick={loadKMLS} className="mt-2 col-lg-2 btn btn-primary rounded">Carregar</button>
                  </div>
                </div>
              </div>
              {display && (
                <MapWithAMarker
                  googleMapURL={_googleUrl}
                  loadingElement={<div style={{ height: '640px' }} />}
                  containerElement={<div style={{ height: '640px' }} />}
                  mapElement={<div style={{ height: '640px' }} />}
                  markers={pinArray?.map((place, index) => (
                    <Marker
                      key={index}
                      position={{ lat: Number(place?.lat), lng: Number(place?.lng) }}
                      icon={{
                        url: `https://maps.google.com/mapfiles/ms/icons/${(!place?.availability || place?.availability === 'unavailable') ? "red" : "green"}-dot.png`,
                      }}
                      onClick={() => { setInfoWindowID(index); }}>
                      {infoWindowID === index && (
                        <InfoWindow>
                          <div>
                            <h1>Código {place?.code}</h1>
                            <img src={place?.image} height={160} width={320} />
                            <p></p>
                            <h4>Endereço</h4>
                            <p>{place?.address}, {place?.number} </p>
                            <p>{place?.district} - {place?.zipCode} </p>
                            <p>{place?.city} - {place?.state} </p>
                            <div style={{ display: 'flex', flexdirection: 'row' }}>
                              <div style={{ flex: 1 }}>
                                <h4>Área total</h4>
                                <p>{place?.buildingArea} m²</p>
                              </div>
                              <div style={{ flex: 1 }}>
                                <h4>Área do terreno</h4>
                                <p>{place?.landArea} m²</p>
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  ))}
                  kmlLayer={<KmlLayer url={kmlUrl} options={{ preserveViewport: true }} />}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const MapWithAMarker = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: -21.168689731059985, lng: -47.81409986083559 }}>
      {props.markers}
      {props.kmlLayer}
    </GoogleMap>
  ))
);

export default PageMapImoveisSearch;