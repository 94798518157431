import { enumPropertyTypeDescriptions,  enumPropertyTypeDescriptionsInverter } from "../../../enums/EPROPERTYTYPE";

export const generalColumns = [
    {
      name: "Código",
      wrap: true,
      sortable: true,
      selector: (row) => row.code,
    },
    {
      name: "Tipo do imóvel",
      wrap: true,
      sortable: true,
      selector: (row) => row.propertyType,
    },
    {
      name: "Uso",
      wrap: true,
      sortable: true,
      selector: (row) => row.use,
    },
    {
      name: "Status",
      wrap: true,
      sortable: true,
      selector: (row) => row.status,
    },
    {
      name: "Classe",
      selector: (row) => row.class,
      wrap: true,
      sortable: true,
    },
    {
      name: "Área de terreno (m²)",
      selector: (row) => row.landAreaNumber,
      wrap: true,
      sortable: true,
    },
    {
      name: "Área de Galpão (m²)",
      wrap: true,
      sortable: true,
      selector: (row) => row.shedAreaNumber,
    },
    {
      name: "Área de Escritório",
      wrap: true,
      sortable: true,
      selector: (row) => row.officeAreaNumber,
    },
    {
      name: "Área Construída",
      wrap: true,
      sortable: true,
      selector: (row) => row.buildingAreaNumber,
    },
    {
      name: "Pé direito",
      selector: (row) => row.rightFootNumber,
      wrap: true,
      sortable: true,
    },
    {
      name: "Nº de Docas",
      selector: (row) => row.numberOfDocksNumber,
      wrap: true,
      sortable: true,
    },
    {
      name: "Tipo de docas",
      selector: (row) => row.typeOfDocks,
      wrap: true,
      sortable: true,
    },
    {
      name: "Pátio de Manobra Interno",
      selector: (row) => row.internalManeuverYard,
      wrap: true,
      sortable: true,
    },
    {
      name: "Estacionamento para Carretas",
      selector: (row) => row.carParking,
      wrap: true,
      sortable: true,

    },
    {
      name: "Idade do Imóvel ",
      selector: (row) => row.finishingLevel,
      wrap: true,
      sortable: true,

    },
    {
      name: "Nível do Acabamento ",
      selector: (row) => row.propertyStatus,
      wrap: true,
      sortable: true,

    },
    {
      name: "Estado do Imóvel ",
      selector: (row) => row.economicSector,
      wrap: true,
      sortable: true,

    },
    {
      name: "Infraestrutura",
      selector: (row) => row.infrastructure,
      wrap: true,
      sortable: true,

    },
    {
      name: "Obs/Diferenciais",
      selector: (row) => row.obsDifferentials,
      wrap: true,
      sortable: true,

    },
    {
      name: "Ocorrências / Comentários",
      selector: (row) => row.occurrencesComments,
      wrap: true,
      sortable: true,

    },
    {
      name: "Exclusividade ?",
      selector: (row) => row.exclusivity,
      wrap: true,
      sortable: true,

    },
    {
      name: "Endereço",
      selector: (row) => row.address,
      wrap: true,
      sortable: true,

    },
    {
      name: "Nº",
      selector: (row) => row.number,
      wrap: true,
      sortable: true,

    },
    {
      name: "Bairro",
      selector: (row) => row.district,
      wrap: true,
      sortable: true,

    },
    {
      name: "Bairro Comercial",
      selector: (row) => row.commercialDistrict,
      wrap: true,
      sortable: true,

    },
    {
      name: "Cidade",
      selector: (row) => row.city,
      wrap: true,
      sortable: true,

    },
    {
      name: "Estado",
      selector: (row) => row.state,
      wrap: true,
      sortable: true,

    },
    {
      name: "CEP",
      selector: (row) => row.zipCode,
      wrap: true,
      sortable: true,

    },
    {
      name: "Região Comercial ",
      selector: (row) => row.commercialRegion,
      wrap: true,
      sortable: true,

    },
    {
      name: "Região Técnica",
      selector: (row) => row.technicalRegion,
      wrap: true,
      sortable: true,

    },
    {
      name: "Via de Referência",
      selector: (row) => row.referralRouteHighways,
      wrap: true,
      sortable: true,

    },
    {
      name: "Latitude e Longitude",
      selector: (row) => row.latitudeAndLongitude,
      wrap: true,
      sortable: true,

    },
    {
      name: "Ativo ou Inativo",
      selector: (row) => row.activeOrInactive,
      wrap: true,
      sortable: true,

    },
    {
      name: "Destaque / Super Destaque / Sem destaque",
      selector: (row) => row.highLight,
      wrap: true,
      sortable: true,

    },
    {
      name: "Disponibilidade",
      selector: (row) => row.availability,
      wrap: true,
      sortable: true,

    },
    {
      name: "Área Disponível ",
      selector: (row) => row.availableAreaNumber,
      wrap: true,
      sortable: true,

    },
    {
      name: "Valor  Venda",
      selector: (row) => row.saleValueNumber,
      wrap: true,
      sortable: true,

    },
    {
      name: "Valor Locação",
      selector: (row) => row.leaseValueNumber,
      wrap: true,
      sortable: true,

    },
    {
      name: "Valor IPTU ",
      selector: (row) => row.iptuValueNumber,
      wrap: true,
      sortable: true,

    },
    {
      name: "Valor de Condomínio",
      selector: (row) => row.condominiumValueNumber,
      wrap: true,
      sortable: true,

    },
    {
      name: "Custo total da Ocupação (R$)",
      selector: (row) => row.totalCostOfOccupationNumber,
      wrap: true,
      sortable: true,

    },
    {
      name: "Tipo de Transação",
      selector: (row) => row.transactionType,
      wrap: true,
      sortable: true,

    },
    {
      name: "Comissão Acordada",
      selector: (row) => row.agreedCommission,
      wrap: true,
      sortable: true,

    },
    {
      name: "Comercializado por",
      selector: (row) => row.marketedBy,
      wrap: true,
      sortable: true,

    },
    {
      name: "Valor Transacionado",
      selector: (row) => row.amountTransactedNumber,
      wrap: true,
      sortable: true,

    },
    {
      name: "Encontrar Proprietário",
      selector: (row) => row.findOwner,
      wrap: true,
      sortable: true,

    },
    {
      name: "Proprietário / Administrador (Nome)",
      selector: (row) => row.ownerAdministrator,
      wrap: true,
      sortable: true,

    },
    {
      name: "Representante",
      selector: (row) => row.representative,
      wrap: true,
      sortable: true,

    },
    {
      name: "Contato telefone",
      selector: (row) => row.contactPhone,
      wrap: true,
      sortable: true,

    },
    {
      name: "Contato telefone 2",
      selector: (row) => row.contactPhone2,
      wrap: true,
      sortable: true,

    },
    {
      name: "Contato e-mail",
      selector: (row) => row.contactEmail,
      wrap: true,
      sortable: true,

    },
    {
      name: "Último Ocupante",
      selector: (row) => row.lastOccupant,
      wrap: true,
      sortable: true,

    },
    {
      name: "Ocupante atual",
      selector: (row) => row.currentOccupant,
      wrap: true,
      sortable: true,

    },
    {
      name: "Contato Ocupante",
      selector: (row) => row.occupantContact,
      wrap: true,
      sortable: true,

    },
    {
      name: "Ramo de atividade do Ocupante",
      selector: (row) => row.occupantsLineOfBusiness,
      wrap: true,
      sortable: true,

    },
    {
      name: "Período de Contrato ",
      selector: (row) => row.contractPeriod,
      wrap: true,
      sortable: true,

    },
    {
      name: "Vencimento do contrato",
      selector: (row) => row.contractExpiration,
      wrap: true,
      sortable: true,

    },
    {
      name: "Uso e Ocupação de Solo - zoneamento",
      selector: (row) => row.landUseAndOccupancyZoning,
      wrap: true,
      sortable: true,

    },
    {
      name: "Índice",
      selector: (row) => row.index,
      wrap: true,
      sortable: true,

    },
    {
      name: "Cadastro Prefeitura",
      selector: (row) => row.cityHallRegister,
      wrap: true,
      sortable: true,

    },
    {
      name: "Descrição Anúncio",
      selector: (row) => row.adDescription,
      wrap: true,
      sortable: true,

    },
    {
      name: "Anúncios",
      selector: (row) => row.salesChannel,
      wrap: true,
      sortable: true,

    },
    {
      name: "Captador/Cadastrador",
      selector: (row) => row.pickupEnroller,
      wrap: true,
      sortable: true,

    },
    {
      name: "Data de Cadastro",
      selector: (row) => row.registrationDate,
      wrap: true,
      sortable: true,

    },
    {
      name: "Status de aprovação",
      selector: (row) => row.approvedStatus,
      wrap: true,
      sortable: true,

    },
  ];