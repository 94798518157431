import React, { useState, createContext } from "react";
import { getUserByfireBaseUid } from "./../jsx/services/apiUser";
import { getProfilesRulesByUid } from "./../jsx/services/apiProfileRules";

const UsefulContext = createContext({
  user: {},
  updateUser: {},
  getUser: {},
  updateProfiles: {},
  userProfiles: {},
});

export const UsefulProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userProfiles, setUserProfiles] = useState(null);

  const updateUser = (value) => {
    try {
      setUser(value);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const updateProfiles = (value) => {
    try {
      setUserProfiles(value);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getUser = async (firebaseUid) => {
    try {
      const _result = await getUserByfireBaseUid(firebaseUid);
      if (_result.status === 200) {
        setUser(_result.data);
        const result = await getProfilesRulesByUid(_result.data.customRules ? _result.data._id : _result.data.profile);
        setUserProfiles(JSON.parse(result.data.rules));
        _result.data.rules = JSON.parse(result.data.rules);
        return _result.data;
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <UsefulContext.Provider
      value={{
        user: user,
        updateUser: updateUser,
        getUser: getUser,
        updateProfiles: updateProfiles,
        userProfiles: userProfiles,
      }}
    >
      {children}
    </UsefulContext.Provider>
  );
};

export default UsefulContext;