import React from "react";
import "../../../css/spinnerplanilha.css";
import spinnerImage from "../../../images/spinn.png";


const LoadingPlanilha = () => {
    return (
        <div><img src={spinnerImage} className="image-spin-planilha"/></div>
    );
}

export default LoadingPlanilha;