import React from "react";
import { Link } from "react-router-dom";
import { auth } from "./../../../../firebaseApi";
import Alert from "sweetalert2";
import logo from "../../../../images/img-leonel-imobiliaria.png";
import "../../../../css/pesquisador.css";
import { BsBoxArrowRight, BsPlusLg,BsListOl } from "react-icons/bs";

function PageHeaderSearch(props) {
  const logOut = async () => {
    const _result = await Alert.fire({
      icon: "warning",
      title: "Atenção",
      text: "Deseja realmente sair da aplicação?",
      showConfirmButton: true,
      showCancelButton: true,
    });
    if (_result.isConfirmed) {
      auth.signOut().then(() => {
        window.location.reload()
        history.push("/page-login");
       

      });
    }
  };

  const goToRegister= () => {
    props.history.push("/page-general-register-search");
  };

  const goToList= () => {
    props.history.push("/page-research");
  };  

  return (
    <div style={{position:'fixed',width:'100vw',zIndex:6,top:0}}>
    <div className="navbar row bg-primary ">
      <div className="col-lg-7">
        <Link
          to="#"
          className="nav-logo"
         >
          <img src={logo} height={50}/>
        </Link>
        <span className="text-white fs-18 pb ml-2 ">  
          PESQUISADOR
        </span>
      </div>
      <div className="col-lg-1">
        <strong className='text-white' onClick={() =>logOut()}>
          <BsBoxArrowRight/>  
        </strong>
      </div>
    </div>
    <div className="navbar row" style={{backgroundColor:'#7795d3',justifyContent:'left'}}>
      <div className="col-lg-2 col-sm-5 mb-1">
      <Link
          to="/page-research"
>          <strong className="text-white" style={{background:'transparent'}}>          <BsListOl className="mr-2"/>
          Lista de imóveis
          </strong>
        </Link>
     
      </div>
      <div className="col-lg-2 col-sm-5">
      <Link
          to="/page-general-register-search"
>      
       <strong className="text-white mb-1" style={{background:'transparent'}}>
       <BsPlusLg className="mr-2"/>
          Novo cadastro
        </strong>
        </Link>

      </div>
    </div>
    </div>
  );
}

export default PageHeaderSearch;