import React, { useEffect, useState } from "react";

const Faq = () => {


  return (
    <>
<iframe src="https://scribehow.com/page-embed/FAQ_Plataforma_Leonel_Copy__ceLAju-_TSqXjRXMvCqR1g" width="100%" height="800" allowfullscreen frameborder="0"></iframe>
   </>
  );
};

export default Faq;
