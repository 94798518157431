import React from "react";
import UsefulContext from "./usefulProvider";

export const checkPermissions = (feature) => {

  const { userProfiles } = React.useContext(UsefulContext);
  
  Object.entries(userProfiles).forEach((values) => {
    if (feature === values[0]) {
      return (values[1]);
    }
  });
};