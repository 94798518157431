import React, { Fragment, useState, useEffect, useContext } from "react";
import { putUser } from "./../../services/apiUser";
import { auth } from "../../../firebaseApi";
import UsefulContext from "../../../ultis/usefulProvider";
import PageTitle from "../../layouts/PageTitle";
import Alert from "sweetalert2";

const UserProfile = ({ history }) => {
  const { user } = useContext(UsefulContext);

  const [name, setName] = useState(auth.currentUser?.displayName);
  const [nameError, setNameError] = useState();
  const [email, setEmail] = useState(auth.currentUser?.email);
  const [emailError, setEmailError] = useState();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (name) {
      if (
        !name.match(
          /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
        )
      ) {
        setNameError("Campo deve conter nome e sobrenome");
      } else {
        setNameError();
      }
    } else {
      setNameError();
    }
  }, [name]);

  useEffect(() => {
    if (password) {
      if (!password.match(/\w*[a-z]\w*/)) {
        setPasswordError("Password deve conter ao menos uma letra minuscula");
      } else if (!password.match(/\w*[A-Z]\w*/)) {
        setPasswordError("Password deve conter ao menos uma letra maiuscula");
      } else if (!password.match(/\d/)) {
        setPasswordError("Password deve conter ao menos um número");
      } else if (!password.match(/[!@#$%^&*()\-_"=+{}; :,<.>]/)) {
        setPasswordError("Password deve conter um caractere especial");
      } else if (!password.length >= 8) {
        setPasswordError(`Password deve conter ao mínimo 8 caracteres`);
      } else {
        setPasswordError();
      }
    } else {
      setPasswordError();
    }
  }, [password]);

  useEffect(() => {
    if (email) {
      if (
        !email.match(`^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$`)
      ) {
        setEmailError("Email inválido");
      } else {
        setEmailError();
      }
    } else {
      setEmailError();
    }
  }, [email]);

  const changePassword = () => {
    if (!password.match(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]{8,}$`)) {
      return;
    }
    try {
      auth.currentUser?.updatePassword(password).then((result) => {
          history.goBack();
        }).catch((error) => {
          if (error?.code.includes("auth/requires-recent-login")) {
            Alert.fire({
              title: "auth/requires-recent-login",
              text: "Esta operação é sensível e requer autenticação recente. Faça login novamente antes de tentar novamente esta solicitação.",
              icon: "error",
            });
          } else {
            Alert.fire({
              title: "Erro",
              text: error,
              icon: "error",
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const save = async () => {
    if (!email.match(`^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$`)) {
      return;
    }
    if (
      !name.match(
        /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
      )
    ) {
      return;
    }
    try {
      auth.currentUser
        ?.updateEmail(email)
        .then((resultUpdate) => {
          auth.currentUser
            ?.updateProfile({ displayName: name })
            .then((result) => {
              var _json = {
                id: user._id,
                name: name,
                email: email,
                profile: user.profile,
              };
              putUser(_json)
                .then((resultUpdateUser) => {
                  history.goBack();
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
            if (error?.code.includes("auth/requires-recent-login")) {
                Alert.fire({
                  title: "auth/requires-recent-login",
                  text: "Esta operação é sensível e requer autenticação recente. Faça login novamente antes de tentar novamente esta solicitação.",
                  icon: "error",
                });
              } else {
                Alert.fire({
                  title: "Erro",
                  text: error,
                  icon: "error",
                });
              }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Perfil" />

      <div className="row">
        <div className="col-xl-12">
          <div className="card card-body">
            <div className="row">
              <div className="col-lg-12 mt-2 mb-3">
                <label>Nome</label>
                <input
                  type="text"
                  className="form-control border-primary rounded"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}/>
                <div
                  id="val-password-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}>
                  {nameError}
                </div>
              </div>
              <div className="col-lg-12 mt-2 mb-3">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control border-primary rounded"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}/>
                <div
                  id="val-password-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}>
                  {emailError}
                </div>
              </div>
              {showPassword && (
                <div className="col-lg-4 mt-2 mb-3">
                  <label>Novo Password</label>
                  <input
                    type="text"
                    className="form-control border-primary rounded"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}/>
                  <div
                    id="val-password-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}>
                    {passwordError}
                  </div>
                </div>
              )}
            </div>
            <div className="mt-4 text-right">
              {!showPassword && (
                <button
                  onClick={() => setShowPassword(true)}
                  className="btn rounded btn-primary light mr-2 mb-1 ml-1">
                  Alterar senha
                </button>
              )}
              {showPassword && (
                <button
                  onClick={() => setShowPassword(false)}
                  className="btn rounded btn-primary light mr-2 mb-1 ml-1">
                  Cancelar
                </button>
              )}
              {!showPassword && (
                <button
                  onClick={save}
                  className="btn rounded btn-primary mb-1 ml-1">
                  Salvar
                </button>
              )}
              {showPassword && (
                <button
                  onClick={changePassword}
                  className="btn rounded btn-primary mb-1 ml-1">
                  Salvar senha
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserProfile;