import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createGroup, updateGroup } from "./../../services/apiGroups";
import { getRegions } from "./../../services/apiRegions";
import Alert from "sweetalert2";
import "../../../css/general_register.css";

const GroupsRegister = ({ history, location }) => {
  const [group, setGroup] = useState({});
  const [selectedRegion, setSelectedRegion] = useState();
  const [regions, setRegions] = useState([]);
  const [pageStatus, setPageStatus] = useState();

  const { state } = location;

  useEffect(() => {
    if (state) {
      setGroup(state.data);
      setSelectedRegion(JSON.parse(state.data.regions)[0]);
      setPageStatus(state.status);
    }
  }, [state]);

  useEffect(() => {
    setRegions([]);
    try {
      getRegions().then((result) => {
        var _array = [];
        for (let index = 0; index < result.data.length; index++) {
          const element = result.data[index];
          _array.push(element.region);
        }
        setRegions(_array);
      });
    } catch (error) {
      console.log(JSON.parse(error));
    }
  }, []);

  const handleSubmit = () => {
    if (!group.name) {
      Alert.fire({
        title: "Atenção!",
        text: `O campo Nome é inválido!`,
        icon: "warning",
      }).then(() => {
        return;
      });
    }
    if (!regions || !regions.length) {
      Alert.fire({
        title: "Atenção!",
        text: `O campo Regiões é inválido!`,
        icon: "warning",
      }).then(() => {
        return;
      });
    }
    if (!pageStatus) {
      createGroup({
        name: group.name,
        description: group.description,
        regions: [selectedRegion],
      })
        .then((result) => {
          Alert.fire({
            title: "Sucesso!",
            text: `Grupo criado com sucesso!`,
            icon: "success",
          }).then(() => {
            history.goBack();
            return;
          });
        })
        .catch((error) => {
          Alert.fire({
            title: "Falha ao criar o grupo!",
            text: `${error}`,
            icon: "error",
          }).then(() => {
            return;
          });
        });
    } else if (pageStatus && pageStatus === 2) {
      var _json = group;
      _json.id = _json._id;
      _json.regions = [selectedRegion];
      updateGroup(_json)
        .then((result) => {
          Alert.fire({
            title: "Sucesso!",
            text: `Grupo alterado com sucesso!`,
            icon: "success",
          }).then(() => {
            history.goBack();
            return;
          });
        })
        .catch((error) => {
          Alert.fire({
            title: "Falha ao alterar o grupo!",
            text: `${error}`,
            icon: "error",
          }).then(() => {
            return;
          });
        });
    }
  };

  return (
    <>
      <div className="container-fluid-register">
        <div className="form-head page-titles d-flex  align-items-center bg-brad">
          <div className="mr-auto  d-lg-block">
            <h4 className="text-black font-w600">Cadastro de Grupo</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to="#">Grupos</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="#">Cadastro de um grupo</Link>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="form">
        <div className="background">
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-3 text-center">Cadastro de Grupo</h4>
            </div>
          </div>
          <div className="row fs-14">
            <div className="col-md-6 mt-3">
              <label className="ml-3 labelNormal">Nome:</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="inputName"
                value={group.name}
                onChange={(e) => setGroup({ ...group, name: e.target.value })}
                placeholder=""
                required
              ></input>
            </div>
            <div className="col-md-6 mt-3">
              <label className="ml-3 labelNormal">Descrição:</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="inputUser"
                value={group.description}
                onChange={(e) =>
                  setGroup({ ...group, description: e.target.value })
                }
                placeholder=""
              ></input>
            </div>
            <div className="col-md-6 mt-3">
              <label className="ml-3 labelNormal">Regiões:</label>
              <select
                className="form-control input-rounded"
                value={selectedRegion}
                onChange={(e) => {
                  setSelectedRegion(e.target.value);
                }}
              >
                <option disabled={true} selected={true}>{`Selecione`}</option>
                {regions &&
                  regions.map((_data, index) => {
                    return (
                      <option key={index} value={_data}>
                        {_data}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {pageStatus && pageStatus === 2 && (
            <div className="mt-5 text-right">
              <button
                onClick={handleSubmit}
                type="button"
                style={{
                  color: "#fff",
                  backgroundColor: "#144b72",
                  borderRadius: "6px",
                }}
                className="btn borderRadius btnCancel mt-3 mr-3 text-primary"
              >{`Alterar`}</button>
            </div>
          )}
          {!pageStatus && (
            <div className="mt-5 text-right">
              <button
                onClick={handleSubmit}
                type="button"
                style={{
                  color: "#fff",
                  backgroundColor: "#144b72",
                  borderRadius: "6px",
                }}
                className="btn borderRadius btnCancel mt-3 mr-3 text-primary"
              >{`Salvar`}</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GroupsRegister;