import React, { useState } from "react";
import { createRealStates } from './../../services/apiRealState';
import moment from "moment";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";

var uuid = require('uuid');

const ImportPlan = () => {

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  // // process CSV data
  // const processData = (dataString) => {
  //   // const dataStringLines = dataString.split(/\r\n|\n/);
  //   const dataStringLines = dataString.split(/\r\n|\n/);
  //   const headers = dataStringLines[0].split(
  //     /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
  //   );

  //   const list = [];

  //   for (let i = 1; i < dataStringLines.length; i++) {
      
  //     const row = dataStringLines[i].split(
  //       /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
  //     );

  //     if (headers && row.length == headers.length) {
  //       const obj = {};
  //       for (let j = 0; j < headers.length; j++) {
  //         let d = row[j];
  //         if (d.length > 0) {
  //           if (d[0] == '"') d = d.substring(1, d.length - 1);
  //           if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
  //         }
  //         if (headers[j]) {
  //           obj[headers[j]] = d;
  //         }
  //       }
  //       // remove the blank rows
  //       if (Object.values(obj).filter((x) => x).length > 0) {
  //         list.push(obj);
  //       }
  //     }

  //   }

  //   // prepare columns list from headers
  //   const columns = headers.map((c) => ({
  //     name: c,
  //     selector: c,
  //   }));

  //   setColumns(columns);

  //   setData(list);

  //   try {

  //     list.forEach(async (value) => {
       
  //       try {
          
  //         const newJson = JSON.parse(JSON.stringify(value));
          
  //         var json = {
  //           street: newJson["Endereço"] ? newJson["Endereço"] : "",
  //           number: newJson["Nº"]
  //             ? Number(newJson["Nº"]) !== NaN
  //               ? Number(newJson["Nº"])
  //               : 0
  //             : 0,
  //           type_agreement: "RENT",
  //           real_state_type: "OTHERS",
  //           usage: newJson["Uso"] ? newJson["Uso"] : "",
  //           status_real_state: "READY",
  //           district: newJson["Bairro"] ? newJson["Bairro"] : "",
  //           state: newJson["Estado"] ? newJson["Estado"] : "",
  //           city: newJson["Cidade"] ? newJson["Cidade"] : "",
  //           zip_code: newJson["CEP"] ? newJson["CEP"] : "",
  //           property_age: newJson["Idade do Imóvel "]
  //             ? Number(newJson["Idade do Imóvel "]) !== NaN
  //               ? Number(newJson["Idade do Imóvel "])
  //               : 0
  //             : 0,
  //           reference_road: newJson["Via de Referência (Rodovias)"]
  //             ? newJson["Via de Referência (Rodovias)"]
  //             : "",
  //           land_area: newJson["Área de terreno (m²)"]
  //             ? Number(newJson["Área de terreno (m²)"]) !== NaN
  //               ? Number(newJson["Área de terreno (m²)"])
  //               : 0
  //             : "",
  //           property_area: newJson["Área de Galpão (m²)"]
  //             ? Number(newJson["Área de Galpão (m²)"]) !== NaN
  //               ? Number(newJson["Área de Galpão (m²)"])
  //               : 0
  //             : "",
  //           building_area: newJson["Área Construída"]
  //             ? Number(newJson["Área Construída"]) !== NaN
  //               ? Number(newJson["Área Construída"])
  //               : 0
  //             : "",
  //           ceiling: newJson["Pé direito Máximo"]
  //             ? Number(newJson["Pé direito Máximo"]) !== NaN
  //               ? Number(newJson["Pé direito Máximo"])
  //               : 0
  //             : "",
  //           docks_number: newJson["Nº de Docas"]
  //             ? Number(newJson["Nº de Docas"]) !== NaN
  //               ? Number(newJson["Nº de Docas"])
  //               : 0
  //             : 0,
  //           internal_maneuvering_yard: newJson["Pátio de Manobra Interno"]
  //             ? newJson["Pátio de Manobra Interno"]
  //             : false,
  //           parking_for_trailers: newJson["Estacionamento para Carretas"]
  //             ? newJson["Estacionamento para Carretas"]
  //             : false,
  //           avaliability: false,
  //           avaiable_area: newJson["Área Disponível "]
  //             ? newJson["Área Disponível "]
  //             : "",
  //           sale_value: newJson[" Valor Venda (R$)"]
  //             ? Number(newJson[" Valor Venda (R$)"]) !== NaN
  //               ? Number(newJson[" Valor Venda (R$)"])
  //               : 0
  //             : "",
  //           lease_value: newJson[" Valor Locação (R$)"]
  //             ? Number(newJson[" Valor Locação (R$)"]) !== NaN
  //               ? Number(newJson[" Valor Locação (R$)"])
  //               : 0
  //             : "",
  //           iptu_value: newJson[" Valor IPTU (R$ mensal)"]
  //             ? Number(newJson[" Valor IPTU (R$ mensal)"]) !== NaN
  //               ? Number(newJson[" Valor IPTU (R$ mensal)"])
  //               : 0
  //             : "",
  //           condominuim_fees: newJson[" Valor de Condomínio (R$)"]
  //             ? Number(newJson[" Valor de Condomínio (R$)"]) !== NaN
  //               ? Number(newJson[" Valor de Condomínio (R$)"])
  //               : 0
  //             : "",
  //           latitude_and_longitude: newJson["Latitude e Longitude"]
  //             ? newJson["Latitude e Longitude"]
  //             : "",
  //           occupation_total_cost: newJson[" Custo total da Ocupação (R$)"]
  //             ? Number(newJson[" Custo total da Ocupação (R$)"]) !== NaN
  //               ? Number(newJson[" Custo total da Ocupação (R$)"])
  //               : 0
  //             : "",
  //           agreed_comission: newJson["Comissão Acordada"]
  //             ? newJson["Comissão Acordada"]
  //             : "",
  //           exclusivity: false,
  //           citty_hall_registration: newJson["Cadastro Prefeitura"]
  //             ? newJson["Cadastro Prefeitura"]
  //             : "",
  //           competitive_advantages: newJson[
  //             "Obs/Diferenciais: Ponte Rolante, Trasnformador, Energia, etc"
  //           ]
  //             ? newJson[
  //             "Obs/Diferenciais: Ponte Rolante, Trasnformador, Energia, etc"
  //             ]
  //             : "",
  //           occurences_and_comments: newJson["Ocorrências / Comentários"]
  //             ? newJson["Ocorrências / Comentários"]
  //             : "",
  //           announcment_viva_real: false,
  //           date_register: newJson["Data de Cadastro"]
  //             ? moment(newJson["Data de Cadastro"]).isValid()
  //               ? moment(
  //                 newJson["Data de Cadastro"],
  //                 "DD/MM/YYYY"
  //               ).toISOString()
  //               : moment("01/01/1900", "DD/MM/YYYY").toISOString()
  //             : "",
  //           code: newJson["Código"] ? newJson["Código"] : "",
  //           uuid: uuid.v4(), // criando constante que vai criar e armazenar o uuid do imóvel a ser registrado
  //         };

  //         await createRealStates(json);

  //       } catch (error) {

  //         console.log(error);

  //       }

  //     });

  //   } catch (error) {

  //     console.log(error);

  //   }

  // };

  // handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  };


  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);
        };
        reader.readAsArrayBuffer(e.target.files[0]);

    }


}
  return (
    <div>
      <label>
        Selecione
        <input type="file" accept=".csv,.xlsx,.xls" onChange={readUploadFile} />
      </label>
      {/* <DataTable pagination highlightOnHover columns={columns} data={data} /> */}
    </div>
  );
};

export default ImportPlan;