import React, { useEffect, useState } from "react";
import { getUserByfireBaseUid, loginUser } from "./../services/apiUser";
import leonel_icon from "../../images/leonelLogo.png";
import ilus_login from "../../images/ilus_login.png";
import Alert from "sweetalert2";
import Loading from "../components/Loading/Loading";
import "../../css/login_page.css";
import { useHistory } from 'react-router-dom';

const Login = () => {

  const [loggedInState, setLoggedInState] = useState();

  const submitHandler = (e) => {
    e.preventDefault();
    setLoggedInState("logging in");
  };

  const history = useHistory();


  // Criação dos states email e senha onde ficarão armazenados os valores dos inputs
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  // criação do login onde foi importado a função loginUser que está recebendo os valores
  // do email e da senha
  const makeLogin = async () => {
    try {

      const response = await loginUser(email, password);

      const logged = await getUserByfireBaseUid(response.user.uid)
      if(logged.data._id){
        setLoggedInState();
        history.push("/general-register");
      } else{
        await Alert.fire({
          icon: "error",
          title: "Erro",
          text: "Email e/ou senha incorretos!",
        });
        return
      }
    
    } catch (err) {
      await Alert.fire({
        icon: "error",
        title: "Erro",
        text: "Email e/ou senha incorretos!",
      });
      setLoggedInState();
    }
  };

  return (
    <div className="row">
      <div className="col-lg-6 col-md-6 left-bg ">
        <div className="padding-text">
          <div className="text-center">
            <img src={leonel_icon} height="60vw" className="leonel_icon "></img>
            <h1>Bem vindo a imobiliária Leonel</h1>
            <p style={{ color: "#144b72" }}>
              <strong>ACESSE A PLATAFORMA</strong>
            </p>
          </div>
        </div>
        <div className="padding mt-5">
          <form action="" onSubmit={(e) => submitHandler(e)} id="signinForm">
            <span>Email</span>
            <input
              type="email"
              className="mb-3 form-control input-rounded mb-3"
              placeholder="exemplo@gmail.com"
              width="100px"
              name="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            {loggedInState === "logging in" ? (
              <div className="">
                {" "}
                <Loading />
              </div>
            ) : (
              ""
            )}
            <span>Senha</span>
            <input
              type="password"
              className="form-control input-rounded"
              placeholder="*********"
              width="100px"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="text-center">
              <button
                className="mt-5 btn-primary btn-rounded mt-4 mb-4"
                onClick={makeLogin}
              >
                Entrar
              </button>
            </div>
          </form>
        </div>
        <div className="row text-center row-links">
          <div className="col-12 forgot">
            <a href="/page-forgot-password" className="text-primary">
              {" "}
              Esqueceu a senha?
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 right-bg padrao d-md-none d-lg-block">
        <div className="padding-rightside">
          <img src={ilus_login} className="ilus_login"></img>
          <h1>Imóveis que cabem no seu bolso</h1>
          <p>Faça login e confira você mesmo</p>
        </div>
      </div>
    </div>
  );
};

export default Login;