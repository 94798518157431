import { api } from "./api";

const { REACT_APP_API_BASEURL } = process.env;

export const getTotalStock = async (filters) => {
  return await api.get(
    `${REACT_APP_API_BASEURL}/dashboard/totalStock?initialDate=${filters.initialDate}&finalDate=${filters.finalDate}&region=${filters.region}&city=${filters.city}&district=${filters.district}`
    );
};

export const getTotalBusyArea = async (filters) => {
  return await api.get(
    `${REACT_APP_API_BASEURL}/dashboard/totalBusyArea?initialDate=${filters.initialDate}&finalDate=${filters.finalDate}&region=${filters.region}&city=${filters.city}&district=${filters.district}`
    );
};

export const getTotalLocationArea = async (filters) => {
  return await api.get(
    `${REACT_APP_API_BASEURL}/dashboard/totalLocationArea?initialDate=${filters.initialDate}&finalDate=${filters.finalDate}&region=${filters.region}&city=${filters.city}&district=${filters.district}`
    );
};

export const getTotalFutureStock = async (filters) => {
  return await api.get(
    `${REACT_APP_API_BASEURL}/dashboard/totalFutureStock?initialDate=${filters.initialDate}&finalDate=${filters.finalDate}&region=${filters.region}&city=${filters.city}&district=${filters.district}`
    );
};

export const getSalePrice = async (filters) => {
  return await api.get(
    `${REACT_APP_API_BASEURL}/dashboard/salePrice?initialDate=${filters.initialDate}&finalDate=${filters.finalDate}&region=${filters.region}&city=${filters.city}&district=${filters.district}`
    );
};

export const getLocationPrice = async (filters) => {
  return await api.get(
    `${REACT_APP_API_BASEURL}/dashboard/locationPrice?initialDate=${filters.initialDate}&finalDate=${filters.finalDate}&region=${filters.region}&city=${filters.city}&district=${filters.district}`
    );
};

export const getAbsorption = async (filters) => {
  return await api.get(
    `${REACT_APP_API_BASEURL}/dashboard/absorption?initialDate=${filters.initialDate}&finalDate=${filters.finalDate}&period=${filters.period}&region=${filters.region}&city=${filters.city}&district=${filters.district}`
    );
};

export const getVacancy = async (filters) => {
  return await api.get(
    `${REACT_APP_API_BASEURL}/dashboard/vacancy?initialDate=${filters.initialDate}&finalDate=${filters.finalDate}&region=${filters.region}&city=${filters.city}&district=${filters.district}`
    );
};
