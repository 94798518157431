export const enumAvailability = {
      available: "available",
      unavailable: "unavailable",
      competitors: "competitors",
      leonel: "leonel"
};

export const enumAvailabilityDescriptions = (key) => {
      switch (key) {
            case "competitors":
                  return "Concorrentes";
            case "available":
                  return "Disponível";
            case "unavailable":
                  return "Indisponível";
            case "leonel":
                  return "Leonel";
            default:
                  return key;
      }
};

export const enumAvailabilityDescriptionsInverter = (key) => {
      switch (key) {
       
            case "Disponível":
                  return "available";
            case "Indisponível":
                  return "unavailable";
            default:
                  return key;
      }
};

Object.freeze(enumAvailability);