import { api } from "./../services/api";

const { REACT_APP_API_BASEURL } = process.env;

export const getRegions = async () => {
    return await api.get(`${REACT_APP_API_BASEURL}/regions`);
}

export const getRegionsById = async (_id) => {
    return await api.get(`${REACT_APP_API_BASEURL}/regions?id=${_id}`);
}

export const postRegion = async (_data) => {
    return await api.post(`${REACT_APP_API_BASEURL}/regions`, _data);
}

export const postKMZ = async (_data) => {
    return await api.post(`${REACT_APP_API_BASEURL}/regions/transformKMZ`, _data);
}

export const putRegions = async (_id, _data) => {
    return await api.put(`${REACT_APP_API_BASEURL}/regions?id=${_id}`, _data);
}

export const deleteRegions = async (_id) => {
    return await api.delete(`${REACT_APP_API_BASEURL}/regions?id=${_id}`);
}