import React from "react";
import { BsFillPersonFill } from "react-icons/bs";

const OwnerDataCard = (props) => {
  const dados = props.dados;

  const enumKey = (key) => {
    switch (key) {
      case "leonel":
        return "Leonel";
      case "watchdog":
        return "Cão de guarda";
      case "owner":
        return "Proprietário";
      case "realState":
        return "Imobiliária";
        case "other":
          return "Outros";
      default:
        return key;
    }
  };
  return (
    <>
      <div className="card pl-2">
        <div className="card-header row">
          <div className="row ml-2">
            <BsFillPersonFill color="#297F00" className="fa-lg" />
            <h4 className="ml-3">Dados do proprietário</h4>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            {dados?.keyLocation ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Chaves</h4>
                
                <h5>{dados?.keyLocation?.map ? dados.keyLocation.map((data) => {
                  return(<li className="m-1">{enumKey(data)} {data === 'other' && dados?.otherKeyLocation ? <> : {dados.otherKeyLocation} </>: null}</li>)
                }) : <>{enumKey(dados?.keyLocation)} {dados?.keyLocation === 'other' && dados?.otherKeyLocation ? <> : {dados.otherKeyLocation} </>: null}</>}</h5>
              </div>
            ) : null}

            {dados?.ownerAdministrator ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Proprietário</h4>
                <h5>{dados?.ownerAdministrator}</h5>
              </div>
            ) : null}

            {dados?.owner ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Proprietário</h4>
                <h5>{dados?.owner}</h5>
              </div>
            ) : null}

            {dados?.ownerPhone ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Contato proprietário</h4>
                <h5>{dados?.ownerPhone}</h5>
              </div>
            ) : null}

            {dados?.ownerEmail ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Email proprietário</h4>
                <h5>{dados?.ownerEmail}</h5>
              </div>
            ) : null}

            {dados?.representative ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Representante</h4>
                <h5>{dados?.representative}</h5>
              </div>
            ) : null}

            {dados?.representativePhone ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Contato do representante</h4>
                <h5>{dados?.representativePhone}</h5>
              </div>
            ) : null}

            {dados?.representantEmail ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Email do representante</h4>
                <h5>{dados?.representantEmail}</h5>
              </div>
            ) : null}

            {dados?.contactPhone && dados?.contactPhone !== '0' ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Contato 1 </h4>
                <h5>{dados?.contactPhone}</h5>
              </div>
            ) : null}
            
            {dados?.contactPhone2 && dados?.contactPhone2 !== '0'  ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Contato 2 </h4>
                <h5>{dados?.contactPhone2}</h5>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerDataCard;
