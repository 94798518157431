import { api, setBarerToken } from './api';
import { auth } from './../../firebaseApi';
const { REACT_APP_API_BASEURL } = process.env;

export const getUserList = async () => {
    return await api.get(`${REACT_APP_API_BASEURL}/users`);
}

export const postUser = async (_data) => {
    return await api.post(`${REACT_APP_API_BASEURL}/users`, _data);
}

export const putUser = async (_data) => {
    return await api.put(`${REACT_APP_API_BASEURL}/users`, _data);
}

export const getUserByfireBaseUid = async (uidFirebase) => {
    return await api.get(`${REACT_APP_API_BASEURL}/users?uidFirebase=${uidFirebase}`);
}

export const getUserById = async (id) => {
    return await api.get(`${REACT_APP_API_BASEURL}/users?id=${id}`);
}

export const deleteUsers = async (id) => {
    return await api.delete(`${REACT_APP_API_BASEURL}/users?id=${id}`);
}

export const loginUser = async (email, password) => {
    const _result = await auth.signInWithEmailAndPassword(email, password);
    const _token = await _result.user.getIdToken();
    setBarerToken(_token);
    return _result;
}