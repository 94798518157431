import React from "react";

// React router dom
import { Link } from "react-router-dom";

import { Dropdown } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';

// images
import logoText from "./../../../images/leonelLogo.png";

import UsefulContext from "./../../../ultis/usefulProvider";

const NavHader = () => {

   const { userProfiles } = React.useContext(UsefulContext);

   return (

      <div className="nav-header">

         <Link to="#" className="brand-logo">
            <img className="brand-title" src={logoText} alt="" />
         </Link>

         <div className="nav-control">
            
            <Dropdown>
               
               <Dropdown.Toggle className="barsmobilenavbar">
                  <FaBars />
               </Dropdown.Toggle>

               <Dropdown.Menu style={{ overflow: 'scroll', height: '400px' }}>

                  {userProfiles?.addProperties ||
                     userProfiles?.listAllProperties ||
                     userProfiles?.propertyGuide ? (
                     <>
                        <h5 className='dropdown-header text-primary'> <i className="flaticon-381-home-2"></i> Imóveis</h5>
                        {userProfiles?.addProperties && (
                           <Dropdown.Item ><Link to="/general-register"><span className="text-grey"> Novo cadastro</span></Link></Dropdown.Item>
                        )}
                        {userProfiles?.listAllProperties && (
                           <Dropdown.Item ><Link to="/property-listing"><span className="text-grey"> Imóveis cadastrados</span></Link></Dropdown.Item>
                        )}
                        {userProfiles?.propertyGuide && (
                           <Dropdown.Item ><Link to="/property-guide"><span className="text-grey"> Busca avançada</span></Link></Dropdown.Item>
                        )}
                        {userProfiles?.listAllProperties && (
                           <Dropdown.Item ><Link to="/property-listing-to-approve"><span className="text-grey"> Imóveis em fase de pesquisa</span></Link></Dropdown.Item>
                        )}
                     </>
                  ) : null}

                  {userProfiles?.realEstateMap ? (
                     <>
                        <h5 className='dropdown-header text-primary'> <i className="flaticon-381-map-2"></i>Mapa</h5>
                        {userProfiles?.realEstateMap && (
                           <Dropdown.Item ><Link to="/map-imoveis"><span className="text-grey"> Mapa</span></Link></Dropdown.Item>
                        )}
                     </>
                  ) : null}

                  {userProfiles?.addUser ||
                     userProfiles?.userListing ||
                     userProfiles?.addGroup ||
                     userProfiles?.groupListing ||
                     userProfiles?.addProfileAccess ||
                     userProfiles?.profilesListing ||
                     userProfiles?.userSettings ? (
                     <>
                        <h5 className='dropdown-header text-primary'> <i className="flaticon-381-user-7"></i> Configurações de Usuários</h5>
                        {userProfiles?.addUser && (
                           <Dropdown.Item ><Link to="/users-register"><span className="text-grey"> Cadastro de usuários</span></Link></Dropdown.Item>
                        )}
                        {userProfiles?.userListing && (
                           <Dropdown.Item ><Link to="/users-listing"><span className="text-grey"> Lista de usuários</span></Link></Dropdown.Item>
                        )}
                        {userProfiles?.addGroup && (
                           <Dropdown.Item ><Link to="/groups-register"><span className="text-grey"> Cadastro de grupos</span></Link></Dropdown.Item>
                        )}
                        {userProfiles?.groupListing && (
                           <Dropdown.Item ><Link to="/groups-listing"><span className="text-grey"> Lista de grupos</span></Link></Dropdown.Item>
                        )}
                        {userProfiles?.addProfileAccess && (
                           <Dropdown.Item ><Link to="/profiles-register"><span className="text-grey"> Cadastrar perfil de usuário</span></Link></Dropdown.Item>
                        )}
                        {userProfiles?.profilesListing && (
                           <Dropdown.Item ><Link to="/profiles-listing"><span className="text-grey"> Lista de perfis de usuário</span></Link></Dropdown.Item>
                        )}
                        {userProfiles?.profilesListing && (
                           <Dropdown.Item ><Link to="/permissions-register"><span className="text-grey"> Permissões de perfil de usuário</span></Link></Dropdown.Item>
                        )}
                        {userProfiles?.addProfileAccess && (
                           <Dropdown.Item ><Link to="/configurations"><span className="text-grey"> Configurações de permissão de usuário</span></Link></Dropdown.Item>
                        )}
                     </>
                  ) : null}

                  {userProfiles?.userSettings ? (
                     <>
                        <h5 className='dropdown-header text-primary'> <i className="flaticon-381-settings-7"></i> Configuração Geral</h5>
                        {userProfiles?.addRealEstateSpreadsheet && (
                           <Dropdown.Item ><Link to="/input-imoveis"><span className="text-grey"> Adicionar planilha de imóveis</span></Link></Dropdown.Item>
                        )}
                        {userProfiles?.mapOfRegions && (
                           <Dropdown.Item ><Link to="/importKMZ"><span className="text-grey"> Inserção de KMZ</span></Link></Dropdown.Item>
                        )}
                     </>
                  ) : null}

                  {userProfiles?.commercialDashboard && (
                     <>
                        <h5 className='dropdown-header text-primary'> <i className="flaticon-381-networking"></i> Dashboard</h5>
                        <Dropdown.Item ><Link to="/"><span className="text-grey"> Dashboard</span></Link></Dropdown.Item>
                     </>
                  )}

               </Dropdown.Menu>

            </Dropdown>
         </div>
      </div >
   );
};

export default NavHader;