import React from "react";
import { BsJournalBookmarkFill } from "react-icons/bs";

const enumSalesChannelDescriptions = (key) => {
  if(key){
    switch (key) {
      case "zap":
        return "Zap";
      case "olx":
        return "Olx";
      case "vivaReal":
        return "Viva Real";
      case "imovelWeb":
        return "Imovel Web";
      case "casaMineira":
        return "Casa Mineira";
      case "mercadoLivre":
        return "Mercado Livre";
      case "site":
        return "Site";
      case "placa":
        return "Placa";
      case "google":
        return "Google";
      case "facebook":
        return "Facebook";
      case "instagram":
        return "Instagram";
      case "linkedin":
        return "Linkedin";
      case "indicacao":
        return "Indicação";
      case "networking":
        return "Networking";
      case "intern":
          return "Interno";
      case "secret":
          return "Sigilo";
        case "notAnnounce":
          return "Não divulgar";
        case "all":
          return "Todos";
      default:
        return key;
    }
  }
  
};
Object.freeze(enumSalesChannelDescriptions);

const enumFalseTrue = (key) => {
  switch (key) {
    case true:
      return "Sim";
    case false:
      return "Não";
    case "true":
      return "Sim";
    case "false":
      return "Não";
    case "yes":
      return "Sim";
    case "no":
      return "Não";
    default:
      return key;
  }
};
Object.freeze(enumFalseTrue);


const enumHighlight = (key) => {
  switch (key) {
    case 'normal':
      return "Normal";
    case 'highlight':
      return "Destaque";
    case "superHighlight":
      return "Super Destaque";
    default:
      return key;
  }
};
Object.freeze(enumHighlight);

const AdDetailsCard = (props) => {
  const dados = props.dados;

  return (
    <>
      <div className="card pl-2">
        <div className="card-header row">
          <div className="row ml-2">
            <BsJournalBookmarkFill color="#297F00" className="fa-lg" />
            <h4 className="ml-3">Canais de anúncio</h4>
          </div>
        </div>
        <div className="card-body">
          <div className="row">

            {dados?.salesChannel ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Canal de anúncios</h4>
                <h5>
                  {(dados?.salesChannel && dados?.salesChannel?.map) ? dados?.salesChannel?.map((data) => {
                  return(<li className="m-1">{enumSalesChannelDescriptions(data)}</li>)
                }) : enumSalesChannelDescriptions(dados?.salesChannel)}</h5>
              </div>  
            ) : null}

            {dados?.highLight ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Destaque</h4>
                <h5>{enumHighlight(dados?.highLigth)}</h5>
              </div>
            ) : null}

            {dados?.occurrencesComments ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Ocorrências/Comentários</h4>
                <h5>{dados?.occurrencesComments}</h5>
              </div>
            ) : null}

            {dados?.descriptionAdvertisement ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-green">Descrição do anúncio</h4>
                <h5>{dados?.descriptionAdvertisement}</h5>
              </div>
            ) : null}
          </div>

          <div className="row">
            {dados?.adDescription ? (
              <div className="col-lg-12 mb-3">
                <h4 className="text-green">Descrição do anúncio</h4>
                <h5>{dados?.adDescription}</h5>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdDetailsCard;
