import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Alert from "sweetalert2";
import moment from "moment";
import { getActionArea } from "./../../services/apiActionArea";
import { searchRealStatesByZipCode } from "../../services/apiRealState";
import UsefulContext from "../../../ultis/usefulProvider";

const TablePropertyComponent = ({ history }) => {
  const { user } = useContext(UsefulContext);

  const [imoveis, setImoveis] = useState([]);

  useEffect(() => {
    if (user) {
      load(user._id);
    }
  }, [user]);

  // criar função para recuperar area de atuação
  const load = async (_uid) => {
    try {
      const _reusult = await getActionArea(_uid);
      if (_reusult.data) {
        loadData(_reusult.data.area);
      }
    } catch (error) {
      await Alert.fire(error?.response?.data?.message);
    }
  };

  const loadData = async (_zipCode) => {
    try {
      const _result = await searchRealStatesByZipCode(_zipCode);
      if (_result.data.length) {
        setImoveis(
          _result.data.sort((a, b) => {
            if (a && b) {
              if (moment(a.createdAt).unix() > moment(b.createdAt).unix()) {
                return 1;
              }
              if (moment(a.createdAt).unix() < moment(b.createdAt).unix()) {
                return -1;
              }
            }
            return 0;
          })
        );
      }
    } catch (error) {
      await Alert.fire(error?.response?.data?.message);
    }
  };

  return (
    <div className="table-responsive table-hover fs-14">
      <div id="orderList" className="dataTables_wrapper no-footer">
        <table className="table table-hover display mb-4 dataTablesCard card-table dataTable no-footer">
          <thead>
            <tr role="row">
              <th className="sorting"></th>
              <th className="sorting">Cidade</th>
              <th className="sorting">Bairro</th>
              <th className="sorting">Endereço</th>
              <th className="sorting">Status</th>
            </tr>
          </thead>
          <tbody>
            {/* Utilização do Map para criar novas linhas com base nos imóveis registrados */}
            {imoveis.map((dados, index) => {
              return (
                <tr key={index} role="row" className="odd">
                  <td>{dados?.city}</td>
                  <td>{dados?.district}</td>
                  <td>{dados?.address}</td>
                  <td>{dados?.approvedStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TablePropertyComponent;
