import React, { Fragment, useState, useEffect, useContext } from "react";
import { auth } from "../../../../firebaseApi";
import { Link } from "react-router-dom";
import { putUser } from "../../../services/apiUser";
import UsefulContext from "../../../../ultis/usefulProvider";
import TablePropertyComponent from "../TablePropertyComponent";
import profile from "../../../../images/avatar/profileIcon.png";
import PageHeaderSearch from "./page-header-search";
import Alert from "sweetalert2";
import "../../../../css/pesquisador.css";

const PageProfileSearch = ({ history }) => {
  const { user } = useContext(UsefulContext);

  const [name, setName] = useState(auth.currentUser?.displayName);
  const [nameError, setNameError] = useState();
  const [email, setEmail] = useState(auth.currentUser?.email);
  const [emailError, setEmailError] = useState();
  const [phone, setPhone] = useState('n/d');
  const [phoneError, setPhoneError] = useState();
  const [changeData, setChangeData] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (user) { 
      setPhone(user?.phone)
    }
  }, [user])

  useEffect(() => {
    if (name) {
      if (
        !name.match(
          /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
        )
      ) {
        setNameError("Campo deve conter nome e sobrenome");
      } else {
        setNameError();
      }
    } else {
      setNameError();
    }
  }, [name]);

  useEffect(() => {
    if (email) {
      if (
        !email.match(`^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$`)
      ) {
        setEmailError("Email inválido");
      } else {
        setEmailError();
      }
    } else {
      setEmailError();
    }
  }, [email]);

  useEffect(() => {
    if (phone) {
      if (!phone.match(/^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/)) {
        setPhoneError("Telefone inválido");
      } else {
        setPhoneError();
      }
    } else {
      setPhoneError();
    }
  }, [phone]);

  const save = async () => {
    if (!email.match(`^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$`)) {
      setEmailError("Email inválido");
      return;
    }
    if (!name.match(/^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/)) {
      setNameError("Nome é inválido");
      return;
    }
    if (!phone.match(/^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/)) {
      setPhoneError("Telefone inválido");
      return;
    }
    try {
      auth.currentUser?.updateEmail(email).then((resultUpdate) => {
        auth.currentUser?.updateProfile({ displayName: name }).then((result) => {
          putUser({
            id: user._id,
            name: name,
            email: email,
            profile: user.profile,
            phone: phone
          }).then((resultUpdateUser) => {
            setChangeData(false);
            Alert.fire({
              title: "Sucesso",
              text: "Dados alterados com sucesso",
              icon: "success",
            });
          }).catch((error) => { console.log(error); });
        }).catch((error) => { console.log(error); });
      }).catch((error) => {
        if (error?.code.includes("auth/requires-recent-login")) {
          Alert.fire({
            title: "auth/requires-recent-login",
            text: "Esta operação é sensível e requer autenticação recente. Faça login novamente antes de tentar novamente esta solicitação.",
            icon: "error",
          });
        } else {
          Alert.fire({
            title: "Erro",
            text: error,
            icon: "error",
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const goTo = () => {
    history.push({ pathname: "/page-map-imoveis-search" });
  };

  const goToAdd = () => {
    history.push({ pathname: "/page-general-register-search" });
  };

  const goToProperty = () => {
    history.push({ pathname: "/page-property-listing-search" });
  };

  return (
    <>
      <PageHeaderSearch />
      <Fragment>
        <div className="row mt-4"  style={{position:'relative',top:130}}>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
              <h3 className="text-primary fs-16 mt-4 ">
                Ultimos Imóveis do pesquisados
              </h3>
              </div>
              <div className="row ">
                <div className="col-lg-12">
                  
                </div>
                <div className="col-lg-12 mt-3 mb-5">
                  <TablePropertyComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default PageProfileSearch;