import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createProfile, updateProfiles } from "./../../services/apiProfiles";
import moment from "moment";
import Alert from "sweetalert2";
import "../../../css/general_register.css";

const ProfilesRegister = ({ history, location }) => {
  const { state } = location;

  const [profile, setProfile] = useState({});
  const [pageStatus, setPageStatus] = useState();

  useEffect(() => {
    if (state) {
      setProfile(state.data);
      setPageStatus(state.status);
    }
  }, [state]);

  useEffect(() => {
    moment.locale("pt-br");
    window.scrollTo(0, 0);
  }, []);

  const saveProfile = async (e) => {
    e.preventDefault();

    if (!profile.type) {
      await Alert.fire({
        title: "Atenção!",
        text: `O campo Tipo é inválido!`,
        icon: "warning",
      });
      return;
    }

    if (!pageStatus) {
      var _json = { type: profile.type };
      createProfile(_json)
        .then((result) => {
          Alert.fire({
            title: "Sucesso!",
            text: `Perfil de Acesso criado com sucesso!`,
            icon: "success",
          }).then(() => {
            history.goBack();
            return;
          });
        })
        .catch((error) => {
          Alert.fire({
            title: "Falha ao criar o perfil!",
            text: `${error}`,
            icon: "error",
          }).then(() => {
            return;
          });
        });
    } else if (pageStatus === 2) {
      var _json = profile;
      _json.id = _json._id;
      updateProfiles(_json)
        .then((result) => {
          Alert.fire({
            title: "Sucesso!",
            text: `Perfil de Acesso alterado com sucesso!`,
            icon: "success",
          }).then(() => {
            history.goBack();
            return;
          });
        })
        .catch((error) => {
          Alert.fire({
            title: "Falha ao alterar o perfil!",
            text: `${error}`,
            icon: "error",
          }).then(() => {
            return;
          });
        });
    }
  };

  return (
    <>
      <div className="container-fluid-register">
        <div className="form-head page-titles d-flex  align-items-center bg-brad">
          <div className="mr-auto  d-lg-block">
            <h4 className="text-black font-w600">
              Cadastro de Perfil de Acesso
            </h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to="#">Perfil de Acesso</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="#">Cadastro de novo perfil de acesso</Link>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="form">
        <div className="background">
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-3 text-center">Cadastrar Perfil de Acesso</h4>
            </div>
          </div>
          <div className="row fs-14">
            <div className="col-md-12 mt-3">
              <label className="ml-3 labelNormal">Tipo:</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="inputName"
                onChange={(e) =>
                  setProfile({ ...profile, type: e.target.value })
                }
                value={profile.type}
                placeholder="Nome do Perfil"
              ></input>
            </div>
          </div>
          <div className="mt-5 text-right">
            <button
              onClick={saveProfile}
              type="submit"
              style={{
                color: "#fff",
                backgroundColor: "#144b72",
                borderRadius: "6px",
              }}
              className="btn  borderRadius  mt-3"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilesRegister;