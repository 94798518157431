import React from "react";
import { BsInfoCircleFill } from "react-icons/bs";

// Enum de tradução de Disponibilidade
const enumUsageDescriptions = (key) => {
  switch (key) {
    case "luxuryApartment":
      return "Apartamento de luxo";
    case "standartApartment":
      return "Apartamento padrão";
    case "house":
      return "Casa";
    case "condominiumHouse":
      return "Casa de condomínio";
    case "farm":
      return "Chacará";
    case "modularCondominium":
      return "Condomínio modular";
    case "development":
      return "Desenvolvimento";
    case "comercial":
      return "Imóvel Comercial";
    case "schools":
      return "Escolas";
    case "largeRetailers":
      return "Grandes varejistas";
    case "hospitals":
      return "Hospitais";
    case "hotels":
      return "Hotéis";
    case "residentialDevelopment":
      return "Desenvolvimento residencial";
    case "comercialDevelopment":
      return "Desenvolvimento comercial";
    case "industrial":
      return "Industrial";
    case "commercialSlab":
      return "Laje comercial";
    case "logistics":
      return "Logística";
    case "singleUserBuilding":
      return "Prédio Monousuário";
    case "logisticsIndustrial":
      return "Logística Industrial";
    case "shopping":
      return "Shopping";
    case "rural":
      return "Rural";
    default:
      return key;
  }
};
Object.freeze(enumUsageDescriptions);

// Enum de tradução de Disponibilidade
const enumFinishingLevelDescriptions = (key) => {
  switch (key) {
    case "great":
      return "Ótimo";
    case "good":
      return "Bom";
    case "regular":
      return "Regular";
    case "bad":
      return "Ruim";
    default:
      return key;
  }
};
Object.freeze(enumFinishingLevelDescriptions);

// Enum de tradução de Disponibilidade
const enumMarketedByDescriptions = (key) => {
  switch (key) {
    case "competitors":
      return "Concorrentes";
    case "available":
      return "Disponível";
    case "unavailable":
      return "Indisponível";
    case "leonel":
      return "Leonel";
    default:
      return key;
  }
};
Object.freeze(enumMarketedByDescriptions);

// Enum de tradução de Disponibilidade
const enumEconomicSectorDescriptions = (key) => {
  switch (key) {
    case "industry":
      return "Indústria";
    case "commerce":
      return "Comércio";
    case "services":
      return "Serviços";
    case "ind_coml_logistics":
      return "Ind/Coml/Logístico";
    case "Logístico e comercial":
      return "logisticandcommercial";
    default:
      return key;
  }
};
Object.freeze(enumEconomicSectorDescriptions);

// Enum de tradução de Disponibilidade
const enumLineOfBusinessDescriptions = (key) => {
  switch (key) {
    case "agricultureExtractionCultivationAndProcessing":
      return "Agropecuária ( extração, cultivo e beneficiamento)";
    case "foodAndDrinks":
      return "Alimentos e Bebidas";
    case "automobile":
      return "Automobilística";
    case "consultingAccountingLaw":
      return "Consultoria ( Contabilidade, Advocacia)";
    case "education":
      return "Educação";
    case "entertainmentAndTourism":
      return "Entretenimento e Turismo";
    case "realestateConstructionEngineeringRealstate":
      return "Imobiliários (Construtoras, Engenharia, Imobiliárias)";
    case "quickWashAndCleaning":
      return "Lava Rápido e Limpeza";
    case "machinesAndEquipment":
      return "Máquinas e Equipamentos";
    case "constructionMaterials":
      return "Materias de Construção";
    case "marketsAndHypermarkets":
      return "Mercados e Hipermercados";
    case "automobilePartsAndEquipment":
      return "Peças e Equipamentos Automobilísticos";
    case "plasticsAndPackaging":
      return "Plásticos e Embalagens";
    case "chemistryAndPharmaceuticals":
      return "Química e Farmacêutica";
    case "animalHealth":
      return "Saúde  Animal";
    case "healthAndBeauty":
      return "Saúde e Beleza";
    case "steelAndMetallurgy":
      return "Siderurgíca e Metalurgíca";
    case "technology":
      return "Tecnologia";
    case "hardwareAndSoftwareTechnology":
      return "Tecnologia  Hardware e Software";
    case "textileAndFootwear":
      return "Têxtil e Calçados";
    case "transportationAndLogistics":
      return "Transporte e Logística";
    default:
      return key;
  }
};
Object.freeze(enumLineOfBusinessDescriptions);

const getStatusDescription = (key) => {
  switch (key) {
    case "approved":
      return "Aprovado";
    case "waiting":
      return "Aguardando aprovação";
    case "disapproved":
      return "Reprovado";
    case "incompleted":
      return "Incompleto";
    case "findOwner":
      return "Encontrar proprietário";
    default:
      return "Outro";
  }
};

Object.freeze(getStatusDescription);

const AdvancedRegistrationCard = (props) => {
  const dados = props.dados;

  const replaceCoin = (_value) => {
    if (_value != undefined && _value != null) {
      _value = _value.toString();
      if (_value == "0.00") {
        return "0";
      } else {
        _value = _value.replace(/\D/g, "");
        _value = _value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        return _value;
      }
    } else {
      return _value;
    }
  };

  return (
    <>
      <div className="card pl-2">
        <div className="card-header row">
          <div className="row ml-2">
            <BsInfoCircleFill color="#e83e8c" className="fa-lg" />
            <h4 className="ml-3">Cadastro avançado</h4>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            {dados?.use ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Uso</h4>
                <h5>{enumUsageDescriptions(dados?.use)}</h5>
              </div>
            ) : null}

            {dados?.propertyAge ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Idade do Imóvel</h4>
                <h5>{dados?.propertyAge}</h5>
              </div>
            ) : null}

            {dados?.finishingLevel ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Nível do Acabamento</h4>
                <h5>{enumFinishingLevelDescriptions(dados?.finishingLevel)}</h5>
              </div>
            ) : null}

            {dados?.technicalRegion ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Região Técnica</h4>
                <h5>{dados?.technicalRegion}</h5>
              </div>
            ) : null}

            {dados?.agreedCommission ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Comissão Acordada</h4>
                <h5>{dados?.agreedCommission}</h5>
              </div>
            ) : null}

            {dados?.marketedBy ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Comercializado por</h4>
                <h5>{enumMarketedByDescriptions(dados?.marketedBy)}</h5>
              </div>
            ) : null}

            {dados?.amountTransacted ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Valor Transacionado</h4>
                <h5>{replaceCoin(dados?.amountTransacted)}</h5>
              </div>
            ) : null}

            {dados?.currentOccupant ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Ocupante atual</h4>
                <h5>{dados?.currentOccupant}</h5>
              </div>
            ) : null}

            {dados?.occupantContact ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Contato Ocupante</h4>
                <h5>{dados?.occupantContact}</h5>
              </div>
            ) : null}

            {dados?.enumEconomicSector ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Setor Econômico</h4>
                <h5>{enumEconomicSectorDescriptions(dados?.enumEconomicSector)}</h5>
              </div>
            ) : null}

            {dados?.enumLineOfBusiness ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Ramo de atividade do ocupante</h4>
                <h5>{enumLineOfBusinessDescriptions(dados?.enumLineOfBusiness)}</h5>
              </div>
            ) : null}

            {dados?.contractPeriod ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Início do Contrato</h4>
                <h5>{dados?.contractPeriod}</h5>
              </div>
            ) : null}

            {dados?.index ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Índice</h4>
                <h5>{dados?.index}</h5>
              </div>
            ) : null}

            {dados?.cityHallRegister ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Cadastro Prefeitura / Matrícula</h4>
                <h5>{dados?.cityHallRegister}</h5>
              </div>
            ) : null}

            {dados?.approvedStatus ? (
              <div className="col-lg-6 mb-3">
                <h4 className="text-pink">Status da aprovação</h4>
                <h5>{getStatusDescription(dados?.approvedStatus)}</h5>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvancedRegistrationCard;
