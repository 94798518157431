import React, {Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { getActionArea } from "../../services/apiActionArea";
import { getRealStates, searchByValue, searchRealStatesByZipCode } from "../../services/apiRealState";
import { AiOutlineArrowLeft } from "react-icons/ai";
import UsefulContext from "../../../ultis/usefulProvider";
import PageHeaderSearch from "../user/pesquisador/page-header-search";
import Alert from "sweetalert2";
import { Dropdown, Button, Modal } from "react-bootstrap";
import {
  enumTypeAgreement,
  enumTypeAgreementDescriptions,
} from "./../../../enums/ETYPEAGREEMENT";

const enumAvailabilityDescriptions = (key) => {
  switch (key) {
    case "competitors":
      return "Concorrentes";
    case "available":
      return "Disponível";
    case "unavailable":
      return "Indisponível";
    case "leonel":
      return "Leonel";
    default:
      return key;
  }
};
Object.freeze(enumAvailabilityDescriptions);

const PagePropertyListingSearch = ({ history }) => {
  const { user } = useContext(UsefulContext);

  const [imoveis, setImoveis] = useState([]);
  const [actionArea, setActionArea] = useState();

  const [inputFilter, setInputFilter] = useState("");

    
  useEffect(() => {
    if (user) {
      load(user._id);
    }
  }, [user]);

  useEffect(() => {
    if (actionArea?._id) {
      loadData();
    }
  }, [actionArea]);


  // criar função para recuperar area de atuação
  const load = async (_uid) => {
    try {
      const _result = await getActionArea(_uid);
      if (_result.data) {
        setActionArea(_result.data);
      }
    } catch (error) {
      await Alert.fire('Falha ao carregar lista');
    }
  };

  const filterRealStates = (value) => 
  {
    var retorno = false;
    if (actionArea?.region && value?.commercialRegion?.toUpperCase() === actionArea?.region?.toUpperCase()){
      retorno = true;
    }
    else if (actionArea?.district && value?.district?.toUpperCase() === actionArea?.district?.toUpperCase()){
      retorno = true;
    }
    else if (actionArea?.cep?.length){
      for(let i = 0; i < actionArea.cep.length;i++){
        if(actionArea.cep[i] === value.zipCode)
        {
          retorno = true;
        }
      }
    }
   if (retorno){
    if(inputFilter) {
      if(value.code?.toUpperCase()?.includes(inputFilter.toUpperCase()) ||
       value.address?.toUpperCase().includes(inputFilter.toUpperCase()) ||
       value.cep?.toUpperCase().includes(inputFilter.toUpperCase()) ||
       value.commercialRegion?.toUpperCase().includes(inputFilter.toUpperCase()) ||
       value.district?.toUpperCase().includes(inputFilter.toUpperCase()) ||
       value.transactionType?.toUpperCase().includes(inputFilter.toUpperCase()) ||
       value.city?.toUpperCase().includes(inputFilter.toUpperCase()) ||
       value.availability?.toUpperCase().includes(inputFilter.toUpperCase()) || 
             enumAvailabilityDescriptions(value.availability)?.toUpperCase().includes(inputFilter)||
             enumTypeAgreementDescriptions(value.transactionType)?.toUpperCase()?.includes(inputFilter) ||
             value.currentOccupant?.includes(inputFilter)
           ){    
         retorno = true
       } else {  
         retorno =  false
       }
   } else {
     retorno =  true
   }
   }
    return retorno;
  }

  const loadData = async () => {
    try {
      const _result = await getRealStates();
      if (_result.data.length) {
        setImoveis(_result.data.filter(filterRealStates)
        )
      }
    } catch (error) {
      await Alert.fire('Erro ao listar imóveis');
    }
  };

  const onEdit = (props) => {
    history.push({
      pathname: "/page-general-register-search",
      state: { data: props },
    });
  };

  return (
    <>
    <PageHeaderSearch />
    <Fragment>
        <div className="row mt-4"  style={{position:'relative',top:130}}>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
              <h3 className="text-primary fs-16 mt-4 ">
               Lista de imóveis
              </h3>
              </div>
              <div className="row ">
                <div className="col-lg-12 col-xs-12" style={{overflow:'hidden'}}>
                  <div className="row ml-2">
                    <div className="col-lg-8">
                        <input 
                      className="mt-4 form-control input-rounded m-0" 
                      style={{width:'90%'}} 
                      placeholder="Filtrar"
                      value={inputFilter}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          loadData();
                        }
                      }}
                      onChange={e => setInputFilter(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-4">
                    <button
                    style={{
                      color: "#fff",
                      backgroundColor: "#3a4db8",
                    }}
                    className="mt-4 btn pt-2 pb-2 pl-3 pr-3 btn-primary rounded "
                    onClick={loadData}
                  >
                   Buscar <i class="fal fa-search"></i>
                  </button>
                    </div>
                  </div>
     
                </div>

                <div className="col-lg-12 mt-3 mb-5">
                <table className="table table-hover display mb-4 dataTablesCard card-table dataTable no-footer">
                  <thead>
                    <tr role="row">
                      <th className="sorting"></th>
                      <th className="sorting">Endereço</th>
                      <th className="sorting">Código</th>
                      <th className="sorting">Cidade</th>
                      <th className="sorting">Bairro</th>
                    
                      <th className="sorting">Tipo de transação</th>
                      <th className="sorting">Disponibilidade</th>
                      <th className="sorting">Ocupante</th>

                    </tr>
                  </thead>
                  <tbody>
                    {/* Utilização do Map para criar novas linhas com base nos imóveis registrados */}
                    {imoveis.map((dados, index) => {
                      return (
                        <tr key={index} role="row" className="odd">
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                className="p-2"
                                style={{ borderRadius: "6px" }}>
                                <i className="flaticon-381-settings-6"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => onEdit(dados)}>
                                  Editar
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                          <td>{dados?.address} - {dados?.number}</td>
                          <td>{dados?.code}</td>
                          <td>{dados?.city}</td>
                          <td>{dados?.district}</td>
                        
                          <td>{enumTypeAgreementDescriptions(dados?.transactionType)}</td>
                          <td>{enumAvailabilityDescriptions(dados?.availability)}</td>
                          <td>{dados?.currentOccupant ? dados?.currentOccupant : 'Sem ocupante'}</td>

                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
      
    </>
  );
};

export default PagePropertyListingSearch;