import React, { useEffect, useState } from "react";
import { getActionArea, postActionArea, putActionArea } from "./../../services/apiActionArea";
import { postUser, putUser } from "../../services/apiUser";
import { getProfiles } from "../../services/apiProfiles";
import { auth } from "../../../firebaseApi";
import moment from "moment";
import Alert from "sweetalert2";
import "../../../css/general_register.css";
import Select from "react-select";
import { getRegions } from "../../services/apiRegions";

const UserActionArea = ({ history, location }) => {
  const { state } = location;

  const [user, setUser] = useState({});
  const [actionArea, setActionArea] = useState({
  });
  const [pageStatus, setPageStatus] = useState();
  const [profiles, setProfiles] = useState([]);
  const [firstCep, setFirstCep] = useState();
  const [secondCep, setSecondCep] = useState();
  const [thirdCep, setThirdCep] = useState();
  const [fourthCep, setFourthCep] = useState();
  const [district, setDistrict] = useState();
  const [region, setRegion] = useState();
  const [regionsToDisplay, setRegionsToDisplay] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    moment.locale("pt-br");
    loadData();
    loadRegions();

  }, []);


  useEffect(() => {
    if (state) {
      setUser(state.data);
      loadActionArea(state.data._id);
    }
  }, [state]);

  const loadRegions = async () => {
    try {
      const _result = await getRegions();
      if (_result?.response) {
        console.log(error)
        return;
      } else if (!_result?.data?.length) {
        return;
      }
      setRegions(_result?.data);
      var _tempRegions = [{ label: "Selecionar todos", value: "all" }];
      for (let x = 0; x < _result?.data.length; x++) {
        const _reg = _result?.data[x];
        _tempRegions.push({ value: _reg?._id, label: _reg?.region });
      }
      setRegionsToDisplay(_tempRegions);
    } catch (error) {
      console.log(error)
    }
  }

  const loadData = async () => {
    try {
      const _response = await getProfiles();
      setProfiles(_response.data);
    } catch (error) {
      console.log(error)
    }
  };

  const loadActionArea = async (_uid) => {
    try {
      const _result = await getActionArea(_uid);
      if (_result.data) {
        setActionArea(_result.data);
        setFirstCep(_result.data?.cep[0] ? _result.data?.cep[0] : '');
        setSecondCep(_result.data?.cep[1]);
        setThirdCep(_result.data?.cep[2]);
        setFourthCep(_result.data?.cep[3]);
        setDistrict(_result.data?.district);
        setRegion(_result.data?.region);
      }
    } catch (error) {
console.log(error)
    }
  };

  const handleSubmit = async () => {
    try {
     
        var _json = JSON.parse(JSON.stringify(user));
        var body = {
            userId :_json._id,
            cep: [],
            district: '',
            region: '',
        }   
       
        if(firstCep){
            body.cep.push(firstCep)
            
        }
        if(secondCep){
            body.cep.push(secondCep)

        }
        if(thirdCep){
            body.cep.push(thirdCep)

        }
        if(fourthCep){
            body.cep.push(fourthCep)

        }
        if(district){
            body.district = district
        }
        if(region){
            body.region = region
        }
     
        if (actionArea?._id) {
            await putActionArea(actionArea?._id,body);
        } else {
            await postActionArea(body);
        }
      //  await Alert.fire({ title: "Sucesso!", text: `Área de atuação editada com sucesso!`, icon: "success" });
        //history.goBack();
      
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <div className="container-fluid-register">
        <div className="form-head page-titles d-flex  align-items-center bg-brad">
          <div className="mr-auto  d-lg-block">
            <h4 className="text-black font-w600">Área de atuação do pesquisador</h4>
          </div>
        </div>
      </div>
      <div className="form">
        <div className="background">
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-3 text-center">Definir imóveis liberados</h4>
            </div>
          </div>
          <div className="row fs-14">
            <div className="col-md-6 mt-4">
              <label className="ml-3 labelNormal">Bairro:</label>
              <input
                className="form-control input-rounded"
                type="text"
                onChange={(e) => setDistrict( e.target.value)}
                value={district}
                placeholder=""
              />
            </div>
            <div className="col-md-6 mt-4">
              <label className="ml-3 labelNormal">Região comercial:</label>
              {regionsToDisplay.length ?
              <Select
                          name="regions"
                          value={selectedRegions}
                          options={regionsToDisplay}
                          onChange={(e) =>
                            [setRegion(e.label),setSelectedRegions(e)]
                          }
                        />
                        : <input
                        className="form-control input-rounded"
                        type="text"
                        name="commercialRegion"
                        value={region}
                        onChange={(e) =>
                            setRegion(e.target.value)
                        }
                      />
                    }
            </div>
           
            <div className="col-md-3 mt-4">
                    <label className="ml-3 labelNormal">Cep 1:</label>
                    <input
                        className="form-control input-rounded"
                        type="text"
                        name="inputName"
                        maxLength={9}
                        onChange={(e) => setFirstCep(e.target.value)}
                        value={firstCep}
                        placeholder=""
                    />
            </div>

            <div className="col-md-3 mt-4">
                    <label className="ml-3 labelNormal">Cep 2:</label>
                    <input
                        className="form-control input-rounded"
                        type="text"
                        name="inputName"
                        maxLength={9}
                        onChange={(e) => setSecondCep(e.target.value)}
                        value={secondCep}
                        placeholder=""
                    />
            </div>

            <div className="col-md-3 mt-4">
                    <label className="ml-3 labelNormal">Cep 3:</label>
                    <input
                        className="form-control input-rounded"
                        type="text"
                        name="inputName"
                        maxLength={9}
                        onChange={(e) => setThirdCep(e.target.value)}
                        value={thirdCep}
                        placeholder=""
                    />
            </div>

            <div className="col-md-3 mt-4">
                    <label className="ml-3 labelNormal">Cep 4:</label>
                    <input
                        className="form-control input-rounded"
                        type="text"
                        name="inputName"
                        maxLength={9}
                        onChange={(e) => setFourthCep(e.target.value)}
                        value={fourthCep}
                        placeholder=""
                    />
            </div>
          </div>
          <div className="mt-5 text-right">
            <button
              onClick={handleSubmit}
              type="submit"
              style={{
                color: "#fff",
                backgroundColor: "#144b72",
                borderRadius: "6px",
              }}
              className="btn  borderRadius  mt-3">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </>
  );

};

export default UserActionArea;