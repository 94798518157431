import React, { useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import axios from "axios";
import Geocode from "react-geocode";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyA-uIcPdvgx5alYfpWXuQzRE1yJG88jWBY");
// Colocando a Linguagem
Geocode.setLanguage("pt");
// set response region. Its optional.
// A Geocoding request with region=br (Brazil) will return the Brazil.
Geocode.setRegion("br");

const RealTimeMap = () => {
  let [realPosition, setRealPosition] = useState([]);
  var [cep, setCep] = useState("");
  var lat = 0;
  var lng = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate) {
      firstUpdate.current = false;
    }
  }, []);

  const ValidaCep = () => {
    if (cep == "" || cep == null) {
      alert("Insira um CEP");
    } else {

      // consumindo Viacep Para pegar o endereço
      var config = {
        method: "get",
        url: "https://viacep.com.br/ws/" + cep + "/json/",
        headers: {},
      };
      axios(config).then(function (response) {
        // convertendo endereço em latitude e longitude
        Geocode.fromAddress(
          response.data.logradouro + response.data.localidade
        ).then(
          (res) => {
            setRealPosition([]);
            var { lat, lng } = res.results[0].geometry.location;
            setRealPosition((realPosition) => [...realPosition, { lat, lng }]);
            realPosition.map((realPosi) => console.log("realPosi", realPosi));
            
          },
          (error) => {
            console.error(error);

          }
        );
      });
    }
  };

  return (
    <>
      <div>
        <div class="modal-body">
          <h4 className="text-capitalize">Digite o CEP</h4>
          <input
            name="inputTitulo"
            type="text"
            className="form-control"
            onChange={(e) => {
              setCep(e.target.value);
            }}
            placeholder="14050400"
          ></input>
          <button
            type="button"
            class="btn btn-success btnRadius"
            data-dismiss="modal"
            onClick={ValidaCep}
          >
            Confirmar
          </button>
        </div>
        {realPosition.map((realPosi) => {
          return (
            <MapContainer
              style={{ height: "500px", width: "100%", zIndex: 0 }}
              zoom={10}
              center={realPosi}
              scrollWheelZoom={true}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={realPosi}></Marker>
            </MapContainer>
          );
        })}
      </div>
    </>
  );
};

export default RealTimeMap;