import React, { useEffect, useState } from 'react';
import { getProfilesRulesByUsers, updateProfiles } from './../../services/apiProfileRules';
import { isEmpty } from './../../../ultis/listFunctions';
import { Link } from 'react-router-dom';
import "./../../../css/general_register.css";

const Config = ({ history }) => {

    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState({});
    const [currentPermissions, setCurrentPermissions] = useState({});
    const [customRules, setCustomRules] = useState(false);
    const [blockDate, setBlockDate] = useState();

    useEffect(() => {
        getProfilesRulesByUsers(true).then((result) => { 
            let array = [];
            for (let i in result.data){
                if(result.data[i].user.profile !== "622fdff7c8e45553c9ae4c7f"){
                    array.push(result.data[i])
                }
            }
            setUsers(array);
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    const saveConfig = () => {
        updateProfiles({
            'id': currentUser._id,
            'uid': currentUser.uid,
            'rules': currentPermissions,
            'isUser': currentUser.isUser,
            'customRules': customRules,
            'blockDate': blockDate,
        }).then(result => {
            history.goBack();
        }).catch(error => {
            console.log(error)
        });
    }

    const setUser = (user) => {
        var _user = JSON.parse(user);
        setCustomRules(_user.user.customRules);
        setCurrentUser(_user.profileRules);
        setBlockDate(_user.blockDate)
        if (!_user.profileRules.rules || Array.isArray(_user.profileRules.rules)) { setCurrentUser({ ...currentUser, rules: {} }); }
        setCurrentPermissions(JSON.parse(_user.profileRules.rules));
    }

    return (
        <>
            <div className="container-fluid-register">
                <div className='form-head page-titles d-flex align-items-center bg-brad'>
                    <div className='mr-auto  d-lg-block'>
                        <h4 className='text-black font-w600'>Configurações</h4>
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item active'>
                                <Link to='#'>Usuários</Link>
                            </li>
                            <li className='breadcrumb-item'>
                                <Link to='#'>Configurar Permissões</Link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className="form">

                <div className="background">

                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-3 text-center">Configurar Permissões de Usuário</h4>
                        </div>
                    </div>

                    <div className="row fs-14">

                        <div className="col-md-8">
                            <label className="ml-3 labelNormal">Usuários</label>
                            <select
                                className="form-control input-rounded"
                                onChange={(e) => { setUser(e.target.value); }}
                                name="inputRole">
                                <option disabled={true} selected={true}>{`selecione`}</option>
                                {users && users.map((_data) => { return (<option key={_data._id} value={JSON.stringify(_data)}>{_data?.user?.name}</option>) })}
                            </select>
                        </div>

                        {!isEmpty(currentUser) &&
                            <div className="col-md-4">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <label>Customizar?</label>

                                        <div>
                                            <input type="radio" className='form-control-md mr-1' id="yes" name="custom" onChange={(e) => { setCustomRules(true) }} checked={customRules} />
                                            <label for="yes">Sim</label>
                                        </div>

                                        <div>
                                            <input type="radio" id="no" className="form-control-md mr-1" name="custom" onChange={(e) => { setCustomRules(false) }} checked={!customRules} />
                                            <label for="no">Não</label>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <label>Data de bloqueio:</label>
                                        <input type="date" className="input-rounded form-control mr-1" name="data" onChange={(e) => { setBlockDate(e.target.value) }} value={blockDate} />
                                    </div>

                                </div>

                            </div>
                        }

                        {/* Funcionalidades dos imóveis  */}
                        <div className="div-access col-md-6 mt-3">
                            <label className="font-weight-bold h4 text-primary mt-4">Imóveis</label>
                            <div>

                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="addProperties"
                                        checked={currentPermissions.addProperties}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, addProperties: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="property-listing-checkbox">
                                        Adicionar imóveis
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id='listAllProperties'
                                        checked={currentPermissions.listAllProperties}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, listAllProperties: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                        Imóveis cadastrados
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id='exportProperties'
                                        checked={currentPermissions.exportProperties}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, exportProperties: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="Profiles-listing-checkbox">
                                        Exportar planilha de imóveis
                                    </label>
                                </div>
                                
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="editProperty"
                                        checked={currentPermissions.editProperty}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, editProperty: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="create-user-checkbox">
                                        Editar imóvel
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input text-muted fs-15"
                                        type="checkbox"
                                        id="deleteProperties"
                                        checked={currentPermissions.deleteProperties}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, deleteProperties: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="add-property-checkbox">
                                        Deletar imóveis
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="createFlyer"
                                        checked={currentPermissions.createFlyer}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, createFlyer: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                        Criar flyer
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="propertyGuide"
                                        checked={currentPermissions.propertyGuide}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, propertyGuide: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                       Busca avançada
                                    </label>
                                </div>
                                
                            </div>
                        </div>

                        {/* Funcionalidades de usuários  */}
                        <div className="div-access col-md-6 mt-3">
                            <label className="font-weight-bold h4 text-primary mt-4">Usuários</label>
                            <div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="userListing"
                                        checked={currentPermissions.userListing}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, userListing: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                        Usuários cadastrados
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="addUser"
                                        checked={currentPermissions.addUser}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, addUser: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="property-listing-checkbox">
                                        Adicionar usuário
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="editUser"
                                        checked={currentPermissions.editUser}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, editUser: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="create-user-checkbox">
                                        Editar usuário
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input text-muted fs-15"
                                        type="checkbox"
                                        id="deleteUser"
                                        checked={currentPermissions.deleteUser}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, deleteUser: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="add-property-checkbox">
                                        Deletar usuário
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="viewUserProfile"
                                        checked={currentPermissions.viewUserProfile}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, viewUserProfile: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                        Visualizar perfil do usuário
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="addProfileAccess"
                                        checked={currentPermissions.addProfileAccess}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, addProfileAccess: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                        Configurações de permissão de usuário
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Funcionalidades de regiões  */}
                        <div className="div-access col-md-6 mt-3">
                            <label className="font-weight-bold h4 text-primary mt-4">Regiões</label>
                            <div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="addRealEstateSpreadsheet"
                                        checked={currentPermissions.addRealEstateSpreadsheet}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, addRealEstateSpreadsheet: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                        Adicionar planilha de imóveis
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="addKMZ/KML"
                                        checked={currentPermissions.addKMZ}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, addKMZ: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="property-listing-checkbox">
                                        Adicionar KMZ/KML
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Funcionalidades de mapas  */}
                        <div className="div-access col-md-6 mt-3">
                            <label className="font-weight-bold h4 text-primary mt-4">Mapas</label>
                            <div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="realEstateMap"
                                        checked={currentPermissions.realEstateMap}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, realEstateMap: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                        Mapa de imóveis
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="mapOfRegions"
                                        checked={currentPermissions.mapOfRegions}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, mapOfRegions: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="property-listing-checkbox">
                                        Mapa de regiões
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Funcionalidades de grupos  */}
                        <div className="div-access col-md-6 mt-3">
                            <label className="font-weight-bold h4 text-primary mt-4">Grupos</label>
                            <div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="groupListing"
                                        checked={currentPermissions.groupListing}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, groupListing: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                        Grupos cadastrados
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="addGroup"
                                        checked={currentPermissions.addGroup}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, addGroup: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="property-listing-checkbox">
                                        Adicionar grupo
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="editGroup"
                                        checked={currentPermissions.editGroup}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, editGroup: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="create-user-checkbox">
                                        Editar grupo
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input text-muted fs-15"
                                        type="checkbox"
                                        id="deleteGroup"
                                        checked={currentPermissions.deleteGroup}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, deleteGroup: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="add-property-checkbox">
                                        Deletar grupo
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Funcionalidades de perfis  */}
                        <div className="div-access col-md-6 mt-3">
                            <label className="font-weight-bold h4 text-primary mt-4">Perfis de Acesso</label>
                            <div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="profilesListing"
                                        checked={currentPermissions.profilesListing}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, profilesListing: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="add-property-checkbox">
                                        Acessar a lista de perfis
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="addProfiles"
                                        checked={currentPermissions.addProfiles}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, addProfiles: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="property-listing-checkbox">
                                        Adicionar perfil de acesso
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="editProfiles"
                                        checked={currentPermissions.editProfiles}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, editProfiles: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="create-user-checkbox">
                                        Editar perfil de acesso
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input text-muted fs-15"
                                        type="checkbox"
                                        id="deleteProfiles"
                                        checked={currentPermissions.deleteProfiles}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, deleteProfiles: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="add-property-checkbox">
                                        Deletar perfil de acesso
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Funcionalidades de permissões  */}
                        <div className="div-access col-md-6 mt-3">
                            <label className="font-weight-bold h4 text-primary mt-4">Permissões de Acesso</label>
                            <div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="editPermissions"
                                        checked={currentPermissions.editPermissions}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, editPermissions: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="create-user-checkbox">
                                        Editar permissões
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Funcionalidades de configurações  */}
                        <div className="div-access col-md-6 mt-3 ">
                            <label className="font-weight-bold h4 text-primary mt-4">Configurações</label>
                            <div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="userSettings"
                                        checked={currentPermissions.userSettings}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, userSettings: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                        Configurações de usuários
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Funcionalidades de dashboard  */}
                        <div className="div-access col-md-6 mt-3">
                            <label className="font-weight-bold h4 text-primary mt-4">Dashboards</label>
                            <div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="commercialDashboard"
                                        checked={currentPermissions.commercialDashboard}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, commercialDashboard: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                        Dashboard comercial
                                    </label>
                                </div>

                            </div>
                        </div>

                        {/* Funcionalidades de chat  */}
                        <div className="div-access col-md-6 mt-3">
                            <label className="font-weight-bold h4 text-primary mt-4">Chat</label>
                            <div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="chat"
                                        checked={currentPermissions.chat}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, chat: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                        Chat
                                    </label>
                                </div>

                            </div>
                        </div>

                        <div className="div-access col-md-6 mt-3">
                            <label className="font-weight-bold h4 text-primary mt-4">Manual de Uso</label>
                            <div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="userListing"
                                        checked={currentPermissions.platformUse}
                                        onChange={(e) => { setCurrentPermissions({ ...currentPermissions, platformUse: e.target.checked }) }} />
                                    <label
                                        className="form-check-label text-muted fs-15"
                                        for="user-listing-checkbox">
                                        Manual de uso
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="mt-5 text-right">
                        <button onClick={() => saveConfig()} style={{ color: "#fff", backgroundColor: "#144b72", borderRadius: "6px" }} className="btn borderRadius mt-3">Salvar</button>
                    </div>

                </div>

            </div>

        </>
    )
}

export default Config;