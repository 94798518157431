export const enumFinishingLevel = {
  great: "great",
  good: "good",
  regular: "regular",
  bad: "bad",
};

export const enumFinishingLevelDescriptions = (key) => {
  switch (key) {
    case "great":
      return "Ótimo";
    case "good":
      return "Bom";
    case "regular":
      return "Regular";
    case "bad":
      return "Ruim";
    default:
      return key;
  }
};

export const enumFinishingLevelDescriptionsInverter = (key) => {
  switch (key) {
    case "Ótimo":
      return "great";
    case "Bom":
      return "good";
    case "Regular":
      return "regular";
    case "Ruim":
      return "bad";
    default:
      return key;
  }
};

Object.freeze(enumFinishingLevel);
