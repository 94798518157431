export const enumUsage = {
  luxuryApartment: "luxuryApartment",
  standartApartment: "standartApartment",
  house: "house",
  condominiumHouse: "condominiumHouse",
  farm: "farm",
  modularCondominium: "modularCondominium",
  development: "development",
  comercial: "comercial",
  schools: "schools",
  largeRetailers: "largeRetailers",
  hospitals: "hospitals",
  hotels: "hotels",
  residentialDevelopment: "residentialDevelopment",
  comercialDevelopment: "comercialDevelopment",
  industrial: "industrial",
  commercialSlab: "commercialSlab",
  logistics: "logistics",
  singleUserBuilding: "singleUserBuilding",
  rural: "rural",
  shopping: "shopping",
  logisticsIndustrial: "logisticsIndustrial",
};

export const enumUsageDescriptions = (key) => {
  switch (key) {
    case "luxuryApartment":
      return "Apartamento de luxo";
    case "standartApartment":
      return "Apartamento padrão";
    case "house":
      return "Casa";
    case "condominiumHouse":
      return "Casa de condomínio";
    case "farm":
      return "Chacará";
    case "modularCondominium":
      return "Condomínio modular";
    case "development":
      return "Desenvolvimento";
    case "comercial":
      return "Comercial";
    case "schools":
      return "Escolas";
    case "largeRetailers":
      return "Grandes varejistas";
    case "hospitals":
      return "Hospitais";
    case "hotels":
      return "Hotéis";
    case "residentialDevelopment":
      return "Desenvolvimento residencial";
    case "comercialDevelopment":
      return "Desenvolvimento comercial";
    case "industrial":
      return "Industrial";
    case "commercialSlab":
      return "Laje comercial";
    case "logistics":
      return "Logística";
    case "singleUserBuilding":
      return "Prédio Monousuário";
    case "logisticsIndustrial":
      return "Logística Industrial";
    case "shopping":
      return "Shopping";
    case "rural":
      return "Rural";
    default:
      return key;
  }
};

export const enumUsageDescriptionsInverter = (key) => {
  switch (key) {
    case "Apartamento de luxo":
      return "luxuryApartment";
    case "Apartamento padrão":
      return "standartApartment";
    case "Casa":
      return "house";
    case "Casa de condomínio":
      return "condominiumHouse";
    case "Chacará":
      return "farm";
    case "Condomínio modular":
      return "modularCondominium";
    case "Desenvolvimento":
      return "development";
    case "Comercial":
      return "comercial";
    case "Escolas":
      return "schools";
    case "Grandes varejistas":
      return "largeRetailers";
    case "Hospitais":
      return "hospitals";
    case "Hotéis":
      return "hotels";
    case "Desenvolvimento residencial":
      return "residentialDevelopment";
    case "Desenvolvimento comercial":
      return "comercialDevelopment";
    case "Industrial":
      return "industrial";
    case "Laje comercial":
      return "commercialSlab";
    case "Logística":
      return "logistics";
    case "Prédio Monousuário":
      return "singleUserBuilding";
    case "Logística Industrial":
      return "logisticsIndustrial";
    case "Shopping":
      return "shopping";
    case "Rural":
      return "rural";
    default:
      return key;
  }
};

Object.freeze(enumUsage);


