import axios from "axios";
import { auth } from './../../firebaseApi';
import { useHistory } from "react-router-dom";

const { REACT_APP_API_BASEURL } = process.env;

const redirect = () => {
  let history = useHistory();
  auth.signOut();
  history.push('/page-login');
}

var _barerToken = '';

axios.interceptors.request.use(request => {
  if (_barerToken) { request.headers.Authorization = _barerToken; }
  return request;
}, error => {
  return error;
});

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    // change context to login
    redirect();
  }
  return error;
});

export const setBarerToken = (token) => {
  _barerToken = `Bearer ${token}`;
}

export const api = axios.create({
  baseURL: REACT_APP_API_BASEURL
});

export default api;