import { api } from './api';

const { REACT_APP_API_BASEURL } = process.env;

export const getProfilesRules = async (id) => {
    return await api.get(`${REACT_APP_API_BASEURL}/profileRules?id=${id}`);
}

export const getProfilesRulesByUid = async (uid) => {
    return await api.get(`${REACT_APP_API_BASEURL}/profileRules?uid=${uid}`);
}

export const getProfilesRulesByProfiles = async () => {
    return await api.get(`${REACT_APP_API_BASEURL}/profileRules/byProfiles`);
}

export const getProfilesRulesByUsers = async (_value) => {
    return await api.get(`${REACT_APP_API_BASEURL}/profileRules?isUser=${_value}`);
}

export const createProfile = async (data) => {
    return await api.post(`${REACT_APP_API_BASEURL}/profileRules`, data);
}

export const updateProfiles = async (data) => {
    return await api.put(`${REACT_APP_API_BASEURL}/profileRules`, data);
}

export const deleteProfile = async (id) => {
    return await api.delete(`${REACT_APP_API_BASEURL}/profileRules`, { data: { id: id } });
}