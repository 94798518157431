import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FirebaseApp } from "../../../firebaseApi";
import { Modal, Button } from "react-bootstrap";
import {createRealStates,updateRealStates,getCodeByPropertyType,getRealStatesById} from "./../../services/apiRealState";
import moment from "moment";
import "../../../css/general_register.css";
import Alert from "sweetalert2";
import {enumPropertyType,enumPropertyTypeDescriptions} from "./../../../enums/EPROPERTYTYPE";
import { enumUsage, enumUsageDescriptions } from "./../../../enums/EUSAGE";
import { enumStatus, enumStatusAreaDescriptions } from "./../../../enums/ESTATUS";
import {enumTypeAgreement,enumTypeAgreementDescriptions} from "./../../../enums/ETYPEAGREEMENT";
import { enumExclusivity, enumExclusivityDescriptions } from "./../../../enums/EEXCLUSIVITY";
import {enumFinishingLevel,enumFinishingLevelDescriptions} from "./../../../enums/ELEVELOFFINISH";
import { enumMarketedBy, enumMarketedByDescriptions } from "./../../../enums/EMARKETDBY";
import {enumLineOfBusiness,enumLineOfBusinessDescriptions,} from "./../../../enums/ELINEOFBUSINESS";
import {enumEconomicSector,enumEconomicSectorDescriptions} from "./../../../enums/EECONOMICSECTOR";

import {enumAvailability,enumAvailabilityDescriptions,} from "./../../../enums/EAVALIABILITY";

import CurrencyInput from "react-currency-input-field";
import Geocode from "react-geocode";
import Multiselect from 'multiselect-react-dropdown';
import { getRegions } from "../../services/apiRegions";
import Select from "react-select";

const optionsKey = [
  {name: 'Leonel', id:'leonel'},
  {name: 'Cão de guarda', id:'watchdog'},
  {name: 'Proprietário', id:'owner'},
  {name: 'Imobiliária', id:'realState'},
  {name: 'Outros', id:'other'},
]

const optionsAnnounce = [
  {name: 'Zap', id:'zap'},
  {name: 'Olx', id:'olx'},
  {name: 'Viva real', id:'vivaReal'},
  {name: 'Imóvel Web', id:'imovelWeb'},
  {name: 'Casa mineira', id:'casaMineira'},
  {name: 'Mercado livre', id:'mercadoLivre'},
  {name: 'Site', id:'site'},
  {name: 'Placa', id:'placa'},
  {name: 'Google', id:'google'},
  {name: 'Facebook', id:'facebook'},
  {name: 'Instagram', id:'instagram'},
  {name: 'Linkedin', id:'linkedin'},
  {name: 'Indicação', id:'indicacao'},
  {name: 'Networking', id:'networking'},
  {name: 'Interno', id:'intern'},
  {name: 'Sigilo', id:'secret'},
  {name: 'Não divulgar', id:'notAnnounce'},
  {name: 'Todos', id:'all'},
]

const GeneralRegister = ({ history, location }) => {
  const { state, search } = location;
  const inputImagens = useRef();

  const uuid = require("uuid");

  const [regions, setRegions] = useState([]);
  const [regionsToDisplay, setRegionsToDisplay] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);

  // Criando um state onde ficarão armazenadas as imagens em array
  const [imagefiles, setImagefiles] = useState([]);

  const [previewimg, setPreviewImg] = useState([]);
  const [imageDesc, setImageDesc] = useState([]);
  const [urls, setUrls] = useState([]);
  const [main, setMain] = useState();
  const [sendMessage, setSendMessage] = useState(false);
  const [imovelId, setImovelId] = useState();
  const [contador, setContador] = useState(0);
  const [upload, setUpload] = useState(false);
  const [duplicate, setDuplicate] = useState(null);

  const [imovel, setImovel] = useState({pictures:[]});
  const [descriptionValue, setDescriptionValue] = useState("");
  const [capaPlataform, setCapaPlataform] = useState("");
  const [capaSite, setCapaSite] = useState("");
  const [imagePlataform, setImagePlataform] = useState("");
  const [imageSite, setImageSite] = useState("");
  const [buildingValue, setBuildingValue] = useState('');
  const [leaseValue, setLeaseValue] = useState('');
  const [saleValue, setSaleValue] = useState('');
  const [shedValue, setShedValue] = useState('');
  const [officeValue, setOfficeValue] = useState('');
  const [landValue, setLandValue] = useState('');
  const [iptuValue, setIptuValue] = useState('');
  const [condominiumValue, setCondominiumValue] = useState('');
  const [rightValue, setRightValue] = useState('');
  const [totalCostValue, setTotalCostValue] = useState('');
  const [transectedValue, setTransectedValue] = useState('');
  const [negociationValue, setNegociationValue] = useState('');

  const [key, setKey] = useState("");
  const [keyArray, setKeyArray] = useState("");

  const [announceChannel, setAnnounceChannel] = useState("");
  const [announceArray, setAnnounceArray] = useState("");
  
  const [disabled, setDisabled] = useState(false);
  const [otherRegion, setOtherRegion] = useState('');
  const [regionSelect, setRegionSelect] = useState('');

 
  const replaceValue = (_value) => {
    if (_value > 0) {
      return _value.toLocaleString("pt-BR");
    } else {
      return "";
    }
  };

  const onSetDescriptionValue = () => {
    setDescriptionValue(`
    Código cadastro: ${imovel?.code !== undefined ? imovel?.code : null}
    Descritivo: Galpão localizado no bairro ${
      imovel?.district !== undefined ? imovel?.district : null
    }, em ${
      imovel?.city !== undefined ? imovel?.city : null
    }, perto de vias de acesso privilegiadas.  Possui transporte público nas proximidades.  
    Descritivo do imóvel:
    - Área de terreno:  ${
      imovel?.landAreaNumber !== undefined ? replaceValue(imovel?.landAreaNumber) : ""
    } m²
    - Área de Construção: ${
      imovel?.buildingAreaNumber !== undefined ? replaceValue(imovel?.buildingAreaNumber) : ""
    } m²
    - Pátio de Manobras
    - Docas
    - Guarita
    - Pé direito alto ou com ${
      imovel?.rightFootNumber !== undefined ? replaceValue(imovel?.rightFootNumber) : ""
    } m
    - Escritório
    - Projeto arquitetônico
    - Banheiros
    - Sistema de câmeras
    - Alarme
    - Elevador
    - Refeitório
    - Sanitários 
    - Vestiário feminino e masculino
    - Portão de segurança de enrolar automatizado
    - Energia solar instalada
    - Reuso de água da chuva 
    - Ponte rolante toneladas
    - AVCB, HABITE-SE e projeto aprovado 
    
    Somos especialistas em galpões e imóveis corporativos. Saiba mais sobre este e outros imóveis em nosso site: http://www.leonel.com.br/imobiliaria/imoveis
    IMPORTANTE: disponibilidade, metragem e valores anunciados de venda e de locação estão sujeitos a confirmação e/ou alteração pelos proprietários representantes.
    
    A localização divulgada aproximada ao imóvel, para conhecer pessoalmente agende sua visita. 
    
    Contato:
    (16) 99153-8148 (WhatsApp) - Alice Leonel CRECI 173343
    `)
  }

  const getRealState = async (_id) => {
    try {
      const _result = await getRealStatesById(_id);
      if (_result?.response) {
        await Alert.fire({
          title: "Atenção!",
          text: _result?.response?.data,
          icon: "error",
        });
        return;
      }
      setImovel(_result?.data);
    } catch (error) {
      await Alert.fire(JSON.stringify(error));
    }
  };

  //constante que pega os arquivos de iamgem enviados pelo input file
  const getfile = async (e) => {
    
    setContador(previewimg?.concat(mappedFiles).length); //contador para updateRealStates
   
    let array = [];
    array = Array.from(e?.target?.files);
    const mappedFiles = array.map((file) => ({
      ...file,
      preview: URL.createObjectURL(file),
      name:file?.name,
      id: Math.random(),
    }));

    for (let i = 0; i < e?.target?.files?.length; i++) {
      setImagefiles((prevState) => [...prevState, e?.target?.files[i]]);
    }

    setPreviewImg(previewimg?.concat(mappedFiles));
  };

  const loadRegions = async () => {
    try {
      const _result = await getRegions();
      if (_result?.response) {
        await Alert.fire(_result?.response?.data);
        return;
      } else if (!_result?.data?.length) {
        return;
      }
      setRegions(_result?.data);
      var _tempRegions = [{ label: "Selecionar todos", value: "all" },{ label: "Outro", value: "other" }];
      for (let x = 0; x < _result?.data.length; x++) {
        const _reg = _result?.data[x];
        _tempRegions.push({ value: _reg?.region, label: _reg?.region });
      }
      
      setRegionsToDisplay(_tempRegions);
    } catch (error) {
      await Alert.fire(JSON.stringify(error));
    }
  }

  //constante responsável pelo envio das iamgens ao firebase
  const handleUpload = (id) => {
    imagefiles.map((image) => {
      const uploadTask = FirebaseApp.storage()
        .ref("images/" + id + "/" + image.name)
        .put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          // setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          await FirebaseApp.storage()
            .ref("images/" + id + "/")
            .child(image.name)
            .getDownloadURL()
            .then((urls) => {
              setUrls((prevState) => [...prevState, urls]);
            });
        }
      );
    });

  };

  // Cria o imóvel e pega o 'id' para associar às imagens
  const createImovelId = async () => {
    let create = await createRealStates(imovel);
    let dados = create.data;
    setImovelId(dados?._id);
    if (imagefiles.length === 0) {
      setUpload(true);
    } else {
      handleUpload(dados?._id);
    }
  };
  
  const cancelImovel = async (e) => {
    e.preventDefault();
    await Alert.fire({
      title: "Atenção",
      text: "Deseja mesmo cancelar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        history.push("/property-listing");
      }
    });
  };

  useEffect(() => {
    getTotalPhotos()
  }, [urls]);


  function getTotalPhotos(){
    if (urls.length > 0 && urls.length === imagefiles.length) {
      const picture = urls.map((url, index) => ({
        id: imagefiles[index]?.id,
        image: url,
        description: imageDesc[index],
        capaSite: capaSite[index],
        capaPlataform: capaPlataform[index],
        imageSite: imageSite[index],
        imagePlataform: imagePlataform[index]
      }));

      if(picture)
      setImovel({ ...imovel, pictures: imovel?.pictures?.concat(picture) });
      setUpload(true)
    }
  }

  //salva o imóvel ou atualiza caso o path venha com o id
  const saveImovel = async () => {
    
    try {
      setDisabled(true)
    if (!imovel?.propertyType) {
      await Alert.fire({
        title: "Atenção",
        text: "Selecione o tipo do imóvel",
      });
      setDisabled(false)
      return;
    }

    let lat = 0
    let lng = 0
    if (imovel?.address && imovel?.city) {

      if(!imovel?.address?.toUpperCase().includes('VERIFICAR') && imovel?.zipCode !== '0'){

        const _resultGeoCode = await Geocode.fromAddress(
          `${imovel?.address}, ${imovel?.city}`,
          "AIzaSyA-uIcPdvgx5alYfpWXuQzRE1yJG88jWBY",
          "pt",
          "br"
        );  

        lat = _resultGeoCode?.results[0]?.geometry?.location?.lat
        lng = _resultGeoCode?.results[0]?.geometry?.location?.lng

      }

      setImovel({
        ...imovel,
        latitudeAndLongitude: lat + "," + lng, 
        adDescription: descriptionValue ? descriptionValue : imovel?.adDescription,
        keyLocation: keyArray,
        salesChannel: announceArray,
        commercialRegion: regionSelect === 'Outro' ? otherRegion : regionSelect,
        updatedAt: moment()
      
      });
      
    } else {
      await Alert.fire({
        title: "Atenção",
        text: "Endereço do imóvel incompleto.",
      });
      setDisabled(false)
      return;
    }

    if (imovel?._id) {
      if (imagefiles.length === 0) {
        setUpload(true);
      } else {
        handleUpload(imovel?._id);
      }
    } else {
      createImovelId()
        .then(() => {
          Alert.fire({
            title: "Sucesso",
            text: "O imóvel foi salvo com sucesso",
            icon: "success",
          });
          setDisabled(false)

        })
        .catch((err) => {
          Alert.fire({
            title: "Atenção",
            text: "Verifique os valores preenchidos.",
          });
          setDisabled(false)

        });
    }
    } catch (error) {
      setDisabled(false)

    }
    
  };

  const loadCep = async () => {
    try {
      if (imovel?.zipCode) {
        var cep = imovel?.zipCode?.replace(/\D/g, "");
        let url = `https://viacep.com.br/ws/${cep}/json/`;
        fetch(url).then((response) => {
          response.json().then((data) => {
            var _imovel = JSON.parse(JSON.stringify(imovel));
            if (data?.logradouro) {
              _imovel.address = data?.logradouro;
            }
            if (data?.bairro) {
              _imovel.district = data?.bairro;
            }
            if (data?.uf) {
              _imovel.state = data?.uf;
            }
            if (data?.localidade) {
              _imovel.city = data?.localidade;
            }
            setImovel(_imovel);
            setDisabled(false)
          });
        });
      }
    } catch (error) {
      console.log(error);
      setDisabled(false)
    }
  };
  
  const createCode = async (_propertyType) => {
    try {
      var _imovel = JSON.parse(JSON.stringify(imovel));
      _imovel.propertyType = _propertyType;
      const _result = await getCodeByPropertyType(_propertyType);
      if (_result?.response) {
        await Alert.fire(error?.response?.data);
        return;
      }
      _imovel.code = _result?.data;
      setImovel(_imovel);
    } catch (error) {
      await Alert.fire("Houve um erro ao tentar recuperar o código" + error);
    }
  };

  useEffect(async () => {
    if (state?.duplicate === false) {
      var response = await getRealStatesById(state.data?._id);
      setImovel(response.data);
      setBuildingValue(response?.data?.buildingAreaNumber);
      setShedValue(response?.data?.shedAreaNumber);
      setLandValue(response?.data?.landAreaNumber);
      setLeaseValue(response?.data?.leaseValueNumber);
      setSaleValue(response?.data?.saleValueNumber);
      setIptuValue(response?.data?.iptuValueNumber);
      setCondominiumValue(response?.data?.condominiumValueNumber);
      setOfficeValue(response?.data?.officeAreaNumber);
      setRightValue(response?.data?.rightFootNumber);
      setTransectedValue(response?.data?.amountTransactedNumber); 
      setNegociationValue(response.data?.negociationValueNumber)
      setSelectedRegions({label: response.data?.commercialRegion,value:response.data?.commercialRegion})

    }
    if (state?.duplicate === true) {
      var response = await getRealStatesById(state.data?._id);
      setImovel(response.data);
      setDuplicate(true);
    }
  }, [state]);

  useEffect(async () => {
    if (duplicate === true) {

      delete imovel?._id;
      delete imovel?.createdAt;
      delete imovel?.updatedAt;
      delete imovel?.__v;
      var code = await createCode(imovel?.propertyType);
      let timeLoop = setTimeout(() => {
        clearInterval(timeLoop);
      }, 2000);
    }
  }, [duplicate]);

  useEffect(() =>{

    if(key.length){
      let array= [];
      for (const i in key){
        array.push(key[i]?.id)
      }
      setKeyArray(array)
    }
  },[key])

  useEffect(() =>{

    if(announceChannel.length){
      let array= [];
      for (const i in announceChannel){
        array.push(announceChannel[i]?.id)
      }
      setAnnounceArray(array)
    } else {
      setAnnounceArray([])
    }
  },[announceChannel])

  useEffect(() =>{

    if(imovel?.keyLocation?.map){
      var array = [];
      for(const i in imovel.keyLocation){
        for(const j in optionsKey){
          if(imovel.keyLocation[i] === optionsKey[j]?.id){
            array.push(optionsKey[j])
          }
        }
      }
      setKey(array)
    }
  },[imovel?.keyLocation])

  useEffect(() =>{

    if(imovel?.salesChannel?.map){
      var array = [];
      for(const i in imovel.salesChannel){
        for(const j in optionsAnnounce){
          if(imovel.salesChannel[i] === optionsAnnounce[j]?.id){
            array.push(optionsAnnounce[j])
          }
        }
      }
      setAnnounceChannel(array)
    }
  },[imovel?.salesChannel])

  useEffect(() => {
    if (!imovel?.approvedStatus) {
      setImovel({
        ...imovel,
        approvedStatus: 'approved', 
      });
    } else if(imovel?.approvedStatus === 'waiting') {
      setImovel({
        ...imovel,
        code: `TC-${uuid.v4()}` 
      });
    }
    if(imovel?.approvedStatus !=='waiting' && imovel?.code?.includes('TC-')){
      createCode(imovel?.propertyType)
    }
  },[imovel?.approvedStatus])

  useEffect(() => {
 
    let totalCost = 0;
    totalCost =  (imovel?.iptuValueNumber ?? 0) + (imovel?.leaseValueNumber ?? 0) + (imovel?.condominiumValueNumber ?? 0);
      document.getElementById("total_cost").value = totalCost;
      setTotalCostValue(totalCost)
    }, [imovel]);


  useEffect(() => {
      const params = new URLSearchParams(search);
      if (params) {
        const _id = params.get("id");
        if (_id) {
          getRealState(_id);
        }
      }
  }, [search]);
  
  useEffect(() => {
      if (imovel?._id && upload) {
        updateRealStates(imovel?._id, imovel).then((resultUpdate) => {
          Alert.fire({
            title: "Sucesso",
            text: "O imóvel foi salvo com sucesso",
            icon: "success",
          });
          history.push("/property-guide");
        });
      }
  
      if (imovelId && upload) {
  
        updateRealStates(imovelId, imovel).then((resultUpdate) => {
          Alert.fire({
            title: "Sucesso",
            text: "O imóvel foi salvo com sucesso",
            icon: "success",
          });
          history.push("/property-listing");
        });
      }
  }, [upload]);

  useEffect(() => {
    moment.locale("pt-br");
    window.scrollTo(0, 0);
    if (!imovel?.registrationDate) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      document.getElementById("current_date").value = day + "/" + month + "/" + year;
    }
    loadRegions();

}, []);


  return (
    <>
      <Modal className="modal fade" show={sendMessage}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Imagem cadastrada</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setSendMessage(false)}
            >
              <span>x</span>
            </Button>
          </div>
          <div className="modal-body">
            {/* {imovel?.pictures && imovel.pictures.map && (
              imovel.pictures.map((dados) => {
              return (
                <>
                  <img
                    src={dados?.image}
                    alt="image"
                    className="mb-3"
                    width={250}
                    height={240}
                  />
                  <button
                    className="ml-3 btn btn-danger rounded"
                    onClick={() => [deleteImage(dados), set]}
                  >
                    Deletar Imagem
                  </button>
                </>
              );
            }))} */}
          </div>  
        </div>
      </Modal>

      <div className="container-fluid-register">
        <div className="form-head page-titles d-flex  align-items-center bg-brad">
          <div className="mr-auto  d-lg-block">
            <h4 className="text-black font-w600">Cadastro Geral</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to="#">Imóveis</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="#">Cadastro de novo imóvel</Link>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className="form">
        <div className="background">
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-3 text-center">Cadastro geral de imóveis</h4>
            </div>
          </div>

          <div className="col-md-12 mt-5 mb-2">
            <h4 className="fs-18 text-primary">Cadastro básico</h4>
          </div>

          {/* // 1. Tipo do Imóvel 2. Código do Imóvel 3. Data do cadastro */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Tipo do imóvel *</label>
              <select
                className="form-control input-rounded"
                value={imovel?.propertyType}
                onChange={(e) => createCode(e.target.value)}
              >
                <option value="" disabled>Selecione</option>
                <option value={enumPropertyType.area}>
                  {enumPropertyTypeDescriptions(enumPropertyType.area)}
                </option>
                <option value={enumPropertyType.hangar}>
                  {enumPropertyTypeDescriptions(enumPropertyType.hangar)}
                </option>
                <option value={enumPropertyType.commercial}>
                  {enumPropertyTypeDescriptions(enumPropertyType.commercial)}
                </option>
                <option value={enumPropertyType.store}>
                  {enumPropertyTypeDescriptions(enumPropertyType.store)}
                </option>
                <option value={enumPropertyType.businessAdaptableResidence}>
                  {enumPropertyTypeDescriptions(enumPropertyType.businessAdaptableResidence)}
                </option>
                <option value={enumPropertyType.residential}>
                  {enumPropertyTypeDescriptions(enumPropertyType.residential)}
                </option>
                <option value={enumPropertyType.rurais}>
                  {enumPropertyTypeDescriptions(enumPropertyType.rurais)}
                </option>
                <option value={enumPropertyType.commercialRoom}>
                  {enumPropertyTypeDescriptions(enumPropertyType.commercialRoom)}
                </option>
                <option value={enumPropertyType.walledLand}>
                  {enumPropertyTypeDescriptions(enumPropertyType.walledLand)}
                </option>
                <option value={enumPropertyType.ground}>
                  {enumPropertyTypeDescriptions(enumPropertyType.ground)}
                </option>
              </select>
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Código do imóvel *</label>
              <input
                className="form-control input-rounded"
                type="text"
                value={imovel?.code}
                disabled
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    code: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Data do Cadastro *</label>
              <input
                className="form-control input-rounded"
                type="text"
                id="current_date"
                name="registrationDate"
                value={imovel?.registrationDate}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    registrationDate: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/* // 4. Cadastrador/Captador 5. CEP  .6 Endereço 7.Nº 8. Complemento 9. Bairro 10.Bairro Comercial 11. Cidade 12.Estado 13.Referencia 14.Disponibilidade*/}
          <div className="row fs-14">
            <div className="col-md-12 mt-3">
              <label className="ml-3">Cadastrador/Captador ou Parceria *</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="pickupEnroller"
                value={imovel?.pickupEnroller}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    pickupEnroller: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-3 mt-3">
              <label className="ml-3">CEP *</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="zipCode"
                value={imovel?.zipCode}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    zipCode: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-3 mt-3">
              <label className="ml-3 mt-3"> </label>
              <br />
              <button className="btn btn-primary rounded w-100" onClick={loadCep}>
                <i className="flaticon-381-search-1 mr-1"></i>
                buscar
              </button>
            </div>

            <div className="col-md-3 mt-3">
              <label className="ml-3">Rua/Avenida/Rodovia *</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="address"
                value={imovel?.address}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    address: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-3 mt-3">
              <label className="ml-3">Nº *</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="number"
                value={imovel?.number}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    number: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Complemento</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="complement"
                value={imovel?.complement}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    complement: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Nome do condomínio/empreendimento</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="complement"
                value={imovel?.buildName}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    buildName: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Bairro</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="district"
                value={imovel?.district}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    district: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Região Comercial</label>
                {regionsToDisplay.length ?
                        <Select
                          name="regions"
                          value={selectedRegions}
                          options={regionsToDisplay}
                          onChange={(e) =>
                            [setRegionSelect(e.label,
                            ),setSelectedRegions(e)]
                          }
                        />
                        : <input
                        className="form-control input-rounded"
                        type="text"
                        name="commercialRegion"
                        value={regionSelect}
                        onChange={(e) =>
                          setRegionSelect(e.target.value,
                            )
                        }
                      />}
            </div>

            {regionSelect === 'Outro' && (
              <div className="col-md-4 mt-3">
              <label className="ml-3">Nome da Região Comercial</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="city"
                value={otherRegion}
                onChange={(e) =>
                  setOtherRegion(e.target.value)
                }
              />
            </div>
            )}

            <div className="col-md-4 mt-3">
              <label className="ml-3">Cidade</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="city"
                value={imovel?.city}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    city: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Estado</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="state"
                value={imovel?.state}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    state: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Referencia</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="reference"
                value={imovel?.reference}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    reference: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Disponibilidade</label>
              <select
                className="form-control input-rounded"
                value={imovel?.availability}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    availability: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value={enumAvailability.available}>
                  {enumAvailabilityDescriptions(enumAvailability.available)}
                </option>
                <option value={enumAvailability.unavailable}>
                  {enumAvailabilityDescriptions(enumAvailability.unavailable)}
                </option>
              </select>
            </div>
          </div>

          {/* // 15.Ativo 16. Tipo transação */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Ativo</label>
              <select
                className="form-control input-rounded"
                type="text"
                name="activeOrInactive"
                value={imovel?.activeOrInactive}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    activeOrInactive: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value="ativo">Ativo</option>
                <option value="inativo">Inativo</option>
              </select>
            </div>
            <div className="col-md-4 mt-3">
              <label className="ml-3">Tipo transação:</label>
              <select
                className="form-control input-rounded"
                value={imovel?.transactionType}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    transactionType: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value={enumTypeAgreement.buitToSuit}>
                  {enumTypeAgreementDescriptions(enumTypeAgreement.buitToSuit)}
                </option>
                <option value={enumTypeAgreement.location}>
                  {enumTypeAgreementDescriptions(enumTypeAgreement.location)}
                </option>
                <option value={enumTypeAgreement.sale}>
                  {enumTypeAgreementDescriptions(enumTypeAgreement.sale)}
                </option>
                <option value={enumTypeAgreement.saleAndLease}>
                  {enumTypeAgreementDescriptions(enumTypeAgreement.saleAndLease)}
                </option>
                <option value={enumTypeAgreement.saleRented}>
                  {enumTypeAgreementDescriptions(enumTypeAgreement.saleRented)}
                </option>
                <option value={enumTypeAgreement.saleLeaseback}>
                  {enumTypeAgreementDescriptions(enumTypeAgreement.saleLeaseback)}
                </option>
              </select>
            </div>
          </div>

          {/* // 17. Valor de Locação 18. Valor de Venda  19. Valor de IPTU (R$ Mensal) */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Valor Locação (R$)</label>
              <CurrencyInput
                className="form-control input-rounded"
                groupSeparator="."
                decimalSeparator=","
                value={leaseValue}
                onValueChange={(value) =>
                  [setImovel({
                    ...imovel,
                    leaseValueNumber: Number(value?.replace(/,/g, ".")),
                  }), setLeaseValue(value)]
                }
              />
            </div>

            <div className="col-md-4 mt-3 ">
              <label className="ml-3">Valor Venda (R$)</label>
              <CurrencyInput
                className="form-control input-rounded"
                groupSeparator="."
                decimalSeparator=","
                value={saleValue}
                onValueChange={(value) =>
                  [setImovel({
                    ...imovel,
                    saleValueNumber: Number(value?.replace(/,/g, ".")),
                  }), setSaleValue(value)]
                }
              />
            </div>

            <div className="col-md-4 mt-3 ">
              <label className="ml-3">Valor IPTU (R$ mensal )</label>
              <CurrencyInput
                className="form-control input-rounded"
                groupSeparator="."
                decimalSeparator=","
                value={iptuValue}
                onValueChange={(value) =>
                  [setImovel({
                    ...imovel,
                    iptuValueNumber: Number(value?.replace(/,/g, ".")),
                  }), setIptuValue(value)]
                }
              />
            </div>
          </div>

          {/* // 20. Valor de Condomínio (R$ Mensal) 21. Custo total da ocupação */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Valor de Condomínio (R$)</label>
              <CurrencyInput
                className="form-control input-rounded"
                groupSeparator="."
                decimalSeparator=","
                value={condominiumValue}
                onValueChange={(value) =>
                  [setImovel({
                    ...imovel,
                    condominiumValueNumber: Number(value?.replace(/,/g, ".")),
                  }), setCondominiumValue(value)]
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Valor acordado (R$)</label>
              <CurrencyInput
                className="form-control input-rounded"
                groupSeparator="."
                decimalSeparator=","
                value={negociationValue}
                onValueChange={(value) =>
                 [setImovel({
                    ...imovel,
                    negociationValueNumber: Number(value?.replace(/,/g, ".")),
                  }), setNegociationValue(value)]
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Custo total da Ocupação (R$)</label>
              <CurrencyInput
                className="form-control input-rounded"
                groupSeparator="."
                decimalSeparator=","
                id="total_cost"
                value={totalCostValue}
                onValueChange={(value) =>
                 [setImovel({
                    ...imovel,
                    totalCostOfOccupationNumber: Number(value?.replace(/,/g, ".")),
                  }), setTotalCostValue(value)]
                }
              />
            </div>
          </div>

          {/* // 22.Chaves 23.Proprietario 24. Contato do Proprietário . 25.Representante 26. Contato do representante */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Chaves</label>
              <Multiselect
                      showArrow={true}
                      showCheckbox={true}
                      placeholder='Selecione'
                      options={optionsKey} // Options to display in the dropdown
                      selectedValues={key}
                      onRemove={(e) => setKey(e)} // Function will trigger on select event
                      onSelect={(e) => setKey(e)} // Function will trigger on select event
                      displayValue="name" // Property name to display in the dropdown options
                    />
            </div>

            {imovel?.keyLocation === "other" || imovel?.keyLocation?.includes('other') || keyArray?.includes('other') ? (
              <div className="col-md-4 mt-3 ">
                <label className="">Localização da chave</label>
                <input
                  className="form-control input-rounded"
                  value={imovel?.otherKeyLocation}
                  onChange={(e) =>
                    setImovel({
                      ...imovel,
                      otherKeyLocation: e.target.value,
                    })
                  }
                />
              </div>
            ) : null}

            <div className="col-md-4 mt-3">
              <label className="ml-3">Proprietário</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="owner"
                value={imovel?.ownerAdministrator}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    ownerAdministrator: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-md-4 mt-3">
              <label className="ml-3">Contato proprietário</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="ownerPhone"
                value={imovel?.ownerPhone}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    ownerPhone: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Email proprietário</label>
              <input
                className="form-control input-rounded"
                type="email"
                name="ownerEmail"
                value={imovel?.ownerEmail}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    ownerEmail: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Representante</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="representative"
                value={imovel?.representative}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    representative: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Contato do representante</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="representativePhone"
                value={imovel?.representativePhone}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    representativePhone: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Email do representante</label>
              <input
                className="form-control input-rounded"
                type="email"
                name="representantEmail"
                value={imovel?.representantEmail}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    representantEmail: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/* // 27. Exclusividade 28. Status (pronto/ construção etc) 29. Área do terreno m² 30. Área do Galpão m² */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Exclusividade ?</label>
              <select
                className="form-control input-rounded"
                type="text"
                name="exclusivity"
                value={imovel?.exclusivity}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    exclusivity: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value={enumExclusivity.yes}>
                  {enumExclusivityDescriptions(enumExclusivity.yes)}
                </option>
                <option value={enumExclusivity.no}>
                  {enumExclusivityDescriptions(enumExclusivity.no)}
                </option>
              </select>
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Status</label>
              <select
                className="form-control input-rounded"
                value={imovel?.status}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    status: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value={enumStatus.construction}>
                  {enumStatusAreaDescriptions(enumStatus.construction)}
                </option>
                <option value={enumStatus.project}>
                  {enumStatusAreaDescriptions(enumStatus.project)}
                </option>
                <option value={enumStatus.ready}>
                  {enumStatusAreaDescriptions(enumStatus.ready)}
                </option>
                <option value={enumStatus.development}>
                  {enumStatusAreaDescriptions(enumStatus.development)}
                </option>
              </select>
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Área de terreno m²</label>
              <CurrencyInput
                value={landValue}
                className="form-control input-rounded"
                groupSeparator="."
                decimalSeparator=","
                onValueChange={(value) =>
                  [setImovel({
                    ...imovel,
                    landAreaNumber: Number(value?.replace(/,/g, ".")),
                  }), setLandValue(value)]
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Área de Galpão m²</label>
              <CurrencyInput
                value={shedValue}
                className="form-control input-rounded"
                groupSeparator="."
                decimalSeparator=","
                onValueChange={(value) =>
                  [setImovel({
                    ...imovel,
                    shedAreaNumber: Number(value?.replace(/,/g, ".")),
                  }),setShedValue(value)]
                }
              />
            </div>
          </div>

          {/* // 31. Área do Escritório m² 32. Área Construída total m² 33. Pé direito (texto) 34.Pe direito valor */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Área de Escritório</label>
              <CurrencyInput
                className="form-control input-rounded"
                groupSeparator="."
                value={officeValue}
                decimalSeparator=","
                onValueChange={(value) =>
                  [setImovel({
                    ...imovel,
                    officeAreaNumber: Number(value?.replace(/,/g, ".")),
                  }), setOfficeValue(value)]
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Área Construída</label>
              <CurrencyInput
                className="form-control input-rounded"
                groupSeparator="."
                decimalSeparator=","
                value={buildingValue}
                onValueChange={(value) =>
                  [setImovel({
                    ...imovel,
                    buildingAreaNumber: Number(value?.replace(/,/g, "."))
                  }), setBuildingValue(value)]
                }
              />
            </div>
           
              <div className="col-md-4 mt-3">
                <label className="ml-3">Altura pé direito</label>
                <CurrencyInput
                  className="form-control input-rounded"
                  groupSeparator="."
                  decimalSeparator=","
                  value={rightValue}
                  onValueChange={(value) =>
                    [setImovel({
                      ...imovel,
                      rightFootNumber: Number(value?.replace(/,/g, ".")),
                    }), setRightValue(value)]
                  }
                />
              </div>
            
          </div>

          {/* // 35. Docas 36. Tipo de docas 37. Nº de docas 38. Pátio de Manobras Internas (sim, não, à verificar) */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Docas</label>
              <select
                className="form-control input-rounded"
                value={imovel?.docks}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    docks: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
                <option value="Verificar">Verificar</option>
              </select>
            </div>

            {imovel?.docks === "Sim" ? (
              <>
                <div className="col-md-4 mt-3">
                  <label className="ml-3">Tipo de docas</label>
                  <input
                    className="form-control input-rounded"
                    value={imovel?.typeOfDocks}
                    onChange={(e) =>
                      setImovel({
                        ...imovel,
                        typeOfDocks: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-md-4 mt-3">
                  <label className="ml-3">Número de docas</label>
                  <input
                    className="form-control input-rounded"
                    value={imovel?.numberOfDocksNumber}
                    onChange={(e) =>
                      setImovel({
                        ...imovel,
                        numberOfDocksNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            ) : null}

            <div className="col-md-4 mt-3 ">
              <label className="ml-3">Pátio de Manobra Interno</label>
              <select
                className="form-control input-rounded"
                value={imovel?.internalManeuverYard}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    internalManeuverYard: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
                <option value="Verificar">Verificar</option>
              </select>
            </div>
          </div>

          {/* // 39. Estacionamento para Carretas (sim, não, à verificar) 40. Vestiário (sim, não, à verificar) 41. Refeitório (sim, não, à verificar) */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Estacionamento para Carretas</label>
              <select
                className="form-control input-rounded"
                value={imovel?.carParking}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    carParking: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
                <option value="Verificar">Verificar</option>
              </select>
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Vestiário</label>
              <select
                className="form-control input-rounded"
                value={imovel?.dressingRoom}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    dressingRoom: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
                <option value="Verificar">Verificar</option>
              </select>
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Refeitório</label>
              <select
                className="form-control input-rounded"
                value={imovel?.refectory}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    refectory: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
                <option value="Verificar">Verificar</option>
              </select>
            </div>
          </div>

          {/* // 42. Ponte Rolante (sim, não, à verificar) 43. Transformador (sim, não, à verificar) 44. Infraestrutura */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Ponte Rolante</label>
              <select
                className="form-control input-rounded"
                value={imovel?.overheadCrane}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    overheadCrane: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
                <option value="Verificar">Verificar</option>
              </select>
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Transformador</label>
              <select
                className="form-control input-rounded"
                value={imovel?.transformer}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    transformer: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
                <option value="Verificar">Verificar</option>
              </select>
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Infraestrutura</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="infrastructure"
                value={imovel?.infrastructure}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    infrastructure: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/* // 45. Diferenciais 46. Observações 48. Ultimo ocupante */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Diferenciais</label>
              
               <textarea
                className="form-control"
                rows={5}
                name="occurrencesComments"
                value={imovel?.obsDifferentials}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    obsDifferentials: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Observações</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="observation"
                value={imovel?.observation}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    observation: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3 ">
              <label className="ml-3">Último Ocupante</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="lastOccupant"
                value={imovel?.lastOccupant}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    lastOccupant: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/* // ANÚNCIO (para básico e avançado) */}
          <div className="col-md-12 mt-5 mb-2">
            <h4 className="fs-18 text-primary">Anúncio</h4>
          </div>

          {/* // 49. Fotos com marca d'agua 50. Canal de Vendas */}
          <div className="row fs-14">
            <div className="col-md-3 mt-3">
             
              <button
                type="button"
                style={{ borderRadius: "6px" }}
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#ModalPictures"
              >
                Adicionar Imagens
              </button>

              <div
                className="modal fade"
                id="ModalPictures"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content text-center">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Adicionar Imagens
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body pt-0" id="modal-body">
                      <div className="row d-flex justify-content-end"></div>
                      <div className="row">
                        <label className="btn btn-primary mr-2" htmlFor="inputPictures">
                          Adicionar imagem
                          <span className="btn-icon-right">
                            <i className="fa fa-upload mr-2" />
                          </span>
                        </label>
                      </div>
                      <div className="row">
                        <input
                          className="form-control-file filearch"
                          type="file"
                          id="inputPictures"
                          name="inputPictures"
                          multiple={true}
                          onChange={getfile}
                          accept="image/*"
                        />
                      </div>
                      <div className="PreviewImages" ref={inputImagens}>
                        {previewimg.map((file, index) => {
                          return (
                            <div className="PreviewImages text-left" key={index}>
                              <div className="row">
                                <div className="col-md-6 mt-3" style={{overflow:'hidden'}}>
                                <button 
                                className="btn btn-danger pt-1 pb-1 pl-2 pr-2" 
                                style={{position:'absolute', left:'2px',borderRadius:'0.5rem'}}
                                onClick={() => 
                                  {
                                    Alert.fire({
                                      title: "Atenção",
                                      text: "Deseja apagar essa imagem?",
                                      confirmButtonText: "Excluir",
                                      confirmButtonColor: "red",
                                      showCancelButton: true,
                                      cancelButtonText: "Cancelar",
                                      reverseButtons: true,
                                    }).then((result) => {
                                      if (result.isConfirmed) {

                                      imagefiles.splice(index,1)
                                      previewimg.splice(index,1)

                                      setImageDesc([]);
                                      //handleUpload();
                                      for (var i = 0; i < 50; i++) {
                                        let imageDescription = document.getElementsByName(
                                          "inputDescription_" + i
                                        );
                                        let capaPlataforma = document.getElementsByName(
                                          "plataform-capa" + i
                                        )
                                        let capaSitio = document.getElementsByName(
                                          "site-capa" + i
                                        )
                                        let imagemPlataforma = document.getElementsByName(
                                          "platforma" + i
                                        )
                                        let imagemSitio = document.getElementsByName(
                                          "site" + i
                                        )
                                        setCapaPlataform((capaPlatform) => [
                                            ...capaPlatform, capaPlataforma[0]?.checked ? capaPlataforma[0].checked : false
                                        ]);
                                        setCapaSite((capaSite) => [
                                          ...capaSite, capaSitio[0]?.checked ? capaSitio[0].checked : false
                                        ]);
                                        setImageSite((imageSite) => [
                                          ...imageSite, imagemSitio[0]?.checked ? imagemSitio[0].checked  : false
                                       ]);
                                        setImagePlataform((imagePlataform) => [
                                          ...imagePlataform, imagemPlataforma[0]?.checked ? imagemPlataforma[0].checked : false
                                       ]);
                                        if (imageDescription[0]) {
                                          setImageDesc((imageDesc) => [
                                            ...imageDesc,
                                            imageDescription[0].value,
                                          ]);
                                        }
                                      }
                                      }
                                    });
                                    
                                  }
                                }
                                >
                                  X
                                </button>
                                  <img src={file.preview} height="100px" className="mt-3" />
                                </div>{" "}
                                {previewimg ? (
                                  <div className="col-md-6 mt-3 vertical-align">
                                    <div className="custom-control custom-radio mb-2">
                                      <input
                                        id={"plataform" + index}
                                        name={"plataform-capa" + index}
                                        type="checkbox"
                                        className="custom-control-input"
                                        required
                                        onChange={() => setMain(file)}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"plataform" + index}
                                      >
                                        Capa Plataforma
                                      </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                      <input
                                        id={"secondary" + index}
                                        name={"site-capa" + index}
                                        type="checkbox"
                                        className="custom-control-input"
                                        required
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"secondary" + index}
                                      >
                                        Capa Site
                                      </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                      <input
                                        id={"site" + index}
                                        name={"site" + index}
                                        type="checkbox"
                                        className="custom-control-input"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"site" + index}
                                      >
                                        Imagem Site
                                      </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-2">
                                      <input
                                        id={"plataforma" + index}
                                        name={"plataforma" + index}
                                        type="checkbox"
                                        className="custom-control-input"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"plataforma" + index}
                                      >
                                        Imagem Plataforma
                                      </label>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <input
                                type="text"
                                className="mt-3 mb-3 form-control"
                                name={`inputDescription_${index}`}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-dark" data-dismiss="modal">
                        Cancelar
                      </button>
                      <button
                        type="button"
                        className="btn btn-success"
                        data-dismiss="modal"
                        onClick={() => {
                          var up_names = document.getElementsByName("plataform").checked;
                          setImageDesc([]);
                          //handleUpload();
                          for (var i = 0; i < 50; i++) {
                            let imageDescription = document.getElementsByName(
                              "inputDescription_" + i
                            );
                            let capaPlataforma = document.getElementsByName(
                              "plataform-capa" + i
                            )
                            let capaSitio = document.getElementsByName(
                              "site-capa" + i
                            )
                            let imagemPlataforma = document.getElementsByName(
                              "platforma" + i
                            )
                            let imagemSitio = document.getElementsByName(
                              "site" + i
                            )
                            setCapaPlataform((capaPlatform) => [
                                ...capaPlatform, capaPlataforma[0]?.checked ? capaPlataforma[0].checked : false
                            ]);
                            setCapaSite((capaSite) => [
                              ...capaSite, capaSitio[0]?.checked ? capaSitio[0].checked : false
                            ]);
                            setImageSite((imageSite) => [
                              ...imageSite, imagemSitio[0]?.checked ? imagemSitio[0].checked  : false
                           ]);
                            setImagePlataform((imagePlataform) => [
                              ...imagePlataform, imagemPlataforma[0]?.checked ? imagemPlataforma[0].checked : false
                           ]);
                            if (imageDescription[0]) {
                              setImageDesc((imageDesc) => [
                                ...imageDesc,
                                imageDescription[0].value,
                              ]);
                            }
                          }
                        }}
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {imovel?.pictures?.length && (
            <div className="col-md-3 mt-3">
                        
            <button
              type="button"
              style={{ borderRadius: "6px" }}
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#ModalPicturesEdit"
            >
              Editar Imagens
            </button>

            <div
              className="modal fade"
              id="ModalPicturesEdit"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content text-center">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabeEdit">
                      Editar Imagens
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id='closebuttonedit'
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body pt-0" id="modal-body">
                    <div className="row d-flex justify-content-end"></div>
                    <div className="PreviewImages" ref={inputImagens}>
                      {imovel?.pictures?.map((file, index) => {
                        return (
                          <div className="PreviewImages text-left" key={index}>
                            <div className="row">
                              <div className="col-md-6 mt-3" style={{overflow:'hidden'}}  >
                                <button 
                                className="btn btn-danger pt-1 pb-1 pl-2 pr-2" 
                                style={{position:'absolute', left:'2px',borderRadius:'0.5rem'}}
                                onClick={() => 
                                  {
                                    Alert.fire({
                                      title: "Atenção",
                                      text: "Deseja apagar essa imagem?",
                                      confirmButtonText: "Excluir",
                                      confirmButtonColor: "red",
                                      showCancelButton: true,
                                      cancelButtonText: "Cancelar",
                                      reverseButtons: true,
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        let array = imovel.pictures
                                        let arraySplice = array.splice(index,1)
                                        if(array.length === 0) document.getElementById("closebuttonedit").click()
                                        setImovel({
                                          ...imovel,  
                                          pictures: array,
                                        })
                                      }
                                    });
                                    
                                  }
                                }
                                >
                                  X
                                </button>
                                <img src={file.image} height="100px" className="mt-3" />
                              </div>{" "}
                            
                                <div className="col-md-6 mt-3 vertical-align">
                                  <div className="custom-control custom-radio mb-2">
                                    <input
                                      id={"plataform" + index}
                                      name={"plataform-capa" + index}
                                      type="checkbox"
                                      checked={file.capaPlataform}
                                      className="custom-control-input"
                                      required
                                      onChange={
                                        (e) => {
                                          let array = imovel.pictures
                                          array[index] = {
                                            capaPlataform: e.target.checked,
                                            capaSite: file.capaSite,
                                            imagePlataform: file.imagePlataform,
                                            imageSite: file.imageSite,
                                            description: file.description,
                                            image: file.image
                                          }

                                          setImovel({
                                            ...imovel,
                                            pictures: array,
                                          })
                                        }
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={"plataform" + index}
                                    >
                                      Capa Plataforma
                                    </label>
                                  </div>
                                  <div className="custom-control custom-radio mb-2">
                                    <input
                                      id={"secondary" + index}
                                      name={"site-capa" + index}
                                      type="checkbox"
                                      checked={file.capaSite}
                                      className="custom-control-input"
                                      required
                                      onChange={
                                        (e) => {
                                          let array = imovel.pictures
                                          array[index] = {
                                            capaPlataform: file.capaPlataform,
                                            capaSite: e.target.checked,
                                            imagePlataform: file.imagePlataform,
                                            imageSite: file.imageSite,
                                            description: file.description,
                                            image: file.image
                                          }

                                          setImovel({
                                            ...imovel,
                                            pictures: array,
                                          })
                                        }
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={"secondary" + index}
                                    >
                                      Capa Site
                                    </label>
                                  </div>
                                  <div className="custom-control custom-radio mb-2">
                                    <input
                                      id={"site" + index}
                                      name={"site" + index}
                                      type="checkbox"
                                      className="custom-control-input"
                                      checked={file.imageSite}
                                      onChange={
                                        (e) => {
                                          let array = imovel.pictures
                                          array[index] = {
                                            capaPlataform: file.capaPlataform,
                                            capaSite: file.capaSite,
                                            imagePlataform: file.imagePlataform,
                                            imageSite: e.target.checked,
                                            description: file.description,
                                            image: file.image
                                          }

                                          setImovel({
                                            ...imovel,
                                            pictures: array,
                                          })
                                        }
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={"site" + index}
                                    >
                                      Imagem Site
                                    </label>
                                  </div>
                                  <div className="custom-control custom-radio mb-2">
                                    <input
                                      id={"plataforma" + index}
                                      name={"plataforma" + index}
                                      type="checkbox"
                                      className="custom-control-input"
                                      checked={file.imagePlataform}
                                      onChange={
                                        (e) => {
                                          let array = imovel.pictures
                                          array[index] = {
                                            capaPlataform: file.capaPlataform,
                                            capaSite: file.capaSite,
                                            imagePlataform: e.target.checked,
                                            imageSite: file.imageSite,
                                            description: file.description,
                                            image: file.image
                                          }

                                          setImovel({
                                            ...imovel,
                                            pictures: array,
                                          })
                                        }
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={"plataforma" + index}
                                    >
                                      Imagem Plataforma
                                    </label>
                                  </div>
                                </div>
                            </div>
                            <input
                              type="text"
                              className="mt-3 mb-3 form-control"
                              name={`inputDescription_${index}`}
                              value={file?.description}
                              onChange={
                                (e) => {
                                  let array = imovel.pictures
                                  array[index] = {
                                    capaPlataform: file.capaPlataform,
                                    capaSite: file.capaSite,
                                    imagePlataform: file.imagePlataform,
                                    imageSite: file.imageSite,
                                    description: e.target.value,
                                    image: file.image
                                  }

                                  setImovel({
                                    ...imovel,
                                    pictures: array,
                                  })
                                }
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            )}
           

            <div className="col-md-6 mt-3">
              <label className="ml-3">Canal de Anúncios</label>
              <Multiselect
                showArrow={true}
                showCheckbox={true}
                placeholder='Selecione'
                options={optionsAnnounce} // Options to display in the dropdown
                selectedValues={announceChannel}
                onRemove={(e) => setAnnounceChannel(e)} // Function will trigger on select event
                onSelect={(e) => setAnnounceChannel(e)} // Function will trigger on select event
                displayValue="name" // Property name to display in the dropdown options
              />
            </div>
            <div className="col-md-6 mt-3">
              <label className="ml-3">Destaque</label>
              <select
                className="form-control input-rounded"
                value={imovel?.highLight}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    highLight: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value={'normal'}>
                 Normal
                </option>
                <option value={'highlight'}>
                 Destaque
                </option>
                <option value={'superHighlight'}>
                  Super Destaque
                </option>
              </select>
            </div>
          </div>

          {/* // 56. Descrição do anuncio com o texto padrão editável manualmente. 57. Ocorrências / Comentários */}
          <div className="row fs-14">
            <div className="col-md-6 mt-3 ">
              <label className="">Descrição do anúncio</label>
              <strong style={{marginLeft: '1rem', cursor:'pointer'}} onClick={onSetDescriptionValue} >
                  Carregar modelo
              </strong>
              <textarea
                rows={5}
                className="form-control"
                name="descriptionAdvertisement"
                value={descriptionValue ? descriptionValue : imovel?.adDescription}
                onChange={(e) =>
                  setDescriptionValue(e.target.value)
                }
              />
            </div>

            <div className="col-md-6 mt-3">
              <label className="">Ocorrências/Comentários</label>
              <textarea
                className="form-control"
                rows={5}
                name="occurrencesComments"
                value={imovel?.occurrencesComments}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    occurrencesComments: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/* // CADASTRO AVANÇADO (para básico e avançado) */}
          <div className="col-md-12 mt-5 mb-2">
            <h4 className="fs-18 text-primary">Cadastro avançado</h4>
          </div>

          {/* // 1. Uso 2. Idade do Imóvel 3. Nível de acabamento */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Uso</label>
              <select
                className="form-control input-rounded"
                value={imovel?.use}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    use: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value={enumUsage.luxuryApartment}>
                  {enumUsageDescriptions(enumUsage.luxuryApartment)}
                </option>
                <option value={enumUsage.standartApartment}>
                  {enumUsageDescriptions(enumUsage.standartApartment)}
                </option>
                <option value={enumUsage.house}>
                  {enumUsageDescriptions(enumUsage.house)}
                </option>
                <option value={enumUsage.condominiumHouse}>
                  {enumUsageDescriptions(enumUsage.condominiumHouse)}
                </option>
                <option value={enumUsage.farm}>{enumUsageDescriptions(enumUsage.farm)}</option>
                <option value={enumUsage.modularCondominium}>
                  {enumUsageDescriptions(enumUsage.modularCondominium)}
                </option>
                <option value={enumUsage.development}>
                  {enumUsageDescriptions(enumUsage.development)}
                </option>
                <option value={enumUsage.comercial}>
                  {enumUsageDescriptions(enumUsage.comercial)}
                </option>
                <option value={enumUsage.schools}>
                  {enumUsageDescriptions(enumUsage.schools)}
                </option>
                <option value={enumUsage.largeRetailers}>
                  {enumUsageDescriptions(enumUsage.largeRetailers)}
                </option>
                <option value={enumUsage.hospitals}>
                  {enumUsageDescriptions(enumUsage.hospitals)}
                </option>
                <option value={enumUsage.hotels}>
                  {enumUsageDescriptions(enumUsage.hotels)}
                </option>
                <option value={enumUsage.residentialDevelopment}>
                  {enumUsageDescriptions(enumUsage.residentialDevelopment)}
                </option>
                <option value={enumUsage.comercialDevelopment}>
                  {enumUsageDescriptions(enumUsage.comercialDevelopment)}
                </option>
                <option value={enumUsage.industrial}>
                  {enumUsageDescriptions(enumUsage.industrial)}
                </option>
                <option value={enumUsage.commercialSlab}>
                  {enumUsageDescriptions(enumUsage.commercialSlab)}
                </option>
                <option value={enumUsage.logistics}>
                  {enumUsageDescriptions(enumUsage.logistics)}
                </option>
                <option value={enumUsage.singleUserBuilding}>
                  {enumUsageDescriptions(enumUsage.singleUserBuilding)}
                </option>
                <option value={enumUsage.rural}>
                  {enumUsageDescriptions(enumUsage.rural)}
                </option>
                <option value={enumUsage.shopping}>
                  {enumUsageDescriptions(enumUsage.shopping)}
                </option>
                <option value={enumUsage.logisticsIndustrial}>
                  {enumUsageDescriptions(enumUsage.logisticsIndustrial)}
                </option>
              </select>
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Idade do Imóvel</label>
              <input
                className="form-control input-rounded"
                name="propertyAge"
                value={imovel?.propertyAge}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    propertyAge: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Nível do Acabamento</label>
              <select
                className="form-control input-rounded"
                value={imovel?.finishingLevel}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    finishingLevel: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value={enumFinishingLevel.great}>
                  {enumFinishingLevelDescriptions(enumFinishingLevel.great)}
                </option>
                <option value={enumFinishingLevel.good}>
                  {enumFinishingLevelDescriptions(enumFinishingLevel.good)}
                </option>
                <option value={enumFinishingLevel.regular}>
                  {enumFinishingLevelDescriptions(enumFinishingLevel.regular)}
                </option>
                <option value={enumFinishingLevel.bad}>
                  {enumFinishingLevelDescriptions(enumFinishingLevel.bad)}
                </option>
              </select>
            </div>
          </div>

          {/* //  5. Região Técnica 6. Comissão Acordada */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Região Técnica</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="technicalRegion"
                value={imovel?.technicalRegion}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    technicalRegion: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Comissão Acordada</label>
              <input
                className="form-control input-rounded"
                name="agreedCommission"
                value={imovel?.agreedCommission}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    agreedCommission: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/* // 7. Comercializado por ? 8. Valor Transacionado 9. Ocupante atual */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Comercializado por ?</label>
              <select
                className="form-control input-rounded"
                value={imovel?.marketedBy}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    marketedBy: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value={enumMarketedBy.competitors}>
                  {enumMarketedByDescriptions(enumMarketedBy.competitors)}
                </option>
                <option value={enumMarketedBy.leonel}>
                  {enumMarketedByDescriptions(enumMarketedBy.leonel)}
                </option>
               
              </select>
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Valor Transacionado</label>
              <CurrencyInput
                className="form-control input-rounded"
                groupSeparator="."
                decimalSeparator=","
                value={transectedValue}
                onValueChange={(value) =>
                  [setImovel({
                    ...imovel,
                    amountTransactedNumber: Number(value?.replace(/,/g, ".")),
                  }),setTransectedValue(value)]
                }
              />
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Ocupante atual</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="currentOccupant"
                value={imovel?.currentOccupant}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    currentOccupant: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/* // 10. Contato do ocupante 11. Setor Econômico 12. Ramo de atividade do ocupante */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Contato Ocupante</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="occupantContact"
                value={imovel?.occupantContact}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    occupantContact: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-4 mt-3 ">
              <label className="ml-3">Setor Econômico</label>
              <select
                className="form-control input-rounded"
                value={imovel?.economicSector}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    economicSector: e.target.value
                  })
                }
              >
                <option value="">Selecione</option>
                <option value={enumEconomicSector.industry}>
                  {enumEconomicSectorDescriptions(enumEconomicSector.industry)}
                </option>
                <option value={enumEconomicSector.services}>
                  {enumEconomicSectorDescriptions(enumEconomicSector.services)}
                </option>
                <option value={enumEconomicSector.commerce}>
                  {enumEconomicSectorDescriptions(enumEconomicSector.commerce)}
                </option>
                <option value={enumEconomicSector.indcomllogistics}>
                  {enumEconomicSectorDescriptions(enumEconomicSector.indcomllogistics)}
                </option>
                <option value={enumEconomicSector.logisticandcommercial}>
                  {enumEconomicSectorDescriptions(enumEconomicSector.logisticandcommercial)}
                </option>
              </select>
            </div>

            <div className="col-md-4 mt-3">
              <label className="ml-3">Ramo de atividade do Ocupante</label>
              <select
                className="form-control input-rounded"
                value={imovel?.enumLineOfBusiness}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    enumLineOfBusiness: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option
                  value={enumLineOfBusiness.agricultureExtractionCultivationAndProcessing}
                >
                  {enumLineOfBusinessDescriptions(
                    enumLineOfBusiness.agricultureExtractionCultivationAndProcessing
                  )}
                </option>
                <option value={enumLineOfBusiness.foodAndDrinks}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.foodAndDrinks)}
                </option>
                <option value={enumLineOfBusiness.automobile}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.automobile)}
                </option>
                <option value={enumLineOfBusiness.consultingAccountingLaw}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.consultingAccountingLaw)}
                </option>
                <option value={enumLineOfBusiness.education}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.education)}
                </option>
                <option value={enumLineOfBusiness.entertainmentAndTourism}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.entertainmentAndTourism)}
                </option>
                <option value={enumLineOfBusiness.realestateConstructionEngineeringRealstate}>
                  {enumLineOfBusinessDescriptions(
                    enumLineOfBusiness.realestateConstructionEngineeringRealstate
                  )}
                </option>
                <option value={enumLineOfBusiness.quickWashAndCleaning}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.quickWashAndCleaning)}
                </option>
                <option value={enumLineOfBusiness.machinesAndEquipment}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.machinesAndEquipment)}
                </option>
                <option value={enumLineOfBusiness.constructionMaterials}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.constructionMaterials)}
                </option>
                <option value={enumLineOfBusiness.marketsAndHypermarkets}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.marketsAndHypermarkets)}
                </option>
                <option value={enumLineOfBusiness.automobilePartsAndEquipment}>
                  {enumLineOfBusinessDescriptions(
                    enumLineOfBusiness.automobilePartsAndEquipment
                  )}
                </option>{" "}
                <option value={enumLineOfBusiness.plasticsAndPackaging}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.plasticsAndPackaging)}
                </option>
                <option value={enumLineOfBusiness.chemistryAndPharmaceuticals}>
                  {enumLineOfBusinessDescriptions(
                    enumLineOfBusiness.chemistryAndPharmaceuticals
                  )}
                </option>
                <option value={enumLineOfBusiness.animalHealth}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.animalHealth)}
                </option>
                <option value={enumLineOfBusiness.healthAndBeauty}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.healthAndBeauty)}
                </option>
                <option value={enumLineOfBusiness.steelAndMetallurgy}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.steelAndMetallurgy)}
                </option>
                <option value={enumLineOfBusiness.technology}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.technology)}
                </option>
                <option value={enumLineOfBusiness.hardwareAndSoftwareTechnology}>
                  {enumLineOfBusinessDescriptions(
                    enumLineOfBusiness.hardwareAndSoftwareTechnology
                  )}
                </option>
                <option value={enumLineOfBusiness.textileAndFootwear}>
                  {enumLineOfBusinessDescriptions(enumLineOfBusiness.textileAndFootwear)}
                </option>
                <option value={enumLineOfBusiness.transportationAndLogistics}>
                  {enumLineOfBusinessDescriptions(
                    enumLineOfBusiness.transportationAndLogistics
                  )}
                </option>
              </select>
            </div>
          </div>

          {/* // 13. Inicio do contrato 14. Índice 15. Cadastro Prefeitura */}
          <div className="row fs-14">
            <div className="col-md-4 mt-3">
              <label className="ml-3">Início do Contrato</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="contractPeriod"
                value={imovel?.contractPeriod}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    contractPeriod: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-6 mt-3">
              <label className="ml-3">Índice</label>
              <input
                className="form-control input-rounded"
                name="index"
                value={imovel?.index}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    index: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-6 mt-3">
              <label className="ml-3">Cadastro Prefeitura / Matrícula</label>
              <input
                className="form-control input-rounded"
                type="text"
                name="cityHallRegister"
                value={imovel?.cityHallRegister}
                onChange={(e) =>
                  setImovel({
                    ...imovel,
                    cityHallRegister: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-3 mt-3 mb-5">
              <label className="ml-3">Status da aprovação</label>
              <select
                className="form-control input-rounded"
                value={imovel?.approvedStatus}
                onChange={(e) => setImovel({ ...imovel, approvedStatus: e.target.value })}
              >
                <option value="">Selecione</option>
                <option value="approved">Aprovado</option>
                <option value="waiting">Aguardando aprovação</option>
                <option value="disapproved">Reprovado</option>
                <option value="incompleted">Incompleto</option>
                <option value="findOwner">Encontrar proprietário</option>
                <option value="other">Outro</option>
              </select>
            </div>
          </div>

          <div className="row ml-3 mt-5">
            <div className="col-md-4" />
            <div className="col-md-4">
              <button
                type="button"
                className="btn borderRadius btnCancel text-primary w-100"
                onClick={cancelImovel}
                style={{
                  color: "#fff",
                  backgroundColor: "#144b72",
                  borderRadius: "6px",
                }}
              >
                Cancelar
              </button>
            </div>
            <div className="col-md-4">
              <button
                type="submit"
                className="btn borderRadius  w-100"
                onClick={() => saveImovel()}
                disabled = {disabled}
                style={{
                  color: "#fff",
                  backgroundColor: "#144b72",
                  borderRadius: "6px",
                }}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralRegister;
