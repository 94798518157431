import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { UsefulProvider } from './ultis/usefulProvider';
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <UsefulProvider>
          <App />
      </UsefulProvider>
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();