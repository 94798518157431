import React, { useEffect, useState } from "react";
import { getRealStatesById } from "./../../services/apiRealState";
import { Link, useParams } from "react-router-dom";
import UsefulContext from "./../../../ultis/usefulProvider";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../Loading/LoadingComponent";
import AvailabilityCard from "./components/availabilityCard";
import PropertyDataCard from "./components/propertyDataCard";
import OwnerDataCard from "./components/ownerDataCard";
import ImageDataCard from "./components/imageDataCard";
import OtherInformationData from "./components/otherInformationData";
import AdDetailsCard from "./components/adDetailsCard";
import AdvancedRegistrationCard from "./components/advancedRegistrationCard";

const PropertyDetail = ({ match }) => {
  const { userProfiles } = React.useContext(UsefulContext);
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  // Constante que irá receber os valores vindos do banco de dados
  const [dados, setDados] = useState([]);

  //pegar imagem de capa
  const getCapaPicture = (pictures) => {
    let picture = pictures[0].image
    for(let i = 0; i < pictures.length; i++){
      if(pictures[i].capaPlataform === true){
        picture = pictures[i].image
      }
    }
    return picture
  }

  // Requisição GET para pegar dados de imóveis cadastrados no banco de dados
  useEffect(() => {
    setLoading(true);
    try {
      getRealStatesById(id)
        .then((result) => {
          var dataImovel = result.data;

          if (
            dataImovel.plataformMainPicture == undefined ||
            dataImovel.plataformMainPicture == null
          ) {
            if (dataImovel?.pictures?.length != 0) {
              // teste.push({ "plataformMainPicture": dataImovel.pictures[0], dataImovel });

              setDados({ ...dataImovel, plataformMainPicture: getCapaPicture(dataImovel.pictures) });
              if (dataImovel === <enumPropertyStatus />) {
                setLoading(false);
                return dataImovel;
              }
            } else {
              setDados(dataImovel);
              if (dataImovel === <enumPropertyStatus />) {
                setLoading(false);
                return dataImovel;
              }
            }
          } else {
            setDados(dataImovel);
            if (dataImovel === <enumPropertyStatus />) {
              setLoading(false);
              return dataImovel;
            }
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  return (
    <div className="mb-5 mt-4">
      <div className="page-titles mb-5">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/property-guide">Imoveis</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to="#">Detalhes do imóvel</Link>
          </li>
        </ol>
      </div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="col-12">
          <ImageDataCard dados={dados} />
          <PropertyDataCard dados={dados} />
          <AvailabilityCard dados={dados} />
          <OwnerDataCard dados={dados} />
          <OtherInformationData dados={dados} />
          <AdDetailsCard dados={dados} />
          <AdvancedRegistrationCard dados={dados} />
        </div>
      )}
    </div>
  );
};

export default PropertyDetail;
