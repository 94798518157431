import React, { useEffect, useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  KmlLayer,
  InfoWindow
} from "react-google-maps";
import { getRealStates } from "../../services/apiRealState";
import { getRegions } from "../../services/apiRegions";
import { createKml } from './../../../ultis/createKML';
import { Link } from "react-router-dom";
import Select from "react-select";
import Alert from "sweetalert2";
import {
  enumTypeAgreementDescriptions,
} from "./../../../enums/ETYPEAGREEMENT";
import {
  enumPropertyType,
  enumPropertyTypeDescriptions,
} from "./../../../enums/EPROPERTYTYPE";
import { enumAvailability,enumAvailabilityDescriptions, enumAvailabilityDescriptionsInverter} from "../../../enums/EAVALIABILITY";

const _googleUrl = "https://maps.googleapis.com/maps/api/js?key=AIzaSyA-uIcPdvgx5alYfpWXuQzRE1yJG88jWBY&v=3.exp&libraries=geometry,drawing,places";

const MapImoveis = ({ history }) => {

  const [pinArray, setPinArray] = useState(null);
  const [display, setDisplay] = useState(false);
  const [regions, setRegions] = useState([]);
  const [regionsToDisplay, setRegionsToDisplay] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [kmlUrl, setkmlUrl] = useState("");
  const [infoWindowID, setInfoWindowID] = useState("");
  const [statusFilter, setStatusFilter] = useState('approved');
  const [tipologyFilter, setTipologyFilter] = useState('hangar');
  const [availableFilter, setAvailableFilter] = useState('');
  const [addressFilter, setAddressFilter] = useState('');

  useEffect(() => {

    window.scrollTo(0, 0);
    loadRegions();
  }, []);

  useEffect(() => {
    if (pinArray) {
      setDisplay(true);
    }
    else {
      setDisplay(false)
    }

  }, [pinArray]);


  const loadRegions = async () => {
    try {
      const _result = await getRegions();
      if (_result?.response) {
        await Alert.fire(_result?.response?.data);
        return;
      } else if (!_result?.data?.length) {
        return;
      }
      setRegions(_result?.data);
      var _tempRegions = [{ label: "Selecionar todos", value: "all" }];
      for (let x = 0; x < _result?.data.length; x++) {
        const _reg = _result?.data[x];
        _tempRegions.push({ value: _reg?._id, label: _reg?.region });
      }
      setRegionsToDisplay(_tempRegions);
    } catch (error) {
      await Alert.fire('Erro ao buscar regioes');
    }
  }

  const filterData = (value) => {
    let retorno = false;
      if(!tipologyFilter && !availableFilter && !statusFilter &&!addressFilter){
        retorno = true;
      } else {
        let checkAll = 0
        if(value?.propertyType?.toUpperCase() === tipologyFilter?.toUpperCase() || value?.propertyType?.toUpperCase() === enumPropertyTypeDescriptions(tipologyFilter).toUpperCase()|| !tipologyFilter){
          checkAll ++;  
        }

        if(value?.availability?.toUpperCase() === availableFilter?.toUpperCase() || value?.availability?.toUpperCase() === enumAvailabilityDescriptions(availableFilter).toUpperCase() || !availableFilter){
          checkAll ++;
        }

        if(value?.approvedStatus?.toUpperCase() === statusFilter?.toUpperCase() || !statusFilter){
          checkAll ++;
        }

        if(value?.address?.toUpperCase()?.includes(addressFilter?.toUpperCase()) ||
        value?.city?.toUpperCase()?.includes(addressFilter?.toUpperCase()) || 
        value?.district?.toUpperCase()?.includes(addressFilter?.toUpperCase()) || 
         !addressFilter){
          checkAll ++;
        }
        if(checkAll === 4){
          retorno = true;
        } else {
          retorno = false;
        }
      }
   
    return retorno;
  }

  const getCapaPicture = (pictures) => {
    let picture = pictures[0]?.image 
    for(let i = 0; i < pictures.length; i++){
      if(pictures[i].capaPlataform === true){
        picture = pictures[i].image
      }
    }
    return picture
  }

  const loadPins = async () => {
    try {
      const _result = await getRealStates();
      if (_result?.response) {
        await Alert.fire(_result?.response?.data);
        return;
      } else if (!_result?.data?.length) {
        return;
      }
      var _array = [];
      for (let x = 0; x < _result?.data?.length; x++) {
        const _realState = JSON.parse(JSON.stringify(_result?.data[x]));
        if (_realState?.latitudeAndLongitude) {
          try {
            const _lat = _realState?.latitudeAndLongitude?.split(",")[0];
            const _lng = _realState?.latitudeAndLongitude?.split(",")[1];
            const _id = _realState?._id;
            const _availability = _realState?.availability;
            const _code = _realState?.code; // código
            const _image = _realState?.pictures[0]?.image ? getCapaPicture(_realState?.pictures) : "/semImagem.png"; // uma imagem miniatura
            const _address = _realState?.address; // um endereço
            const _number = _realState?.number;
            const _district = _realState?.district;
            const _commercialRegion = _realState?.commercialRegion;
            const _city = _realState?.city;
            const _state = _realState?.state;
            const _zipCode = _realState?.zipCode;
            const _buildingArea = _realState?.buildingAreaNumber?.toLocaleString('pt-br'); // area de galpao
            const _available = enumAvailabilityDescriptions(_realState?.availability); //availability.
            const _transactionType = enumTypeAgreementDescriptions(_realState?.transactionType); // transactionType.
            const _ocupant = _realState?.currentOccupant; // currentOccupant.
            const _landArea = _realState?.landAreaNumber?.toLocaleString('pt-br'); // area de terreno.
            const _propertyType = _realState?.propertyType; // area de terreno.
            const _approvedStatus = _realState?.approvedStatus; // area de terreno.

            if (
              _lat !== null &&
              _lat !== undefined &&
              _lng !== null &&
              _lng !== undefined &&
              _id !== null &&
              _id !== undefined
            ) {
              _array.push({
                lat: _lat,
                lng: _lng,
                id: _id,
                availability: _availability,
                code: _code,
                image: _image,
                address: _address,
                number: _number,
                district: _district,
                commercialRegion: _commercialRegion,
                city: _city,
                state: _state,
                zipCode: _zipCode,
                buildingArea: _buildingArea,
                landArea: _landArea,
                ocupant:_ocupant,
                transactionType: _transactionType,
                available:_available,
                propertyType: _propertyType,
                approvedStatus: _approvedStatus,
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
      setPinArray(_array.filter(filterData));
    } catch (error) {
      await Alert.fire('Erro ao buscar pinos');
    }
  };

  const loadKMLS = async () => {
    try {
      if (selectedRegions.length) {
        var _regions = [];
        for (let x = 0; x < selectedRegions.length; x++) {
          const _cvValue = regions.filter((arg) => arg._id === selectedRegions[x].value);
          for (let y = 0; y < _cvValue.length; y++) {
            const element = _cvValue[y];
            _regions.push({ region: element?.region, coordinates: element?.coordinates });
          }
        }
        const _url = await createKml(_regions);
        setkmlUrl(_url);
      } else {
        setkmlUrl("");
      }
    } catch (error) {
      await Alert.fire(JSON.stringify(error));
    }
  }

  const handleChange = (selected) => {
    if (selected.find(option => option.value === "all")) {
      handleChange(regionsToDisplay.slice(1));
    } else {
      setSelectedRegions(selected);
    }
  }

  return (
    <>
      <div className="container-fluid-register">
        <div className="form-head page-titles d-flex align-items-center bg-brad">
          <div className="mr-auto d-lg-block">
            <h4 className="text-black font-w600">Mapa de imóveis</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to="#">Imóveis</Link>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-body">
              <div className="mb-3">
                <div className="col-lg-12">
                  <h6>Selecione o KML/KMZ</h6>
                  <div className="row">
                    <div className="col-lg-10">
                      {regionsToDisplay.length ?
                        <Select
                          name="regions"
                          isMulti
                          value={selectedRegions}
                          options={regionsToDisplay}
                          onChange={handleChange}
                        />
                        : null}
                    </div>
                    <button onClick={loadKMLS} className="col-lg-2 btn btn-primary light rounded">Carregar</button>
                  </div>
                </div>

              <div className="row mt-3">
                <div className="col-lg-2 col-sm-12 mb-2">
                  <label>Disponibilidade</label>
                  <select
                  className="form-control "
                  value={availableFilter}
                  style={{ borderRadius: "10px" }}
                      onChange={e => setAvailableFilter(e.target.value)}
                      
                >
                  <option value="">Selecione</option>
                  <option value={enumAvailability.available}>
                    {enumAvailabilityDescriptions(enumAvailability.available)}
                  </option>
                  <option value={enumAvailability.unavailable}>
                    {enumAvailabilityDescriptions(enumAvailability.unavailable)}
                  </option>
                  
              
                </select>
                </div>

                <div className="col-lg-2 col-sm-12  mb-2">
                  <label>Tipologia</label>
                
                  <select
                className="form-control"
                style={{ borderRadius: "10px" }}
                value={tipologyFilter}
                onChange={e => setTipologyFilter(e.target.value)}
              
                >
                  <option value="">Selecione</option>
                  <option value={enumPropertyType.area}>
                    {enumPropertyTypeDescriptions(enumPropertyType.area)}
                  </option>
                  <option value={enumPropertyType.hangar}>
                    {enumPropertyTypeDescriptions(enumPropertyType.hangar)}
                  </option>
                  <option value={enumPropertyType.commercial}>
                    {enumPropertyTypeDescriptions(enumPropertyType.commercial)}
                  </option>
                  <option value={enumPropertyType.store}>
                    {enumPropertyTypeDescriptions(enumPropertyType.store)}
                  </option>
                  <option value={enumPropertyType.businessAdaptableResidence}>
                    {enumPropertyTypeDescriptions(enumPropertyType.businessAdaptableResidence)}
                  </option>
                  <option value={enumPropertyType.residential}>
                    {enumPropertyTypeDescriptions(enumPropertyType.residential)}
                  </option>
                  <option value={enumPropertyType.rurais}>
                    {enumPropertyTypeDescriptions(enumPropertyType.rurais)}
                  </option>
                  <option value={enumPropertyType.commercialRoom}>
                    {enumPropertyTypeDescriptions(enumPropertyType.commercialRoom)}
                  </option>
                  <option value={enumPropertyType.walledLand}>
                    {enumPropertyTypeDescriptions(enumPropertyType.walledLand)}
                  </option>
                  <option value={enumPropertyType.ground}>
                    {enumPropertyTypeDescriptions(enumPropertyType.ground)}
                  </option>
                  </select>
                </div>

                <div className="col-lg-2  mb-5">
                  <label className="ml-3">Status da aprovação</label>
                  <select
                    className="form-control "
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    <option value="approved">Aprovado</option>
                    <option value="waiting">Aguardando aprovação</option>
                    <option value="disapproved">Reprovado</option>
                    <option value="incompleted">Incompleto</option>
                    <option value="findOwner">Encontrar proprietário</option>
                    <option value="other">Outro</option>
                  </select>
                </div>

                <div className="col-lg-2  mb-5">
                      <label className="ml-3">Endereço</label>
                      <input
                        className="form-control"
                        type="text"
                        name="contractPeriod"
                        value={addressFilter}
                        onChange={(e) =>
                          setAddressFilter(e.target.value)
                        }
                     />
                </div>
                <button style={{height:'55px'}} onClick={() => {setPinArray(); loadPins()}} className="mt-3 ml-5 col-lg-2 btn btn-primary rounded">Buscar</button>
              </div>
               
              </div>
              {display && (
                <MapWithAMarker
                  googleMapURL={_googleUrl}
                  loadingElement={<div style={{ height: '640px' }} />}
                  containerElement={<div style={{ height: '640px' }} />}
                  mapElement={<div style={{ height: '640px' }} />}
                  markers={pinArray?.map((place, index) => (
                    <Marker
                      key={index}
                      position={{ lat: Number(place?.lat), lng: Number(place?.lng) }}
                      icon={{
                        url: `https://maps.google.com/mapfiles/ms/icons/${(!place?.availability || place?.availability === 'unavailable') ? "red" : "green"}-dot.png`,
                      }}
                      onClick={() => { setInfoWindowID(index) }}  >
                      {infoWindowID === index && (
                        <InfoWindow>
                          <div>
                            <h1><a href={`/property-details/${place.id}`} target='_blank'>Código {place?.code}</a></h1>
                            <img src={place?.image} height={160} width={320} />
                            <p></p>
                            <h4>Endereço</h4>
                            <p>{place?.address}, {place?.number} </p>
                            <p>{place?.district} - {place?.zipCode} </p>
                            <p>{place?.city} - {place?.state} </p>
                            <div style={{ display: 'flex', flexdirection: 'row' }}>
                              <div style={{ flex: 1 }}>
                                <h4>Área total</h4>
                                <p>{place?.buildingArea} m²</p>
                              </div>
                              <div style={{ flex: 1 }}>
                                <h4>Área do terreno</h4>
                                <p>{place?.landArea} m²</p>
                              </div>
                            </div>
                           <h4>Tipo transação</h4>
                           <p>{place?.transactionType}</p>
                            <div style={{ display: 'flex', flexdirection: 'row' }}>
                              <div style={{ flex: 1 }}>
                                <h4>Disponibilidade</h4>
                                <p>{place?.available}</p>
                              </div>
                              <div style={{ flex: 1 }}>
                                <h4>Ocupante</h4>
                                <p>{place?.ocupant}</p>
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  ))}
                  kmlLayer={<KmlLayer url={kmlUrl} options={{ preserveViewport: true }} />}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const MapWithAMarker = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: -21.168689731059985, lng: -47.81409986083559 }}>
      {props.markers}
      {props.kmlLayer}
    </GoogleMap>
  ))
);

export default MapImoveis;