export const enumStatus = {
  construction: "construction",
  project: "project",
  ready: "ready",
  development: "development",
};

export const enumStatusAreaDescriptions = (key) => {
  switch (key) {
    case "construction":
      return "Construção";
    case "project":
      return "Projeto";
    case "ready":
      return "Pronto";
    case "development":
      return "Em desenvolvimento";
    default:
      return key;
  }
};

export const enumStatusDescriptionsInverter = (key) => {
  switch (key) {
    case "Construção":
      return "construction";
    case "Projeto":
      return "project";
    case "Pronto":
      return "ready";
    case "Em desenvolvimento":
      return "development";
    default:
      return key;
  }
};

Object.freeze(enumStatus);


