import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import profile from "../../../images/researcher_icon.png";
import PageTitle from "../../layouts/PageTitle";

const UserProfile = () => {

  const { state } = useLocation();
  const [activeToggle, setActiveToggle] = useState("posts");
  const [user, setUser] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (state) {
      setUser(state.data);
    }
  }, [state])

  return (
    <Fragment>
      <PageTitle activeMenu="Perfil" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content">
                <div className="cover-photo"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={profile}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">
                      {user.name}
                    </h4>
                    <p>{user.type}</p>
                  </div>
                  <div className="profile-email px-2 pt-2 fs-14">
                    <h5 className="text-muted mb-0 ">
                      Email
                    </h5>
                    <p>{user.email}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Menu Conta*/}
      <div className="col-xl-4">
        <div className="card">
          <div className="card-body">
            <div className="profile-tab">
              <div className="custom-tab-1">
                <ul className="nav nav-tabs">
                  <li
                    className="nav-item"
                    onClick={() => setActiveToggle("posts")}>
                    <Link
                      to="#"
                      data-toggle="tab"
                      className={`nav-link ${activeToggle === "posts"
                        ? "active show"
                        : ""
                        }`}>
                      Permissões
                    </Link>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    id="my-posts"
                    className={`tab-pane fade ${activeToggle === "posts"
                      ? "active show"
                      : ""
                      }`}
                  >
                    <div className="my-post-content pt-3">
                      <div className="row mb-5">
                        <div className="col-md-12 text-primary">
                          <i className="flaticon-381-controls-5"></i> <label className="font-weight-bold h4 ml-1">Perfil</label>
                        </div>
                        <div className="col-md-12">
                          <label className="text-muted pl-2 fs-15">
                            {user.type}
                          </label>
                        </div>
                      </div>

                      <div className="row mb-5">
                        <div className="col-md-12 text-primary">
                          <i className="flaticon-381-user-9"></i><label className="font-weight-bold h4 ml-1">Grupos</label>
                        </div>
                        <div className="col-md-12">
                          <label className="text-muted pl-2 fs-15">
                            {user.groups}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default UserProfile;