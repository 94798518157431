export const enumExclusivity = {
  yes: "yes",
  no: "no",
};

export const enumExclusivityDescriptions = (key) => {
  switch (key) {
    case "yes":
      return "Sim";
    case "no":
      return "Não";
    default:
      return key;
  }
};

export const enumExclusivityDescriptionsInverter = (key) => {
  switch (key) {
    case "Não":
      return "no";
    case "Sim":
      return "yes";

    default:
      return key;
  }
};

Object.freeze(enumExclusivity);

