import React, { Component } from "react";
import UsefulContext from "./../../../ultis/usefulProvider";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { userProfiles } = React.useContext(UsefulContext);

  React.useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa?.classList.toggle("menu-toggle");
    }
    btn?.addEventListener("click", toggleFunc);
    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast?.classList.toggle("heart-blast");
    }
    handleheartBlast?.addEventListener("click", heartBlast);
    return () => {
      handleheartBlast?.removeEventListener("click", heartBlast);
      btn?.removeEventListener("click", toggleFunc);
    };
  }, []);

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = ["/"],
  faq = ["/faq"],
    imoveis = [
      "general-register",
      "property-listing",
      "property-guide",
      "property-listing-to-approve",
      "input-imoveis",
    ],
    mapas = ["map-imoveis"],
    users = [
      "users-register",
      "users-listing",
      "groups-register",
      "groups-listing",
      "profiles-register",
      "profiles-listing",
      "permissions-register",
      "configurations",
    ],
    configurations = ["input-imoveis", "input-imoveis"],
    researcher = [
      "page-property-listing-search",
      "page-general-register-search",
      "page-map-imoveis-search",
      "page-profile-search",
    ];

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {userProfiles?.addProperties || // novo cadastro
            userProfiles?.listAllProperties || // listagem de imoveis cadastrados
            userProfiles?.propertyGuide ? ( // busca avançada
          <li className={`${imoveis.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-home-2"></i>
              <span className="nav-text">Imóveis</span>
            </Link>
            <ul aria-expanded="false">
              {userProfiles?.addProperties && (
              <li>
                <Link
                  className={`${path === "general-register" ? "mm-active" : ""}`}
                  to="/general-register"
                >
                  Novo cadastro
                </Link>
              </li>
              )}
              {userProfiles?.listAllProperties && (
              <li>
                <Link
                  className={`${path === "property-listing" ? "mm-active" : ""}`}
                  to="/property-listing"
                >
                  Imóveis cadastrados
                </Link>
              </li>
             )} 
              {userProfiles?.propertyGuide && (
              <li>
                <Link
                  className={`${path === "property-guide" ? "mm-active" : ""}`}
                  to="/property-guide"
                >
                  Busca avançada
                </Link>
              </li>
              )} 
              {userProfiles?.listAllProperties && ( 
              <li>
                <Link
                  className={`${path === "property-listing-to-approve" ? "mm-active" : ""}`}
                  to="/property-listing-to-approve"
                >
                  Imóveis em fase de pesquisa
                </Link>
              </li>
              )} 
            </ul>
          </li>
         ) : null} 

         {userProfiles?.realEstateMap ? ( 
          <li className={`${mapas.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="/map-imoveis" aria-expanded="false">
              <i className="flaticon-381-map-2"></i>
              <span className="nav-text">Mapa</span>
            </Link>
          </li>
          ) : null} 

          {userProfiles?.addUser ||
            userProfiles?.userListing ||
            userProfiles?.addGroup ||
            userProfiles?.groupListing ||
            userProfiles?.addProfileAccess ||
            userProfiles?.profilesListing ||
            userProfiles?.userSettings ? (
          <li className={`${users.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-user-7"></i>
              <span className="nav-text">Configurações de Usuários</span>
            </Link>
            <ul aria-expanded="false">
          {userProfiles?.addUser && ( 
              <li>
                <Link
                  className={`${path === "users-register" ? "mm-active" : ""}`}
                  to="/users-register"
                >
                  Cadastro de usuários
                </Link>
              </li>
             )} 
              {userProfiles?.userListing && ( 
              <li>
                <Link
                  className={`${path === "users-listing" ? "mm-active" : ""}`}
                  to="/users-listing"
                >
                  Lista de usuários
                </Link>
              </li>
              )} 
             {userProfiles?.addGroup && ( 
              <li>
                <Link
                  className={`${path === "groups-register" ? "mm-active" : ""}`}
                  to="/groups-register"
                >
                  Cadastro de grupos
                </Link>
              </li>
             )} 
              {userProfiles?.groupListing && (
              <li>
                <Link
                  className={`${path === "groups-listing" ? "mm-active" : ""}`}
                  to="/groups-listing"
                >
                  Lista de grupos
                </Link>
              </li>
             )}
              {userProfiles?.addProfileAccess && (
              <li>
                <Link
                  className={`${path === "profiles-register" ? "mm-active" : ""}`}
                  to="/profiles-register"
                >
                  Cadastrar perfil de usuário
                </Link>
              </li>
              )}
              {userProfiles?.profilesListing && ( 
              <li>
                <Link
                  className={`${path === "profiles-listing" ? "mm-active" : ""}`}
                  to="/profiles-listing"
                >
                  Lista de perfis de usuário
                </Link>
              </li>
              )}
              {userProfiles?.profilesListing && (
              <li>
                <Link
                  className={`${path === "permissions-register" ? "mm-active" : ""}`}
                  to="/permissions-register"
                >
                  Permissões de perfil de usuário
                </Link>
              </li>
               )}
               {userProfiles?.addProfileAccess && (
              <li>
                <Link
                  className={`${path === "configurations" ? "mm-active" : ""}`}
                  to="/configurations"
                >
                  Configurações de permissão de usuário
                </Link>
              </li>
               )}
            </ul>
          </li>
          ) : null} 

           {userProfiles?.userSettings && ( 
          <li className={`${configurations.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-settings-7"></i>
              <span className="nav-text">Configuração Geral</span>
            </Link>
            <ul aria-expanded="false">
               {userProfiles?.addRealEstateSpreadsheet && (
              <li>
                <Link
                  className={`${path === "input-imoveis" ? "mm-active" : ""}`}
                  to="/input-imoveis"
                >
                  Adicionar planilha de imóveis
                </Link>
              </li>
              )} 
               {userProfiles?.mapOfRegions && ( 
              <li>
                <Link className={`${path === "ImportKMZ" ? "mm-active" : ""}`} to="/importKMZ">
                  Inserção de KMZ
                </Link>
              </li>
               )} 
               {userProfiles?.mapOfRegions && ( 
              <li>
                <Link className={`${path === "DeletKMZ" ? "mm-active" : ""}`} to="/deleteKMZ">
                  Apagar Regiões do KMZ
                </Link>
              </li>
              )} 
            </ul>
          </li>
        )}

          {userProfiles?.commercialDashboard && (
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="/" aria-expanded="false">
              <i className="flaticon-381-networking"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          )}

          {userProfiles?.platformUse && (
          <li className={`${faq.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="/faq" aria-expanded="false">
              <i className="flaticon-381-search-3"></i>
              <span className="nav-text">Manual de uso</span>
            </Link>
          </li>
         )} 

          {/* {userProfiles?.researcher ? (
            <li className={`${researcher.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-user-7"></i>
                <span className="nav-text">Pesquisador</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link
                    className={`${path === "page-profile-search"
                      ? "mm-active"
                      : ""
                      }`}
                    to="/page-profile-search">
                    Inicio
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "page-general-register-search" ? "mm-active" : ""
                      }`}
                    to="/page-general-register-search">
                    Registro de imoveis
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "page-property-listing-search" ? "mm-active" : ""
                      }`}
                    to="/page-property-listing-search">
                    Listagem de imoveis
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "page-map-imoveis-search" ? "mm-active" : ""
                      }`}
                    to="/page-map-imoveis-search">
                    Busca de imoveis
                  </Link>
                </li>
              </ul>
            </li>
          ) : null} */}
        </MM>
        <div className="copyright">
          <p className="fs-14 font-w200">
            <strong className="font-w700">Plataforma Leonel</strong>
          </p>
          <p className="fs-14 font-w200">
            <strong className="font-w400">©All Rights Reserved</strong>
          </p>
          <p>by Fairy Code</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
