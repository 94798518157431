import React from "react";
import { Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

// Enum de Property Type
const enumPropertyTypeDescriptions = (key) => {
  switch (key) {
    case "area":
      return "Area";
    case "hangar":
      return "Galpão";
    case "commercial":
      return "Imóvel Comercial";
    case "store":
      return "Loja";
    case "businessAdaptableResidence":
      return "Residência adpatável comércio";
    case "residential":
      return "Residencial";
    case "rurais":
      return "Rurais";
    case "commercialRoom":
      return "Sala Comercial";
    case "walledLand":
      return "Terreno Murado";
    case "ground":
      return "Terreno";
    default:
      return key;
  }
};
Object.freeze(enumPropertyTypeDescriptions);

const ImageDataCard = (props) => {
  const dados = props.dados;

  return (
    <>
      <div className="card pl-5 pr-5 pt-5 pb-5">
        <div className="row ml-1 mb-3 d-flex justify-content-center align-items-center">
          <div className="col-lg-6">
            <div className="row">
              <h3 className="mb-4">{enumPropertyTypeDescriptions(dados?.propertyType)}</h3>
              <h3 className="mb-4 ml-2 mr-2">-</h3>
              <h3 className="mb-4">{dados?.code}</h3>
            </div>
          </div>

          <div className="col-lg-3">
            <button className="btn btn-primary btn-lg ">
              <Link to={`/flyer-details/` + dados?._id} className="text-white">
                Opções do flyer
              </Link>
            </button>
          </div>
          <div className="col-lg-3">
              <button className="btn btn-primary btn-lg">
                <Link to={{
      pathname: "/general-register",
      state: { data: dados, duplicate: false },
    }} className="text-white"s>
                 Editar imóvel
                </Link>
             </button>
            </div>
        </div>

        {/* Tab panes */}
        <div className="row">
          <Tab.Container defaultActiveKey="first">
            <div className="col-md-6">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <img
                    className="img-fluid rounded shadow"
                    src={
                      dados?.plataformMainPicture
                        ? dados.plataformMainPicture
                        : "/semImagem.png"
                    }
                    alt="image"
                    style={{ height: "auto", width: "100%" }}
                  />
                </Tab.Pane>
                {dados &&
                  dados?.pictures?.length > 0 &&
                  dados?.pictures?.map((dado, index) => {
                    return (
                      <Tab.Pane eventKey={index}>
                        <img
                          className="img-fluid rounded shadow"
                          src={dado.image}
                          alt="image"
                        />
                      
                       <h3 className="mt-2">{dado?.description}</h3>
                      </Tab.Pane>
                      
                    );
                  })}
              </Tab.Content>
            </div>
            <div className="col-md-6">
              <div className="tab-slide-content new-arrival-product mb-4 mb-xl-0">
             
                <Nav as="ul" className="nav slide-item-list mt-3" role="tablist">
                  {dados &&
                    dados?.pictures &&
                    dados?.pictures?.map((dado, index) => {
                      return (
                        <Nav.Item as="li">
                          <Nav.Link as="a" eventKey={index} href={"#" + index}>
                            <img
                              className="mb-3 rounded shadow"
                              src={dado.image}
                              alt="image"
                              width={100}
                              height={100}
                            />
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                </Nav>
              </div>
            </div>

           
          </Tab.Container>
        </div>
        {/*Tab slider End*/}
      </div>
    </>
  );
};

export default ImageDataCard;
