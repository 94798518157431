import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Alert from "sweetalert2";
import { getUserList, deleteUsers } from "../../services/apiUser";
import UsefulContext from "./../../../ultis/usefulProvider";
import "../../../css/listagem_usuarios.css";

const UsersListing = ({ history }) => {
      const { userProfiles } = React.useContext(UsefulContext);

      const [users, setUsers] = useState([]);

      useEffect(() => {
            moment.locale("pt-br");
            loadData();
      }, []);

      const loadData = async () => {
            try {
                  const _response = await getUserList();
                  if (_response.data)
                        setUsers(
                              _response.data.sort((a, b) => {
                                    if (a && b) {
                                          if (
                                                moment(a.created).unix() <
                                                moment(b.created).unix()
                                          ) {
                                                return 1;
                                          }
                                          if (
                                                moment(a.created).unix() >
                                                moment(b.created).unix()
                                          ) {
                                                return -1;
                                          }
                                    }
                                    return 0;
                              })
                        );
            } catch (error) {
                  await Alert.fire(error?.response?.data?.message);
            }
      };

      const onEdit = (props) => {
            history.push({
                  pathname: "/users-register",
                  state: { data: props, status: 2 },
            });
      };

      const onVisualize = (props) => {
            history.push({ pathname: "/user-view", state: { data: props } });
      };

      const onResearcher = (props) => {
            history.push({
                  pathname: "/user-action-area",
                  state: { data: props },
            });
      };

      const onDelete = async (data) => {
            try {
                  const _result = await Alert.fire({
                        title: "Atenção",
                        text: "Deseja excluir esse usuário?",
                        confirmButtonText: "Excluir",
                        confirmButtonColor: "red",
                        showCancelButton: true,
                        cancelButtonText: "Cancelar",
                        reverseButtons: true,
                  });
                  if (_result.isConfirmed) {
                        
                        await deleteUsers(data._id, data);
                        loadData();
                  }
            } catch (error) {
                  await Alert.fire(error?.response?.data?.message);
            }
      };

      return (
            <>
                  <div
                        className="modal fade"
                        id="ModalExclusao"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                  >
                        <div className="modal-dialog" role="document">
                              <div className="modal-content text-center">
                                    <div className="modal-header">
                                          <h5
                                                className="modal-title"
                                                id="exampleModalLabel"
                                          >
                                                User list
                                          </h5>
                                          <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                          >
                                                <span aria-hidden="true">
                                                      &times;
                                                </span>
                                          </button>
                                    </div>
                                    <div className="modal-body">
                                          <h4>
                                                Deseja excluir esse usuário
                                                permanentemente?
                                          </h4>
                                    </div>
                                    <div className="modal-footer">
                                          <button
                                                type="button"
                                                className="btn btn-dark btnRadius"
                                                data-dismiss="modal"
                                          >
                                                Cancelar
                                          </button>
                                          <button
                                                type="button"
                                                className="btn btn-danger btnRadius"
                                          >
                                                Deletar
                                          </button>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <div className="container-fluid-users">
                        <div className="form-head page-titles d-flex  align-items-center">
                              <div className="mr-auto  d-lg-block">
                                    <h4 className="text-black font-w600">
                                          Lista de usuários
                                    </h4>
                                    <ol className="breadcrumb">
                                          <li className="breadcrumb-item active">
                                                <Link to="/users-listing">
                                                      Usuários
                                                </Link>
                                          </li>
                                          <li className="breadcrumb-item">
                                                <Link to="/users-listing">
                                                      Users List
                                                </Link>
                                          </li>
                                    </ol>
                              </div>
                              {userProfiles?.addUser && (
                                    <Link
                                          to="/users-register"
                                          style={{
                                                color: "#fff",
                                                backgroundColor: "#144b72",
                                          }}
                                          className="btn btn-primary rounded  mr-3"
                                    >
                                          Cadastrar usuário
                                    </Link>
                              )}
                        </div>
                        <div className="row">
                              <div className="col-xl-6 col-xxl-12"></div>
                              <div className="col-xl-12">
                                    <div className="table-responsive table-hover fs-14">
                                          <div
                                                id="orderList"
                                                className="dataTables_wrapper no-footer"
                                          >
                                                <table className="table table-hover display mb-4 dataTablesCard card-table dataTable no-footer">
                                                      <thead>
                                                            <tr role="row">
                                                                  <th className="sorting">
                                                                        Id
                                                                  </th>
                                                                  <th className="sorting">
                                                                        Nome
                                                                  </th>
                                                                  <th className="sorting">
                                                                        e-mail
                                                                  </th>
                                                                  <th className="sorting">
                                                                        Data de
                                                                        registro
                                                                  </th>
                                                                  <th className="sorting">
                                                                        Perfil
                                                                        (Role)
                                                                  </th>
                                                                  <th className="sorting" />
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            {users.length > 0 &&
                                                                  users.map(
                                                                        (
                                                                              _dados,
                                                                              index
                                                                        ) => {
                                                                              return (
                                                                                    <tr
                                                                                          role="row"
                                                                                          className="odd"
                                                                                          key={
                                                                                                _dados._id
                                                                                          }
                                                                                    >
                                                                                          <td>
                                                                                                {
                                                                                                      ++index
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                {
                                                                                                      _dados.name
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                {
                                                                                                      _dados.email
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                {moment(
                                                                                                      _dados.created
                                                                                                ).format(
                                                                                                      "DD-MM-YYYY"
                                                                                                )}
                                                                                          </td>
                                                                                          <td>
                                                                                                {
                                                                                                      _dados.type
                                                                                                }
                                                                                          </td>
                                                                                          {/* {userProfiles?.viewUserProfile && ( */}
                                                                                          <td>
                                                                                                <button
                                                                                                      onClick={() =>
                                                                                                            onVisualize(
                                                                                                                  _dados
                                                                                                            )
                                                                                                      }
                                                                                                      className="btn btn-primary shadow btn-xs sharp mr-1"
                                                                                                >
                                                                                                      <i className="fa fa-eye"></i>
                                                                                                </button>
                                                                                          </td>
                                                                                          {/* )} */}
                                                                                          {/* {userProfiles?.editUser && ( */}
                                                                                          <td>
                                                                                                <button
                                                                                                      onClick={() =>
                                                                                                            onEdit(
                                                                                                                  _dados
                                                                                                            )
                                                                                                      }
                                                                                                      className="btn btn-warning shadow btn-xs sharp"
                                                                                                >
                                                                                                      <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                          </td>
                                                                                        
                                                                                          
                                                                                          {/* )} */}
                                                                                          {userProfiles?.deleteUser && (
                                                                                                <td>
                                                                                                      <button
                                                                                                            onClick={() =>
                                                                                                                  onDelete(
                                                                                                                        _dados
                                                                                                                  )
                                                                                                            }
                                                                                                            className="btn btn-danger shadow btn-xs sharp"
                                                                                                      >
                                                                                                            <i className="fa fa-trash"></i>
                                                                                                      </button>
                                                                                                </td>
                                                                                          )}


                                                                                          <td>
                                                                                          {_dados.type === 'PESQUISADOR' && (
                                                                                                <button
                                                                                                      onClick={() =>
                                                                                                            onResearcher(
                                                                                                                  _dados
                                                                                                            )
                                                                                                      }
                                                                                                      className="btn btn-dark shadow btn-xs sharp"
                                                                                                >
                                                                                                      <i className="fa fa-map-marker"></i>
                                                                                                </button>
                                                                                                 )}
                                                                                          </td>
                                                                                         
                                                                                    </tr>
                                                                              );
                                                                        }
                                                                  )}
                                                      </tbody>
                                                </table>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default UsersListing;
