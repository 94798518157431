export const enumTypeAgreement = {
      buitToSuit: "BTS",
      location: "location",
      sale: "sale",
      saleAndLease: "saleAndLease",
      saleRented: "saleRented",
      saleLeaseback: "saleLeaseback",


};

export const enumTypeAgreementDescriptions = (key) => {
      switch (key) {
            case "BTS":
                  return "BTS";
            case "location":
                  return "Locação";
            case "sale":
                  return "Venda";
            case "saleAndLease":
                  return "Venda e locação";
            case "saleRented":
                  return "Venda já alugado";
            case "saleLeaseback":
                  return "Sale leaseback";
            default:
                  return key;
      }
};

export const enumTypeAgreementDescriptionsInverter = (key) => {
      switch (key) {
            case "BTS":
                  return "BTS";
            case "Locação":
                  return "location";
            case "Venda":
                  return "sale";
            case "Venda e locação":
                  return "saleAndLease";
            default:
                  return key;
      }
};

Object.freeze(enumTypeAgreement);
