import React, { useState, useEffect } from "react";
import { getRealStatesById } from "./../../services/apiRealState";
import { jsPDF } from "jspdf";
import { useParams } from "react-router";
import html2canvas from "html2canvas";
import leonelIcon from "../../../images/logoLeonelBranco.png";
import imagem_secundaria from "../../../images/imagem_secundaria.png";
import imagem_secundaria2 from "../../../images/imagem_secundaria2.png";
import qrcode from "../../../images/qrcode.png";
import "../../../css/flyer.css";
import { ImWhatsapp } from "react-icons/im";
import LoadingComponent from "../Loading/LoadingComponent";
import Multiselect from 'multiselect-react-dropdown';
import DropzoneIMG from "../Dropzone/DropzoneImg";
import { FirebaseApp } from "../../../firebaseApi";


// Enum de Property Type
const enumPropertyTypeDescriptions = (key) => {
  switch (key) {
    case "area":
      return "Area";
    case "hangar":
      return "Galpão";
    case "commercial":
      return "Imóvel Comercial";
    case "store":
      return "Loja";
    case "businessAdaptableResidence":
      return "Residência adpatável comércio";
    case "residential":
      return "Residencial";
    case "rurais":
      return "Rurais";
    case "commercialRoom":
      return "Sala Comercial";
    case "walledLand":
      return "Terreno Murado";
    case "ground":
      return "Terreno";
    default:
      return key;
  }
};
Object.freeze(enumPropertyTypeDescriptions);

// Enum de tradução de Disponibilidade
const enumStatusAreaDescriptions = (key) => {
  switch (key) {
    case "construction":
      return "Construção";
    case "project":
      return "Projeto";
    case "ready":
      return "Pronto";
    case "development":
      return "Em desenvolvimento";
    default:
      return key;
  }
};
Object.freeze(enumStatusAreaDescriptions);

const enumFalseTrue = (key) => {
  switch (key) {
    case "true":
      return "Sim";
    case "false":
      return "Não";
    case true:
      return "Sim";
    case false:
      return "Não";
    case "yes":
      return "Sim";
    case "no":
      return "Não";
    default:
      return key;
  }
};
Object.freeze(enumFalseTrue);

const FlyerDetails = ({ match }) => {
  const { id } = useParams();

  const [capaImage,setCapaImage] = useState('')
  const genPDF = () => {
    const Component = document.getElementById("flyer");
    html2canvas(Component).then((canvas) => {
      const componentWidth = Component.offsetWidth;
      const componentHeight = Component.offsetHeight;
      const orientation = componentWidth >= componentHeight ? "l" : "p";
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({ orientation, unit: "px" });
      pdf.internal.pageSize.width = componentWidth;
      pdf.internal.pageSize.height = componentHeight;
      pdf.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
      pdf.save("download.pdf");
    });
  };

 

  const replaceCep = (_value) => {
    if (_value != undefined && _value != null) {
      _value = _value.replace(/\D/g, "");
      _value = _value.replace(/^(\d{5})(\d)/, "$1-$2");
      return _value;
    }
  };

  const replaceCoin = (_value) => {
    if (_value != undefined && _value != null) {
      _value = _value.toString();
      if (_value == "0.00") {
        return "0";
      } else {
        _value = _value.replace(/\D/g, "");
        _value = _value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        return _value;
      }
    } else {
      return _value;
    }
  };

  const negative= ['NO','NÃO','NAO','','FALSE']
  // Constante que irá receber os valores vindos do banco de dados
  const [dados, setDados] = useState({});
  const [titleText, setTitleText] = useState();
  const [loading, setLoading] = useState(false);
  const [finance, setFinance] = useState([]);
  const [characteristic, setCharacteristic] = useState([]);
  const [property, setProperty] = useState([]);
  const [location, setLocation] = useState([]);
  const [logo, setLogo] = useState();

  const [financeArray, setFinanceArray] = useState([]);
  const [characteristicArray, setCharacteristicArray] = useState([]);
  const [propertyArray, setPropertyArray] = useState([]);
  const [locationArray, setLocationArray] = useState([]);
  const [totalCostValue, setTotalCostValue] = useState(0);

  const getData = async () => {
    try {
      getRealStatesById(id)
        .then((result) => {

          var dataImovel = result.data;

          if (
            dataImovel.plataformMainPicture == undefined ||
            dataImovel.plataformMainPicture == null
          ) {
            if (dataImovel.pictures.length != 0) {
              // teste.push({ "plataformMainPicture": dataImovel.pictures[0], dataImovel });

              setDados({ ...dataImovel, plataformMainPicture: getBase64FromUrl(getCapaPicture(dataImovel.pictures)) });
              if (dataImovel === <enumPropertyStatus />) {
                setLoading(false);
                return dataImovel;
              }
            } else {
              setDados(dataImovel);
              setCapaImage("/semImagem.png")
              if (dataImovel === <enumPropertyStatus />) {
                setLoading(false);
                return dataImovel;
              }
            }
          } else {
            setDados(dataImovel);
            if (dataImovel === <enumPropertyStatus />) {
              setLoading(false);
              return dataImovel;
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const getBase64FromUrl = async (url) => {
    let urlCapa = url;
    for(let i = 0; i < dados?.length; i++){
      if(dados?.pictures[i].capaPlataforma){
        urlCapa = dados?.pictures[i].image
      }
    }
    const data = await fetch(urlCapa);
    const blob = await data.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
        const base64data = reader.result;  
        setCapaImage(base64data)
        return base64data;
    } 

  }

  const getBase64Logo = async (url) => {
    let urlCapa = url;
    for(let i = 0; i < dados?.length; i++){
      if(dados?.pictures[i].capaPlataforma){
        urlCapa = dados?.pictures[i].image
      }
    }
    const data = await fetch(urlCapa);
    const blob = await data.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
        const base64data = reader.result;  
        setLogo(base64data)
        return base64data;
    } 

  }

  const optionsLocation = [
    {name: 'Cidade', id:'city'},
    {name: 'CEP', id:'cep'},
    {name: 'Estado', id:'state'},
    {name: 'Rua', id:'address'},
    {name: 'Número', id:'number'},
    {name: 'Bairro', id:'district'},

  ]

  const optionsEmpreendimento = [
    {name: 'Área escritório', id:'officeArea'},
    {name: 'Área construída', id:'buildingArea'},
    {name: 'Área galpão', id:'shedArea'},
    {name: 'Área de terreno', id:'landArea'},
    {name: 'Pé direito', id:'rightFoot'},
  ]

  const optionsFinance = [
    {name: 'Valor venda', id:'saleCost'},
    {name: 'Valor aluguel', id:'leaseCost'},
    {name: 'Valor condomínio', id:'condominiumCost'},
    {name: 'Valor IPTU', id:'iptuCost'},
    {name: 'Valor total', id:'totalCost'},
  ]

  const optionsCharacteristic = [
    {name: 'Refeitório', id:'refeitorio'},
    {name: 'Vestiário', id:'vestiario'},
    {name: 'Estacionamento para carretas', id:'park'},
    {name: 'Ponte rolante', id:'ponteRolante'},
    {name: 'Pátio manobra interno', id:'patioIntern'},
    {name: 'Transformador', id:'transformer'},
    {name: 'Docas', id:'docks'},
  ]

  const getCapaPicture = (pictures) => {
    let picture = pictures[0].image
    for(let i = 0; i < pictures.length; i++){
      if(pictures[i].capaPlataform === true){
        picture = pictures[i].image
      }
    }
    return picture
  }

  const returnFile = async (_selectedFile) => {
    try {
      if(_selectedFile) saveFile(_selectedFile[0])
     
    } catch (error) {
      console.log(error);
    }
  };

  const saveFile = (file) => {
    let id = Math.random()
    const uploadTask = FirebaseApp.storage()
    .ref("file/flyer/"+file.name+id)
    .put(file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      // setProgress(progress);
    },
    (error) => {
      console.log(error);
    },
    async () => {
      await FirebaseApp.storage()
        .ref("file/flyer/"+file.name+id)
        .getDownloadURL()
        .then((link) => {
          getBase64Logo(link);
        });
    }
  );
  }

  const totalCostOcupation = () => {
    let totalCost = 0;
    totalCost =  (dados?.iptuValueNumber ?? 0) + (dados?.leaseValueNumber ?? 0) + (dados?.condominiumValueNumber ?? 0);
    setTotalCostValue(totalCost);
  }

  useEffect(() => {
    totalCostOcupation()
  },[dados])

  // Requisição GET para pegar dados de imóveis cadastrados no banco de dados
  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  useEffect(() =>{

    if(location.length){
      let array= [];
      for (const i in location){
        array.push(location[i].id)
      }
      setLocationArray(array)
    }
  },[location])

  useEffect(() =>{

    if(finance.length){
      let array= [];
      for (const i in finance){
        array.push(finance[i].id)
      }
      setFinanceArray(array)
    }
  },[finance])

  useEffect(() =>{

    if(characteristic.length){
      let array= [];
      for (const i in characteristic){
        array.push(characteristic[i].id)
      }
      setCharacteristicArray(array)
    }
  },[characteristic])

  useEffect(() =>{

    if(property.length){
      let array= [];
      for (const i in property){
        array.push(property[i].id)
      }
      setPropertyArray(array)
    }
  },[property])

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
          <div className="container" >
            <div className="card">
              <div className="card-header">
              <h4 className="h2 font-weight-bold card-title">
                  Criar flyer
                </h4>
              </div>
           
              <div className="card-body">
                <p className="card-subtitle mb-2 text-muted">
                 Preencha as informações a serem exibidas
                </p>
                <div className="row mt-4 ml-1">
                  <div className="col-lg-10">
                  <h5 className="fs-14 font-weight-bold card-title" style={{ color: "rgba(46, 102, 145, 1)" }}>Título:</h5>
                  <input
                    className="form-control"
                    onChange={(e) => setTitleText(e.target.value)}
                  />
                  </div>
                  <div className="col-lg-10">
                  <h5 className="fs-14 font-weight-bold card-title" style={{ color: "rgba(46, 102, 145, 1)" }}>Logo:</h5>
                 
                    <div className="col-lg-12">
                  <DropzoneIMG returnFile={returnFile} />
                </div>
                  </div>
                  <div className="col-lg-5 mt-2">
                  <h5 className="fs-14 font-weight-bold card-title" style={{ color: "rgba(46, 102, 145, 1)" }}>Localização:</h5>
                    <Multiselect
                      showArrow={true}
                      showCheckbox={true}
                      placeholder='Selecione'
                      options={optionsLocation} // Options to display in the dropdown
                      selectedValues={location}
                      onRemove={(e) => setLocation(e)} // Function will trigger on select event
                      onSelect={(e) => setLocation(e)} // Function will trigger on select event
                      displayValue="name" // Property name to display in the dropdown options
                    />
                  </div>
                  <div className="col-lg-5 mt-2">
                  <h5 className="fs-14 font-weight-bold card-title" style={{ color: "rgba(46, 102, 145, 1)" }}>Dimensões:</h5>
                    <Multiselect
                      showArrow={true}
                      showCheckbox={true}
                      placeholder='Selecione'
                      options={optionsEmpreendimento} // Options to display in the dropdown
                      selectedValues={property}
                      onSelect={(e) => setProperty(e)} 
                      onRemove={(e) => setProperty(e)} // Function will trigger on select event
                      displayValue="name" // Property name to display in the dropdown options
                    />
                  </div>
                  <div className="col-lg-5 mt-2">
                  <h5 className="fs-14 font-weight-bold card-title" style={{ color: "rgba(46, 102, 145, 1)" }}>Características:</h5>
                    <Multiselect
                      showArrow={true}
                      showCheckbox={true}
                      placeholder='Selecione'
                      options={optionsCharacteristic} // Options to display in the dropdown
                      selectedValues={characteristic}
                      onSelect={(e) => setCharacteristic(e)} 
                      onRemove={(e) => setCharacteristic(e)} 
                      displayValue="name" // Property name to display in the dropdown options
                    />
                  </div>
                  <div className="col-lg-5 mt-2">
                  <h5 className="fs-14 font-weight-bold card-title" style={{ color: "rgba(46, 102, 145, 1)" }}>Condições comerciais:</h5>
                    <Multiselect
                      showArrow={true}
                      showCheckbox={true}
                      placeholder='Selecione'
                      options={optionsFinance} // Options to display in the dropdown
                      selectedValues={finance}
                      onSelect={(e) => setFinance(e)} 
                      onRemove={(e) => setFinance(e)} 
                      displayValue="name" // Property name to display in the dropdown options
                    />
                  </div>
                </div>
                
                <div className="text-right row">
                  <div className="col-lg-12">
                    <button
                      type="submit"
                      onClick={genPDF}
                      className="btn btn-primary borderRadius  mt-3"
                      style={{ borderRadius: "6px" }}
                    >
                      {" "}
                      Baixar flyer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{justifyContent:'center'}}>
            <div className="flyer p-5" id="flyer">
              <div className="row">
                <div className="col-md-3">
                  <img src={logo} width={150}></img>
                </div>
                <div className="col-md-12 text-center mt-4">
                  <h2 className="ml-0" style={{ color: "white" }}>
                    {titleText}
                  </h2>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12 text-center font-weight-bold">
                  <span id="">
                    {enumPropertyTypeDescriptions(dados?.propertyType ?? "")} {dados?.city && dados?.propertyType ? '|' : ''} {dados?.city ?? ""}{" "}
                    {dados?.city && dados?.state ? '-' : ''}
                    {dados?.state ?? ""}
                  </span>{" "}
                  {dados?.state && dados?.code ? '|' : ''}

                  {dados?.code !== null ? <span id="codigo"> {dados?.code}</span> : null}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-12 text-center">
                  <img
                    src={capaImage ??  "/semImagem.png"}
                    style={{width:'600px', height:'320px', borderRadius:'5px'}}
                  ></img>
                </div>
              </div>
              <div className="row mt-5" style={{justifyContent:'center'}}>
                {location?.length ? property?.length? 
                (  
                  <>
                  <div className="col-lg-6">
                    <div className="row">
                    <div className="col-lg-11 m-1 mr-5 text-white p-1 text-center" style={{borderRadius:'5px', background:'rgba(46, 102, 145, 0.8)'}}>
                      Localização
                    </div>
                      <div className="col-lg-11 m-1">
                        <ul>
                          <li> {locationArray?.includes('city') ? dados?.city : null} 
                          {locationArray?.includes('city') && locationArray?.includes('state') ? ' - ' : ''}
                           {locationArray?.includes('state') ? dados?.state : null}</li>
                          <li>{locationArray?.includes('address') ? dados?.address : null}
                          {locationArray?.includes('address') && locationArray?.includes('number') ? ', ' : ''}
                           {locationArray?.includes('number') ? dados?.number : null} 
                           {locationArray?.includes('number') && locationArray?.includes('district') ? ' | ' : ''}
                            {locationArray?.includes('district') ? dados?.district : null} 
                            {locationArray?.includes('district') && locationArray?.includes('cep') ? ' - ' : ''}
                             {locationArray?.includes('cep') ? dados?.zipCode : null}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-11 m-1  text-white p-1 text-center" style={{borderRadius:'5px', background:'rgba(46, 102, 145, 0.8)'}}>
                      Dimensões
                    </div>
                      <div className="col-lg-11 m-1">
                         <ul>
                            <li>
                             {propertyArray?.includes('buildingArea') && dados?.buildingAreaNumber && dados?.buildingAreaNumber !==0 ? <>Área construida: {dados?.buildingAreaNumber} m²</>: null}  
                            </li>
                            <li>
                             {propertyArray?.includes('landArea') && dados?.landAreaNumber && dados?.landAreaNumber !==0  ? <>Área terreno: {dados?.landAreaNumber} m²</>: null}  
                            </li>
                            <li>
                             {propertyArray?.includes('shedArea') && dados?.shedAreaNumber && dados?.shedAreaNumber !==0 ? <>Área galpão: {dados?.shedAreaNumber} m²</>: null}  
                            </li>
                            <li>
                             {propertyArray?.includes('shedArea') && dados?.officeAreaNumber && dados?.officeAreaNumber !==0 ? <>Área escritório: {dados?.officeAreaNumber} m²</>: null}  
                            </li>
                            <li>
                             {propertyArray?.includes('rightFoot') && dados?.rightFootNumber && dados?.rightFootNumber !==0 ? <>Pé direito: {dados?.rightFootNumber} m</>: null}  
                            </li>
                         </ul>
                      </div>
                    </div>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-11 m-1  text-white p-1 text-center" style={{borderRadius:'5px', background:'rgba(46, 102, 145, 0.8)'}}>
                        Localização
                      </div>
                      <div className="col-lg-11 m-1">
                       <ul>
                          <li> {locationArray?.includes('city') && dados?.city ? dados?.city : null}  {locationArray?.includes('state') && dados?.state ? dados?.state : null}</li>
                          <li>{locationArray?.includes('address') && dados?.address ? dados?.address : null}  {locationArray?.includes('number') && dados?.number ? dados?.number : null}  {locationArray?.includes('district') ? dados?.district : null}  {locationArray?.includes('cep') ? dados?.zipCode : null}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                   
                ) : property?.length ? (

                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-11 m-1  text-white p-1 text-center" style={{borderRadius:'5px', background:'rgba(46, 102, 145, 0.8)'}}>
                        Dimensões
                      </div>
                      <div className="col-lg-11 m-1">
                        <ul>
                          <li>
                            {propertyArray?.includes('buildingArea') && dados?.buildingAreaNumber && dados?.buildingAreaNumber !== 0 ? <>Área construida: {dados?.buildingAreaNumber} m²</>: null}  
                          </li>
                          <li>
                            {propertyArray?.includes('landArea') && dados?.landAreaNumber && dados?.landAreaNumber !== 0 ? <>Área terreno: {dados?.landAreaNumber} m²</>: null}  
                          </li>
                          <li>
                            {propertyArray?.includes('shedArea') && dados?.shedAreaNumber && dados?.shedAreaNumber !== 0? <>Área galpão: {dados?.shedAreaNumber} m²</>: null}  
                          </li>
                          <li>
                            {propertyArray?.includes('shedArea') && dados?.officeAreaNumber && dados?.officeAreaNumber !== 0? <>Área escritório: {dados?.officeAreaNumber} m²</>: null}  
                          </li>
                          <li>
                            {propertyArray?.includes('rightFoot') && dados?.rightFootNumber && dados?.rightFootNumber !== 0? <>Pé direito: {dados?.rightFootNumber}m</>: null}  
                          </li>
                        </ul>
                      </div>
                  </div>
               </div>
                ) : null}
               
              </div>
              <div className="row mt-3" style={{justifyContent:'center'}}>

              {characteristic.length ? finance.length? 
                (  
                  <>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-11 m-1 mr-5 text-white p-1 text-center" style={{borderRadius:'5px', background:'rgba(46, 102, 145, 0.8)'}}>
                          Características da propriedade
                        </div>
                        <div className="col-lg-11 m-1">
                        <ul>
                          <li>
                            {characteristicArray?.includes('refeitorio') && dados?.refectory && !negative.includes(dados?.refectory?.toUpperCase()) ? <>Refeitório </>: null}  
                          </li>
                          <li>
                            {characteristicArray?.includes('vestiario') && dados?.dressingRoom && !negative.includes(dados?.dressingRoom?.toUpperCase()) ? <>Vestiário </>: null}  
                          </li>
                          <li>
                            {characteristicArray?.includes('park') && dados?.carParking && !negative.includes(dados?.carParking?.toUpperCase()) ? <>Estacionamento para carretas </>: null}  
                          </li>
                          <li>
                            {characteristicArray?.includes('ponteRolante')  && dados?.overheadCrane && !negative.includes(dados?.overheadCrane?.toUpperCase()) ? <>Ponte rolante </>: null}  
                          </li>
                          <li>
                            {characteristicArray?.includes('patioIntern') && dados?.internalManeuverYard && !negative.includes(dados?.internalManeuverYard?.toUpperCase()) ? <>Pátio de manobra interno </>: null}  
                          </li>
                          <li>
                            {characteristicArray?.includes('transformer') && dados?.transformer && !negative.includes(dados?.transformer?.toUpperCase()) ? <>Transformador </>: null}  
                          </li>
                          <li>
                          {characteristicArray?.includes('docks') && dados?.docks && dados?.numberOfDocksNumber !== 0  ? <>Docas: {dados?.numberOfDocksNumber}</>: null}  
                          </li>
                        </ul>
                      </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-11 m-1  text-white p-1 text-center" style={{borderRadius:'5px', background:'rgba(46, 102, 145, 0.8)'}}>
                          Condições comerciais
                        </div>
                        <div className="col-lg-11 m-1">
                        <ul>
                          <li>
                            {financeArray?.includes('saleCost') && dados?.saleValueNumber && dados?.saleValueNumber !== 0 ? <>Valor venda: {dados?.saleValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </>: null}  
                          </li>
                          <li>
                            {financeArray?.includes('leaseCost') && dados?.leaseValueNumber && dados?.leaseValueNumber !== 0 ? <>Valor aluguel: {dados?.leaseValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</>: null}  
                          </li>
                          <li>
                            {financeArray?.includes('condominiumCost') && dados?.condominiumValueNumber && dados?.condominiumValueNumber !== 0 ? <>Valor condomínio: {dados?.condominiumValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </>: null}  
                          </li>
                          <li>
                            {financeArray?.includes('iptuCost') && dados?.iptuValueNumber && dados?.iptuValueNumber !== 0 ? <>Valor IPTU: {dados?.iptuValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </>: null}  
                          </li>
                          <li>
                            {financeArray?.includes('totalCost') && totalCostValue && totalCostValue !== 0 ? <>Valor total: {totalCostValue?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </>: null}  
                          </li>
                        </ul>
                      </div>
                      </div>
                    </div>
                    
                  </>
                ) : (

                 <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-11 m-1 mr-5 text-white p-1 text-center" style={{borderRadius:'5px', background:'rgba(46, 102, 145, 0.8)'}}>
                          Características da propriedade
                        </div>
                        <div className="col-lg-11 m-1">
                        <ul>
                          <li>
                            {characteristicArray?.includes('refeitorio') && dados?.refectory  && !negative.includes(dados?.refectory?.toUpperCase()) ? <>Refeitório </>: null}  
                          </li>
                          <li>
                            {characteristicArray?.includes('vestiario') && dados?.dressingRoom && !negative.includes(dados?.dressingRoom?.toUpperCase()) ? <>Vestiário </>: null}  
                          </li>
                          <li>
                            {characteristicArray?.includes('park') && dados?.carParking && !negative.includes(dados?.carParking?.toUpperCase()) ? <>Estacionamento para carretas </>: null}  
                          </li>
                          <li>
                            {characteristicArray?.includes('ponteRolante') && dados?.overheadCrane && !negative.includes(dados?.overheadCrane?.toUpperCase()) ? <>Ponte rolante </>: null}  
                          </li>
                          <li>
                            {characteristicArray?.includes('patioIntern') && dados?.internalManeuverYard && !negative.includes(dados?.internalManeuverYard?.toUpperCase()) ? <>Pátio de manobra interno </>: null}  
                          </li>
                          <li>
                            {characteristicArray?.includes('transformer') && dados?.transformer && !negative.includes(dados?.transformer?.toUpperCase()) ? <>Transformador </>: null}  
                          </li>
                          <li>
                          {characteristicArray?.includes('docks') && dados?.docks && dados?.numberOfDocksNumber !== 0  ? <>Docas: {dados?.numberOfDocksNumber}</>: null}  
                          </li>
                        </ul>
                      </div>
                      </div>
                    </div>
                ) : finance.length ? (
                  <div className="col-lg-6">
                      <div className="row">
                      <div className="col-lg-11 m-1  text-white p-1 text-center" style={{borderRadius:'5px', background:'rgba(46, 102, 145, 0.8)'}}>
                        Condições comerciais
                      </div>
                        <div className="col-lg-11 m-1">
                        <ul>
                          <li>
                            {financeArray?.includes('saleCost') && dados?.saleValueNumber && dados?.saleValueNumber !== 0 ? <>Valor venda: {dados?.saleValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </>: null}  
                          </li>
                          <li>
                            {financeArray?.includes('leaseCost') && dados?.leaseValueNumber && dados?.leaseValueNumber !== 0 ? <>Valor aluguel: {dados?.leaseValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</>: null}  
                          </li>
                          <li>
                            {financeArray?.includes('condominiumCost') && dados?.condominiumValueNumber && dados?.condominiumValueNumber !== 0 ? <>Valor condomínio: {dados?.condominiumValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </>: null}  
                          </li>
                          <li>
                            {financeArray?.includes('iptuCost') && dados?.iptuValueNumber && dados?.iptuValueNumber !== 0 ? <>Valor IPTU: {dados?.iptuValueNumber?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </>: null}  
                          </li>
                          <li>
                            {financeArray?.includes('totalCost') && totalCostValue && totalCostValue !== 0 ? <>Valor total: {totalCostValue?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </>: null}  
                          </li>
                        </ul>
                      </div>
                      </div>
                    </div>
                 
                ) : null}
              </div>
              
              <div className="row mt-1">
                <div className="col-lg-12">
                <h4 className="mt-4 fs-22 font-weight-bold" style={{ color: "rgba(46, 102, 145, 0.8)" }}>
                    Fale conosco:
                  </h4>
                </div>
                <div className="col-lg-12" >
                  <div className="row" style={{justifyContent:'center'}}>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-1">
                            <i
                              className="fas fa-phone mt-2"
                              style={{ color: "#144b72", fontSize:'22px' }}
                            ></i>
                        </div>
                        <div className="col-lg-10">
                        <p className="fs-12 m-0">(16) 3516-0202</p>
                          <p className="fs-12 m-0">
                            (16) 99153-8844 <ImWhatsapp className=" mb-1" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-1">
                            <i
                              className="fas fa-map-marker-alt mt-2"
                              style={{ color: "#144b72", fontSize:'22px' }}
                            ></i>
                        </div>
                        <div className="col-lg-10">
                        <p className="m-0 fs-12">Rua João Penteado, 157</p>
                          <p className="fs-12 mt-1">Boulevard - Ribeirão Preto, SP</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-1">
                            <i
                              className="fas fa-comment-alt mt-2"
                              style={{ color: "#144b72", fontSize:'22px' }}
                            ></i>
                        </div>
                        <div className="col-lg-10">
                        <p className="m-0 fs-12">www.leonel.com.br</p>
                          <p className="fs-12 mt-1">contato@leonel.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
          </div>
            </div>
            
          </div>
      )}
    </>
  );
};

export default FlyerDetails;
