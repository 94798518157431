export const enumPropertyStatus = {
      new: "new",
      wellMaintained: "wellMaintained",
      preserved: "preserved",
      littleDepreciated: "littleDepreciated",
      veryDepreciated: "veryDepreciated",
      demolition: "demolition",
      area: "area",
};

export const enumPropertyStatusDescriptions = (key) => {
      switch (key) {
            case "new":
                  return "Novo";
            case "wellMaintained":
                  return "Bem conservado";
            case "preserved":
                  return "Conservado";
            case "littleDepreciated":
                  return "Pouco depreciado";
            case "veryDepreciated":
                  return "Muito depreciado";
            case "demolition":
                  return "Para demolição";
            case "area":
                  return "Area/Terreno";
            default:
                  return key;
      }
};

export const enumPropertyStatusDescriptionsInverter = (key) => {
      switch (key) {
            case "Novo":
                  return "new";
            case "Conservado":
                  return "preserved";
            case "Pouco depreciado":
                  return "littleDepreciated";
            case "Muito depreciado":
                  return "veryDepreciated";
            case "Para demolição":
                  return "Para demolition";
            case "Area/Terreno":
                  return "area";
            default:
                  return key;
      }
};

Object.freeze(enumPropertyStatus);
